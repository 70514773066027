import React, { ChangeEvent, Fragment } from 'react';
import TimeField from 'react-simple-timefield';
import { StaffRosterForm as StaffRosterFormClass } from '../../../../app/models/StaffRoster';
import { ITaskType } from '../../../../app/models/Task';
import moment from 'moment';
import { UserSearch, YesNoRadio } from '../../../common/inputs';
import { FormControl, Row, InputGroup } from 'react-bootstrap';
import { Form, FieldGroup, UserDetailsBox } from '../../../common/uiElements';
import { PlacesList, TaskTypesList, RunTypesList } from '../../../common/lists';
import { IListItem } from '../../../../app/models/List';

interface IProps extends StaffRosterFormClass {
	formId?: string;
	isEditMode?: boolean; // disable the fields that can't be changed in the roster
	isReadOnly?: boolean;
	isDateReadOnly?: boolean;
	isStartingPlaceReadOnly?: boolean;
	isWorkerReadOnly?: boolean;
	isTaskTypeReadOnly?: boolean;
	onChangeInput: (id: string, value: string | boolean) => void;
	onChangeStartingPlace: (startingDepotId: string, startingDepotName: string) => void;
	onChangeWorker: (worker?: IListItem) => void;
	onChangeTaskType: (e: any, TaskType?: ITaskType) => void;
	onChangeStartTime: (startTime: string) => void;
}

const validations = {
	date: 'required',
	workerId: 'required',
	startingDepotId: 'required',
	mainTaskTypeId: 'required',
	startTime: 'required',
	budgetedTime: 'required'
};

function StaffRosterForm(props: IProps) {
	const handleChangeTaskType = (e: any, text?: string, TaskType?: ITaskType) => {
		props.onChangeTaskType(e, TaskType);
	};

	const handleChangeStartingPlace = (e: ChangeEvent<FormControl & HTMLInputElement>, startingDepotName: string) => {
		props.onChangeStartingPlace(e.target.value, startingDepotName);
	}

	const handleChangeInput = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		const { id, value, type, checked } = e.target;
		props.onChangeInput(id, type === 'checkbox' ? checked : value);
	};

	const startDateTime = props.date + ' ' + props.startTime;
	return (
		<Form id={props.formId} validations={validations}>
			<Row>
				{
					(props.isDateReadOnly || props.isReadOnly || props.isEditMode) && (
						<FieldGroup label="DoW" sm={2}>
							<p>{ moment(props.date).isValid() ? moment(props.date).format('dddd') : '-' }</p>
						</FieldGroup>
					)
				}
				<FieldGroup label="Date" sm={props.isReadOnly || props.isEditMode ? 4 : 6} required>
					{
						props.isDateReadOnly || props.isReadOnly || props.isEditMode ? (
							<p>{ moment(props.date).format('DD/MM/YYYY') }</p>
						) : (
							<InputGroup>
								<InputGroup.Addon>
									{ moment(props.date).isValid() ? moment(props.date).format('dddd') : '-' }
								</InputGroup.Addon>
								<FormControl
									id="date"
									type="date"
									value={props.date || ''}
									onChange={handleChangeInput}
								/>
							</InputGroup>
						)
					}
				</FieldGroup>
			</Row>
			{
				props.date && (
					<Fragment>
						{
							!props.isWorkerReadOnly && !props.isReadOnly && !props.isEditMode && (
								<FieldGroup label="Employee" required>
									<UserSearch
										showLicenceClass
										id="workerId"
										containerId="workerId_container"
										value={props.workerId}
										onChange={props.onChangeWorker}
									/>
								</FieldGroup>
							)
						}
						{ props.workerId && <UserDetailsBox azureId={props.workerId} workerData={props.Worker} /> }
						<Row>
							<FieldGroup label="Main Task" sm={6} required>
								{
									props.isTaskTypeReadOnly || props.isReadOnly || props.isEditMode ? (
										<p>{ props.mainTaskTypeName || '-' }</p>
									) : (
										<TaskTypesList 
											id="mainTaskTypeId"
											value={props.mainTaskTypeId}
											onChange={handleChangeTaskType}
										/>
									)
								}
							</FieldGroup>
							<FieldGroup label="Starting Place" sm={6} required>
								{
									props.isStartingPlaceReadOnly || props.isReadOnly ? (
										<p>{ props.startingDepotName || '-' }</p>
									) : (
										<PlacesList
											id="startingDepotId"
											type="rosterStartingDepot"
											value={props.startingDepotId || ''}
											onChange={handleChangeStartingPlace}
										/>
									)
								}
							</FieldGroup>
						</Row>
						<Row>
							<FieldGroup label="Start Time" sm={4} required>
								{
									props.isReadOnly ? (
									<p>{ props.startTime }</p>
									) : (
										<TimeField
											width={4}
											value={props.startTime || '__:__'} 
											input={<FormControl id="startTime" />} 
											onChange={props.onChangeStartTime}
										/>
									)
								}
							</FieldGroup>
							<FieldGroup label="Ordinary Hours" sm={4} required>
								{
									props.isReadOnly ? (
										<p>{ props.budgetedTime } h</p>
									) : (
										<InputGroup>
											<FormControl
												id="budgetedTime"
												type="number"
												max={20}
												min={0.5}
												value={props.budgetedTime || ''}
												onChange={handleChangeInput}
											/>
											<InputGroup.Addon>
												HOURS
											</InputGroup.Addon>
										</InputGroup>
									)
								}
							</FieldGroup>
							<FieldGroup label="Estimated Finish Time" sm={4}>
								<p>
									{ moment(startDateTime).isValid() && props.budgetedTime ? moment(startDateTime).add(props.budgetedTime, 'hours').format('HH:mm') : '-' }
								</p>
							</FieldGroup>
						</Row>
						<Row>
							<FieldGroup label="Is Overtime?" sm={4}>
								{
									props.isReadOnly ? (
										props.isOvertime ? 'YES' : 'NO'
									) : (
										<YesNoRadio 
											value={props.isOvertime || false}
											onChange={(e: any) => props.onChangeInput('isOvertime', e.target.value)} 
										/>
									)
								}
							</FieldGroup>
							{
								props.TaskType?.isDriversLicenceRequired && (
									<FieldGroup label="Main Run Type" sm={4}>
										{
											props.isReadOnly ? (
												<p>{ props.runTypeName || '-' }</p>
											) : (
												<RunTypesList
													id="runTypeId"
													taskTypeId={props.mainTaskTypeId}
													placeholder="NONE"
													value={props.runTypeId || ''}
													onChange={handleChangeInput}
												/>
											)
										}
									</FieldGroup>
								)
							}
						</Row>
					</Fragment>
				)
			}
		</Form>
	);
}

export default StaffRosterForm;