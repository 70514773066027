/* eslint-disable eqeqeq */

import React from 'react';
import PalletJackBox from './PalletJackBox';
import { IPalletJackLiveMonitoring } from '../../../../app/models/Panel';

interface IProps {
	palletJacksList: IPalletJackLiveMonitoring[];
	selectedDepotId: string;
	onClickBox: (fleetNumber: string) => void;
}

const PalletJackBoxList: React.FC<IProps> = props => (
	<div className="panel-live-monitoring-container">
		{
			props.palletJacksList.map(palletJack => (
				<div key={palletJack.fleetNumber}>
					<PalletJackBox 
						isSimplified={palletJack.depotId?.toString() !== props.selectedDepotId}
						data={palletJack}
						onClick={props.onClickBox} 
					/>
				</div>
			))
		}
	</div>
);

export default PalletJackBoxList;
