export default {
	absenceReasons: '/List/AbsenceReason',
	australianStates: '/List/AustralianState',
	azurePermissionGroups: '/List/AzurePermissionGroup',
	commodityTypes: '/List/CommodityType',
	commodityTypesLinksWithCategoryId: (placeCategoryId: number) => `/List/CommodityTypesLink/${placeCategoryId}`,
	companies: '/List/Company',
	companyDocTypes: '/List/CompanyDocumentType',
	runTypes: '/List/RunType',
	curfewTypes: '/List/CurfewType',
	accessPointTypes: '/List/PlaceAccessType',
	faultReportTypes: '/List/FaultReportType',
	faultReportAuthorisedBy: '/List/FaultReportAuthorisedBy',
	faultReportActionBy: '/List/FaultReportActionBy',
	faultReportClosedOffBy: '/List/FaultReportClosedOffBy',
	nonConformanceRootCauses: '/List/NonConformanceRootCause',
	nonConformanceTypes: '/List/NonConformanceType',
	palletJackLiveMonitoringDepots: '/List/PalletJackLiveMonitoringDepot',
	placeCategories: '/List/PlaceCategory',
	placeSubCategories: '/List/PlaceSubCategory',
	placeByRunType: '/List/PlaceByRunType',
	placeSubCategoriesWithCategoryId: (placeCategoryId: number) => `/List/PlaceSubCategory/${placeCategoryId}`,
	preStartDepots: '/List/PreStartDepot',
	preStartSupervisors: '/List/PreStartSupervisor',
	rosterStartingDepots: '/List/RosterStartingDepot',
	rosterUpdateReasons: '/List/RosterUpdateReason',
	runs: '/List/Run',
	taskUpdateReasonCategories: '/List/TaskUpdateReasonCategory',
	taskUpdateReasons: '/List/TaskUpdateReason',
	superFundCompanies: '/List/SuperFundCompany',
	taskTypes: '/List/TaskType',
	workers: '/List/Worker',
	workersForTask: '/List/WorkerForTask',
	workerDocTypes: '/List/WorkerDocumentType',
	workerStatusLeftReasons: '/List/WorkerStatusLeftReason',
	workerStatusRoleNames: '/List/WorkerStatusRoleName',
	vehicles: '/List/Vehicle',
	vehiclesForTask: '/List/VehicleForTask',
	vehicleMakes: '/List/VehicleMake',
	vehicleTypes: '/List/VehicleType',
	vehicleTrackingSettings: '/List/VehicleTrackingSetting'
};