/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import isMobile from 'ismobilejs';
import 'js-marker-clusterer'; 

// Components
import InfoWindowContent from './InfoWindowContent';


// Global Settings
var settings_mapIconTrailer             = '/fullmap/layouts/layout/micway/mapIcons/trailer.png';
var settings_mapIconBDouble             = '/fullmap/layouts/layout/micway/mapIcons/bDouble.png';
var settings_mapIconPrimeMover          = '/fullmap/layouts/layout/micway/mapIcons/primeMover.png';
//var settings_mapIconPrimeMoverEngineOn  = '/fullmap/layouts/layout/micway/mapIcons/primeMover_EngineOn.png';
var settings_mapIconRigid               = '/fullmap/layouts/layout/micway/mapIcons/rigid.png';
//var settings_mapIconRigidEngineOn       = '/fullmap/layouts/layout/micway/mapIcons/rigid_EngineOn.png';
var settings_mapIconPalletJack          = '/fullmap/layouts/layout/micway/mapIcons/palletJack.png';
// var settings_mapIconPalletJackBattOk    = '/fullmap/layouts/layout/micway/mapIcons/palletJackBattOk.png';
// var settings_mapIconPalletJackBattLow   = '/fullmap/layouts/layout/micway/mapIcons/palletJackBattLow.png';
// var settings_mapIconPalletJackCharging  = '/fullmap/layouts/layout/micway/mapIcons/palletJackCharging.png';
// var settings_mapIconSearchMarker        = '/fullmap/layouts/layout/micway/mapIcons/searchMarker.png';
//var settings_mapIconArrow               = '/fullmap/layouts/layout/micway/mapIcons/arrowMapMarker.png';
var settings_mapClusterMaxZoom          = 16;
// var settings_mapSearchVehicleZoom       = 17;
// var settings_mapSearchPlaceZoom         = 17;
var settings_mapClusterGridSize         = 60;
// var settings_ajaxTimeout                = 12000;
// var settings_ajaxPostMaxBattCharges     = 6;
// var settings_maxVoltageToConsiderAsZero = 3;

//Global Vars
// var autocompleteSearchPlace             = null; // Auto complete used by Google Place
// var autocompleteSearchVehicle           = null; // Auto complete used to search the vehicle
// var autocompleteSearchPlaceListener     = null; // Listener used by the Google Place auto complete
var breadcrumbsData                     = [];
var markersData                         = [];
// var mapInfowindowContent                = [];
var mapMarkerData                       = [];   // Marker array with all vehicles
var mapMarkerBreadcrumbs                = [];
// var mapMarkerDataAutoComplete           = [];   // Marker array used to show the icon once the user select one address from the auto complete field
var mapMarkerClusterer                  = null; // Marker array with the map cluster
// var selectedVehicleDrawer               = null; // If a vehicle has been selected, this var will be used by Json function to reload the info each refresh
// var timer_screenRefreshTimeout          = null; // Timeout to refresh the data
// var trafficLayer                        = null; // Global var with the Google Traffic Layer

// var circleDrawed = null;
var infowindow = null;
var map = null;
// var marker = null;
// var searchBox = null;

// Micway Position
var defaultLat = -33.7555692;
var defaultLng = 150.90469859999996;

class GoogleMap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false
		};  

		this.geocoder = null;
	}
  
	componentDidMount() {
		this.initMap();

		window.$('.map-control').each(function() {
			var element = window.$(this)[0];
			var cssClasses = window.$(element).attr('class');
			if (cssClasses.indexOf('top-left-control') !== -1)
				map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(element);
			else if (cssClasses.indexOf('left-top-control') !== -1)
				map.controls[window.google.maps.ControlPosition.LEFT_TOP].push(element);
		});

		// Fix map bug when change tabs
		var mapElement = this.mapElement;
		var tabID = window.$(mapElement).parents('div.tab-pane').attr('id');
		if (tabID && tabID !== undefined) {
			window.$(mapElement).parents().find('ul.nav-tabs a[href="#'+ tabID +'"]').on('shown.bs.tab', function() {
				var center = map.getCenter();
				window.google.maps.event.trigger(map, 'resize');
				map.setCenter(center);
			});
		}
    

		this.setState({ isLoaded: true });
		infowindow = new window.google.maps.InfoWindow({ maxWidth: 250 });
	}

	initMap() {
		this.geocoder = new window.google.maps.Geocoder();
		var {
			lat           = defaultLat, // Latitude
			lng           = defaultLng, // longitude
			trafficLayer  = true,       // Show the Traffic Layer
			typeControl   = false       // Enable / Disable the buttons like Satellite & Map
		} = this.props;
    
		var {
			zoom
		} = this.props.map;

		var mapElement = this.mapElement;
		map = window.map = new window.google.maps.Map(mapElement, {
			center: { lat: lat, lng: lng },
			gestureHandling: isMobile.any ? 'cooperative' : 'greedy',
			mapTypeControl: typeControl,
			zoom:   zoom,

			// Set the Zoom option to the left bottom
			zoomControlOptions: {
				position: window.google.maps.ControlPosition.RIGHT_BOTTOM
			},

			// Set the Street view options to the left
			streetViewControlOptions: {
				position: window.google.maps.ControlPosition.RIGHT_BOTTOM
			}
		});

		// Enable the traffic layer
		if (trafficLayer) {
			trafficLayer = new window.google.maps.TrafficLayer();
			trafficLayer.setMap(map);
		}
	}

	clearBreadcrumbs = () => {
		mapMarkerBreadcrumbs.forEach(function arrayElements(element) {
			element.setMap(null);
		});

		mapMarkerBreadcrumbs = [];
	}
	
	clearMarkers = () => {
		mapMarkerData.forEach(function arrayElements(element) {
			element.setMap(null);
		});

		mapMarkerData = [];
	}

	clearMarkerCluster = () => {
		if (mapMarkerClusterer) {
			mapMarkerClusterer.clearMarkers();
		}
	}

	createInfoWindow = data => {
		if (data && data !== undefined) {
			var {
				trkCollectedOnEpoch,
				trkIsDoorOpen,
				trkIsEngineOn,
				trkIsFreezer,
				trksptRoadSpeedLimit,
				trkSpeedKm,
				trkTempZone1,
				trkTempZone2,
				trkTempZone3,
				trkVehicleBattVolt
			} = data;
			var content = document.createElement('div');
			ReactDOM.render(<InfoWindowContent 
				batteryVoltage={trkVehicleBattVolt} 
				isDoorOpen={trkIsDoorOpen} 
				isEngineOn={trkIsEngineOn} 
				isFreezerOn={trkIsFreezer}
				pingTime={trkCollectedOnEpoch}
				roadSpeedLimit={trksptRoadSpeedLimit}
				speed={trkSpeedKm}
				tempZone1={trkTempZone1}
				tempZone2={trkTempZone2}
				tempZone3={trkTempZone3}
			/>, content);

			return content;
		} else {
			return '';
		}    
	}

	drawBreadcrumbs = data => {
		var self = this;

		if (mapMarkerBreadcrumbs.length > 0)
			this.clearBreadcrumbs();

		if (typeof data != 'undefined') {
			breadcrumbsData = data;
			// Load each point from the json and add to the map
			for (var index in data) {
				// Check if the lat & long is not invalid
				if (data[index] && data[index].trkPositionLat !== null && data[index].trkPositionLong !== null && data[index].trkPositionLat !== 0 && data[index].trkPositionLong !== 0) {

					// The color of the point is based on the speed or if the truck is parked
					var iconColor = 'black';

					if (data[index].trkIsEngineOn === false) {
						iconColor = 'black';
					} else {
						if (data[index].trksptIsOverSpeedLimit) {
							iconColor = '#A30B00';
						}
						else if (data[index].trkSpeedKm <= 20) {
							iconColor = '#0C5200';
						}
						else if (data[index].trkSpeedKm <= 50) {
							iconColor = '#0091B2';
						}
						else if (data[index].trkSpeedKm <= 80) {
							iconColor = '#0064B2';
						}
						else if (data[index].trkSpeedKm <= 95) {
							iconColor = '#0046B2';
						}
						else {
							iconColor = '#A30B00';
						} 
					}

					// Create the icon
					mapMarkerBreadcrumbs[index] = new window.google.maps.Marker({
						icon:       {
							path:           window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
							scale:          1.8,
							strokeColor:    iconColor,
							rotation: parseInt(data[index].trkGpsHeading, 10)
						},
						infowindow: infowindow,
						map:        map,
						position: {
							lat: data[index].trkPositionLat, 
							lng: data[index].trkPositionLong
						},
						zIndex:     parseInt(index, 10)
					});

				
					// eslint-disable-next-line 
					window.google.maps.event.addListener(mapMarkerBreadcrumbs[index], 'click', function() {
						infowindow && infowindow.close();

						var i = this.zIndex;
						var infowindowContent = self.createInfoWindow(breadcrumbsData[i]);  

						infowindow.setContent(infowindowContent);
						this.infowindow.open(map, this);
					});
				}
			}

			this.fitBounds(mapMarkerBreadcrumbs);
		}
	}

	drawMarkersWithVehicleIcon = data => {
		var self = this;

		if (typeof data != 'undefined') {
			var totalVehicles = 0;
			this.clearMarkers();
			this.clearMarkerCluster();

			for (var index in data) {
				markersData = data;
				var mapIconUrl      = 'https://maps.google.com/mapfiles/ms/micons/red-dot.png';
				var mapIconSize     = new window.google.maps.Size(35, 35);

				// Pallet Jack Icon
				if (data[index].vehTypeID === 1) {
					mapIconUrl = settings_mapIconPalletJack;
				}
				// Rigid Icon
				else if (data[index].vehTypeID === 2)
				{
					mapIconUrl = settings_mapIconRigid;
				}
				// Trailer Icon
				else if (data[index].vehTypeID === 3)
				{
					mapIconUrl = settings_mapIconTrailer;
				}
				// Prime Mover Icon
				else if (data[index].vehTypeID === 4) {
					mapIconUrl = settings_mapIconPrimeMover;
				}
				// B Double Icon
				else if (data[index].vehTypeID === 5)
				{
					mapIconUrl = settings_mapIconBDouble;
				}

				var lat = data[index].lat;
				var lng = data[index].lng;

				if (lat != null && lng != null) {
					var marker = new window.google.maps.Marker({
						position: {lat, lng},
						map: map,
						title: data[index].vehFleetNumber,
						icon: {
							url:        mapIconUrl,
							scaledSize: mapIconSize,
							labelOrigin: new window.google.maps.Point(20, 45)
						},
						infowindow: infowindow,
						label: {
							text: data[index].vehFleetNumber,
							fontSize: '12px',
							fontWeight: 'bold'
						},
						zIndex:     parseInt(index, 10)
					});

					mapMarkerData[totalVehicles] = marker;
					totalVehicles++;

					// eslint-disable-next-line 
					window.google.maps.event.addListener(mapMarkerData[index], 'click', function() {
						infowindow.close();
					
						var i = this.zIndex;
						var infowindowContent = self.createInfoWindow(markersData[i].infowindow);  

						infowindow.setContent(infowindowContent);
						this.infowindow.open(map, this);
					});
				}
			}

			this.loadMarkerCluster();
		}
	}

	fitBounds = data => {
		if (data.length > 0) {
			var bounds = new window.google.maps.LatLngBounds();
			data.forEach(function arrayElements(element) {
				bounds.extend(new window.google.maps.LatLng(element.position.lat(), element.position.lng()));
			});

			map.fitBounds(bounds);
		}
	}

	loadMarkerCluster = () => {
		this.clearMarkerCluster();

		mapMarkerClusterer = new window.MarkerClusterer(map, mapMarkerData, {
			maxZoom:        settings_mapClusterMaxZoom,
			gridSize:       settings_mapClusterGridSize,
			imagePath:      '/fullmap/global/plugins/js-marker-clusterer-gh-pages/images/m'
		});
	}

	setMapPosition = (pos, zoom) => {
		const position = { 
			lat: parseFloat(pos.lat),
			lng: parseFloat(pos.lng)
		};

		setTimeout(() => {
			map.setCenter(position);

			if (zoom)
				map.setZoom(zoom);
		}, 200);
	}

	render() {   
		var { isLoaded } = this.state;

		var { 
			height = '70vh'
		} = this.props;

		var {
			breadcrumbs,
			markers,
			position,
			zoom
		} = this.props.map;

		if (isLoaded) {
			// Center the Map with the position and zoom
			if (position)
				this.setMapPosition(position, zoom);

			// Draw Breadcrumbs
			if (breadcrumbs)
				this.drawBreadcrumbs(breadcrumbs);
			else if(!breadcrumbs) {
				this.clearBreadcrumbs();
				this.clearMarkerCluster();
			}

			if (markers)
				this.drawMarkersWithVehicleIcon(markers);
			else if (!markers) {
				this.clearMarkers();
				this.clearMarkerCluster();
			}
		}
			
		return (
			<div>
				<div 
					ref={ref => this.mapElement = ref}
					className="google-map"
					style={{height: height}} 
				>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	map: state.map
});

export default connect(mapStateToProps)(GoogleMap);