import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from 'react-fontawesome';
import TrackingApi from '../../../app/api/TrackingApi';
import { ErrorBox, Table, Loader } from '../uiElements';

const propTypes = {
	searchEnabled: PropTypes.bool,
	onSelectMissingVehicle: PropTypes.func
};

class MissingVehiclesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			missingVehiclesData: null,
			isLoading: false,
			error: false
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchMissingVehicles();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchMissingVehicles = () => {
		this.setState({ isLoading: true, missingVehiclesData: null, error: null });
		
		TrackingApi.vehiclesMissingNow()
			.then(missingVehiclesData => {
				if (this.unmounted) return;
			
				this.setState({ missingVehiclesData, isLoading: false });
				window.$('[data-toggle="popover"]').popover();
			})
			.catch(error => {
				console.error(error);
				this.setState({ 
					error: 'It wasn\'t possible to retrieve the data. Try again or contact the TMS Support.', 
					isLoading: false 
				});
			});
	}

	onSelectMissingVehicle = (vehicleData, index) => {
		if (this.props.onSelectMissingVehicle)
			this.props.onSelectMissingVehicle(vehicleData, index);
	}

	render() {
		const s = this.state;
		const p = this.props;

		if (s.error)
			return <ErrorBox error={s.error} retryFunc={this.fetchMissingVehicles} />;

		if (s.isLoading)
			return <Loader text="Loading data..." />;

		return (
			<div>
				{
					s.missingVehiclesData && s.missingVehiclesData.length > 0 ? (
						<Table 
							info={p.info} 
							lengthChange={p.lengthChange} 
							ordering={p.ordering} 
							pageLength={p.pageLength} 
							paging={p.paging} 
							searching={p.searching} 
							cssClass="table-hover"
						>
							<thead>
								<tr>
									<th><Icon name="truck" /> Vehicle</th>
									<th><Icon name="microchip" /> Device ID</th>
									<th><Icon name="cube" /> Type</th>
									<th><Icon name="globe" /> State</th>
									<th><Icon name="clock-o" /> Last Ping</th>
								</tr>
							</thead>
							<tbody>
								{
									s.missingVehiclesData.map((missingVehicle, index) => (
										<tr className="cursor-pointer" key={missingVehicle.vehFleetNumber} data-row={index} onClick={() => { this.onSelectMissingVehicle(missingVehicle, index);}}>
											<td>{missingVehicle.vehFleetNumber}{missingVehicle.vehRego && (' - ' + missingVehicle.vehRego)}</td>
											<td>{missingVehicle.vehDeviceId}</td>
											<td>{missingVehicle.vehTypeName}</td>
											<td>{missingVehicle.vehState}</td>
											<td>{moment.unix(missingVehicle.vehLastPingEpoch).format('DD/MM/YY HH:mm')}</td>
										</tr>
									))
								}
							</tbody>
						</Table>
					) : (
						<p><i>No data has been found</i></p>
					)
				}
			</div>
		);
	}
}

MissingVehiclesList.propTypes = propTypes;

export { MissingVehiclesList };
