/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, browserHistory } from 'react-router';
import Icon from 'react-fontawesome';
import { Button, Row, Col, FormGroup } from 'react-bootstrap';
import moment from 'moment';
import PermissionGroups from '../../../../../app/schemas/PermissionGroups';
import toastr from 'toastr';

// APIs
import WorkerApi from '../../../../../app/api/WorkerApi';
import { UserProfilePicture, ErrorBox, Loader, ContentBox, Callout } from '../../../../common/uiElements';

// Forms
import WorkerDetails from '../forms/WorkerDetails';
import WorkerSuperFunds from '../forms/WorkerSuperFunds';
import WorkerDriverLicences from '../forms/WorkerDriverLicences';
import WorkerStatus from '../forms/WorkerStatus';
import WorkerResetPassword from '../forms/WorkerResetPassword';
import WorkerSendPin from '../forms/WorkerSendPin';
import componentRequestHandler from '../../../../../app/api/helpers/componentRequestHandler';
import WorkerAvailability from '../forms/WorkerAvailability';
import clarityEndpoints from '../../../../../app/api/endpoints/clarityEndpoints';
import azureEndpoints from '../../../../../app/api/endpoints/azureEndpoints';
// import WorkerCovidTests from './WorkerCovidTests';

const propTypes = {
	azureId: PropTypes.string,
	data: PropTypes.object,
	isLoggedUser: PropTypes.bool,
	showTfn: PropTypes.bool,
	showBackButton: PropTypes.bool,
	hideWorkerLeftReason: PropTypes.bool,
	onClickEdit: PropTypes.func,
	loggedUser: PropTypes.shape({
		permissionGroupName: PropTypes.string
	})
};

const defaultProps = {
	showBackButton: true
};

class WorkerViewDetails extends Component {
	constructor() {
		super();
		this.state = {
			userData: null,
			driverLicenceToView: null,
			error: false,
			isLoading: false,
			showArchivedFiles: false
		};
	}

	UNSAFE_componentWillMount() {
		if (this.props.data)
			return this.setState({ userData: this.props.data });

		this.fetchData();
	}

	fetchData = () => {
		const promise = () => WorkerApi.getWorkerDetails(this.props.azureId, true, true);
		componentRequestHandler(this, promise, 'userData');
	}

	toggleArchivedFiles = () => {
		this.setState({ showArchivedFiles: !this.state.showArchivedFiles });
	}

	render() {
		const s = this.state;
		const p = this.props;
		var { showTfn, isLoggedUser } = this.props;
		
		var filesArray = [];
		if (s.userData && s.userData.WorkerDocuments) {
			s.userData.WorkerDocuments.forEach(function(doc) {
				if (!doc.isArchived || s.showArchivedFiles) {
					var typeId = doc.typeId;
					var typeName = doc.typeName;
					var isAlreadyAdded = filesArray.map(function(x) {return x.typeId; }).indexOf(typeId) !== -1;
					
					if (!isAlreadyAdded)
						filesArray.push({ typeId, typeName, files: [] });
					
					var typeIdIndex = filesArray.map(function(x) {return x.typeId; }).indexOf(typeId);
					filesArray[typeIdIndex].files.push(doc);
				}
			});
		}			
		
		return (
			<div>
				{
					!isLoggedUser && (
						<Row>
							{
								p.showBackButton && (
									<Col xs={4} sm={3} md={2}>
										<FormGroup>
											<Button block bsStyle="danger" onClick={this.props.onBack || (() => browserHistory.push('/management/workers'))}>
												<Icon name="arrow-left" /> Back
											</Button>
										</FormGroup>
									</Col>
								)
							}
							{
								!s.isLoading && !s.error && s.userData && (
									!s.userData.azureEmail ? (
										<Col xs={12}>
											<FormGroup>
												<Callout
													icon="warning" 
													title="Terminated Employee"
													text="This employee does not exist in the Active Directory anymore and cannot be edited. If you need anything else other than data visualization, contact the IT support."
												/>
											</FormGroup>
										</Col>
									) : (
										<Col xs={8} sm={4} md={3}>
											<FormGroup>
												{
													p.onClickEdit ? (
														<Button block bsStyle="warning" onClick={p.onClickEdit}>
															<Icon name="edit" /> Edit
														</Button>
													) : (
														<Link className="btn btn-block btn-warning" to={('/management/workers/edit/' + s.userData.azureId)}>
															<Icon name="edit" /> Edit
														</Link>
													)
												}
											</FormGroup>
										</Col>
									)
								)
							}
						</Row>
					)
				}
				{
					s.isLoading ? (
						<Loader text="Loading data. Please wait..." />
					) : (
						s.error ? (
							<ErrorBox error={s.error} retryFunc={this.fetchData} />							
						) : (
							<Row>
								<Col md={4} lg={3}>
									{
										p.loggedUser.permissionGroupName === PermissionGroups.ADMIN && (
											<ContentBox>
												<b>Azure ID:</b><br />
												<a 
													onClick={() => {
														navigator.clipboard.writeText(s.userData.azureId);
														toastr.success('Azure ID copied to clipboard');
													}} 
													style={{ fontSize: '12.5px' }}>
														{ s.userData.azureId }
												</a>
											</ContentBox>
										)
									}
									<div className="box box-primary">
										<div className="box-body box-profile">
											<UserProfilePicture width={500} height={500} disableCache className="profile-user-img img-responsive" azureId={s.userData.azureId}/>
											<h3 className="profile-username text-center">{ s.userData.firstName + ' ' + s.userData.surname }</h3>
											<p className="text-muted text-center">{ s.userData.currentRoleName }</p>
											<ul className="hidden-xs list-group list-group-unbordered">
												{
													s.userData.azureEmail && (
														<li className="list-group-item text-center">
															<b>Login</b><br/>
															<a><u>{ s.userData.azureEmail }</u></a>
														</li>
													)
												}
												<li className="list-group-item">
													<b>Employee Number:</b> <span className="pull-right">{ s.userData.dtoCurrentEmployeeNumber }</span>
												</li>
												<li className="list-group-item">
													<b>Date of Birth</b> <span className="pull-right">{ moment(s.userData.dob).format('DD/MM/YYYY') }</span>
												</li>
												<li className="list-group-item">
													<b>Phone</b>&nbsp;
													<span className="pull-right">
														{ 
															s.userData.WorkerPhones.length === 0 ? '-' : (
																<a href={`tel:${s.userData.WorkerPhones[0].phoneNumber}`}>
																	{ s.userData.WorkerPhones[0].phoneNumber }
																</a>
															)
														}
													</span>
												</li>
											</ul>
										</div>
									</div>
									{
										s.userData.azureEmail && (
											<ContentBox hideOnPrint color="primary" title="Authentication">
												<FormGroup>
													<WorkerSendPin azureId={p.azureId} />
												</FormGroup>
												{
													!p.isLoggedUser && (
														<FormGroup>
															<WorkerResetPassword azureId={p.azureId} /> 
														</FormGroup>
													)
												}
												
											</ContentBox>
										)
									}
									{
										p.loggedUser.permissionGroupName === PermissionGroups.ADMIN && (
											<ContentBox title="External Admin Tools">
												<Col>
													<a href={clarityEndpoints.byAzureId(p.azureId)} target="_blank" rel="noopener noreferrer">
														<Icon name="external-link" /> Microsoft Clarity 
													</a>
												</Col>
												<Col>
													<a href={azureEndpoints.azureActiveDirectory(p.azureId)} target="_blank" rel="noopener noreferrer">
														<Icon name="external-link" /> Azure Active Directory
													</a>
												</Col>
												<Col>
													<a href={azureEndpoints.exchange(p.azureId)} target="_blank" rel="noopener noreferrer">
														<Icon name="external-link" /> Azure Email Exchange
													</a>
												</Col>
											</ContentBox>
										)
									}
								</Col>		
								<Col md={8} lg={9}>
									<div className="nav-tabs-custom">
										<ul className="nav nav-tabs">
											<li className="active">
												<a href="#details" data-toggle="tab" aria-expanded="true">
													<Icon name="user" /> <span className="hidden-sm hidden-xs">Details</span>
												</a>
											</li>
											{
												!p.isLoggedUser && (
													<li>
														<a href="#workerAvailability" data-toggle="tab" aria-expanded="false">
															<Icon name="clock-o" /> <span className="hidden-sm hidden-xs">Availability</span>
														</a>
													</li>
												)
											}
											<li>
												<a href="#roles" data-toggle="tab" aria-expanded="true">
													<Icon name="tasks" /> <span className="hidden-sm hidden-xs">Roles</span>
												</a>
											</li>
											<li>
												<a href="#superFund" data-toggle="tab" aria-expanded="false">
													<Icon name="usd" /> <span className="hidden-sm hidden-xs">SuperFund</span>
												</a>
											</li>
											<li>
												<a href="#documents" data-toggle="tab" aria-expanded="false">
													<Icon name="file" /> <span className="hidden-sm hidden-xs">Documents</span>
												</a>
											</li>
											<li>
												<a href="#driverLicence" data-toggle="tab" aria-expanded="false">
													<Icon name="drivers-license" /> <span className="hidden-sm hidden-xs">Driver&apos;s Licence</span>
												</a>
											</li>
											{/* Covid Test were removed as per Ally request as it's no longer needed */}
											{/* <li>
												<a href="#covidTests" data-toggle="tab" aria-expanded="false">
													<Icon name="heartbeat" /> <span className="hidden-sm hidden-xs">COVID Tests</span>
												</a>
											</li> */}
										</ul>
										<div className="tab-content">
											<div className="tab-pane active" id="details">
												<WorkerDetails readOnly={true} data={ s.userData } showTfn={showTfn}/>
											</div>
											{
												!p.isLoggedUser && (
												<div className="tab-pane" id="workerAvailability">
													<WorkerAvailability isReadOnly {...s.userData.WorkerAvailability} />												
												</div>
												)
											}
											<div className="tab-pane" id="roles">
												<Row>
													<Col xs={12}>
														<WorkerStatus hideWorkerLeftReason={p.hideWorkerLeftReason} readOnly={true} data={ s.userData.WorkerStatus } />
													</Col>
												</Row>
											</div>
											<div className="tab-pane" id="superFund">
												<Row>
													<Col xs={12}>
														<WorkerSuperFunds readOnly={true} data={ s.userData.WorkerSuperFunds } />
													</Col>
												</Row>
											</div>
											<div className="tab-pane" id="documents">
												{
													s.userData.WorkerDocuments && s.userData.WorkerDocuments.length === 0 ? (
														<i>No Files uploaded.</i>
													) : (
														<div className="nav-tabs-custom">
															<ul className="nav nav-tabs">
																<li className="cursor-pointer" onClick={this.toggleArchivedFiles}>
																	{
																		s.showArchivedFiles ? (
																			<a className="text-muted">
																				<Icon name="eye-slash" /> Hide archiveds
																			</a>
																		) : (
																			<a>
																				<Icon name="eye" /> Show archiveds
																			</a>
																		)
																	}
																</li>
																{
																	filesArray.map((tab, index) => {
																		return (
																			<li className={index === 0 ? 'active' : ''} key={index}>
																				<a href={('#' + tab.typeId)} data-toggle="tab" aria-expanded="true">{tab.typeName} ({tab.files.length})</a>
																			</li>
																		);
																	})
																}
															</ul>
															<div className="tab-content">
																{
																	filesArray.map((tab, index) => {
																		return (
																			<div className={('tab-pane ' + (index === 0 ? 'active' : ''))} id={tab.typeId} key={index}>
																				<table className="table table-bordered table-condensed">
																					<thead>
																						<tr>
																							<th>Type</th>
																							<th>Description</th>
																							<th>Number</th>
																							<th>Expiry Date</th>
																							<th>Download</th>
																						</tr>
																					</thead>
																					<tbody>
																						{
																							tab.files.map((file, indexFile) => {
																								var { typeName, description, number, expiryDate } = file;
																								return (
																									<tr className={(file.isArchived ? 'text-muted' : '')} key={indexFile}>
																										<td>{typeName == '' ? '-' : typeName}</td>
																										<td>{description == '' ? '-' : description}</td>
																										<td>{number == '' ? '-' : number}</td>
																										<td>{expiryDate ? moment(expiryDate).format('DD/MM/YYYY') : '-'}</td>
																										<td width={40} className="text-center">
																											<a onClick={() => WorkerApi.getWorkerDocument(s.userData.azureId, file.id)}>
																												<Icon name="download" />
																											</a>
																										</td>
																									</tr>
																								);
																							})
																						}
																					</tbody>
																				</table>
																			</div>
																		);
																	})
																}
															</div>
														</div>
													)
												}
											</div>
											<div className="tab-pane" id="driverLicence">
												<WorkerDriverLicences workerId={s.userData.azureId} data={s.userData.WorkerDriverLicences} readOnly={true}/>
											</div>
											{/* Covid Test were removed as per Ally request as it's no longer needed */}
											{/* <div className="tab-pane" id="covidTests">
												<Row>
													<Col xs={12}>
														<WorkerCovidTests 
															isReadOnly 
															workerId={p.azureId} 
															WorkerCovidTests={ s.userData.WorkerCovidTests } 
														/>
													</Col>
												</Row>
											</div> */}
										</div>
									</div>
								</Col>	        
							</Row>
						)
					)
				}
			</div>
		);
	}
}

WorkerViewDetails.propTypes = propTypes;
WorkerViewDetails.defaultProps = defaultProps;

const mapStateToProps = ({ user }) => ({ 
	loggedUser: user.data
});

export default connect(mapStateToProps)(WorkerViewDetails);
