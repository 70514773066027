import React from 'react';
import moment from 'moment-timezone';
import { IStaffRosterListItem } from '../../../../../app/models/StaffRoster';
import './StaffRosterList.scss';
import { Callout } from '../../../../common/uiElements';
import { Table, Row, Col } from 'react-bootstrap';
import weekDays from '../../../../../app/store/weekDays';
import './StaffRosterListResponsive.scss';

interface IProps {
	staffRosterList: IStaffRosterListItem[];
}

function StaffRosterListResponsive(props: IProps) {
	if (props.staffRosterList.length === 0)
		return (
			<Callout 
				icon="times"
				title="No Registered Roster"
				text="You have no roster registered for the selected week"
			/>
		);
		
	return (
		<Row id="staff-roster-list-responsive" className="row-aligned">
			{
				weekDays.map(weekDay => {
					const rosterOfTheDay = props.staffRosterList.find(roster => moment(roster.startTimeLocal).format('dddd') === weekDay);
					return (
						<Col key={weekDay} xs={12} sm={6} md={4} lg={3}>
							<Callout  
								title={`${weekDay.toUpperCase()}${rosterOfTheDay?.isOvertime ? ' (Overtime)' : ''}`}
								icon={rosterOfTheDay ? 'check' : 'times'}
								color={rosterOfTheDay ? rosterOfTheDay.isOvertime ? 'warning' : 'success' : 'danger'}
							>
								{
									rosterOfTheDay ? (
										<Table condensed>
											<tbody>
												<tr>
													<th>Date</th>
													<td>{ moment(rosterOfTheDay.startTimeLocal).format('DD/MM/YYYY') }</td>
												</tr>
												<tr>
													<th>Start</th>
													<td>{ moment(rosterOfTheDay.startTimeLocal).format('HH:mm') }</td>
												</tr>
												<tr>
													<th>Est. Finish</th>
													<td>{ moment(rosterOfTheDay.startTimeLocal).add(rosterOfTheDay.budgetedTime, 'hours').format('HH:mm') }</td>
												</tr>
												<tr>
													<th>Hours</th>
													<td>{ rosterOfTheDay.budgetedTime } h</td>
												</tr>
												<tr>
													<th>Depot</th>
													<td>{ rosterOfTheDay.startingDepotName }</td>
												</tr>
											</tbody>
										</Table>
									) : (
										<h1>DAY OFF</h1>
									)
								}
							</Callout>
						</Col>
					);
				})
			}
		</Row>
	);
}

export default StaffRosterListResponsive;