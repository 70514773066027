import workerEndpoints from './endpoints/workerEndpoints';
import httpRequests from './httpRequests';
import { IWorkerListFilter } from '../models/Worker';
import WorkerOutputType from '../enums/WorkerOutputType';

export default {
	/**
	 * Get the worker data
	 * @param azureId
	 * @param fullDetails If true, it will return the worker's full data
	 * @param includeArchivedDocuments If true, it will return the archived documents too
	 */
	getWorkerDetails(azureId: string, fullDetails?: boolean, includeArchivedDocuments?: boolean) {
		const endpoint = workerEndpoints.byId(azureId) + (fullDetails ? '?outputType=FullObject' : '');
		return httpRequests.get(endpoint, { includeArchivedDocuments });
	},

	/**
	 * Get the worker data (Simple Object)
	 * @param azureId
	 */
	getWorkerDetailsSimple(azureId: string) {
		const endpoint = workerEndpoints.byId(azureId)// + '?outputType=' + WorkerOutputType.SIMPLE_NO_CHILDREN;
		return httpRequests.get(endpoint, { outputType: WorkerOutputType.SIMPLE_NO_CHILDREN });
	},

	/**
	 * Get the base 64 of the profile picture
	 * @param azureId Worker Azure ID 
	 */
	getWorkerProfilePhoto(azureId: string, width?: number, height?: number) {
		const endpoint = workerEndpoints.photo(azureId);
		return httpRequests.getImage(endpoint, { width, height });
	},

	/**
	 * Save the user's Profile Picture
	 * @param azureId The Azure ID
	 * @param pictureFile The PIN Number
	 */
	saveWorkerProfilePicture(azureId: string, pictureFile: object) {
		const endpoint = workerEndpoints.photo(azureId);
		return httpRequests.putWithFiles(endpoint, { attachedFiles: [pictureFile] });
	},

	/**
	 * Check if the user exists in the system
	 * @param user User name or Email
	 */
	checkUserExists(user: string) {
		var isByPrincipalName;
		let endpoint = workerEndpoints.checkUserExists(user);

		// Is By Email
		if (user.indexOf('@') !== -1)
			isByPrincipalName = true;
		
		return httpRequests.get(endpoint, { isByPrincipalName });	
	},

	/**
	 * Check if the TFN exists in the system
	 * @param tfn
	 */
	checkTfnExists(tfn: string) {
		let endpoint = workerEndpoints.checkTfnExists(tfn);
		return httpRequests.get(endpoint);	
	},

	/**
	 * Get the last Pre-Start done by the user
	 * @param azureId 
	 * @param onlyWorkerActive 
	 */
	getLastPreStart(azureId: string, onlyWorkerActive: boolean = true) {
		const endpoint = workerEndpoints.lastPreStart(azureId);
		return httpRequests.get(endpoint, { onlyWorkerActive });
	},

	/**
	 * Get worker document
	 * @param azureId Worker Azure ID 
	 * @param fileId File ID
	 */
	getWorkerDocument(azureId: string, fileId: number) {
		const endpoint = workerEndpoints.document(azureId, fileId);
		return httpRequests.downloadFile(endpoint);
	},

	/**
	 * Get covid test result
	 * @param azureId Worker Azure ID 
	 */
	getCovidTestResult(azureId: string, covidTestId: number) {
		const endpoint = workerEndpoints.covidTest(azureId, covidTestId);
		return httpRequests.downloadFile(endpoint);
	},

	/**
	 * Get the URL of the driver licence picture
	 * @param azureId Worker Azure ID 
	 * @param driverLicenceId Driver licence ID
	 * @param type 'front' or 'back'
	 */
	getWorkerDriverLicenceImage(azureId: string, driverLicenceId: number, type: 'front' | 'back') {
		const endpoint = workerEndpoints.driverLicence(azureId, driverLicenceId, type);
		return httpRequests.getImage(endpoint);
	},

	/**
	 * Get the Worker List
	 */
	getWorkerList(filters: string) {
		const endpoint = workerEndpoints.root;

		if (filters)
			filters = JSON.stringify(filters);

		return httpRequests.get(endpoint, { filterString: filters });
	},

	/**
	 * Export the Worker List to a file
	 */
	getWorkerListReportFile(filterString: IWorkerListFilter) {
		const endpoint = workerEndpoints.exportReport;

		return httpRequests.downloadFile(endpoint, { filterString: JSON.stringify(filterString) });
	},

	// SAVE
	saveWorkerData(data: object) {
		const endpoint = workerEndpoints.root;  
		return httpRequests.postWithFiles(endpoint, data);
	},

	/**
	 * Save the user's PIN Number
	 * @param azureId The Azure ID
	 * @param quickPin The PIN Number
	 */
	saveWorkerQuickPin(azureId: string, quickPin: number, forceMatchActionById: boolean = true) {
		const endpoint = workerEndpoints.quickPin(azureId);
		return httpRequests.put(endpoint, { quickPin, forceMatchActionById });
	},

	// UPDATE
	updateWorkerData(data: object, azureId: string) {
		const endpoint = workerEndpoints.byId(azureId);
		return httpRequests.putWithFiles(endpoint, data);
	},

	/**
	 * Updates the last version of the app that the user has seen
	 * @param version Current App Version
	 * @param azureId The Azure ID
	 */
	updateWorkerLastVersionSeen(version: string, azureId: string) {
		const endpoint = workerEndpoints.lastVersionSeen(azureId);
		return httpRequests.put(endpoint, { version });
	},

	// INACTIVATE
	inactivateWorker(azureId: string) {
		const endpoint = workerEndpoints.byId(azureId);
		return httpRequests.delete(endpoint);
	},

	// SEND USER ACCOUNT BY EMAIL
	sendUserAccountByEmail(data: object) {
		const endpoint = workerEndpoints.sendAccountDetailsByEmail;  
		return httpRequests.post(endpoint, data);
	},

	/**
	 * Send an email to a department
	 * @param azureId The Azure ID
	 * @param workerName The name of the user that is sending the email 
	 * @param department Department ID
	 * @param message The message to send
	 */
	sendEmail(azureId: string, workerName: string, department: number, message: string) {
		const endpoint = workerEndpoints.sendEmail(azureId);
		return httpRequests.post(endpoint, { workerName, department, message });
	},

	/**
	 * Reset the user's password and PIN
	 * @param azureId The Azure ID
	 */
	resetPassword(azureId: string) {
		const endpoint = workerEndpoints.resetPassword(azureId);
		return httpRequests.post(endpoint);
	},

	/**
	 * Send the user's PIN Number
	 * @param azureId The Azure ID
	 */
	sendPin(azureId: string) {
		const endpoint = workerEndpoints.sendPin(azureId);
		return httpRequests.post(endpoint);
	}
};