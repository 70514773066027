import React from 'react';
import { Marker, MarkerProps } from 'react-google-maps';
 
const GoogleMarker: React.FC<MarkerProps> = props => {
	const markerProps = { ...props };

	if (props.label) 
		markerProps.label = {
			...props.label,
			className: props.label.className,
			fontSize: props.label.fontSize || "12px",
			fontWeight: props.label.fontWeight || "bold"
		}

	if (!props.icon)
		markerProps.icon = {
			url: 'http://cdn.micway.com.au/tms/img/map_marker.png',
			labelOrigin: new window.google.maps.Point(20, 45),
			scaledSize: new window.google.maps.Size(35, 35),
		}

	return <Marker { ...markerProps } />;
};

export { GoogleMarker };