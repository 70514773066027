import React, { Fragment } from 'react';
import { IWorkerForTaskOrRoster } from '../../../app/models/Worker';
import { Popover, Overlay, OverlayTrigger } from 'react-bootstrap';
import { uniqueId } from 'lodash';
import moment from 'moment';

interface IProps {
	worker?: IWorkerForTaskOrRoster;
	totalHoursOvertime: number;
	taskStartTime: string;
	taskBudgetedTime: number;
	show?: boolean;
	targetElementId?: string;
}

export const WorkerPossibleOvertimeOverlayTrigger: React.FC<IProps> = props => {
	if (!props.worker?.Roster || !props.taskStartTime)
		return (
			<Fragment>
				{ props.children }
			</Fragment>
		);

	const { Roster } = props.worker;
	const $workerShiftFinishTime = moment(Roster.startTimeLocal).add(Roster.budgetedTime, 'hours');
	const $taskEstimatedFinishTime = moment(props.taskStartTime).add(props.taskBudgetedTime, 'hours');
	const totalHoursOvertime = props.totalHoursOvertime;

	const title = (
		<b className="text-danger">
				Possible Overtime ({totalHoursOvertime}h)
		</b>
	);

	const content = (
		<Popover title={title} id={'tooltip_roster_' + uniqueId()}>
			<b>{ props.worker.firstName }</b> is rostered to finish at <b>{$workerShiftFinishTime.format('HH:mm')}</b> but this task is set to finish at <b>{$taskEstimatedFinishTime.format('HH:mm')}</b>, which means <b>{totalHoursOvertime}h overtime</b>. Please check if the employee is available to do any overtime or if their roster is correct in the system.
		</Popover>
	)
	if (props.show && props.targetElementId)
		return (
			<div>
				{ props.children }
				<Overlay
					show
					placement="top" 
					target={() => document.getElementById(props.targetElementId!)}
				>
					{ content }
				</Overlay>
			</div>
		)

	return (
		<OverlayTrigger
			placement="top" 
			overlay={content}
		>
			{ props.children }
		</OverlayTrigger>
	)
}