import moment from 'moment';
import React, { useState } from 'react';
import { Button, FormControl, Row } from 'react-bootstrap';
import { IFormField, IReactSelectOption } from '../../../../../app/models/Application';
import { IPallet, IPalletStore, PalletDetailsForm as PalletDetailsFormClass } from '../../../../../app/models/Pallet';
import { FieldGroup, Form } from '../../../../common/uiElements';
import { PlacesListNew } from '../../../../common/lists';
import { DropDownList, RadioButtonList, UserSearch } from '../../../../common/inputs';
import { commodityList, palletLocationInTrailer, palletTypeList } from '../dataForLists';
import PalletsList from './PalletsList';
import PalletDetailsModal from './PalletDetailsModal';

interface IProps {
	palletsList?: IPallet[];
	pallet: PalletDetailsFormClass;
	isReadOnly?: boolean;
	isAddingNewPallet?: boolean;
	isConsolidatedPallet?: boolean;
	onChangeInput: (id: string, value?: string) => void;
	onChangeStore: (store: IPalletStore) => void;
	onChangeLoader: (loader: IReactSelectOption) => void;
	onChangeConsolidationType: (wasPalletTimberKept: boolean) => void;
	onSaveConsolidatedPallet?: (consolidatedPallet: PalletDetailsFormClass) => void;
	onAddNewConsolidatedPallet?: (pallet: IPallet) => void;
	onConsolidateExistingPallet?: (pallet: IPallet) => void;
	onRemoveConsolidatedPallet?: (pallet: IPallet) => void;
	onDeleteConsolidatedPallet?: (palletId: string) => void;
}

const PalletDetailsForm: React.FC<IProps> = props => {
	const { pallet } = props;
	const [selectedConsolidatedPallet, setSelectedConsolidatedPallet] = useState<IPallet | undefined>(undefined);
	const [isAddingNewConsolidatedPallet, setIsAddingConsolidatedPallet] = useState(false);
	const [addNewConsolidatedPalletFromList, setAddNewConsolidatedPalletFromList] = useState(false);

	const handleChangeConsolidationType = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.onChangeConsolidationType(e.target.value === 'forkedUp');
	};
	
	// Adding a new consolidated pallet to isUpstackedWith[IPallet]
	const handleAddNewConsolidatedPallet = (consolidatedPallet: IPallet) => {
		props.onAddNewConsolidatedPallet && props.onAddNewConsolidatedPallet(consolidatedPallet);
		setSelectedConsolidatedPallet(undefined);
		setIsAddingConsolidatedPallet(false);
	}

	// Consolidating an already existing pallet on to the selected pallet
	const handleAddConsolidateExistingPallet = (consolidatedPallet: IPallet) => {
		props.onConsolidateExistingPallet && props.onConsolidateExistingPallet(consolidatedPallet);
	}

	// Removing a pallet from the list
	const handleRemoveConsolidateExistingPallet = (consolidatedPallet: IPallet) => {
		props.onRemoveConsolidatedPallet && props.onRemoveConsolidatedPallet(consolidatedPallet);
	}
	
	// Editing an already existing consolidated pallet
	const handleSaveConsolidatedPallet = (consolidatedPallet: IPallet) => {
		props.onSaveConsolidatedPallet && props.onSaveConsolidatedPallet(consolidatedPallet);
		setSelectedConsolidatedPallet(undefined);
		setIsAddingConsolidatedPallet(false);
	}

	const handleDeleteConsolidatedPallet = (consolidatedPallet: IPallet) => {
		// Toggle the pallet's deleted status as it can be undone
		consolidatedPallet.isDeleted = !consolidatedPallet.isDeleted;
		props.onSaveConsolidatedPallet && props.onSaveConsolidatedPallet(consolidatedPallet);
	}

	const detailFields: IFormField[] = [
		/** ID  */
		{
			id: 'palletId',
			label: 'ID', 
			required: true,
			sm: 4,
			readOnlyValue: pallet.palletId,
			isReadOnly: props.isReadOnly,
			inputComponent: !props.isAddingNewPallet ? undefined : 
				<FormControl
					id="palletId"
					type="text"
					value={pallet.palletId}
					onChange={(e: React.FormEvent<FormControl>) => props.onChangeInput('palletId', (e.target as HTMLInputElement).value)}
				/>
		},

		/** Loaded By */
		{
			id: 'loadedBy',
			label: 'Loaded By',
			required: true,
			sm: 4,
			readOnlyValue: pallet.loadedBy.name || '-',
			isReadOnly: props.isReadOnly,
			inputComponent: <UserSearch id="loadedBy" value={pallet.loadedBy.id} onChange={props.onChangeLoader} />

		},

		/** Loaded At */
		{
			id: 'loadedOn',
			label: 'Loaded At',
			sm: 4,
			readOnlyValue: moment(pallet.loadedOn).format('HH:mm')
		},

		/** Store */
		{
			id: 'store',
			label: 'Store',
			required: true,
			sm: 12,
			readOnlyValue: pallet.store?.storeNumber ? pallet.store.storeNumber + ' - ' + pallet.store.name : '-',
			isReadOnly: props.isReadOnly,
			inputComponent: (
				<PlacesListNew
					required
					id="store"
					type="deliveryStores"
					placeholder={pallet.store?.storeNumber ? `${pallet.store.name} (${pallet.store.storeNumber})` : "--- STORE ---"}
					value={ pallet.store?.id && pallet.store?.id !== -1 ? pallet.store.id.toString() : '' }
					onChange={(place: any) => {
						props.onChangeStore && props.onChangeStore({
							id: place?.value ? parseInt(place.value) : -1,
							name: (place.fullObject as any)?.name || '',
							storeNumber: (place.fullObject as any)?.storeNumber || '',
						});
					}}
				/>
			)
		},

		/** Commodity */
		{
			id: 'commodity',
			label: 'Commodity',
			required: true,
			sm: 4,
			readOnlyValue: pallet.commodity,
			isReadOnly: props.isReadOnly,
			inputComponent: (
				<DropDownList
					required
					id={`commodity_` + pallet.palletId}
					placeholder="--- COMMODITY ---"
					value={ pallet.commodity }
					data={ commodityList }
					onChange={(commodity: string) => props.onChangeInput('commodity', commodity)}
				/>
			)
		},

		/** Type */
		{
			id: 'type',
			label: 'Type',
			required: true,
			sm: 4,
			readOnlyValue: pallet.palletType,
			isReadOnly: props.isReadOnly,
			show: pallet.wasPalletTimberKept,
			inputComponent: (
				<DropDownList
					required
					id={`palletType_` + pallet.palletId}
					placeholder="--- PALLET TYPE ---"
					value={ pallet.palletType }
					data={ palletTypeList }
					onChange={(palletType: string) => props.onChangeInput('palletType', palletType)}
				/>
			)
		},

		/** Location */
		{
			id: 'location',
			label: 'Location',
			required: true,
			sm: 4,
			readOnlyValue: pallet.locationInTrailer,
			isReadOnly: props.isReadOnly,
			show: !props.isConsolidatedPallet,
			inputComponent: (
				<DropDownList
					required
					id={`locationInTrailer_` + pallet.palletId}
					placeholder="--- SIDE ---"
					value={ pallet.locationInTrailer || ""}
					data={ palletLocationInTrailer }
					onChange={(locationInTrailer?: string) => props.onChangeInput('locationInTrailer', locationInTrailer)}
				/>
			)
		},

		/** UPSTACKED OR FORKED UP (wasPalletTimberKept) */
		{
			id: 'wasPalletTimberKept',
			label: 'Consolidation Type',
			required: true,
			sm: 12,
			readOnlyValue: pallet.wasPalletTimberKept ? 'Forked Up' : 'Upstacked',
			isReadOnly: props.isReadOnly,
			show: !!props.isConsolidatedPallet && !pallet.isUpstackedWith,
			inputComponent: (
				<RadioButtonList 
					value={props.isConsolidatedPallet && pallet.wasPalletTimberKept === undefined ? '' : pallet.wasPalletTimberKept ? 'forkedUp' : 'upstacked'}
					items={[
						{ text: 'Upstacked', value: 'upstacked' },
						{ text: 'Forked up', value: 'forkedUp' }
					]}
					onChange={handleChangeConsolidationType}
				/>
			)
		}
	]

	const renderFields = (fields: IFormField[]) => (
		fields.map(field => {
			if (field.show === false)
				return null;

			return (
				<FieldGroup 
					key={field.label} 
					sm={field.sm} 
					label={field.label} 
					required={field.required} 
					optional={field.inputComponent && !props.isReadOnly && !field.required}
				>
					{
						(field.isReadOnly || !field.inputComponent) ? (
							<p>{ field.readOnlyValue || '-'}</p>
						) : (
							field.inputComponent
						)
					}
				</FieldGroup>
			)
		})
	)

	// Added validations for required objects and ensuring the palletId matches the palletId regex (found in customValidation.js)
	const validations = {
		...detailFields.filter(p => p.required).reduce((obj, item) => Object.assign(obj, { [item.id]: 'required' }), {}),
		palletId: "palletId"
	};

	return (	
		<Form id={selectedConsolidatedPallet ? "consolidated-pallet-details-form" : "pallet-details-form"} validations={validations}>
			<Row>
				{ renderFields(detailFields) }
			</Row>

			{/* Consolidate a pallet on the selected pallet */}
			{
				(!props.isAddingNewPallet) && (
					<>
						{/* Create a new consolidated pallet */}
						<Button block bsStyle="success" style={{ marginBottom: 10 }}
							onClick={() => {
								setIsAddingConsolidatedPallet(true);
								setSelectedConsolidatedPallet({
									palletId: '',
									store: pallet.store,
									commodity: '',
									palletType: '',
									locationInTrailer: '',
									wasPalletTimberKept: false,
									isUpstacked: true,
									loadedOn: moment().toISOString(),
									loadedBy: { id: '', name: '' },
									isDeleted: false,
									commodityManuallyEntered: true,
									isDamaged: false,
									manuallyEntered: true
								})
							}}>
								Create New Consolidated Pallet
						</Button>

						{/* Consolidate from list of available pallets */}
						<Button block bsStyle="info" style={{ marginBottom: 10 }}
							onClick={() => { addNewConsolidatedPalletFromList ? setAddNewConsolidatedPalletFromList(false) : setAddNewConsolidatedPalletFromList(true) }}>
								Consolidate Pallet From List
						</Button>
					</>
				) 
			}

			{/* Show a list of pallets that are consolidated with the selected pallet */}
			{
				pallet.isUpstackedWith && pallet.isUpstackedWith.length > 0 && (
					<>
						<h3 className="title">Consolidated With</h3>
						<PalletsList 
							hideStore
							locationInTrailer
							hideLoadedAt
							isConsolidatedPallet
							pallets={pallet.isUpstackedWith} 
							isAddingConsolidatedPalletFromList={addNewConsolidatedPalletFromList}
							isReadOnly={props.isReadOnly}
							onSelectPallet={setSelectedConsolidatedPallet}
							onRemoveConsolidatedPallet={handleRemoveConsolidateExistingPallet}
							onDeletePallet={handleDeleteConsolidatedPallet}
						/>
					</>
				)
			}

			{/* Show a list of pallets that can be consolidated on to the selected pallet */}
			{
				addNewConsolidatedPalletFromList && (
					<>
						<h3 className="title">Available Pallets to Consolidate</h3>
						<PalletsList
							hideStore
							locationInTrailer
							hideLoadedAt
							isConsolidatedPallet={props.isConsolidatedPallet}
							isAddingConsolidatedPalletFromList={addNewConsolidatedPalletFromList}
							pallets={props.palletsList?.filter(p => p.store?.id === pallet.store?.id && p.palletId !== pallet.palletId && !p.isUpstacked) || []}
							isReadOnly={props.isReadOnly}
							onSelectPallet={setSelectedConsolidatedPallet}
							onConsolidateExistingPallet={handleAddConsolidateExistingPallet}
							onDeletePallet={handleDeleteConsolidatedPallet}
						/>
					</>
				)
			}
			
			{/* PALLET DETAILS MODAL  */}
			{
				selectedConsolidatedPallet && (
					<PalletDetailsModal 
						pallet={selectedConsolidatedPallet}
						isAddingNewPallet={true}
						isReadOnly={props.isReadOnly}
						onClose={() => {
							setSelectedConsolidatedPallet(undefined); 
							setIsAddingConsolidatedPallet(false);
							
						}}
						onSave={isAddingNewConsolidatedPallet ? handleAddNewConsolidatedPallet : handleSaveConsolidatedPallet}
					/>
				)
			}
		</Form>
	)
}

export default PalletDetailsForm;