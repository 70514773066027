import React from 'react';
import Select, { ReactSelectProps } from 'react-select';
import { FormControl } from 'react-bootstrap';
import componentRequestHandler from '../../../app/api/helpers/componentRequestHandler';
import { IError } from '../../../app/models/Application';
import RunTemplateApi from '../../../app/api/RunTemplateApi';
import { IRunTemplateListItem } from '../../../app/models/Run/RunTemplate';

interface IProps extends Omit<ReactSelectProps, 'onChange'> {
	dayOfWeek: number;
	emptyOptionText?: string;
	hideErrorMessage?: boolean;
	onChange?: (run?: IRunTemplateListItem) => void
}

interface IState {
	runsList: IRunTemplateListItem[];
	isLoading: boolean;
	error?: IError;
}

class RunsTemplateListInput extends React.Component<IProps, IState> {
	state = {
		runsList: [],
		isLoading: false,
		error: undefined
	}

	componentDidMount() {
		this.fetchList();
	}

	componentDidUpdate(prevProps: Readonly<IProps>) {
		if (prevProps.dayOfWeek !== this.props.dayOfWeek)
			this.fetchList();
	}
	
	fetchList = () => {
		const { emptyOptionText, dayOfWeek } = this.props;
		if (!dayOfWeek) return;
		
		const promise = () => RunTemplateApi.getList({ dayOfWeek });
		componentRequestHandler(this, promise, 'runsList')
		.then(runsList => {
			if (emptyOptionText)
				this.setState({ runsList: [{ value: '', label: emptyOptionText }, ...runsList] });
		})
	}

	handleChange = (run: IRunTemplateListItem) => {
		this.props.onChange && this.props.onChange(run);
	}

	renderListItem = (run: IRunTemplateListItem) => {
		const listItem = {
			value: run.id,
			label: `${run.runNumberCombined}${run.area ? ` (${run.area})` : ''}`,
			...run
		}

		return listItem;
	}

	render() {
		const { props, state } = this;

		return (
			<div>
				<Select 
					value={props.value}
					// required={props.required}
					placeholder={state.isLoading ? 'Loading...' : props.placeholder || 'Select Run...'}
					noResultsText={!state.isLoading ? props.noResultsText || 'No results were found' : ''}
					disabled={props.disabled}
					options={state.runsList.map(this.renderListItem)}
					isLoading={state.isLoading}
					onChange={this.handleChange as any}
				/>
				{
					!props.hideErrorMessage && (
						<FormControl 
							id={props.id} 
							required={props.required}
							type="hidden" 
							value={props.value as string || ''}
						/>
					)
				}
			</div>
		)
	}
}

export { RunsTemplateListInput };