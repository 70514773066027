import React, { ChangeEvent, Fragment } from 'react';
import { ITaskType } from '../../../../app/models/Task';
import { Modal, Button, FormControl, Well} from 'react-bootstrap';
import { Loader, ErrorBox, Form, Callout } from '../../../common/uiElements';
import { ILeaveRequestSimple } from '../../../../app/models/LeaveRequest';
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';
import { IWorkerBasicInfo } from '../../../../app/models/Worker';
import { IError } from '../../../../app/models/Application';
import { IListItem } from '../../../../app/models/List';
import StaffRosterForm from './StaffRosterForm';
// import { getTaskUpdateReasonCategories } from '../helper';
import { RosterUpdateReasonsList } from '../../../common/lists';
import RosterApi from '../../../../app/api/RosterApi';
import { 
	IStaffRosterDetails, RosterUpdateReasonForm, StaffRosterForm as StaffRosterFormClass,
	StaffRosterPOST,
	StaffRosterPUT
} from '../../../../app/models/StaffRoster';
import $ from 'jquery';
import { getRosterUpdateReasonCategories } from './staffRosterHelper';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';

interface IProps {
	rosterId?: number;
	show?: boolean;
	initialDate?: string;
	initialStartingDepotId?: string;
	initialStartingDepotName?: string;
	initialWorker?: IWorkerBasicInfo;
	initialStartTime?: string;
	initialMainTaskTypeId?: string;
	initialMainTaskTypeName?: string;
	updateReasonNotRequired?: boolean;
	isDateReadOnly?: boolean;
	isStartingPlaceReadOnly?: boolean;
	isWorkerReadOnly?: boolean;
	isTaskTypeReadOnly?: boolean;
	dontAddTask?: boolean;
	onClose: () => void;
	onSaveComplete?: (date: string) => void;
}

interface IState {
	rosterDetails: StaffRosterFormClass;
	originalRosterDetails?: StaffRosterFormClass;
	employeeOverlappingLeaveRequest?: ILeaveRequestSimple;
	updateReasonsFormData?: RosterUpdateReasonForm[];
	showUpdateReasonForm: boolean;
	anyChangeHasBeenMade: boolean;
	isSaving: boolean;
	isLoadingRoster: boolean;
	isLoadingLeaveRequests: boolean;
	errorSaving?: IError;
	errorLoadingRoster?: IError;
	errorLoadingLeaveRequests?: IError;
}

class StaffRosterModal extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = { 
			rosterDetails: {
				...new StaffRosterFormClass(),
				date: props.initialDate || '',
				startingDepotId: props.initialStartingDepotId || '',
				startingDepotName: props.initialStartingDepotName || '',
				workerId: props.initialWorker?.azureId || '',
				startTime: props.initialStartTime || '',
				mainTaskTypeId: props.initialMainTaskTypeId || '',
				mainTaskTypeName: props.initialMainTaskTypeName || '',
				Worker: props.initialWorker
			},
			originalRosterDetails: undefined,
			showUpdateReasonForm: false,
			anyChangeHasBeenMade: false,
			employeeOverlappingLeaveRequest: undefined,
			isSaving: false,
			isLoadingRoster: false,
			isLoadingLeaveRequests: false,
			errorSaving: undefined,
			errorLoadingRoster: undefined,
			errorLoadingLeaveRequests: undefined
		}
	}

	componentDidUpdate(prevProps: IProps) {
		if (this.props.rosterId !== prevProps.rosterId)
			this.fetchRosterDetails();
	}	

	componentDidMount() {
		this.fetchRosterDetails();
	}

	fetchRosterDetails = () => {
		const { rosterId } = this.props;
		if (!rosterId) return;

		const promise = () => RosterApi.getRosterDetails(rosterId);
		componentRequestHandler(this, promise, 'originalRosterDetails', { 
			loadingAttrName: 'isLoadingRoster',
			errorAttrName: 'errorLoadingRoster'
		})
		.then((data: IStaffRosterDetails) => this.setState({
			rosterDetails: new StaffRosterFormClass(data),
			originalRosterDetails: new StaffRosterFormClass(data)
		}))
	}

	saveNewTask = () => {
		const data = new StaffRosterPOST(this.state.rosterDetails, this.props.dontAddTask);
		const promise = () => RosterApi.createRoster(data);

		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isSaving',
			errorAttrName: 'errorSaving',
			showSuccessNotification: true
		})
		.then(this.handleSaveComplete);
	}

	saveRosterChanges = () => {
		const { rosterDetails, updateReasonsFormData } = this.state;
		if (!rosterDetails.id) return;

		let promise;
		// if (rosterDetails.isCancelled && updateReasonsFormData) {
		// 	const { updateReasonId, updateDescription } = updateReasonsFormData[0];
		// 	promise = () => RosterApi.cancelRoster(rosterDetails.id!, new StaffRosterCANCEL(updateReasonId, updateDescription));
		// } 
		// else // If it's just an update
		promise = () => RosterApi.updateRoster(rosterDetails.id!, new StaffRosterPUT(rosterDetails, updateReasonsFormData!));

		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isSaving',
			errorAttrName: 'errorSaving',
			showSuccessNotification: true
		})
		.then(this.handleSaveComplete);
	}

	checkUpdateReasonsForm = () => {
		const { originalRosterDetails, rosterDetails } = this.state;
		if (!originalRosterDetails || !rosterDetails) 
			return;

		const updateReasonsFormData = getRosterUpdateReasonCategories(originalRosterDetails, rosterDetails);

		if (updateReasonsFormData.length === 0)
			return this.saveRosterChanges();

		this.setState({ 
			updateReasonsFormData,
			showUpdateReasonForm: true
		});
	}	

	showUpdateReasonForm = () => {
		this.setState({ 
			showUpdateReasonForm: true,
		}, this.checkUpdateReasonsForm);
	}

	handleSaveComplete = () => {
		if (!this.props.onSaveComplete) return;

		this.props.onSaveComplete(this.state.rosterDetails.date)
	}

	handleClickGoBack = () => {
		this.changeForm({ isCancelled: false })
			.then(() => {
				this.setState({ 
					showUpdateReasonForm: false,
					updateReasonsFormData: undefined,
					errorSaving: undefined
				});
			});
	}

	resetForm = () => {
		return new Promise((resolve => {
			const { initialDate, initialStartingDepotId } = this.props;

			this.setState({
				anyChangeHasBeenMade: false,
				rosterDetails: {
					...new StaffRosterFormClass(), 
					startingDepotId: initialStartingDepotId || '',
					startTimeLocal: initialDate || ''
				}
			}, () => resolve(true))
		}))
	}

	changeForm = (newData: object) => {
		return new Promise(resolve => {
			this.setState({
				anyChangeHasBeenMade: true,
				rosterDetails: {
					...this.state.rosterDetails,
					...newData
				}
			}, () => resolve(true));
		})
	}
	
	handleChangeInput = (id: string, value: string | boolean) => {
		if (id === 'date')
			return this.changeForm({ [id]: value, workerId: undefined, Worker: undefined });

		this.changeForm({ [id]: value })
	}

	handleChangeStartingPlace = (startingDepotId: string, startingDepotName: string) => {
		this.changeForm({ startingDepotId, startingDepotName });
	}

	handleChangeWorker = (worker?: IListItem) => {
		this.changeForm({ workerId: worker?.id || ''})
	}

	handleChangeTaskType = (e: any, TaskType?: ITaskType) => {
		this.changeForm({ mainTaskTypeId: e.target.value, TaskType });
	}

	handleChangeStartTime = (startTime: string) => {
		this.changeForm({ startTime });
	}

	handleCancel = () => {
		this.changeForm({ isCancelled: true })
			.then(this.showUpdateReasonForm);
	}

	handleSave = () => {
		if (this.state.showUpdateReasonForm) {
			if ($('#update-reason-form').valid())
				this.saveRosterChanges();

			return;
		}

		if ($('#roster-form').length > 0 && !$('#roster-form').valid())
			return;

		if (this.props.rosterId)
			return this.showUpdateReasonForm();
		else
			this.saveNewTask();
	}

	handleChangeUpdateReason = (id: string, value: string, updateReasonIndex: number) => {
		const { updateReasonsFormData } = this.state;
		if (!updateReasonsFormData) return;

		updateReasonsFormData[updateReasonIndex] = {
			...updateReasonsFormData[updateReasonIndex],
			[id]: value
		};

		this.setState({ updateReasonsFormData });
	}

	renderRosterDetails = () => {
		const { rosterDetails: staffRosterDetails } = this.state;
		return (
			<StaffRosterForm 
				{...staffRosterDetails}
				formId="roster-form"
				isReadOnly={staffRosterDetails.isCancelled}
				isEditMode={staffRosterDetails.id && !staffRosterDetails.isCancelled ? true : false}
				isWorkerReadOnly={this.props.isWorkerReadOnly}
				isDateReadOnly={this.props.isDateReadOnly}
				isStartingPlaceReadOnly={this.props.isStartingPlaceReadOnly}
				isTaskTypeReadOnly={this.props.isTaskTypeReadOnly}
				onChangeInput={this.handleChangeInput}
				onChangeStartingPlace={this.handleChangeStartingPlace}
				onChangeWorker={this.handleChangeWorker}
				onChangeTaskType={this.handleChangeTaskType}
				onChangeStartTime={this.handleChangeStartTime}
			/>
		)
	}

	renderUpdateReasonForm = () => {
		const { 
			rosterDetails, 
			anyChangeHasBeenMade, 
			updateReasonsFormData
		} = this.state;

		return (
			<Fragment>
				{
					rosterDetails.isCancelled && (
						<Callout icon="warning" title="Attention!" color="warning">
							This roster will be cancelled only for <b>{moment(rosterDetails.date).format('DD/MM/YYYY')}</b> and it will be generated next week despite this cancellation. <br /><br />
							{"If you want this shift not be automatically generated anymore, please go to the employee's profile and adjust their availability."}
						</Callout>
					) 
				}
				{
					!anyChangeHasBeenMade ? (
						<Callout icon="warning" title="Attention" text="No changes have been made for this roster" />
					) : (
						<Form id="update-reason-form">
							{
								updateReasonsFormData?.map((p, index) => (
									<div key={p.updateReasonCategoryId} className="update-reason-box">
										{ index !== 0 && <hr /> }
										<Well 
											bsSize="small"
											style={{ marginBottom: 0, fontWeight: 'bold' }}
										>
											{p.updateReasonLabel}
											{
												(p.oldValue || p.newValue) && (
													<Fragment>
														&nbsp;from <b className="text-danger"><u>{p.oldValue || 'None'}</u></b>
														&nbsp;to <b className="text-success"><u>{p.newValue || 'None'}</u></b>:
													</Fragment>
												)
											}
										</Well>
										<RosterUpdateReasonsList 
											id={`updateReasonId_${index}`}
											rosterUpdateReasonCategoryId={p.updateReasonCategoryId}
											value={p.updateReasonId}
											className="required"
											onChange={(e: ChangeEvent<FormControl & HTMLInputElement>) => this.handleChangeUpdateReason('updateReasonId', e.target.value, index)}
										/>
										<FormControl 
											id={`updateDescription_${index}`}
											placeholder="Please describe with details why this change is being made..."
											componentClass="textarea"
											className="required"
											rows={3}
											value={p.updateDescription}
											onChange={(e: ChangeEvent<FormControl & HTMLInputElement>) => this.handleChangeUpdateReason('updateDescription', e.target.value, index)}
										/>
									</div>
								))
							}
						</Form>
					)
				}
			</Fragment>
		)
	}

	renderBodyContent = () => {
		const { state } = this;

		if (state.errorLoadingRoster)
			return <ErrorBox error={state.errorLoadingRoster} retryFunc={this.fetchRosterDetails} />;

		if (state.isSaving || state.isLoadingRoster)
			return <Loader isLoading={state.isLoadingRoster} isSaving={state.isSaving} />;

		return (
			<Fragment>
				{
					!state.showUpdateReasonForm && state.rosterDetails.isCancelled && (
						<Callout 
							icon="times"
							title="Roster Cancelled"
							text="This roster was cancelled and can't be changed."
						/>
					)
				}
				{ state.errorSaving && <ErrorBox error={state.errorSaving} retryFunc={state.rosterDetails.id ? this.saveRosterChanges : this.saveNewTask} /> }
				{
					state.showUpdateReasonForm ? (
						this.renderUpdateReasonForm()
					) : (
						this.renderRosterDetails()
					)
				}
			</Fragment>
		)
	}

	render() {
		const { state, props } = this;
		
		return (
			<Modal
				id="roster-modal"
				backdrop="static"
				show={props.show}
				onHide={props.onClose}
				onEscapeKeyDown={props.onClose}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{ props.rosterId ? `Update Roster #${props.rosterId}` : 'Add New Roster' }
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ this.renderBodyContent() }
				</Modal.Body>
				{
					!state.isSaving && !state.isLoadingRoster &&  (
						<Modal.Footer>
							{
								!state.errorLoadingRoster && (
									<Fragment>
										<Button 
											bsStyle="success"
											className="pull-left"
											bsSize="lg"
											onClick={this.handleSave}
										>
											<FontAwesome name="floppy-o" />&nbsp;&nbsp;
											{ state.rosterDetails?.id ? state.showUpdateReasonForm ? 'Submit Changes' : 'Save Changes' : 'Save Roster' }
										</Button>
										{
											state.rosterDetails?.id && (
												!state.showUpdateReasonForm && !state.rosterDetails?.isCancelled ? (
													<Button
														bsStyle="danger"
														onClick={this.handleCancel}
													>
														<FontAwesome name="times" /> Cancel Roster
													</Button>
												) : (
													<Button
														bsStyle="warning"
														bsSize="lg"
														onClick={this.handleClickGoBack}
													>
														<FontAwesome name="arrow-left"/> Go Back
													</Button>
												)
											)
										}
									</Fragment>
								)
							}
							<Button bsSize="lg" onClick={props.onClose}>
								<FontAwesome name="sign-out" /> Close Without Saving
							</Button>
						</Modal.Footer>
					)
				}
			</Modal>
		)
	}
}

export default StaffRosterModal;