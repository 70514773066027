/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import moment from 'moment';
import ReactTable, { RowInfo } from 'react-table';
import 'react-table/react-table.css';

/// TODO: convert "any" to proper types

interface IProps {
	data: any[];
	page: number;
	pageSize: number;
	totalPages: number;
	sorted?: { desc: boolean; id: string }[];
	defaultPageSize?: number;
	isLoading: boolean;
	onPageChange?: () => void;
	onPageSizeChange?: () => void;
	onSortedChange?: () => void;
	onFetchData?: () => void;
	onViewDetails?: (vehicleDamage: any) => void;
}

const VehicleDamagesList: React.FC<IProps> = props => {

	const handleSeeFaultReportDetails = (vehicleDamage: any) => { 
		props.onViewDetails && props.onViewDetails(vehicleDamage);
	};

	return (
		<div className="fault-reports-list">
			<ReactTable
				getTrProps={(state: any, rowInfo?: RowInfo) => ({ 
					onClick: () => handleSeeFaultReportDetails(rowInfo?.original),
					className: rowInfo?.original.repairedOn ? 'green' : 'blue' 
				})}
				data={props.data}
				minRows={0}
				manual
				loading={props.isLoading}
				defaultPageSize={props.defaultPageSize}
				page={props.page}
				pageSize={props.pageSize}
				pages={props.totalPages}
				sorted={props.sorted}
				onFetchData={props.onFetchData}
				onPageChange={props.onPageChange}
				onPageSizeChange={props.onPageSizeChange}
				onSortedChange={props.onSortedChange}
				collapseOnDataChange={false}
				columns={[
					{
						Header: 'Tracker No.',
						accessor: 'serviceTrackerNumber',
						width: 90,
						Cell: row => row.value || '-'
					},
					{
						Header: 'Vehicle',
						accessor: 'fleetNumber',
						width: 80,
					},
					{
						Header: 'Created On',
						accessor: 'createdOnLocal',
						width: 100,
						Cell: row => moment(row.value).format('DD/MM/YYYY')
					},
					{
						Header: 'Reported By',
						accessor: 'WorkerCreatedBy.shortName',
						width: 150,
					},
					{
						Header: 'Company',
						accessor: 'WorkerCreatedBy.currentCompanyName',
						width: 220,
						Cell: row => row.value || '-',
					},
					{
						Header: 'Repaired On',
						accessor: 'repairedOn',
						width: 100,
						Cell: row => row.value ? moment(row.value).format('DD/MM/YYYY') : '-'
					},
					{
						Header: 'Closed On',
						accessor: 'closedOnLocal',
						width: 100,
						Cell: row => row.value ? moment(row.value).format('DD/MM/YYYY') : '-'
					},
					{
						Header: 'Closed By',
						accessor: 'WorkerClosedBy.shortName',
						width: 150,
						Cell: row => row.value || '-',
					},
					{
						Header: 'Comments',
						accessor: 'comments',
						Cell: row => row.value || '-',
					},
					{
						Header: 'Status',
						accessor: 'repairedOn',
						width: 80,
						Cell: row => (
							<b className={row.value ? 'text-success' : 'text-info'}>
								{ row.value ? 'Closed' : 'Open' }
							</b>
						)
					},
					// {
					// 	Header: 'Photo',
					// 	id: 'photo',
					// 	sortable: false,
					// 	width: 130,
					// 	accessor: d => d,
					// 	Cell: row => {
					// 		const { id, fleetNumber } = row.value;
							
					// 		return (
					// 			<div onClick={(e) => e.stopPropagation()}>
					// 				<VehicleDamagePicture 
					// 				damageId={id}
					// 				fleetNumber={fleetNumber}
					// 				onLoadImage={loadViewerJs}
					// 			/>
					// 			</div>
					// 		);
					// 	}
					// }
				]}
			/>
		</div>
	);
};

export default VehicleDamagesList;