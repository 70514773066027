import { AppInsights } from "applicationinsights-js"

export const logError = (error: Error, handledAt = 'Unhandled Exception', extraData?: { [name: string]: string; }) => {
	console.error(error);
	
	if (process.env.REACT_APP_ENVIRONMENT !== 'production')
		console.error('AppInsights Extra Data', extraData);

	if (!AppInsights) 
		return;
		
	AppInsights.trackException(error, handledAt, extraData);
}