import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { Creatable } from 'react-select';
import { FormControl } from 'react-bootstrap';

import ListsApi from '../../../app/api/ListsApi';
import moment from 'moment';
import componentRequestHandler from '../../../app/api/helpers/componentRequestHandler';
import { ErrorBox } from '../uiElements';

const propTypes = {
	id: PropTypes.string,
	containerId: PropTypes.string,
	value: PropTypes.string,
	disabled: PropTypes.bool,
	onlyActive: PropTypes.bool,
	isCreatable: PropTypes.bool,
	dropDown: PropTypes.bool,
	showLicenceClass: PropTypes.bool,
	showRoster: PropTypes.bool,
	allDetails: PropTypes.bool,
	onChange: PropTypes.func,
	color: PropTypes.oneOf(['danger', 'success', 'warning', 'primary', 'info', 'default']),
	bsSize: PropTypes.oneOf(['small', 'large']),
	type: PropTypes.oneOf([
		'all', 
		'mechanics', 
		'preStartSupervisors', 
		'faultReportAuthorisedBy', // Who can authorize the actions on fault reports
		'faultReportActionBy', // Who can do an action on fault reports
		'faultReportClosedOffBy', // Who can close an action on fault reports
	]),
	companyAbn: PropTypes.string,
	availabilityDate: PropTypes.string, // Check if the worker will be available on this date
	rosterDate: PropTypes.string, // used to retrieve the user's roster for the date if necessary
	isRequired: PropTypes.bool
};

const defaultProps = {
	id: 'azureId',
	type: 'all',
	allDetails: false,
	onlyActive: true
};

class UserSearch extends Component {
		state = {
			users: [],
			isLoading: true,
			error: null
		};
    
	componentDidMount() {
		this.fetchUsersData();
	}

	componentDidUpdate(prevProps) {
		const { companyAbn, state, rosterDate, availabilityDate } = this.props;
		if (
			prevProps.companyAbn !== companyAbn 
			|| prevProps.state !== state 
			|| prevProps.rosterDate !== rosterDate
			|| prevProps.availabilityDate !== availabilityDate
		)
			this.fetchUsersData();
	}	

	fetchUsersData = () => {
		const { availabilityDate } = this.props;
		const promise = this.getUserListApi();
		componentRequestHandler(this, promise, 'users')
		.then(users => {
			if (!availabilityDate) 
				return;
			
			users.forEach(user => {
				const { LeaveRequest, Roster } = user;
				
				if (LeaveRequest)
					user.className = (LeaveRequest.isApproved ? 'text-danger' : 'text-warning');
				else if (Roster)
					user.className = 'text-success text-bold';
			});
			this.setState({ users });
		})
	}	
	

	handleChange = user => {
		if (user) {
			const $select = window.$(this.select);
			$select.find('.Select-control').removeClass('invalid');
			$select.find('label.error').remove();
		}
		
		user = user || { value: '' };
		
		this.props.onChange && this.props.onChange(user, this.props.id);
	}

	handleChangeDropdown = e => {
		const user = this.state.users.find(p => p.azureId === e.target.value);		
		this.props.onChange && this.props.onChange(user, e);
	}

	getUserListApi = () => {
		const { type, onlyActive, companyAbn, availabilityDate, rosterDate, allDetails } = this.props;
		switch(type) {
		case 'preStartSupervisors':
			return ListsApi.getPreStartSupervisors;
		case 'faultReportAuthorisedBy':
			return ListsApi.getFaultReportAuthorisedBy;
		case 'faultReportActionBy':
			return ListsApi.getFaultReportActionBy;
		case 'faultReportClosedOffBy':
			return ListsApi.getFaultReportClosedOffBy;
		default:
			if (allDetails)
				return ListsApi.getWorkersListForTasks.bind(this, onlyActive, companyAbn, availabilityDate, rosterDate);	

			return ListsApi.getWorkersList.bind(this, false, onlyActive);
		}
	}

	renderNameLabel = (user) => {
		const { availabilityDate, showLicenceClass } = this.props;
		let label = user.shortName || user.name;		

		if (showLicenceClass)
			label += ` (${user.currentLicenceType || 'No Licence'})`;


		if (availabilityDate) {
			const { LeaveRequest, Roster } = user;
			
			if (LeaveRequest)
				label += ` (${LeaveRequest.isApproved ? 'Approved' : 'Pending'} ${LeaveRequest.absenceReasonTypeName} from ${moment(LeaveRequest.startDate).format('DD/MM')} to ${moment(LeaveRequest.endDate).format('DD/MM')})`;
			else if (!Roster)
				label += ' (Not Rostered)';
			else {
				const { startTimeLocal, budgetedTime } = Roster;
				const $rosterStartTime = moment(startTimeLocal);
				const $rosterFinishTime = $rosterStartTime.clone().add(budgetedTime, 'hours');
				label += ` (${$rosterStartTime.format('HH:mm')} - ${$rosterFinishTime.format('HH:mm')})`
			}
		}

		
		return label;
	}
	
	render() {
		const { state, props } = this;

		if (state.error)
			return <ErrorBox error={state.error} retryFunc={this.fetchUsersData} />;

		if (props.dropDown)
			return (
				<FormControl 
					id={props.id}
					value={props.value}
					disabled={props.disabled}
					bsSize={props.bsSize}
					componentClass="select"
					className={props.color ? `form-control-${props.color}` : ''}
					onChange={this.handleChangeDropdown}
				>
					<option value="">{ state.isLoading ? 'Loading...' : '--- SELECT ---'}</option>
					{
						state.users.map(user => (
							<option 
								key={user.azureId}
								value={user.azureId}
							>
								{ user.shortName || user.name }
							</option>
						))
					}
				</FormControl>
			);

		const selectPops = {
			value: props.value,
			placeholder: state.isLoading ? 'Loading...' : 'Search User...',
			noResultsText: !state.isLoading && 'No user was found',
			disabled: props.disabled,
			options: state.users.map(user => ({ value: user.azureId || user.id, label: this.renderNameLabel(user), ...user })),
			cache: false,
			isLoading: state.isLoading,
			promptTextCreator: () => 'If it\'s not a Micway employee, type the full name and click here to proceed',
			onChange: this.handleChange
		};

		return (
			<div 
				id={props.containerId} 
				ref={ref => this.select = ref} 
				className="react-select-container"
			>
				{
					props.isCreatable ? (
						<Creatable { ...selectPops } />
					) : (
						<Select { ...selectPops } />
					)
				}
				<FormControl 
					id={props.id} 
					className={props.isRequired ? 'required' : ''}
					type="hidden" 
					value={props.value || ''}
				/>
			</div>
		);
	}
}

UserSearch.propTypes = propTypes;
UserSearch.defaultProps = defaultProps;

export { UserSearch };