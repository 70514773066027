import React, { useEffect, useState, ChangeEvent } from 'react';
import ListsApi from '../../../app/api/ListsApi';
import ListContainer from './_ListContainer';
import { v4 as uuid } from 'uuid';
import { IListRunTypeItem } from '../../../app/models/List';
import { FormControl } from 'react-bootstrap';

interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>  {
	firstOptionText?: string;
	bsSize?: 'small' | 'large';
	taskTypeId?: string;
	onlyActive?: boolean;
	onChange?: (e: ChangeEvent<FormControl & HTMLInputElement>, runTypeName: string, runType: IListRunTypeItem) => void;
}

export const RunTypesList: React.FC<IProps> = props => {
	// Used to update the list in case props change
	const [componentKey, setComponentKey] = useState<string | undefined>(uuid());
	
	const { onlyActive, taskTypeId } = props;
	useEffect(() => {
		setComponentKey(uuid());
	}, [onlyActive, taskTypeId]);

	return (
		<ListContainer
			{...props as any}	
			key={componentKey} 
			promise={() => ListsApi.getRunTypes(props.onlyActive, props.taskTypeId ? parseInt(props.taskTypeId) : undefined)}
		/>
	)
};