// This is for a new section in the left menu, mainly for external links 
// import groups from '../app/schemas/PermissionGroups';

// export default  [
// 	{ 
// 		title: 'Department', 
// 		icon: 'pie-chart',
// 		external: true,
// 		urlPath: '/',
// 		authorize: [groups.ADMIN],
// 		nested: [
// 			{
// 				title: 'Delivery Summaries', 
// 				icon: 'truck',
// 				external: true,
// 				urlPath: 'https://micway.au/bi-delivery-summaries',
// 			}, 
// 		]
// 	},
// ]

import groups from '../app/schemas/PermissionGroups';
import WorkerPermission from '../app/enums/WorkerPermission';
import EmbedPowerBI from '../components/features/powerBI/EmbedPowerBI';

const PowerBIRoutes = [
	{
		path: 'deliverySummaries',
		menuItem: { title: 'Delivery Summaries', icon: 'truck' },
		reportId: '6202a59f-833a-4351-a67d-36b4b0f6c5c9',
		allowedPermissionId: WorkerPermission.POWER_BI_DELIVERY_SUMMARIES
	},
	{
		path: 'palletSummary',
		menuItem: { title: 'Pallet Summaries', icon: 'archive' },
		reportId: '0cea1c84-4f28-46ca-b3cb-ea19d622ac51',
		allowedPermissionId: WorkerPermission.POWER_BI_PALLET_SUMMARIES
	}
]

export default { 
	path: 'power-bi',
	menuItem: { title: 'Power BI', icon: 'pie-chart' },
	authorize: [groups.ADMIN, WorkerPermission.POWER_BI_FULL],
	childRoutes: PowerBIRoutes.map(route => ({
		...route,
		component: () => EmbedPowerBI({ reportId: route.reportId })
	}))
};