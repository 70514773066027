enum WorkerPermission {
	LIST_PRE_START_CHECK_OUT = 1,
	PRE_START_NOTIFICATION = 2,
	LIST_FAULT_REPORT_ACTION_BY = 3,
	STARTUP_SETUP_PIN = 4,
	LIST_FAULT_REPORT_AUTHORISED_BY = 5,
	LIST_FAULT_REPORT_CLOSED_OFF_BY = 6,
	ENABLE_PRE_START_V2 = 7,
	ENABLE_FLEET_TRACKING = 8,
	POWER_BI_FULL = 9,									// Need to add to SQL `ListPermission` table
	POWER_BI_DELIVERY_SUMMARIES = 10,		// Need to add to SQL `ListPermission` table
	POWER_BI_PALLET_SUMMARIES = 11			// Need to add to SQL `ListPermission` table
}

export default WorkerPermission;
