import task from './endpoints/taskEndpoints';
import httpRequests from './httpRequests';
import { ITaskDetail, TaskPOST, TaskPUT, TaskCANCEL, TaskDELETE, ITaskListItem } from '../models/Task';
import { IResponsePOST } from '../models/Application';

export default {
	/**
		 * Gets a list with the tasks based on the start time and place
		 */
	getTasksList(startTimeLocal: string, finishTimeLocal: string, startingDepotId?: number | string):  Promise<ITaskListItem[]> {
		const endpoint = task.root;
		const filterString = JSON.stringify({ startTimeLocal, finishTimeLocal, startingDepotId, pageSize: 10000, sorted: [], page: 0 });
		return httpRequests.get(endpoint, { filterString }).then(result => result.records);
	},

	/**
	 * Gets the details of a task
	*/
	getTaskDetails(taskId: number): Promise<ITaskDetail> {
		const endpoint = task.byId(taskId);
		return httpRequests.get(endpoint);
	},

	/** 
	 * Gets the task details + run details, but using the Run ID
	 */
	getTaskDetailsWithRunData(runId: number): Promise<ITaskDetail> {
		const endpoint = task.byRunId(runId);
		return httpRequests.get(endpoint);
	},

	createTask(data: TaskPOST): Promise<IResponsePOST> {
		const endpoint = task.root;
		return httpRequests.post(endpoint, data);
	},

	updateTask(taskId: number, data: TaskPUT) {
		const endpoint = task.byId(taskId);
		return httpRequests.put(endpoint, data);
	},

	/**
	 * Cancels a task but only for that specific day.
	 * it will continue to appear in the future weeks when replication is done
	*/
	cancelTask(taskId: number, data: TaskCANCEL) {
		const endpoint = task.cancel(taskId);
		return httpRequests.put(endpoint, data);
	},

	/**
	 * Deletes a task "forever"
	 * it will never appear again in the future weeks when replication is done
	*/
	deleteTask(taskId: number, data: TaskDELETE) {
		const endpoint = task.delete(taskId);
		return httpRequests.put(endpoint, data);
	},

	/**
	 * Replicates one week into another
	 * Attention: it deletes any roster of the targeted week to avoid duplication
	 */
	replicateTasks(yearSource: number, weekSource: number, yearTarget: number, weekTarget: number) {
		const endpoint = task.replicate;
		return httpRequests.post(endpoint, { yearSource, weekSource, yearTarget, weekTarget });
	},
	
};