import groups from '../app/schemas/PermissionGroups';

// Servicing
import ServicingCounters from '../components/features/vehicles/ServicingCounters';

import VehicleDamagesListContainer from '../components/features/vehicles/damages/list/VehicleDamagesListContainer';
import VehiclesManagement from '../components/features/vehicles/management';
import VehiclesMaintenance from '../components/features/vehicles/maintenance';

export default { 
	path: 'vehicles',
	menuItem: { title: 'Vehicles', icon: 'truck' },
	authorize: [
		groups.ADMIN_STAFF_FULL, groups.ADMIN_STAFF_SIMPLE, 
		groups.WASHBAY_ADMIN, groups.WASHBAY_MECHANICS, groups.DEPOT_ADMIN,
		groups.SUPERVISORS, groups.KIOSK
	],
	childRoutes: [
		{ 
			path: 'management',
			menuItem: { title: 'Management', icon: 'gear' },
			component: VehiclesManagement
		},
		{ 
			path: 'services',
			menuItem: { title: 'Veh. Services', icon: 'wrench' },
			component: VehiclesMaintenance
		},
		{ 
			path: 'damages',
			menuItem: { title: 'Damages', icon: 'fire' },
			component: VehicleDamagesListContainer
		},
		{ 
			path: 'servicing',
			menuItem: { title: 'Servicing', icon: 'edit' },
			childRoutes: [
				{ 
					path: 'counters', 
					menuItem: { title: 'Counters', icon: 'file-excel-o' },
					component: ServicingCounters
				}
			] 
		}
	]
};