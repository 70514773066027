import { InteractionRequiredAuthError, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { logError } from '../helpers/logger';

export const msalInstance = new PublicClientApplication({
  auth: {
		authority: 'https://login.microsoftonline.com/micway.com.au',
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID || '',
		redirectUri: process.env.REACT_APP_HOST,
		postLogoutRedirectUri: 'https://micway.com.au'
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
});

export const redirectRequest: RedirectRequest = {
	scopes: [process.env.REACT_APP_AZURE_SCOPE || '']
};

export const acquireAccessToken = async () => {
	try {
			await checkAndSetActiveAccount();
			const authResult = await msalInstance.acquireTokenSilent(redirectRequest);
			return authResult.accessToken;
	} catch (e) {
			if (e instanceof InteractionRequiredAuthError)
				logError(e, 'acquireAccessToken: InteractionRequiredAuthError', { error: e.message });
			else {
				const error = e as Error;
				logError(error, "acquireAccessToken: Unexpected Error", {error: error?.message })
			}

		await msalInstance.loginRedirect(redirectRequest);
	}
};

export const initializeMsal = async () => {
	await msalInstance.initialize();
	await checkAndSetActiveAccount();
}

export const logout = () => {
	msalInstance.logoutRedirect();
}

const checkAndSetActiveAccount = async () => {
	const activeAccount = msalInstance.getActiveAccount();
	const accounts = msalInstance.getAllAccounts();

	// If there's no active account redirect to login page
	if (!activeAccount && accounts.length === 0)
		return await msalInstance.loginRedirect(redirectRequest);

	// If there's no active account and there's more than one account, redirect to select account page
	if (!activeAccount && accounts.length > 1)
		return await msalInstance.acquireTokenRedirect({ ...redirectRequest, prompt: 'select_account' });

	// If no active account is found, and there's only one account, set the first account to be active
	if (!activeAccount && accounts.length === 1)
		msalInstance.setActiveAccount(accounts[0]);
}