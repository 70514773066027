import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
// import { Col, Row } from 'react-bootstrap';
import DurationParse from '../../../../app/helpers/DurationParse';
// import Icon from 'react-fontawesome';
import moment from 'moment';
import { IPalletLoadListItem } from '../../../../app/models/Pallet';
import { ProgressBar } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';

interface IProps {
	data: IPalletLoadListItem;
	onClick?: (id: string) => void;
}

const PalletLoadBox: React.FC<IProps> = props => {
	var interval = setInterval(() => {
		setLoadingTimeInSec(loadingTimeInSec + 60)
	}, 60000);

	const { data } = props;
	const [loadingTimeInSec, setLoadingTimeInSec] = useState(moment().diff(moment(data.loadStartedOn), 'seconds'));

	useEffect(() => {
		if (data.loadFinishedOn)
			clearInterval(interval);

		return () => clearInterval(interval);
	}, [data.loadFinishedOn, interval])
	
	// Get percentage of loaded pallets based on total pallet capacity of the trailer
	const percentageLoadedTrailer = data.loadFinishedOn ? 100 : Math.round((data.totalPallets / data.trailerPalletCapacity) * 100);

	const status = useMemo(() => {
		if (data.isPaused && !data.loadFinishedOn)
			return { style: 'danger', bgColor: 'bg-red' };
		if (percentageLoadedTrailer < 50)
			return { style: 'warning', bgColor: 'bg-blue' };
		if (percentageLoadedTrailer < 90)
			return { style: undefined, bgColor: 'bg-blue' };
		if (percentageLoadedTrailer < 100)
			return { style: 'success', bgColor: 'bg-blue' };
		
		return { style: 'success', bgColor: 'bg-green' };
	}, [data.isPaused, data.loadFinishedOn, percentageLoadedTrailer]);

	const $startTime = moment(data.loadStartedOn);
	const $finishTime = moment(data.loadFinishedOn || Date.now());
	const timeSpentParsed = data.loadCancelledOn ? '-' : DurationParse.toHourMin(moment.duration($finishTime.diff($startTime)).asSeconds(), 'short');
	
	const statusIcon = useMemo(() => {
		if (data.loadFinishedOn)
			return "check";

		if (data.isPaused)
			return "pause";
		
		return "refresh"
	}, [data.isPaused, data.loadFinishedOn]);

	return (
		<div className={classNames("panel-row-container", status.bgColor, "cursor-pointer")} onClick={() => props.onClick && props.onClick(data.id)}>
			<div className="flex-1 flex flex-row panel-row">
				<span className="flex-2">{ data.runNumber }</span>
				<span className="flex-2">{ data.depotName }</span>
				<span className="flex-1">{ data.dockNumber || '-' }</span>
				<span className="flex-2">{ data.fleetNumber || '-' }</span>
				<span className="flex-2">{ data.totalPallets || '0' } {!data.loadFinishedOn ? `/ ${ data.trailerPalletCapacity || '0' }` : ''} pallets</span>
				<span className="flex-3">{ data.workerName || '-' }</span>
				<span className="flex-2">{ timeSpentParsed }</span>
				<FontAwesome name={statusIcon} className="status-icon" />
				<ProgressBar 
					active={!data.isPaused && !data.loadFinishedOn}
					now={percentageLoadedTrailer} 
					bsStyle={status.style}
				/>
			</div>
		</div>
	)
}

export default PalletLoadBox;