import React from 'react';
import { Link } from 'react-router';
import MainLayout from './_MainLayout';
import { MainContent } from '../common/layout';
import UnauthorizedImage from '../../app/assets/img/unauthorized.png';

const Forbidden = () => (
	<MainContent>
		<MainLayout
			showLogo={false}
			title="Forbidden"
			color="yellow"
			text="Oops! Access denied"
			imageSize={200}
			image={UnauthorizedImage}
		>
			You don{"'"}t have permission to access this page.<br/>
			<Link to="/">Click Here</Link> to return to the initial page.
		</MainLayout>
	</MainContent>
)

export { Forbidden };