import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';
import Icon from 'react-fontawesome';
import { MainContent } from '../../common/layout';
import { setMarkers, setZoom, setPosition } from '../../../stores/redux/map';
import TrackingApi from '../../../app/api/TrackingApi';
import GoogleMap from '../../common/GoogleMapOld';
import { MissingVehiclesList } from '../../common/tracking';

import { ContentBox, Loader, ErrorBox } from '../../common/uiElements';

class ReportMissingVehicles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
	}
	
	UNSAFE_componentWillMount() {
		this.fetchMissingVehiclesData();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchMissingVehiclesData = () => {
		this.setState({ isLoading: true, error: null });
		TrackingApi.vehiclesMissingNow()
			.then(missingVehicles => {
				if (this.unmounted) return;
		
				this.props.setMarkers(missingVehicles);
				this.setState({ isLoading: false });
			})
			.catch(error => {
				console.error(error);
				this.setState({ error: 'It wasn\'t possible to retrieve the data', isLoading: false });
			});
	}

	handleRefresh = () => {
		this.fetchMissingVehiclesData();
	}

	onSelectMissingVehicle = vehicleData => {
		var { lat, lng } = vehicleData;

		this.props.setZoom(19);
		this.props.setPosition(lat, lng);

		window.$('html, body').animate({scrollTop: window.$('#mapBox').offset().top - 50}, 500);
	}

	render() {
		const s = this.state;

		return (
			<div>
				<MainContent title="Missing Vehicles" subtitle="">
					<Row>
						<Col xs={12}>
							<FormGroup>
								<Button 
									block 
									bsStyle="primary"
									disabled={s.isLoading}
									onClick={this.handleRefresh}
								>
									<Icon name="refresh"/> Refresh Missing Vehicles
								</Button>
							</FormGroup>
						</Col>
					</Row>
					{
						s.error ? (
							<ErrorBox error={s.error} retryFunc={this.fetchMissingVehiclesData} />
						) : (
							s.isLoading ? (
								<Loader text="Loading data..."/>
							) : ( 
								<Row>
									<Col xs={12}>
										<ContentBox title="Vehicles" icon="truck" color="primary" noMarginBottom={true}>
											<MissingVehiclesList onSelectMissingVehicle={this.onSelectMissingVehicle} />
										</ContentBox>
										<div id="mapBox">
											<GoogleMap height="50vh" typeControl={true} />
										</div>
									</Col>
								</Row>
							)
						)
					}
				</MainContent>
			</div>
		);
	}
}


const mapStateToProps = state => ({ filters: state.filters });
const mapDispatchToProps = { setMarkers, setZoom, setPosition };

export default connect(mapStateToProps, mapDispatchToProps)(ReportMissingVehicles);