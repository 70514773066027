import moment from 'moment';
import React from 'react';
import ReactTable, { Column, RowInfo } from 'react-table';
import { IPallet_Unload } from '../../../../../app/models/Pallet';
import { Callout } from '../../../../common/uiElements';

interface IProps {
	pallets: IPallet_Unload[];

	isReadOnly?: boolean;

	onSelectPallet: (pallet: IPallet_Unload, rowIndex: number) => void;
	onDeletePallet: (pallet: IPallet_Unload) => void;
}

const PalletsListUnloads: React.FC<IProps> = props => (
	<>
		<Callout icon="info-circle" color="info" title="Tap on the pallet to edit" />
		<ReactTable
			minRows={0}
			pageSize={props.pallets.length}
			showPagination={false}
			data={props.pallets as any}
			getTdProps={(state: any, rowInfo?: RowInfo, column?: Column) => {
				// Disable row selection for the DELETE column
				if (!rowInfo || column?.id === 'delete') return {};

				const pallet = rowInfo.original as IPallet_Unload;
				return {
					onClick: () => props.onSelectPallet && props.onSelectPallet(pallet, rowInfo.index)
				}
			}}
			// getTrProps={(state: any, rowInfo?: RowInfo) => {
			// 	if (!rowInfo) return {};
			// }}
			columns={[
				{
					Header: '#',
					id: 'order',
					width: 30,
					Cell: row => row.index + 1,
				},
				{
					Header: 'Pallet ID',
					id: 'palletId',
					width: 150,
					Cell: row => {
						const pallet = row.original as IPallet_Unload;
						return (
							<>
								{pallet.palletId}
							</>
						)
					}
				},
				{
					Header: 'Unloaded At',
					accessor: 'scannedAt',
					width: 100,
					Cell: row => row.value ? moment(row.value).format('HH:mm') :  '-'
				},
				
				{
					Header: 'Unloaded By',
					accessor: 'scannedBy',
					Cell: row => !row.value ? '-' : row.value ? row.value.name : '',
				},
			]}
		/>
	</>
);



export default PalletsListUnloads;