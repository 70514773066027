import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import moment from 'moment';

import 'datatables.net';
import 'datatables.net-buttons-bs';
import 'datatables.net-buttons';

const propTypes = {
	cssClass: PropTypes.string,
	filename: PropTypes.string,
	info: PropTypes.bool,
	lengthChange: PropTypes.bool,
	ordering: PropTypes.bool,
	sortColumnIndex: PropTypes.number,
	sortColumnType: PropTypes.oneOf(['asc', 'desc']),
	pageLength: PropTypes.number,
	paging: PropTypes.bool,
	printable: PropTypes.bool,
	printTitle: PropTypes.string,
	printHeader: PropTypes.any,
	searching: PropTypes.bool
};

const defaultProps = {
	cssClass: '',
	info: true,
	lengthChange: true,
	ordering: true,
	sortColumnIndex: null,
	sortColumnType: 'asc',
	pageLength: 10,
	paging: true,
	printable: false,
	printTitle: '',
	printHeader: '',
	searching: true
};

class Table extends Component {
	componentDidMount() {
		var { 
			filename,
			info,
			lengthChange,
			ordering,
			pageLength,
			paging,
			printable,
			printTitle,
			printHeader,
			searching,
			sortColumnIndex,
			sortColumnType
		} = this.props;

		if(window.$(this.table).find('> table > tbody > tr').length > 0) {
			let options = {
				info,
				lengthChange,
				ordering,
				pageLength,
				paging,
				searching,
				dom: '',
				language: {
					search: '_INPUT_',
					searchPlaceholder: 'Search...',
					'lengthMenu': '_MENU_'
				},
				lengthMenu: [[5, 10, 25, 50, -1], [5, 10, 25, 50, 'All']],
				order: [],
				buttons: []
			};

			if (printable) {
				options.buttons = [
					{
						extend: 'print',
						titleAttr: 'Print',
						autoPrint: true,
						text: '<i class="fa fa-print"></i> Print',
						customize: function (win) {
							window.$(win.document.body).find('h1').remove();

							// Logo + Title
							window.$(win.document.body).prepend(
								`<div id="title" style="height: 85px; border-bottom: 2px solid #dedede; margin-bottom: 30px;">
						<img src="https://s3-ap-southeast-2.amazonaws.com/micwaydata/cdn/logo.png" style="float: left; margin-top: 8px; height: 70px;" />
						<h1 style="font-size: 30px; border-left: 2px solid rgb(16, 16, 16); padding-left: 15px; float: left;">${printTitle}</h1>
					</div>
					`
							);

							var printHeaderDiv = document.createElement('div');
							ReactDOM.render(printHeader, printHeaderDiv);
							window.$(win.document.body).find('#title').after(printHeaderDiv);
				
							// Table
							window.$(win.document.body)
								.find('table')
								.addClass('table-condensed')
								.css('font-size', '14px');
						}
					},
					{
						extend: 'csv',
						text: '<i class="fa fa-file-excel-o"></i> CSV',
						titleAttr: 'CSV',
						filename: () => filename || `report_${moment().format('DD-MM-YYYY_HH-mm-ss')}`
					},
					{
						extend: 'copyHtml5',
						text: '<i class="fa fa-copy"></i> Copy',
						titleAttr: 'Copy to clipboard',
					}
				];

				options.dom = `<'row'<'col-sm-6 left'${printable ? 'B' : ''}><'col-sm-6 right'lf>>`;
			}

			options.dom += `<'row'<'col-xs-12't>>
				<'row'<'col-xs-12 center'p>>
				<'row'<'col-xs-12 center'i>>`;

			window.$.fn.dataTable.moment('DD/MM/YY HH:mm');
			var dataTable = window.$(this.table).find('> table').DataTable(options);

			if (sortColumnIndex != null)
				dataTable.column(sortColumnIndex).order(sortColumnType).draw();
		}
		else
			window.$(this.table).find('tbody').append('<tr><td>No data has been found.</td></tr>');
	}

	render() {
		var { cssClass, children } = this.props;

		return (
			<div className="table-responsive" ref={ref => this.table = ref}>
				<table className={('table '  + cssClass)}>
					{children}
				</table>
			</div>
		);
	}
}

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export { Table };