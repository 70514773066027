import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { AlertModal, Callout } from '../../../../common/uiElements';
import { Table, FormGroup, Button } from 'react-bootstrap';

const propTypes = {
	canBeTypingError: PropTypes.bool,
	massManagementData: PropTypes.object,
	overloadedList: PropTypes.arrayOf(
		PropTypes.shape({
			fleet: PropTypes.string.isRequired,
			overWeight: PropTypes.number.isRequired
		})
	),
	onClose: PropTypes.func,
	onClickIsNotTypingError: PropTypes.func
};

const PreStartOverloadModal = ({
	canBeTypingError,
	massManagementData,
	overloadedList,
	onClose,
	onClickIsNotTypingError
}) => {
	const { PreStartRuns, fleet1, fleet2 } = massManagementData;
	return (
		<AlertModal title="Warning" onClose={onClose} hideOkButton={canBeTypingError}>
			{
				canBeTypingError ? (
					<Fragment>
						<Callout title="Attention!" color="warning" icon="warning">
							The discrepancy of the mass breach is over 5,000Kg.
						</Callout>
						<h4><b>Are you sure you entered the right information?</b></h4>
						<Table condensed>
							<thead>
								<tr>
									<th>Run</th>
									<th>Payload {fleet1}</th>
									{ fleet2 && <th>Fleet {fleet2}</th> }
								</tr>
							</thead>
							<tbody>
								{
									PreStartRuns.map(({ number, fleet1PayloadInKg, fleet2PayloadInKg }, index) => (
										<tr key={index}>
											<td>{number}</td>
											<td>{fleet1PayloadInKg}Kg</td>
											{ fleet2 && <td>{fleet2PayloadInKg}Kg</td> }
										</tr>
									))
								}
							</tbody>
						</Table>
					</Fragment>
				) : (
					<Fragment>
						<Callout title="Mass Breach" color="danger" icon="warning">
							The payload is over the limit for the fleet below:
						</Callout>
						<Table>
							<thead>
								<tr>
									<th>Fleet</th>
									<th>Overweight</th>
								</tr>
							</thead>
							<tbody>
								{
									overloadedList.map(({ fleet, overWeight }) => (
										<tr key={fleet}>
											<td>{fleet}</td>
											<td>{overWeight} Kg</td>
										</tr>
									))
								}
							</tbody>
						</Table>
					</Fragment>
				)
			}
			{
				canBeTypingError ? (
					<Fragment>
						<FormGroup>
							<Button
								block
								bsStyle="danger"
								onClick={onClose}
							>
								It was a typing error
							</Button>
						</FormGroup>
						<FormGroup>
							<Button
								block
								bsStyle="success"
								onClick={onClickIsNotTypingError}
							>
								I entered the right information
							</Button>
						</FormGroup>
					</Fragment>
				) : (
					<Fragment>
						All supervisors have been notified of this issue. Please, review the payload and try again.<br /><br />
						<Callout color="danger">
							Report to your supervisor immediately
						</Callout>		
					</Fragment>
				)
			}
			
		</AlertModal>
	);
};

PreStartOverloadModal.propTypes = propTypes;

export default PreStartOverloadModal;
