import React, { useMemo, useRef, useState } from 'react';
import { Popover, OverlayTrigger, Badge } from 'react-bootstrap';
import RunApi from '../../../../../app/api/RunApi';
import { Callout, Loader } from '../../../../common/uiElements';
import { IListItem } from '../../../../../app/models/List';
import FontAwesome from 'react-fontawesome';

interface IProps {
	runId: number;
	totalStores: number;
}

interface IState {
	runId: number;
	storesList?: IListItem[];
	isLoading: boolean;
	error?: Error;
}

const TaskStoresPopover: React.FC<IProps> = props => {
	const isPopoverOpen = useRef(false);
	const [state, setState] = useState<IState>({
		runId: props.runId,
		storesList: undefined as IListItem[] | undefined,
		isLoading: true,
		error: undefined
	})

	const fetchStores = () => {
		// If the popover is not open or the stores list is already loaded, don't fetch again
		if (!isPopoverOpen.current || state.storesList)
			return;
		
		RunApi.getStores(props.runId)
		.then(storesList => {
			if (!isPopoverOpen.current) 
				return;

			setState({ ...state, storesList: storesList, isLoading: false })
		})
		.catch(error => {
			if (!isPopoverOpen.current)
				return;
			
			setState({ ...state, error, isLoading: false })
		})
	};

	const handleEnter = () => {
		isPopoverOpen.current = true;
		// Timeout so the popover doesn't fetch data if the suer is just hovering over the task quickly
		setTimeout(fetchStores, 500);
	}

	const handleExit = () => {
		isPopoverOpen.current = false;
	}

	const popoverContent = useMemo(() => (
		<Popover 
			id={"stores-popover_" + props.runId}
			title="Stores"
			style={{ minHeight: 100 + props.totalStores * 20, minWidth: 320 }} 
		>
			<div>
				{
					!state.storesList || state.isLoading ? (
						<Loader />
					) : (
						<>
							{
								!state.storesList || state.storesList.length === 0 ? (
									<Callout title="No Stores Found">
										No stores were found for this run
									</Callout>
								) : (
									state.storesList.map((store, index) => (
										<div key={index} style={{ lineHeight: 1.7 }}>
											<Badge style={{ backgroundColor: '#3498db' }}>
												{ index + 1 }
											</Badge>
											&nbsp;
											{ store.name }
										</div>
										
									))
								)
							}
						</>
					)
				}
			</div>
		</Popover>
	), [state.storesList, state.isLoading, props.totalStores, props.runId]);

	return (
		<OverlayTrigger 
			rootClose
			trigger={['hover', 'focus']}
			placement="top" 
			overlay={popoverContent}
			onEnter={handleEnter}
			onExit={handleExit}
		>
			<div className="text-info cursor-pointer" style={{ height: '100%', paddingLeft: 5, paddingTop: 7 }}>
				<FontAwesome name="search" /> { props.totalStores } Store{  props.totalStores > 1 ? 's' : ''}
			</div>
		</OverlayTrigger>
	);
}

export default TaskStoresPopover;