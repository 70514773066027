/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import MainLayout from './_MainLayout';
import { logout } from '../../app/config/auth.config';
import { Callout } from '../common/uiElements';
import * as Bowser from "bowser"; // TypeScript

const AuthCacheError = () => {
	const browser = Bowser.getParser(window.navigator.userAgent).getBrowserName(true);

	const renderError = () => {
		if (browser.indexOf('safari') !== -1)
			return (
				<Callout title="ATTENTION IPHONE USERS" color="danger" icon="warning">
					<b>If you are using SAFARI, follow the instructions below:</b>
						<br/>
						<br/>
						<ol className="text-left">
							<li>
								Go to the Phone{"'"}s <b>Settings</b> &gt; <b>Safari</b> &gt; Disable <b>Prevent Cross-Site Tracking</b><br />
							</li>
							<li>
								Once complete, <a onClick={window.location.reload}>Click Here</a> to reload the page.
							</li>
						</ol>
						<a download="tms-safari-fix.jpg" rel="noopener noreferrer" href="http://cdn.micway.com.au/public/safari_disable_cross-site.jpg" target="_blank">
							<h5>Click Here to download a step-by-step tutorial for SAFARI</h5>
						</a>
				</Callout>
			);

		if (browser.indexOf('chrome') !== -1)
			return (
				<Callout title="ATTENTION GOOGLE CHROME USERS" color="danger" icon="warning">
					<b>If you are using GOOGLE CHROME, follow the instructions below:</b>
					<br/>
					<br/>
					<ol className="text-left">
						<li>
							Go to the Phone{"'"}s <b>Settings</b> &gt; <b>Chrome</b> &gt; Enable <b>Allow Cross-Website Tracking</b><br />
						</li>
						<li>
							Once complete, <a onClick={window.location.reload}>Click Here</a> to reload the page.
						</li>
					</ol>
				</Callout>
			);

		return (
			<Callout title="READ CAREFULLY" color="danger" icon="warning">
				Due to some issues with Android default browsers, please use Google Chrome.
			</Callout>
		);
	};

	return (
		<MainLayout color="red">
			{ renderError() }
			<h4><b>Please try the steps below in case the previous one didn{"'"}t work:</b></h4>
			<ul className="text-left">
				{ browser.indexOf('safari') !== -1 && <li>Try to use Google Chrome</li> }
				{ browser.indexOf('chrome') !== -1 && <li>Try to use Safari</li> }
				<li><a onClick={logout}>Sign Out</a> and try again</li>
				<li>Clean your browser{"'"}s cache</li>
				<li>Check if the date and time of your device are correct and if it{"'"}s on automatic mode</li>
				<li>If the error persists, contact IT support through <a href="mailto:support@micway.com.au">support@micway.com.au</a>.</li>
			</ul>
		</MainLayout>
	);
}

export { AuthCacheError };