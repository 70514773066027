/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import Icon from 'react-fontawesome';

import { Modal, Row, Col, FormGroup, Button } from 'react-bootstrap';
import WorkerAddressHistsSchema from '../../../../../app/schemas/worker/WorkerAddressHistsSchema.json';
import { CompleteAddressInput } from '../../../../common/inputs';
import { Form } from '../../../../common/uiElements';

const propTypes = {
	data: PropTypes.array,
	editMode: PropTypes.bool,
	readOnly: PropTypes.bool,
	onChange: PropTypes.func,

	/** If true, the user won't be able to edit any already added address  */
	isEditable: PropTypes.bool,

	/** If true, the user won't be able to delete any already added address  */
	isRemovable: PropTypes.bool,
};

const validations = {
	address1: 'required'
};

class WorkerAddressHists extends React.Component {
	constructor() {
		super();
		this.state = {
			currentActiveAddress: null,
			showModalAddressData: false,
			showModalAddressHistory: false,
			dataAddressModal: {
				...WorkerAddressHistsSchema,
				isActive: true
			}
		};

		this.onChange = this.onChange.bind(this);
		this.reorderAddresses = this.reorderAddresses.bind(this);
		this.handleChangeAddress = this.handleChangeAddress.bind(this);
		this.handleChangeAddressModal = this.handleChangeAddressModal.bind(this);
		this.handleToggleDeletedStatus = this.handleToggleDeletedStatus.bind(this);
		this.handleEditAddress = this.handleEditAddress.bind(this);
		this.handleClickAddNew = this.handleClickAddNew.bind(this);
		this.handleCloseAddressDataModal = this.handleCloseAddressDataModal.bind(this);
		this.handleClickSeeHistory = this.handleClickSeeHistory.bind(this);
		this.handleCloseModalHistory = this.handleCloseModalHistory.bind(this);
		this.handleSaveAddress = this.handleSaveAddress.bind(this);
		this.renderTableAddress = this.renderTableAddress.bind(this);
	}

	UNSAFE_componentWillMount() {
		const currentActiveAddress = this.props.data.find(p => p.isActive);
		this.setState({ currentActiveAddress });
		this.onChange(this.props.data);
	}

	/**
     * When any address data changes. Call the onChange prop.
     * @param {array} addresses Addresses Array
     */
	onChange(addresses) {
		addresses = this.reorderAddresses(addresses);

		for (let i in addresses) {
			addresses[i].index = parseInt(i, 10);
		}

		if (this.props.onChange)
			return this.props.onChange(addresses);
	}

	/**
     * Reorder the data. Actives first, followed by 'lastChangedOn' Desc.
     * @param {array} data The addresses array
     */
	reorderAddresses(addresses) {
		return addresses.sortBy(function(o){ return [ -o.isActive, -new Date(o.lastChangedOn || moment()) ]; });	
	}

	/**
     * Change the address when it's just a single one address to add (new worker page)
     * @param {object} address Address Object
     */
	handleChangeAddress(address) {
		address.lastChangedOn = moment();
		return this.onChange([address]);
	}
    
	/**
     * Change the address data that is openned on Modal
     * @param {object} address Address Object
     */
	handleChangeAddressModal(address) {
		return this.setState({ dataAddressModal: address });
	}

	/**
     * Toggle address deleted status
     * @param {number} indexAddress Address index on Array to be toggled
     */
	handleToggleDeletedStatus(indexAddress) {
		let { data } = this.props;
		const { currentActiveAddress } = this.state;

		if (data[indexAddress].id)
			data[indexAddress].isDeleted = !data[indexAddress].isDeleted;
		else
			data.splice(indexAddress, 1);
        
		const hasOneActiveAddress = data.find(p => p.isActive);
		if (!hasOneActiveAddress && currentActiveAddress) {
			let addressToBeActive = data.find(p => p.id === currentActiveAddress.id);
			addressToBeActive.isActive = true;
			addressToBeActive.isDeleted = false;

		}

		return this.onChange(data);
	}

	/**
     * open Modal to edit the Address using its index from array
     * @param {number} indexAddress Address index on Array to be edited
     */
	handleEditAddress(indexAddress) {
		let { data } = this.props;
		return this.setState({
			showModalAddressData: true,
			dataAddressModal: {
				...data[indexAddress]
			}
		});
	}

	/**
     * Open the 'Add New Address' Modal
     */
	handleClickAddNew() {
		return this.setState({ showModalAddressData: true });
	}

	/**
     * Close the 'Add New Address' Modal
     */
	handleCloseAddressDataModal() {
		return this.setState({
			showModalAddressData: false,
			dataAddressModal: {
				...WorkerAddressHistsSchema,
				isActive: true
			}
		});
	}

	/**
     * Open the 'Addresses History' Modal
     */
	handleClickSeeHistory() {
		return this.setState({ showModalAddressHistory: true });
	}

	/**
     * Close the 'Addresses History' Modal
     */
	handleCloseModalHistory() {
		return this.setState({ showModalAddressHistory: false });
	}

	/**
     * Add the New Address from the Modal Data that is openned to the Array
     */
	handleSaveAddress() {
		if(window.$(this.modalAddressData).find('form').valid()) {
			let { data } = this.props;
			let { dataAddressModal } = this.state;

			if (dataAddressModal.index >= 0) {
				let indexAddress = dataAddressModal.index;
				data[indexAddress] = dataAddressModal;
			} else {
				// Set all previous addresses to isActive = false
				for (let i in data) {
					data[i].isActive = false;
				}
                
				// Add the new Address to the Array
				data.push(dataAddressModal);
			}

			this.handleCloseAddressDataModal();
			return this.onChange(data);
		}
	}

	/**
     * Render the Addresses table with the edit and delete options if they have
     * @param {array} addresses Addresses Array 
     * @param {bool} isEditable If the addresses are editable
     * * @param {bool} isRemovable If the addresses are removable
     */
	renderTableAddress(addresses, isEditable, isRemovable) {
		let { readOnly } = this.props;

		return (
			<div className="table-responsive">
				<table className="table table-bordered">
					<thead>
						<tr>
							<th>Number and Street</th>
							<th>Post Code</th>
							<th>City</th>
							<th>State</th>
							<th>Country</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody>
						{
							addresses.map((address, index) => {
								var { id, address1, address2, postCode, city, state, country, lastChangedOn, isDeleted } = address;
								lastChangedOn = moment(lastChangedOn).isValid() ? lastChangedOn : moment();
								return (
									<tr key={index} className={classNames({'deleted': isDeleted})}>
										<td>{address2 && address2 !== '' ? address2 + ', ' : ''} {address1}</td>
										<td>{postCode}</td>
										<td>{city}</td>
										<td>{state}</td>
										<td>{country}</td>
										<td>{moment(lastChangedOn).format('DD/MM/YY')}</td>
										{ 
											((!readOnly && isEditable) || !id) && (
												<td width="40" className="text-center text-middle">
													<a onClick={() => this.handleEditAddress(address.index)}>
														<Icon name="edit" size="2x" className="text-warning" />
													</a>
												</td>
											)  
										}
										{ 
											((!readOnly && isRemovable) || !id) && (
												<td width="40" className="text-center text-middle">
													<a onClick={() => this.handleToggleDeletedStatus(address.index)}>
														{
															address.isDeleted ? (
																<Icon name="undo" size="2x" className="text-success" />
															) : (
																<Icon name="trash" size="2x" className="text-danger" />
															)
														}
													</a>
												</td>
											)
										}
									</tr>
								);
							})
						}
					</tbody>
				</table>
			</div>
		);
	}

	render() {
		const s = this.state;
		const p = this.props;

		let activeAddresses = p.data.filter(a => a.isActive);
		let inactiveAddresses = p.data.filter(a => !a.isActive);

		return (
			<div>
				<Row>
					<Col xs={12}>
						{
							p.readOnly && p.data.length === 0 ? (
								<p><i>No address has been registered.</i></p>
							) : (
								(p.readOnly || p.editMode) ? (
									this.renderTableAddress(activeAddresses)
								) : (
									<CompleteAddressInput address={p.data[0]} onChange={this.handleChangeAddress}/>
								)
							)
						}
					</Col>
				</Row>
				{
					((p.editMode && !p.readOnly) || (p.readOnly && inactiveAddresses.length > 0)) && (
						<Row>
							<Col xs={12}>
								<FormGroup>
									{
										p.editMode && (
											<Button bsStyle="success" onClick={this.handleClickAddNew}>
												<Icon name="plus" /> Add New
											</Button>
										)
									}
									&nbsp;&nbsp;
									{
										((p.readOnly || p.editMode) && inactiveAddresses.length > 0) && (
											<Button bsStyle="info" onClick={this.handleClickSeeHistory}>
												Addresses History
											</Button>
										)
									}
								</FormGroup>
							</Col>
						</Row>
					)
				}
				{
					(p.readOnly || p.editMode) && (
						<div>
							<Modal show={s.showModalAddressData} onHide={this.handleCloseAddressDataModal}>
								<Modal.Header closeButton>
									<Modal.Title>New Address</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<div ref={ref => this.modalAddressData = ref}>
										<Form validations={validations}>
											<CompleteAddressInput address={s.dataAddressModal} onChange={this.handleChangeAddressModal}/>
										</Form>
									</div>
								</Modal.Body>
								<Modal.Footer>
									{ !p.readOnly && s.dataAddressModal.city && <Button bsStyle="success" onClick={this.handleSaveAddress}>Save</Button> }
									<Button bsStyle="danger" onClick={this.handleCloseAddressDataModal}>{p.readOnly ? 'Close' : 'Cancel'}</Button>
								</Modal.Footer>       
							</Modal>

							<Modal show={s.showModalAddressHistory} onHide={this.handleCloseModalHistory}>
								<Modal.Header closeButton>
									<Modal.Title>Addresses History</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<h3 className="title">Current</h3>
									{ this.renderTableAddress(activeAddresses) }
									{
										inactiveAddresses.length > 0 && (
											<div>
												<h3 className="title">History</h3>
												{ this.renderTableAddress(inactiveAddresses, p.isEditable, p.isRemovable) }
											</div>
										)
									}
								</Modal.Body>
								<Modal.Footer>
									<Button bsStyle="danger" onClick={this.handleCloseModalHistory}>Close</Button>
								</Modal.Footer>       
							</Modal>
						</div>
					)
				}
			</div>
		);
	}
}

WorkerAddressHists.propTypes = propTypes;

export default WorkerAddressHists;