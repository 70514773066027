import React from 'react';
import ReactTable, { PageChangeFunction } from 'react-table';
import { RunNumberCombinedColored, UserDetailsLink, 
	VehicleServicingOverlayTrigger, WorkerDriversLicenceOverlayTrigger, 
	WorkerLeaveRequestOverlayTrigger, WorkerPossibleOvertimeOverlayTrigger, WorkerRosterStartTimeOverlayTrigger 
} from '../../../../common/uiElements';
import moment from 'moment-timezone';
import Icon from 'react-fontawesome';
import { ITaskDetail, ITaskListItem, ITaskType } from '../../../../../app/models/Task';
import { IVehicleDetailsForTask, VehicleTypes } from '../../../../../app/models/Vehicle';
import { canDriveVehicleCombination } from '../../../../../app/helpers/vehicles';
import { getTaskStatus, getVehicleServiceOverlappingTask, getTotalHoursOvertime } from '../tasksHelper';
import { FormControl } from 'react-bootstrap';
import { capitalize } from '../../../../../app/helpers/stringHelper';
import FontAwesome from 'react-fontawesome';
import TaskStoresPopover from './TaskStoresPopover';
import './TaskList.scss';

interface IProps {
	tasksList?: ITaskListItem[];
	isLoading?: boolean;
	isReadOnly?: boolean;
	currentPage?: number;
	onChangePage?: PageChangeFunction;
	onSelectTask?: (task: ITaskListItem, rowIndex: number) => void;
	onRowHover?: (task: ITaskListItem, rowIndex: number) => void;

	// Table Config
	hideFilters?: boolean;
	hidePagination?: boolean;

	// Show/Hide Columns
	hideID?: boolean;
	hideDate?: boolean;
	hideRunNumber?: boolean;
	hideArea?: boolean;
	hideBudgetedKm?: boolean;
	hideBudgetedTime?: boolean;
	hideTaskType?: boolean;
	hideStatus?: boolean;
	hideMainVehicle?: boolean;
	hideTrailer1?: boolean;
	hideTrailer2?: boolean;
	hidePreLoaded?: boolean;
	hideEmployee?: boolean;
}

const TasksList: React.FC<IProps> = props => {
	const renderFilterInput = ({ filter, onChange }: any) => (
		<FormControl placeholder="Filter..." bsSize="small" value={filter?.value || ''} onChange={(e: any) => onChange(e.target.value)} />
	);

	return (
		<div id="roster-tasks-list">
			<ReactTable
				filterable={!props.hideFilters}
				showPagination={!props.hidePagination}
				page={props.currentPage}
				minRows={0}
				pageSizeOptions={props.hidePagination ? undefined : [25, 50, 100]}
				loading={props.isLoading}
				defaultPageSize={props.hidePagination ? 9999 : 50}
				collapseOnDataChange={false}
				onPageChange={props.onChangePage}
				onPageSizeChange={() => props.onChangePage && props.onChangePage(0)}
				onFilteredChange={() => props.onChangePage && props.onChangePage(0)}
				FilterComponent={renderFilterInput}
				defaultFilterMethod={(filter, row) => {
					const id =  filter.pivotId || filter.id;
					return row[id] !== undefined ? String(row[id]).toLowerCase().includes(String(filter.value).toLowerCase()) : true
				}}
				getTrProps={(state: any, rowInfo: any) => {
					if (!rowInfo) 
						return {};
					
					const task = rowInfo.original as ITaskListItem;
					const taskStatus = getTaskStatus(task);
	
					return {
						className: taskStatus.className,
						onClick: () => props.onSelectTask && props.onSelectTask(task, rowInfo.index)
					}
				}}
				getTdProps={(state: any, rowInfo: any, column: any) => {
					if (!rowInfo) return {};
	
					let cellValue = rowInfo.original[column.id];
	
					const { 
						isStartTimeAdjustNeeded, startTimeLocal, budgetedTime, isCancelled,
						isMainVehicleNotTracked, isTrailer1NotTracked, isDriverNotTracked,
						subContractorAbn, TaskType, Worker, MainVehicle, Trailer1, Trailer2
					} = rowInfo.original as ITaskListItem;

					const isDeliveryTask = TaskType?.isRunRequired && TaskType?.isDriversLicenceRequired;
	
					let className = '';
					
					// If the task is not cancelled, check if there is any issue with the task
					if (!isCancelled)
						switch (column.id) {
							case 'startTime':
								if (isStartTimeAdjustNeeded)
									className = 'bg-danger';
								break;
							case 'budgetedTime':
								if (!budgetedTime)
									className = 'bg-danger';
								break;
							case 'mainVehFleetNumber': 
								// If task needs a vehicle and there is no vehicle selected or the selected subcontractor settings is set not to track the main vehicle
								if (isDeliveryTask && !MainVehicle && (!subContractorAbn || !isMainVehicleNotTracked)) 
									className = 'bg-danger';
		
								if (MainVehicle && startTimeLocal && budgetedTime) {
									const vehicleWillBeOnService = getVehicleServiceOverlappingTask(startTimeLocal, budgetedTime, MainVehicle?.MaintenanceServices || [])
									if (vehicleWillBeOnService)
										className = 'bg-danger';
								}
								break;
							case 'trailer1FleetNumber': 
								// If main vehicle is a prime mover and there is no trailer selected and the trailer is tracked
								// Or if it's a subcontractor and the subcontractor settings is set not to track the main vehicle but it needs a trailer
								if (isDeliveryTask && !Trailer1 && !isTrailer1NotTracked &&
										(
											(MainVehicle && MainVehicle.typeId === VehicleTypes.PRIME_MOVER)
											|| ((subContractorAbn && isMainVehicleNotTracked && !isTrailer1NotTracked))
										)	
									) 
									className = 'bg-danger';
		
								if (Trailer1 && startTimeLocal && budgetedTime) {
									const vehicleWillBeOnService = getVehicleServiceOverlappingTask(startTimeLocal, budgetedTime, Trailer1.MaintenanceServices || [])
									if (vehicleWillBeOnService)
										className = 'bg-danger';
								}
								break;
							case 'trailer2FleetNumber':
								if (Trailer2 && startTimeLocal && budgetedTime) {
									const vehicleWillBeOnService = getVehicleServiceOverlappingTask(startTimeLocal, budgetedTime, Trailer2.MaintenanceServices || [])
									if (vehicleWillBeOnService)
										className = 'bg-danger';
								}
								break;
							case 'Worker': 
								if (!Worker && (!subContractorAbn || !isDriverNotTracked))
									className = 'bg-danger';
								else if (Worker) {
									const canDriveCombination = canDriveVehicleCombination(Worker, MainVehicle, Trailer1, Trailer2);
									const licenceIssue = TaskType?.isDriversLicenceRequired && !canDriveCombination;
									const leaveRequestIssue = Worker.LeaveRequest;
									const workerStartTimeIssue = startTimeLocal && Worker?.Roster?.startTimeLocal && moment(Worker?.Roster?.startTimeLocal) > moment(startTimeLocal);
									const workerPossibleOvertime = startTimeLocal && Worker?.Roster && budgetedTime && getTotalHoursOvertime(Worker.Roster.startTimeLocal, Worker.Roster.budgetedTime, startTimeLocal, budgetedTime);
		
									if (workerStartTimeIssue || licenceIssue || leaveRequestIssue?.isApproved)
										className = 'bg-danger';
									else if (leaveRequestIssue || workerPossibleOvertime)
										className = 'bg-warning';

									cellValue = Worker.shortName;
								}
								break;
							default:
								break;
						}

					return { className, title: cellValue ? cellValue.toString() : undefined };
				}}
				data={props.tasksList}
				columns={[
					{
						Header: 'Ref ID',
						accessor: 'id',
						width: 60,
						show: !props.hideID
					},
					{
						id: 'date',
						Header: 'Date',
						width: 80,
						accessor: 'startTimeLocal',
						filterable: false,
						show: !props.hideDate,
						Cell: row => moment(row.value).format('DD/MM - ddd')
					},
					{
						Header: 'Starting Place',
						id: 'startingDepotName',
						accessor: 'startingDepotName',
						width: 110,
						Cell: row => row.value || '-'
					},
					{
						Header: 'Start (Actual)',
						id: 'startTime',
						accessor: 'startTimeLocal',
						width: 100,
						filterMethod: (filter: any, row: any) => (row.startTime || 'none').includes(filter.value),
						Cell: row => {
							const { isStartTimeAdjustNeeded, actualStartTimeLocal } = row.original as ITaskDetail;
	
							if (isStartTimeAdjustNeeded)
								return (
									<b className="text-danger">
										<Icon name="warning"/> None
									</b>
								)
							
							return (
								<>
									{ moment(row.value).format('HH:mm') }
									{ actualStartTimeLocal && <b> ({moment(actualStartTimeLocal).format('HH:mm')})</b> }
								</>
							)
						}
					},
					{
						Header: 'Finish (Actual)',
						id: 'finishTime',
						width: 100,
						filterMethod: (filter: any, row: any) => {
							let { budgetedTime, startTime } = row as ITaskDetail;
							const finishTime = startTime && budgetedTime ? moment(startTime).add(budgetedTime, 'hours').format('HH:mm') : '';
							return finishTime.includes(filter.value);
						},
						Cell: row => {
							let { startTimeLocal, budgetedTime, startTime, actualFinishTimeLocal } = row.original as ITaskDetail;
							startTime = startTime || moment(startTimeLocal).format('HH:mm');
							return (
								<>
									{ budgetedTime ? moment(moment(startTimeLocal).format('YYYY-MM-DDT' + startTime)).add(budgetedTime, 'hours').format('HH:mm') : '-' }
									{ actualFinishTimeLocal && <b> ({moment(actualFinishTimeLocal).format('HH:mm')})</b> }
								</>
							)
						}
					},
					{
						Header: 'B. Time',
						accessor: 'budgetedTime',
						width: 80,
						show: !props.hideBudgetedTime,
						Cell: row => {
							const { budgetedTime, actualStartTimeLocal, actualFinishTimeLocal, isCancelled } = row.original as ITaskDetail;
	
							if (isCancelled)
								return <i>Cancelled</i>;

							if (!budgetedTime)
								return (
									<b className="text-danger">
										<Icon name="warning"/> None
									</b>
								)
	
							const color = budgetedTime > 10 ? 'danger' : budgetedTime > 6 ? 'warning' : 'success';
							const actualTime = actualStartTimeLocal && actualFinishTimeLocal && moment.duration(moment(actualFinishTimeLocal).diff(moment(actualStartTimeLocal))).asHours().toFixed(1);
							
							return (
								<>
									<b className={`text-${color}`}>{ budgetedTime + ' h' }</b>
									{ actualTime && <b> ({actualTime})</b> }
								</>
							)
						}
					},
					{
						Header: 'Task Type',
						accessor: 'TaskType',
						id: 'taskType',
						show: !props.hideTaskType,
						width: 100,
						filterMethod: (filter: any, row: any) => (row._original.TaskType?.name?.toLowerCase() || '').includes(filter.value),
						Cell: row => {
							const taskType = row.value as ITaskType;

							return (
								<b title={taskType?.name}>
									<FontAwesome name={taskType?.isDriversLicenceRequired ? 'truck' : 'user-circle' } />
									&nbsp; {taskType?.name}
								</b>
							)
						}
					},
					{
						Header: 'Employee',
						accessor: 'Worker',
						show: !props.hideEmployee,
						minWidth: 170,
						filterMethod: (filter: any, row: any) => (row.Worker?.shortName || row._original.SubContractor?.name || 'no employee').toLowerCase().includes(filter.value.toLowerCase()),
						Cell: row => {							
							const { startTimeLocal, budgetedTime, isCancelled, isDriverNotTracked, SubContractor, TaskType, MainVehicle, Trailer1, Trailer2, Worker } = row.original as ITaskDetail;

							if (isCancelled)
								return <i>Cancelled</i>;

							const subContactorName = SubContractor?.name?.replace('SUB - ', '');

							if (SubContractor && isDriverNotTracked)
								return <span title={subContactorName}><b>{ subContactorName }</b> <i>(Sub-Contractor)</i></span>;

							if (!Worker)
								return (
									<span>
										<b className="text-danger">
											{ <Icon name="warning"/> } No Employee Assigned
										</b>
										{ SubContractor && ` (${subContactorName})` }
									</span>
								)
							
							const canDriveVehicles = canDriveVehicleCombination(Worker, MainVehicle, Trailer1, Trailer2);
							const licenceIssue = TaskType?.isDriversLicenceRequired && !canDriveVehicles;
							const leaveRequestIssue = Worker.LeaveRequest;
							const workerStartTimeIssue = startTimeLocal && Worker?.Roster?.startTimeLocal && moment(Worker?.Roster?.startTimeLocal) > moment(startTimeLocal);
							const workerTotalHoursOvertime = Worker?.Roster && budgetedTime && getTotalHoursOvertime(Worker.Roster.startTimeLocal, Worker.Roster.budgetedTime, startTimeLocal, budgetedTime);

							return (
								<span>
									<UserDetailsLink azureId={Worker.azureId} showDetailsModal={false}>
										<b>{ Worker.shortName }</b>
									</UserDetailsLink>
									{ TaskType?.isDriversLicenceRequired && ` (${ Worker.currentLicenceType || 'No Licence'})` }
									{ SubContractor && ` (${subContactorName})` }
									{
										workerStartTimeIssue && (
											<span className="text-danger">
												&nbsp;|&nbsp;
												<WorkerRosterStartTimeOverlayTrigger 
													worker={Worker} 
													taskStartTime={startTimeLocal}
												>
													<b>
														<Icon name="warning"/> <i><u>Roster Issue</u></i>
													</b>
												</WorkerRosterStartTimeOverlayTrigger>
											</span>
										)
									}
									{
										licenceIssue && (
											<span className="text-danger">
												&nbsp;|&nbsp;
												<WorkerDriversLicenceOverlayTrigger worker={Worker}>
													<b>
														<Icon name="warning"/> <i><u>Licence Issue</u></i>
													</b>
												</WorkerDriversLicenceOverlayTrigger>
											</span>
										)
									}
									{
										leaveRequestIssue && (
											<span className="text-danger">
												&nbsp;|&nbsp;
												<WorkerLeaveRequestOverlayTrigger 
													worker={Worker} 
													leaveRequest={leaveRequestIssue}
												>
													<b>
														<Icon name="warning"/> <i><u>{leaveRequestIssue.isApproved ? 'Approved Leave' : 'Pending Leave Request'}</u></i>
													</b>
												</WorkerLeaveRequestOverlayTrigger>
											</span>
										)
									}
									{
										(startTimeLocal && budgetedTime && workerTotalHoursOvertime) ? (
											<span className="text-warning">
												&nbsp;|&nbsp;
												<WorkerPossibleOvertimeOverlayTrigger
													worker={Worker} 
													totalHoursOvertime={workerTotalHoursOvertime}
													taskStartTime={startTimeLocal} 
													taskBudgetedTime={budgetedTime}
												>
													<b>
														<Icon name="warning"/> <i><u>Possible Overtime</u> ({workerTotalHoursOvertime}h)</i>
													</b>
												</WorkerPossibleOvertimeOverlayTrigger>
											</span>
										) : null
									}
								</span>
							)
						}
					},
					{
						Header: 'Run Number',
						id: 'runNumber',
						accessor: 'runNumber',
						width: 100,
						show: !props.hideRunNumber,
						sortMethod: (a, b, desc) => {
							a = !a || a === '-' ? -Infinity : parseInt(a.slice(3, 6))
							b = !b || b === '-' ? -Infinity : parseInt(b.slice(3, 6))
							
							if (a > b) return 1;
							if (a < b) return -1;
							return 0;
						},
						Cell: row => {
							const { runNumber } = row.original as ITaskListItem;

							if (!runNumber)
								return <i title="Not Applicable">N/A</i>;

							return (
								<RunNumberCombinedColored runNumberCombined={runNumber} />
							)
						}
					},
					{
						Header: 'Area',
						accessor: 'area',
						width: props.hideEmployee ? undefined : 180,
						show: !props.hideArea,
						Cell: row => {
							const { runNumber, area } = row.original as ITaskListItem;

							if (!runNumber || !area)
								return <i title="Not Applicable">N/A</i>;

							const text = capitalize(area);
							return <span title={text}>{text}</span>
						}
					},
					{
						Header: 'Stores',
						id: 'stores',
						width: 80,
						style: { padding: 0 },
						filterable: false,
						Cell: row => {
							const { runNumber, totalStores, runId } = row.original as ITaskListItem;
							
							if (!runNumber)
								return <div style={{ padding: '5px 7px' }}><i title="Not Applicable">N/A</i></div>;

							if (!totalStores)
								return <div style={{ padding: '7px 5px' }} >No Data</div>;

							return <TaskStoresPopover runId={runId} totalStores={totalStores} />;
						}
					},
					{
						Header: 'Pallets',
						id: 'totalPallets',
						width: 70,
						filterable: false,
						accessor: d => d,
						Cell: row => {
							const { 
								runNumber, totalPalletsAU_estimates, totalPalletsT3_estimates,
								totalPalletsAU_actuals, totalPalletsT3_actuals

							} = row.original as ITaskListItem;

							if (!runNumber)
								return <i title="Not Applicable">N/A</i>;

							if (totalPalletsAU_estimates == null || totalPalletsT3_estimates == null)
								return 'No Data';
							
							const totalPalletsEstimates = (totalPalletsAU_estimates || 0) + (totalPalletsT3_estimates || 0);
							const totalPalletsActuals = (totalPalletsAU_actuals || 0) + (totalPalletsT3_actuals || 0);

							return (
								<span title="Pallets">
									{ totalPalletsActuals ? <b>{totalPalletsActuals} / </b> : null } { totalPalletsEstimates }
									&nbsp;(P)
								</span>
							);
						}
					},
					{
						Header: 'Cartons',
						accessor: 'totalCartons',
						width: 60,
						filterable: false,
						Cell: row => {
							const { 
								runNumber, totalCartons_estimates 
							} = row.original as ITaskListItem;

							// if not a run or a FS run (which doesn't have cartons), return Not Applicable
							if (!runNumber || !runNumber.includes('FS'))
								return <i title="Not Applicable">N/A</i>;

							return <span title="Cartons">{ totalCartons_estimates === null ? 'No Data' : totalCartons_estimates + ' (C)' }</span>;
						}
					},
					{
						Header: 'Main Veh.',
						id: 'mainVehFleetNumber',
						accessor: 'MainVehicle',
						width: 80,
						show: !props.hideMainVehicle,
						filterMethod: (filter: any, row: any) => (row._original.MainVehicle?.fleetNumber || '').includes(filter.value),
						Cell: row => {
							const { runNumber, startTimeLocal, budgetedTime, subContractorAbn, isCancelled, TaskType, isMainVehicleNotTracked } = row.original as ITaskListItem;

							if (!runNumber)
								return <i title="Not Applicable">N/A</i>;

							if (isCancelled)
								return <i>Cancelled</i>;

							const vehicle = row.value as IVehicleDetailsForTask;
							
							// If it's a subcontractor and the subcontractor settings is set not to track the trailer, just show 'Subbie'
							if (subContractorAbn && isMainVehicleNotTracked)
								return <b><i>Subbie</i></b>;

							// If task needs a Run and Drivers Licence, it means it's delivery and needs to have a vehicle selected
							if (TaskType?.isRunRequired && TaskType?.isDriversLicenceRequired && !vehicle)
								return (
									<b className="text-danger">
										<Icon name="warning"/> None
									</b>
								);
	
							if (vehicle) {
								const vehicleMaintenanceService = budgetedTime && getVehicleServiceOverlappingTask(startTimeLocal, budgetedTime, vehicle.MaintenanceServices || [])
								
								return (
									<b>
										{
											// VEHICLE WILL BE ON SERVICE
											vehicleMaintenanceService && (
												<span className="text-danger">
													<VehicleServicingOverlayTrigger vehicleMaintenanceService={vehicleMaintenanceService} >
														<Icon name="warning"/>
													</VehicleServicingOverlayTrigger>
													&nbsp;
												</span>
											)
										}
										{ vehicle.fleetNumber }
									</b>
								)
							}
	
							return '-';
						}
					},
					{
						Header: 'Trailer 1',
						id: 'trailer1FleetNumber',
						accessor: 'Trailer1',
						width: 80,
						show: !props.hideTrailer1,
						filterMethod: (filter: any, row: any) => (row._original.Trailer1?.fleetNumber || '').includes(filter.value),
						Cell: row => {
							const { runNumber, startTimeLocal, budgetedTime, subContractorAbn, isTrailer1NotTracked, isMainVehicleNotTracked,  isCancelled, TaskType, MainVehicle } = row.original as ITaskListItem;

							if (!runNumber)
								return <i title="Not Applicable">N/A</i>;

							if (isCancelled)
								return "-";

							const trailer1 = row.value as IVehicleDetailsForTask;

							// If it's a subcontractor and the subcontractor settings is set not to track the trailer1, just show 'Subbie'
							if (subContractorAbn && isTrailer1NotTracked)
								return <b><i>Subbie</i></b>;
							
							// SHOW WARNING IN CASE THE MAIN VEHICLE IS A PRIME MOVER, SO IT NEEDS A TRAILER
							// Or if it's a subcontractor and the subcontractor settings is set not to track the main vehicle but it needs a trailer
							if (TaskType?.isRunRequired && TaskType?.isDriversLicenceRequired && !trailer1 &&
									(
										MainVehicle?.typeId === VehicleTypes.PRIME_MOVER
										|| (isMainVehicleNotTracked && !isTrailer1NotTracked)
									)
								)
								return (
									<b className="text-danger">
										{ <Icon name="warning"/> } None
									</b>
								);
	
							if (trailer1) {
								const vehicleMaintenanceService = budgetedTime && getVehicleServiceOverlappingTask(startTimeLocal, budgetedTime, trailer1.MaintenanceServices || [])
								
								return (
									<b>
										{
											// VEHICLE WILL BE ON SERVICE
											vehicleMaintenanceService && (
												<span className="text-danger">
													<VehicleServicingOverlayTrigger vehicleMaintenanceService={vehicleMaintenanceService} >
														<Icon name="warning"/>
													</VehicleServicingOverlayTrigger>
													&nbsp;
												</span>
											)
										}
										{ trailer1.fleetNumber }
									</b>
								)
							}
	
							return '-';
						}	
					},
					{
						Header: 'Trailer 2',
						id: 'trailer2FleetNumber',
						accessor: 'Trailer2',
						width: 80,
						show: !props.hideTrailer2,
						filterMethod: (filter: any, row: any) => (row._original.Trailer2?.fleetNumber || '').includes(filter.value),
						Cell: row => {
							const { runNumber, startTimeLocal, budgetedTime } = row.original as ITaskListItem;

							if (!runNumber)
								return <i title="Not Applicable">N/A</i>;

							const vehicle = row.value as IVehicleDetailsForTask;
							if (vehicle) {
								const vehicleMaintenanceService = budgetedTime && getVehicleServiceOverlappingTask(startTimeLocal, budgetedTime, vehicle.MaintenanceServices || [])
								
								return (
									<b>
										{
											// VEHICLE WILL BE ON SERVICE
											vehicleMaintenanceService && (
												<span className="text-danger">
													<VehicleServicingOverlayTrigger vehicleMaintenanceService={vehicleMaintenanceService} >
														<Icon name="warning"/>
													</VehicleServicingOverlayTrigger>
													&nbsp;
												</span>
											)
										}
										{ vehicle.fleetNumber }
									</b>
								)
							}
	
							return '-';
						}
					},
					{
						Header: 'P. Loaded',
						accessor: 'isPreLoaded',
						width: 70,
						show: !props.hidePreLoaded,
						filterMethod: (filter: any, row: any) => {
							if (row.isPreLoaded === undefined || row.isPreLoaded === null)
								return filter.value === '-' ? true : false;
								
							return (row.isPreLoaded ? 'yes' : 'no').includes(String(filter.value).toLowerCase())
						},
						Cell: row => {
							const { runNumber, TaskType } = row.original as ITaskListItem;

							if (!runNumber)
								return <i title="Not Applicable">N/A</i>;
							
							return (
								<b className={row.value ? 'text-success' : 'text-danger'}>
									{ row.value === null || !TaskType?.isRunRequired ? '-' : row.value? 'Yes' : 'No' }
								</b>
							)
						}
					},
					{
						Header: 'Run Status',
						id: 'status',
						accessor: d => d,
						width: 90,
						show: !props.hideStatus,
						filterMethod: (filter: any, row: any) => {	
							const taskStatus = getTaskStatus(row._original);						
							return (taskStatus.text || '').toLowerCase().includes(String(filter.value).toLowerCase())
						},
						Cell: row => {
							const task = row.original as ITaskListItem;

							if (!task.runNumber)
								return <i title="Not Applicable">N/A</i>;

							const taskStatus = getTaskStatus(task);
	
							return (
								<b className={taskStatus.colorClassName}>
									{ taskStatus.text }
								</b>
							);
						}
					},
				]}
			/>
		</div>
	);
};

export default TasksList;