import React from 'react';
import { ErrorBox, Loader } from '../../common/uiElements';
import componentRequestHandler from '../../../app/api/helpers/componentRequestHandler';
import { IStaffRosterListItem, StaffRosterListMainFilter } from '../../../app/models/StaffRoster';
import moment from 'moment';
import { IError } from '../../../app/models/Application';
import { MainContent } from '../../common/layout';
import MeApi from '../../../app/api/MeApi';
import StaffRosterMainFilter from '../roster/StaffRoster/list/filters/MainFilter';
import StaffRosterListResponsive from '../roster/StaffRoster/list/StaffRosterListResponsive';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';
import Icon from 'react-fontawesome';
import { getEndDateFromYearWeekNumber, getStartDateFromYearWeekNumber } from '../../../app/helpers/dateTimeHelper';

interface IState {
	staffRosterList: IStaffRosterListItem[];
	mainFilter: StaffRosterListMainFilter;
	isLoading: boolean;
	errorLoading?: IError;
}

class UserRoster extends React.Component<{}, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			staffRosterList: [],
			mainFilter: {
				...new StaffRosterListMainFilter(),
				week: moment().isoWeek(),
			},
			isLoading: false,
			errorLoading: undefined,
		};
	}

	componentDidMount() {
		this.fetchStaffRosterList();
	}	

	fetchStaffRosterList = () => {
		const { year, week: weekNumber } = this.state.mainFilter;
		if (!year || !weekNumber) return;

		const startDateTime = getStartDateFromYearWeekNumber(year, weekNumber).format('YYYY-MM-DD 00:00');
		const finishDateTime = getEndDateFromYearWeekNumber(year, weekNumber).format('YYYY-MM-DD 23:59');

		const promise = () => MeApi.getRoster(startDateTime, finishDateTime);
		componentRequestHandler(this, promise, 'staffRosterList');
	}

	handleChangeYear = (year: number) => {
		this.setState({
			mainFilter: {
				...this.state.mainFilter,
				year,
				startDate: '',
				finishDate: '',
				week: moment().year() !== year ? 1 : moment().isoWeek(),
			},
		}, this.fetchStaffRosterList);
	}

	handleChangeWeek = (week: number) => {
		this.setState({
			mainFilter: {
				...this.state.mainFilter,
				week,
				startDate: '',
				finishDate: ''
			},
		}, this.fetchStaffRosterList);
	}
	

	handleClickCurrentOrNextWeek = (week: 'current' | 'next') => {
		const date = moment().add(week === 'current' ? 0 : 1, 'week').format('YYYY-MM-DD');
		this.setState({
			mainFilter: {
				...this.state.mainFilter,
				week: moment(date).isoWeek(),
				startDate: date,
				finishDate: date,
			}
		}, this.fetchStaffRosterList);
	}

	renderList = () => {
		const { staffRosterList, isLoading, errorLoading } = this.state;

		if (isLoading)
			return <Loader isLoading={isLoading} />;

		if (errorLoading)
			return (
				<ErrorBox 
					error={errorLoading} 
					retryFunc={this.fetchStaffRosterList}
				/>
			);

		return (
			<StaffRosterListResponsive
				staffRosterList={staffRosterList}
			/>
		);
	};
	
	render() {
		const { state } = this;
		const selectedStartDate = state.mainFilter.startDate;
		
		return (
			<MainContent title="My Availability">
				<StaffRosterMainFilter
					{...state.mainFilter}
					hideDepot
					hideFilterBy
					onChangeYear={this.handleChangeYear}
					onChangeWeek={this.handleChangeWeek}
				/>
				<Row>
					<Col xs={6}>
						<FormGroup>
							<Button
								block
								bsStyle={moment(selectedStartDate).isSame(moment(), 'week') ? 'primary' : 'default'}
								onClick={() => this.handleClickCurrentOrNextWeek('current')}
							>
								<Icon name="check-circle-o" /> Current Week
							</Button>
						</FormGroup>
					</Col>
					<Col xs={6}>
						<FormGroup>
							<Button
								block
								bsStyle={moment(selectedStartDate).isSame(moment().add(1, 'week'), 'week') ? 'primary' : 'default'}
								onClick={() => this.handleClickCurrentOrNextWeek('next')}
							>
								<Icon name="arrow-circle-o-right" /> Next Week
							</Button>
						</FormGroup>
					</Col>
				</Row>
				{ this.renderList() }
			</MainContent>
		);
	}
}

export default UserRoster;