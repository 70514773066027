import moment from 'moment';
import React from 'react';
import { Row } from 'react-bootstrap';
import { IFormField } from '../../../../../app/models/Application';
import { PalletUnloadShuttleDetailForm as PalletUnloadDetailFormClass } from '../../../../../app/models/Pallet';
import { FieldGroup, Form } from '../../../../common/uiElements';
import { UserSearch } from '../../../../common/inputs';

interface IProps {
	pallet: PalletUnloadDetailFormClass;
	isReadOnly?: boolean;
	onChangeInput: (id: string, value?: string) => void;
	onChangeLoader?: (loader: {id: string, name: string}) => void;
}

const PalletDetailsForm: React.FC<IProps> = props => {
	const { pallet } = props;

	const detailFields: IFormField[] = [
		/** ID  */
		{ 
			id: 'id',
			label: 'ID', 
			sm: 4,
			readOnlyValue: pallet.palletId
		},

		/** Loaded By */
		{
			id: 'scannedBy',
			label: 'Unloaded By',
			sm: 4,
			readOnlyValue: pallet.scannedBy?.name || '-',
			inputComponent: <UserSearch id="workerId" value={pallet.scannedBy.id} onChange={props.onChangeLoader} />
		},

		/** Loaded At */
		{
			id: 'scannedAt',
			label: 'Unloaded At',
			sm: 4,
			readOnlyValue: moment(pallet.scannedAt).format('HH:mm')
		},
	]

	const renderFields = (fields: IFormField[]) => (
		fields.map(field => {
			if (field.show === false)
				return null;

			return (
				<FieldGroup 
					key={field.label} 
					sm={field.sm} 
					label={field.label} 
					required={field.required} 
					optional={field.inputComponent && !props.isReadOnly && !field.required}
				>
					{
						(field.isReadOnly || !field.inputComponent) ? (
							<p>{ field.readOnlyValue || '-'}</p>
						) : (
							field.inputComponent
						)
					}
				</FieldGroup>
			)
		})
	)

	const validations = detailFields.filter(p => p.required).reduce((obj, item) => Object.assign(obj, { [item.id]: 'required' }), {});
	return (	
		<Form id="pallet-details-form" validations={validations}>
			<Row>
				{ renderFields(detailFields) }
			</Row>
		</Form>
	)
}

export default PalletDetailsForm;