import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, FormGroup, FormControl } from 'react-bootstrap';

import { Checkbox, DatePeriodInput, VehicleSearch, RadioButtonList } from '../../../../common/inputs';
import { FaultReportTypesList } from '../../../../common/lists';

const FaultReportListFilter = props => {
	const handleChange = (e) => {
		const { id, type, checked, value } = e.target;
		var val = type === 'checkbox' ? checked : value;
		props.onChange && props.onChange({ [id]: val });
	};

	const handleKeyPress = e => {
		if (e.key === 'Enter') {
			props.onPressEnter && props.onPressEnter();
		}
	};

	const handleChangeVehicle = ({ vehFleetNumber }) => {
		props.onChange && props.onChange({ vehicle: vehFleetNumber || '' });
	};

	const handleChangePeriod = (startTimeLocal, endTimeLocal) => {
		startTimeLocal = startTimeLocal.format('YYYY-MM-DD HH:mm');
		endTimeLocal = endTimeLocal.format('YYYY-MM-DD HH:mm');
		props.onChange && props.onChange({ startTimeLocal, endTimeLocal });
	};

	return (
		<div>
			<Row>
				<Col sm={12} md={5} lg={4}>
					<FormGroup>
						<RadioButtonList 
							id="filterBy"
							value={props.filterBy}
							items={[{text: 'All', value: 'all'}, {text: 'By Open Date', value: 'openDate'}]} 
							onChange={handleChange} 
						/>
						<div className={props.filterBy !== 'openDate' ? 'hidden' : undefined} style={{marginTop: '-17px'}}>
							<DatePeriodInput 
								startDate={moment(props.startTimeLocal)}
								endDate={moment(props.endTimeLocal)}
								showDefaultRanges={true} 
								showHours={true}
								onChange={handleChangePeriod}/>
						</div>
					</FormGroup>
				</Col>
				<Col sm={6} md={3} lg={4}>
					<FormGroup>
						<label>Vehicle</label>
						<VehicleSearch id="vehicle" onlyActive={false} value={props.vehicle} onChange={handleChangeVehicle} />
					</FormGroup>
				</Col>
				<Col sm={6} md={4} lg={4}>
					<FormGroup>
						<label>Type</label>
						<FaultReportTypesList id="type" value={props.type} showAllOption={true} onChange={handleChange} />
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col sm={6} md={3}>
					<FormGroup>
						<label>Fault Report Num.</label>
						<FormControl type="number" id="faultReportNumber" value={props.faultReportNumber} onChange={handleChange} onKeyPress={handleKeyPress} />
					</FormGroup>
				</Col>
				<Col sm={6} md={3}>
					<FormGroup>
						<label>Service Tracker Num.</label>
						<FormControl id="serviceTrackerNumber" value={props.serviceTrackerNumber} onChange={handleChange} onKeyPress={handleKeyPress} />
					</FormGroup>
				</Col>
				<Col xs={12} md={6}>
					<label>Status:</label>
					<ul className="list-inline">
						<li>
							<Checkbox 
								id="isOpen"
								text="Open"
								checked={props.isOpen}
								onChange={handleChange}
							/>
						</li>
						<li>
							<Checkbox 
								id="isFixed"
								text="Fixed"
								checked={props.isFixed}
								onChange={handleChange}
							/>
						</li>
						<li>
							<Checkbox 
								id="isNoRepairsRequired"
								text="No Repairs Required"
								checked={props.isNoRepairsRequired}
								onChange={handleChange}
							/>
						</li>
						<li>
							<Checkbox 
								id="isMonitored"
								text="Monitored"
								checked={props.isMonitored}
								onChange={handleChange}
							/>
						</li>
						<li>
							<Checkbox 
								id="isDeferred"
								text="Deferred"
								checked={props.isDeferred}
								onChange={handleChange}
							/>
						</li>
					</ul>
				</Col>
			</Row>
		</div>
	);
};

FaultReportListFilter.propTypes = {
	startTimeLocal: PropTypes.string,
	endTimeLocal: PropTypes.string,
	vehicle: PropTypes.string,
	type: PropTypes.string,
	status: PropTypes.string,
	hasFaultReport: PropTypes.bool,
	hasVehicleDamage: PropTypes.bool,
	hasMechanicalIssues: PropTypes.bool,
	hasComplianceIssue: PropTypes.bool,
	onChange: PropTypes.func,
	onPressEnter: PropTypes.func
};

export default FaultReportListFilter;