import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import Icon from 'react-fontawesome';
import TimeField from 'react-simple-timefield';
import moment from 'moment';
import { RadioButtonList, RunsTemplateListInput, YesNoRadio } from '../../../../common/inputs';
import { FormControl, Row, InputGroup, Col, Button, FormGroup, Well } from 'react-bootstrap';
import { Form, FieldGroup, Callout } from '../../../../common/uiElements';
import { PlacesList, RunTypesList, PlacesListNew, TaskTypesList } from '../../../../common/lists';
import { ItemInterface, ReactSortable, Store } from "react-sortablejs";
import { uniq } from 'lodash';
import { IReactSelectReturn } from '../../../../../app/models/Application';
import { IRunTemplateListItem, RunTemplateFormClass } from '../../../../../app/models/Run/RunTemplate';
import { WeekDays } from '../../../../common/inputs/WeekDays';
import weekDays from '../../../../../app/store/weekDays';
import { ITaskType } from '../../../../../app/models/Task';
import FontAwesome from 'react-fontawesome';
import { IListRunTypeItem } from '../../../../../app/models/List';
import { VehicleContent } from '../../../../../app/models/Run/Run';

interface IProps extends RunTemplateFormClass {
	formId: string;
	isReadOnly?: boolean;
	onChangeInput: (id: string, value: string | boolean) => void;
	onChangeTaskType: (e: ChangeEvent<FormControl & HTMLInputElement>, text?: string, TaskType?: ITaskType) => void;
	onChangeStartTime: (startTime: string) => void;

	onChangeRunCustomer: (runCustomerIndex: number, event: ChangeEvent<FormControl & HTMLInputElement>, runType?: IListRunTypeItem) => void;
	onClickAddNewRunCustomer: () => void;
	onClickRemoveRunCustomer: (runCustomerIndex: number) => void;

	onClickAddNewRunTemplateShuttleTaskLink: (vehicleSection: number) => void;
	onClickRemoveRunTemplateShuttleTaskLink: (vehicleSection: number, runShuttleTaskLinkIndex: number) => void;
	onChangeRunTemplateShuttleTaskLink: (vehicleSection: number, runShuttleTaskLinkIndex: number, run?: IRunTemplateListItem) => void;
	onClearRunTemplateShuttleTaskLinks: (vehicleSection: number, shouldAddEmptyLink: boolean) => void;

	onClickAddNewRunCustomerOrder: () => void;
	onClickRemoveRunCustomerOrder: (runCustomerOrderIndex: number) => void;
	onChangeRunCustomerOrder: (obj: IReactSelectReturn, runCustomerOrderIndex: number) => void;
	onChangeSequenceRunCustomerOrder: (RunCustomerOrders: ItemInterface[], sortable: any, store: Store) => void;
}

const validations = {
	dayOfWeek: 'required',
	startTime: 'required',
	startingDepotId: 'required',
	budgetedTime: 'required',
	area: 'required',
	runTypeId: 'required',
	runNumber: 'required',
	runCustomerOrders: 'required',
	trailer1Content: 'required',
}

const messages = {
	runCustomerOrders: {
		required: 'You must include at least one Store or Destination'
	}
}

const RunTemplateForm: React.FC<IProps> = props => {
	const [trailer1Content, setTrailer1Content] = useState('');
	const [trailer2Content, setTrailer2Content] = useState('');

	// If the task is a shuttle task, the user can link runs to the trailers
	const isShuttleTask = props.RunCustomers?.some(p => p.runTypeIsShuttle);

	// If the runType is changed, clear the linked runs data
	useEffect(() => {
		setTrailer1Content('');
		setTrailer2Content('');
	}, [isShuttleTask]);

	useEffect(() => {
		if (!trailer1Content) {
			const linkTrailer1 = props.RunTemplateShuttleTaskLinks.find(link => link.vehicleSection === 1);
			if (linkTrailer1) 
				setTrailer1Content(linkTrailer1.RunTemplate_linked_id ? VehicleContent.RUNS : VehicleContent.EMPTY);
		}
		
		if (!trailer2Content) {
			const linkTrailer2 = props.RunTemplateShuttleTaskLinks.find(link => link.vehicleSection === 2);
			if (linkTrailer2) 
				setTrailer2Content(linkTrailer2.RunTemplate_linked_id ? VehicleContent.RUNS : VehicleContent.EMPTY);
		}
	}, [props.RunTemplateShuttleTaskLinks, trailer1Content, trailer2Content]);

	const handleChangeInput = (e: ChangeEvent<FormControl & HTMLInputElement>) => {
		props.onChangeInput && props.onChangeInput(e.target.id, e.target.value);
	}
	
	const handleChangeTrailer1Content = (trailerContent: string) => {
		// Set the vehicle section for the trailer 1
		const vehicleSection = 1;

		// Remove all the links for the vehicle section
		props.onClearRunTemplateShuttleTaskLinks(vehicleSection, true);
			
		setTrailer1Content(trailerContent);
	}

	const handleChangeTrailer2Content = (trailerContent: string) => {
		// Set the vehicle section for the trailer 2
		const vehicleSection = 2;

		// Remove all the links for the vehicle section
		props.onClearRunTemplateShuttleTaskLinks(vehicleSection, trailerContent !== "");

		setTrailer2Content(trailerContent);
	}


	const renderTaskDetails = () => (
		<Col lg={6}>
			<h3 className="title">Task Details</h3>
			<Row>
				<FieldGroup label="Day of Week" sm={4}>
					{
						!props.isReadOnly ? (
							<WeekDays 
								id="dayOfWeek"
								value={props.dayOfWeek || ''}
								onChange={handleChangeInput}
							/>
						) : (
							<p>{ weekDays[(parseInt(props.dayOfWeek) - 1)] }</p>
						)
					}
				</FieldGroup>
				<FieldGroup label="Starting Depot" sm={8} required>
					{
						!props.isReadOnly ? (
							<PlacesList
								id="startingDepotId"
								type="rosterStartingDepot"
								value={props.startingDepotId || ''}
								onChange={handleChangeInput}
							/>
						) : (
							<p>{ props.startingDepotName }</p>
						)
					}
				</FieldGroup>
			</Row>
			<Row>
				<FieldGroup label="Task Type" xs={12} required>
					{
						!props.isReadOnly ? (
							<TaskTypesList
								id="taskTypeId"
								isDriversLicenceRequired
								isRunRequired
								value={props.taskTypeId || ''}
								onChange={props.onChangeTaskType}
							/>
						) : (
							<p>{ props.taskTypeName }</p>
						)
					}
				</FieldGroup>
			</Row>
			<Row>
				<FieldGroup label="Despatch Time" sm={4} required>
					{
						!props.isReadOnly ? (
							<TimeField
								width={4}
								value={props.startTime || '__:__'} 
								input={<FormControl id="startTime" />} 
								onChange={props.onChangeStartTime}
							/>
						) : (
							<p>{ moment(props.startTime, 'HH:mm:ss').format('HH:mm') }</p>
						)
					}
				</FieldGroup>
				<FieldGroup label="Budgeted Time" sm={4} required>
					{
						!props.isReadOnly ? (
							<InputGroup>
								<FormControl
									id="budgetedTime"
									type="number"
									max={20}
									min={0.5}
									value={props.budgetedTime || ''}
									onChange={handleChangeInput}
								/>
								<InputGroup.Addon>
									HOURS
								</InputGroup.Addon>
							</InputGroup>
						) : (
							props.budgetedTime ? <p>{ props.budgetedTime } h</p> : <p>-</p>
						)
					}
				</FieldGroup>
				<FieldGroup label="Est. Finish Time" sm={4}>
					<p>
					{ moment(props.startTime, 'HH:mm:ss').isValid() && props.budgetedTime ? moment(props.startTime, 'HH:mm:ss').add(props.budgetedTime, 'hours').format('HH:mm') : '-' }
					</p>
				</FieldGroup>
			</Row>
			<Row>
				<FieldGroup label="Destination / Area" sm={8} required>
					{/* Can't change area at this stage */}
					{
						!props.isReadOnly ? (
							<FormControl 
								id="area"
								value={props.area || ''}
								onChange={handleChangeInput}
							/>
						) : (
							<p>{ props.area || '-' }</p>
						)
					}
				</FieldGroup>
				<FieldGroup label="Is Pre-Loaded?" sm={4} required>
					{
						!props.isReadOnly ? (
							<YesNoRadio 
									id="isPreLoaded"
									value={props.isPreLoaded || false}
									onChange={handleChangeInput} 
								/>
						) : (
							<p>{ props.isPreLoaded ? 'YES' : 'NO' }</p>
						)
					}
				</FieldGroup>
			</Row>
			<Row>
				<FieldGroup label="Budgeted Km" sm={4} optional>
						{
							!props.isReadOnly ? (
								<InputGroup>
									<FormControl
										id="budgetedKm"
										value={props.budgetedKm || ''}
										onChange={handleChangeInput}
									/>
									<InputGroup.Addon>
										KM
									</InputGroup.Addon>
								</InputGroup>
							) : (
								<p>{ props.budgetedKm ? props.budgetedKm + ' KM' : '-' }</p>
							)
						}
					</FieldGroup>
			</Row>
		</Col>
	)

	const renderLinkedRunsContent = (vehicleSection: number, trailerContent: string) => {
		if (!trailerContent)
			if (props.isReadOnly)
				return <p>None</p>;
			else
				return null;

		if (trailerContent === VehicleContent.EMPTY) 
			if (props.isReadOnly)
				return <p>Empty Trailer</p>;
			else
				return <Well><b>Trailer {vehicleSection}</b> will be used but it will be <b>Empty</b></Well>;

		const RunTemplateShuttleTaskLinks = props.RunTemplateShuttleTaskLinks.filter(link => link.vehicleSection === vehicleSection);
		return (
			<>
				{
					RunTemplateShuttleTaskLinks.map((link, index) => (
						<FormGroup key={index}>
							<InputGroup>
								<InputGroup.Addon className="text-bold">
									{ index + 1 }
								</InputGroup.Addon>
								<RunsTemplateListInput
									disabled={props.isReadOnly}
									hideErrorMessage
									value={link.RunTemplate_linked_id}
									dayOfWeek={parseInt(props.dayOfWeek)}
									onChange={run => props.onChangeRunTemplateShuttleTaskLink(vehicleSection, index, run)}
								/>
								{
									!props.isReadOnly && index > 0 && (
										<InputGroup.Button>
											<Button bsStyle="danger" onClick={() => props.onClickRemoveRunTemplateShuttleTaskLink(vehicleSection, index)}>
												<FontAwesome name="times" />
											</Button>
										</InputGroup.Button>
									)
								}
							</InputGroup>
							<FormControl 
								required
								id={`trailer${vehicleSection}_Run_id_${index}`}
								type="hidden" 
								value={link.RunTemplate_linked_id || ''}
							/>
						</FormGroup>
					))
				}
				{
					!props.isReadOnly && (
						<FormGroup>
							<Button 
									bsSize="small"
									bsStyle="success"
									onClick={() => props.onClickAddNewRunTemplateShuttleTaskLink(vehicleSection)}
								>
									<Icon name="plus" /> Add Another Run to Trailer { vehicleSection }
							</Button>
						</FormGroup>
					)
				}
			</>
		)
	}

	const renderRunDetails = () => (
		<Col lg={6}>
			<h3 className="title">Run Details</h3>
			{
				!props.taskTypeId ? (
					<Callout color="info" text="Select the Task Type to continue" />
				) : (
					<>
						{
							props.isReadOnly ? (
								<b style={{ fontSize: '25px' }}>
									{ props.runNumberCombined }
								</b>
							) : (
								<>
									<Row>
										<Col xs={5}>
											<label>Run Type (Customer)</label>
										</Col>
										<Col xs={5}>
											<label>Run Number</label>
										</Col>
									</Row>
									{
										props.RunCustomers?.map((runCustomer, index) => (
											<Row key={index}>
												<Col xs={5}>
													<FormGroup>
														<RunTypesList 
															id="runTypeId"
															firstOptionText="--- RUN TYPE ---"
															taskTypeId={props.taskTypeId}
															value={runCustomer.runTypeId}
															className="required"
															onChange={(e, runTypeName, runType) => props.onChangeRunCustomer && props.onChangeRunCustomer(index, e, runType)}
														/>
													</FormGroup>
												</Col>
												<Col xs={5}>
													<FormGroup>
														<FormControl 
															id="runNumber"
															type="number"
															value={runCustomer.runNumber}
															placeholder="Run Number"
															className="required"
															onChange={(e: any) => props.onChangeRunCustomer && props.onChangeRunCustomer(index, e)}
														/>
													</FormGroup>
												</Col>
												<Col xs={2}>
													{ 
														index > 0 && (
															<Button
																block
																bsStyle="danger"
																onClick={() => props.onClickRemoveRunCustomer && props.onClickRemoveRunCustomer(index)}
															>
																<Icon name="times" />
															</Button>
														)
													}
												</Col>
											</Row>
										))
									}
								</>
							)
						}							
						{
							!props.isReadOnly && props.TaskType?.canHaveMultipleRuns && (
								<FormGroup>
									<Button 
											bsSize="small"
											bsStyle="success"
											onClick={props.onClickAddNewRunCustomer}
										>
											<Icon name="plus" /> Add Another Customer
									</Button>
								</FormGroup>
							)
						}
						{
							isShuttleTask && (
								<>
									<h3 className="title">Linked Runs</h3>
									{
										!props.dayOfWeek ? (
											<Callout color="info" text="Select the day of week" />
										) : (
											<>
												<Row>
													<FieldGroup label="Trailer 1" sm={6}>
														{
															!props.isReadOnly && (
																<RadioButtonList 
																	id="trailer1Content"
																	items={[
																		{ value: VehicleContent.RUNS, text: 'Run(s)' },
																		{ value: VehicleContent.EMPTY, text: 'Empty' }
																	]}
																	value={trailer1Content}
																	onChange={e => handleChangeTrailer1Content(e.target.value)}
																/>
															)
														}
														{ renderLinkedRunsContent(1, trailer1Content)}
													</FieldGroup>
													<FieldGroup label="Trailer 2" sm={6}>
														{
															!props.isReadOnly && (
																<RadioButtonList 
																	items={[
																		{ value: '', text: 'None' },
																		{ value: VehicleContent.RUNS, text: 'Run(s)' },
																		{ value: VehicleContent.EMPTY, text: 'Empty' }
																	]}
																	value={trailer2Content}
																	onChange={e => handleChangeTrailer2Content(e.target.value)}
																/>
															)
														}
														{ renderLinkedRunsContent(2, trailer2Content)}
													</FieldGroup>
												</Row>
											</>
										)
									}
								</>
							)
						}
						<h3 className="title">Delivery Sequence</h3>
						{
							!props.RunCustomers.find(p => p.runTypeId) ? (
								<Callout color="info" text="Add at least one Run to be able to select the stores" />
							) : (
								<Fragment>
									<Row>
										<Col xs={2}>
											{
												props.RunCustomerOrders?.map((runCustomer, index) => (
													<FormGroup key={index} className="sortable-list-index">
														{ index + 1 }
													</FormGroup>
												))
											}
										</Col>
										<Col xs={10}>
											<ReactSortable className="sortable-list" list={props.RunCustomerOrders as any || []} setList={props.onChangeSequenceRunCustomerOrder}>
												{
													props.RunCustomerOrders?.map((runCustomerOrder, index) => (
														<FormGroup key={index}>
															{
																props.isReadOnly ? (
																	<FormControl 
																		readOnly
																		value={`${runCustomerOrder.storeNumber && runCustomerOrder.storeNumber !== "0" ? `(${runCustomerOrder.storeNumber}) ` : ''}${runCustomerOrder.placeName}`}
																	/>
																) : (
																	<InputGroup>
																		<InputGroup.Addon>
																			<Icon name="arrows" />
																		</InputGroup.Addon>
																		<PlacesListNew 
																			id="placeId"
																			type="deliveryStores"
																			runTypes={props.RunCustomers ? uniq(props.RunCustomers?.map(p => parseInt(p.runTypeId!))) : undefined}
																			placeholder="Select Store or Destination..."
																			value={runCustomerOrder.placeId}
																			onChange={obj => props.onChangeRunCustomerOrder && props.onChangeRunCustomerOrder(obj, index)}
																		/>
																		{
																			props.RunCustomerOrders.length > 1 && (
																				<InputGroup.Button>
																					<Button
																						bsStyle="danger"
																						onClick={() => props.onClickRemoveRunCustomerOrder && props.onClickRemoveRunCustomerOrder(index)}
																					>
																						<Icon name="times" />
																					</Button>
																				</InputGroup.Button>
																			)
																		}
																		</InputGroup>
																)
															}
														</FormGroup>
													))
												}
											</ReactSortable>
										</Col>
									</Row>
									{
										!props.isReadOnly && (
											<Fragment>
												<FormGroup>
													<FormControl 
														id="runCustomerOrders" 
														type="hidden" 
														value={props.RunCustomerOrders?.find(p => !p.placeId) ? '' : 'OK'} 
													/>
												</FormGroup>
												<FormGroup>
													<Button 
															bsSize="small"
															bsStyle="success"
															onClick={props.onClickAddNewRunCustomerOrder}
														>
															<Icon name="plus" /> Add Another Store
													</Button>
												</FormGroup>
											</Fragment>
										)
									}
								</Fragment>
							)
						}
					</>
				)
			}
			</Col>
	)
	return (
		<Form id={props.formId} validations={validations} messages={messages}>
			<Row>
				{ renderTaskDetails() }
				{ renderRunDetails() }
			</Row>
		</Form>
	)
}

export default RunTemplateForm;