import { ListRequestOptions, ListReturn } from '../models/Application';
import { IPalletLoadListItem, IPalletLoadDetails, PalletLoad_PUT, PalletsListFilter, PalletUnload_POST_PUT, IPalletUnloadListItem, IPalletUnloadDetails } from '../models/Pallet';
import palletLoadingEndpoints from './endpoints/palletEndpoints';
import httpRequests from './httpRequests';

// #region LOADS
const loads = {
	/**
     * Gets the List of Pallet Loads
  */
	getLoadsList(listOptions: ListRequestOptions, filters: PalletsListFilter): Promise<ListReturn<IPalletLoadDetails>> {
		const endpoint = palletLoadingEndpoints.loads.search;
		return httpRequests.get(endpoint, { filterString: JSON.stringify({ ...listOptions, ...filters }) });
	},

	getLoadsListForPanel(minutes = 360): Promise<IPalletLoadListItem[]> {
		const endpoint = palletLoadingEndpoints.loads.panel;
		return httpRequests.get(endpoint, { minutes });
	},

	getLoadDetails(loadId: string): Promise<IPalletLoadDetails> {
		const endpoint = palletLoadingEndpoints.loads.byId(loadId);
		return httpRequests.get(endpoint);
	},

	getLoadDetailsByRunId(runId: string): Promise<IPalletLoadDetails> {
		const endpoint = palletLoadingEndpoints.loads.byRunId(runId);
		return httpRequests.get(endpoint);
	},

	/**
		 * Export the Worker List to a file
		 */
	getLoadsListReportFile(filterString: PalletsListFilter) {
		const endpoint = palletLoadingEndpoints.loads.exportReport;

		return httpRequests.downloadFile(endpoint, { filterString: JSON.stringify(filterString) });
	},
	
	/**
	 * Get the Load Authorisation PDF file
	 * @param loadId load ID
	 * @returns Load Authorisation PDF file
	 */
	getLoadAuthorisationFile(loadId: string): Promise<Blob> {
		const endpoint = palletLoadingEndpoints.loads.loadAuthorisation(loadId);
		return httpRequests.downloadFile(endpoint);
	},

	/**
	 * Get the Load Summary PDF file
	 * @param loadId load ID
	 * @returns Load Summary PDF file
	 */
	getLoadSummaryFile(loadId: string): Promise<Blob> {
		const endpoint = palletLoadingEndpoints.loads.loadSummary(loadId);
		return httpRequests.downloadFile(endpoint);
	},

	// TODO: Change to POST data
	createLoad(data: PalletLoad_PUT) { 
		const endpoint = palletLoadingEndpoints.loads.root;
		return httpRequests.post(endpoint, data);
	},

	updateLoad(id: string, data: PalletLoad_PUT) {
		const endpoint = palletLoadingEndpoints.loads.byId(id);
		return httpRequests.put(endpoint, data);
	},

	deleteLoad(loadId: string) {
		const endpoint = palletLoadingEndpoints.loads.byId(loadId);
		return httpRequests.delete(endpoint);
	},
};
// #endregion

// #region UNLOADS
const unloads = {
	getUnloadsList(listOptions: ListRequestOptions, filters: PalletsListFilter): Promise<ListReturn<IPalletUnloadDetails>> {
		const endpoint = palletLoadingEndpoints.unloads.search;
		return httpRequests.get(endpoint, { filterString: JSON.stringify({ ...listOptions, ...filters }) });
	},

	getUnloadsListForPanel(minutes = 360): Promise<IPalletUnloadListItem[]> {
		const endpoint = palletLoadingEndpoints.unloads.panel;
		return httpRequests.get(endpoint, { minutes });
	},

	getUnloadDetails(unloadId: string): Promise<IPalletUnloadDetails> {
		const endpoint = palletLoadingEndpoints.unloads.byId(unloadId);
		return httpRequests.get(endpoint);
	},

	// TODO: Change to POST data
	createUnload(data: PalletUnload_POST_PUT) { 
		const endpoint = palletLoadingEndpoints.unloads.root;
		return httpRequests.post(endpoint, data);
	},

	updateUnload(unloadId: string, data: PalletUnload_POST_PUT) {		
		const endpoint = palletLoadingEndpoints.unloads.byId(unloadId);
		return httpRequests.put(endpoint, data);
	},

	deleteUnload(unloadId: string) {
		const endpoint = palletLoadingEndpoints.unloads.byId(unloadId);
		return httpRequests.delete(endpoint);
	},
};
// #endregion

export default { loads, unloads };