import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FaultReportApi from '../../../../../app/api/FaultReportApi';

import { ErrorBox, Loader } from '../../../../common/uiElements';

const propTypes = {
	faultReportId: PropTypes.string.isRequired,
	photoId: PropTypes.string.isRequired,
	onLoadComplete: PropTypes.func
};

class FaultReportPhoto extends Component {
	constructor() {
		super();
		this.state = {
			imgBase64: null,
			isLoading: false,
			error: null
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchPhoto();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchPhoto = () => {
		const { faultReportId, photoId, onLoadComplete } = this.props;

		this.setState({ isLoading: true, error: null });
		FaultReportApi.getFaultReportPhoto(faultReportId, photoId)
			.then(imgBase64 => {
				if (this.unmounted) return;

				this.setState({ imgBase64, isLoading: false });
			})
			.catch(error => {
				console.error(error);
				if (this.unmounted) return;

				this.setState({ error });
			})
			.then(onLoadComplete);
	}

	render() {
		const s = this.state;
		
		if (s.error)
			return <ErrorBox error={s.error} retryFunc={this.fetchPhoto} />;

		if (s.isLoading)
			return <Loader />;

		return (
			<img 
				className="img-rounded" 
				height="80" 
				src={s.imgBase64} 
				alt="fault report" 
			/>
		)
	}
}

FaultReportPhoto.propTypes = propTypes;

export default FaultReportPhoto;