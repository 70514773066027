import React from 'react';
import { Col, FormGroup } from 'react-bootstrap';
import { DashboardLinkButton } from '../../common/uiElements';

export default (text, to, icon, cols, color, releaseDate, external) =>(
	<Col { ...cols }>
		<FormGroup>
			<DashboardLinkButton 
				text={text} 
				to={to} 
				icon={icon} 
				color={color} 
				external={external}
				releaseDate={releaseDate}
			/>
		</FormGroup>
	</Col>
);