/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import './style.css';
import helper from '../helper';
import { PreStart } from '../../../../../app/schemas/signalr/Hubs';

import { MainContent } from '../../../../common/layout';

import CheckoutBox from './CheckoutBox';
import CheckoutApproveAllModal from './CheckoutApproveAllModal';
import SignalRContainer from '../../../../common/SignalRContainer';

// APIs
import PreStartApi from '../../../../../app/api/PreStartApi';

// UI Elements
import { Callout, Loader, ProgressBar } from '../../../../common/uiElements';
import cookies from '../../../../../app/helpers/cookies';
import { PlacesList } from '../../../../common/lists';

class CheckoutContainer extends Component {
	static propTypes = {
		refreshEveryHour: PropTypes.bool,
		isReadOnly: PropTypes.bool,
		keepFiltersSaved: PropTypes.bool
	}

	constructor(props) {
		super(props);
		this.state = {
			preStartList: [],
			preStartDataList: [],
			preStartsWithoutProblemToApprove: [],
			preStartIdCheckingOut: null,
			isFetchingPreStartList: false,
			isSavingAllWithoutProblems: false, 
			depotId: '',
			showLateReports: true,
			showApproveAllModal: false
		};

		this.pendingPreStartsAudio = new Audio('https://cdn.micway.com.au/tms/audio/attention_pending_pre_starts.mp3');
	}

	componentDidMount() {
		this.startPendingTimer();

		if (this.props.refreshEveryHour)
			setTimeout(() => window.location.reload(), 1 * 60 * 60 * 1000);	
	}

	componentWillUnmount() {
		this.unmounted = true;

		this.disconnectSignalRHubs();
		this.clearPendingTimer();
	}

	handleSignalRConnected = () => {
		this.connectToSignalRHubs();
		let { depotId } = this.state;
		if (this.props.keepFiltersSaved) {
			const cookieDepotId = cookies.get('preStartsCheckOutDepotId');
			if (cookieDepotId)
				depotId = cookieDepotId;
		}

		this.setState({ depotId }, this.fetchPreStartList);
	}
	
	connectToSignalRHubs = () => {
		this.disconnectSignalRHubs();
		this.props.preStartHub.on(PreStart.client.SUPERVISOR_REFRESH_SCREEN, this.supervisorRefreshScreen);
		this.props.preStartHub.on(PreStart.client.SUPERVISOR_REFRESH_BOX_LOCKER_STATUS, this.supervisorRefreshPreStartBoxLockerStatus);
	}
	
	disconnectSignalRHubs = () => {
		this.props.preStartHub.off(PreStart.client.SUPERVISOR_REFRESH_SCREEN);
		this.props.preStartHub.off(PreStart.client.SUPERVISOR_REFRESH_BOX_LOCKER_STATUS);
	}
	
	shouldComponentUpdate() {
		return !this.state.preStartIdCheckingOut;
	}

	startPendingTimer = () => {
		this.pendingTimer = window.setInterval(() => {
			
			//Get all pre-start that has been pending for more than 7 minutes
			const pendingTimeToAlertInMinutes = 7;
			const pendingPreStarts = this.state.preStartList.filter(
				p => moment.duration(moment().diff(moment(p.preStart.lastReportedOnLocal || p.preStart.inspectedOnLocal)))._milliseconds > 1000 * 60 * pendingTimeToAlertInMinutes
			);

			if (pendingPreStarts.length > 0)
				this.pendingPreStartsAudio.play()
					.catch(error => console.error(error));
			
		}, 1000 * 60 * 5);
	}

	clearPendingTimer = () => {
		window.clearInterval(this.pendingTimer)
	}

	/**
	 * Method to add the Pre Start Data to the main Pre Start List from the State
	 * All the data is loaded when a new List comes from the server and it is loaded already
	 */
	addPreStartDataToMainList = () => {
		let { preStartList } = this.state;
		for (let key in preStartList) {
			if (Object.hasOwnProperty.call(preStartList, key)) {
				// eslint-disable-next-line
				let preStartData = this.state.preStartDataList.find(p => p.id === preStartList[key].preStartId);

				if (preStartData) {
					let issues = helper.renderAllIssuesObject(preStartData);
					preStartList[key] = {
						...preStartList[key],
						...issues,
						preStart: preStartData,
					};
				}
			}
		}

		preStartList = preStartList.sortBy(p => [ new Date(p.preStart.lastReportedOnLocal || p.preStart.inspectedOnLocal) ]);
		
		if (preStartList.length > 0 && preStartList[0].preStart && this.state.depotId)
			preStartList = preStartList.filter((p => p.preStart.depotId == this.state.depotId));

		this.setState({ preStartList, isFetchingPreStartList: false });
	}

	/**
	 * Get the List of All Pre-Starts invoking the server method
	 */
	fetchPreStartList = () => {
		this.setState({ isFetchingPreStartList: true, error: false });
		this.props.preStartHub.invoke(PreStart.server.SUPERVISOR_LOAD_PRE_START_LIST)
			.fail(error => {
				console.error(error);
				this.setState({ isFetchingPreStartList: false });
			});
	}

	/**
	 * Change the status of the Box to Locked or Unlocked
	 * @param {string} preStartId 
	 * @param {boolean} isLocked Array list with current opened Pre-Start
	 */
	supervisorChangeBoxLockerStatus = (preStartId, isLocked) => {
		this.props.preStartHub.invoke(PreStart.server.SUPERVISOR_CHANGE_BOX_LOCKER_STATUS, preStartId, isLocked)
			.fail(error => {
				console.error(error);
			});
	}

	/**
	 * When the supervisor is Checking Out the Pre-Start, this method calls the server
	 * saying who is checking out and its Status (isChecking out or not)
	 * @param {string} preStartId Pre-Start ID
	 * @param {string} supervisorId Supervisor Azure ID
	 * @param {boolean} isCheckingOut If is checking
	 */
	supervisorIsCheckingOut = (preStartId, supervisorId, isCheckingOut) => {
		this.props.preStartHub.invoke(PreStart.server.SUPERVISOR_IS_CHECKING_OUT, preStartId, supervisorId, isCheckingOut)
			.fail(error => {
				console.error(error);
			});
	}
	
	/**
	 * When the Pre-Starts List changes on server
	 * @param {array} preStartList Array list with current opened Pre-Start
	 */
	supervisorRefreshScreen = (preStartNewList) => {
		let { preStartDataList } = this.state;

		// Remove data that is not on the screen anymore
		preStartDataList = preStartDataList.filter(d => preStartNewList.find(n => n.preStartId === d.id));

		let preStartsIdsToGetData = [];
		for (let key in preStartNewList) {
			if (Object.hasOwnProperty.call(preStartNewList, key)) {
				const newPreStart = preStartNewList[key];
				const newPreStartId = newPreStart.preStartId;

				const isDataAlreadyStored = preStartDataList.find(p => p.id === newPreStartId);

				if (!isDataAlreadyStored)
					preStartsIdsToGetData.push(newPreStartId);
			}
		}

		if (preStartsIdsToGetData.length === 0)
			this.setState({ 
				preStartDataList,
				preStartList: preStartNewList
			}, this.addPreStartDataToMainList);
		else
			PreStartApi.getPreStartsListById(preStartsIdsToGetData)
				.then(returnedPreStartDataList => {
					if (this.unmounted) return;
					
					preStartDataList = preStartDataList.concat(returnedPreStartDataList);
					this.setState({ 
						preStartDataList,
						preStartList: preStartNewList
					}, this.addPreStartDataToMainList);
				})
				.catch(error => {
					console.error(error);
				});
	}

	/**
	 * When someone lock a Pre-Start
	 * @param {string} preStartId ID of the Pre-Start that is being locked
	 * @param {boolean} isLocked If it's locked or not
	 */
	supervisorRefreshPreStartBoxLockerStatus = (preStartId, isLocked) => {
		if (this.state.preStartIdCheckingOut !== preStartId) {
			let { preStartList } = this.state;
			const preStart = preStartList.find(p => p.preStartId === preStartId);

			if (preStart)
				preStart.isLocked = isLocked;

			this.setState({ preStartList }, this.forceUpdate);
		}
	}

	/**
	 * ===== End SignalR Functions
	 */
	
	/**
	 * Get a list of Pre-Start IDs without any problem
	 */
	getPreStartsWithoutProblem = () => {
		let { preStartList, isFetchingPreStartList } = this.state;
		let preStartWithoutProblems = [];

		if (!isFetchingPreStartList)
			for (var key in preStartList) {
				if (Object.hasOwnProperty.call(preStartList, key)) {
					var preStartData = preStartList[key];
					if (preStartData.preStart && !helper.isThereAnyProblem(preStartData) && !preStartData.isLocked)
						preStartWithoutProblems.push(preStartData.preStart);
				}
			}

		return preStartWithoutProblems;
	}

	/**
	 * Get an ID list of Pre-Starts that are a late report
	 */
	getLatePreStarts = () => {
		let { preStartList, isFetchingPreStartList } = this.state;
		let latePreStarts = [];

		if (!isFetchingPreStartList)
			for (var key in preStartList) {
				if (Object.hasOwnProperty.call(preStartList, key)) {
					let { preStart } = preStartList[key];
					if (preStart && preStart.isLateReport)
						latePreStarts.push(preStart.id);
				}
			}

		return latePreStarts;
	}

	handleToggleLateReports = () => {
		this.setState({ showLateReports: !this.state.showLateReports });
	};
	
	handleChangeDepotId = e => {
		const depotId = e.target.value;

		if (this.props.keepFiltersSaved)
			cookies.set('preStartsCheckOutDepotId', depotId);

		if(this.state.preStartIdCheckingOut)
			this.handleToggleCheckout(this.state.preStartIdCheckingOut, false);
		
		this.setState({ depotId }, this.fetchPreStartList);  
		
	};
	
	handleClickApproveAllWithoutProblem = () => {
		const preStartsWithoutProblem = this.getPreStartsWithoutProblem();
		if (preStartsWithoutProblem.length === 0)
			return toastr.info('There are no Pre-Starts without problems to checkout', 'Info');
		else {
			for (let i = 0; i < preStartsWithoutProblem.length; i++) {
				var preStartId = preStartsWithoutProblem[i].id;
				this.handleToggleCheckout(preStartId, true);
			}
	
			return this.setState({ showApproveAllModal: true, preStartsWithoutProblemToApprove: preStartsWithoutProblem });
		}
	};
	
	handleCloseApproveAllModal = () => {
		const { preStartsWithoutProblemToApprove } = this.state;
		for (let i = 0; i < preStartsWithoutProblemToApprove.length; i++) {
			var preStartId = preStartsWithoutProblemToApprove[i].id;
			this.handleToggleCheckout(preStartId, false);
		}
		this.setState({ showApproveAllModal: false, preStartsWithoutProblemToApprove: [] }, this.forceUpdate);
	};
	
	/**
		* Checkout all pre-start without problems
		* @param {string} azureId User ID
		*/
	handleSubmitApproveAll = (azureId, preStartData) => {
		this.setState({ isSavingAllWithoutProblems: true, preStartIdCheckingOut: null, showApproveAllModal: false }, this.forceUpdate);
		PreStartApi.checkoutManyPreStarts(azureId, preStartData)
			.then(() => {
				toastr.success('All Pre-Start without problems were checked out', 'Success!');
				if (this.unmounted) return;
	
				this.setState({ isSavingAllWithoutProblems: false }, this.handleCloseApproveAllModal);
			})
			.catch(error => {
				console.error(error);
				this.setState({ isSavingAllWithoutProblems: false }, this.handleCloseApproveAllModal);
			});
	};
	
	/**
		* When the supervisor change to or from Checkout Page, this method calls the server
		* saying that the Box Status (Locked or Unlocked)
		*/
	handleToggleCheckout = (preStartId, isLocked) => {
		if (isLocked)
			this.setState({ preStartIdCheckingOut: preStartId });
		else {
			// Unlock the this preStart on the current list
			let { preStartList } = this.state;
			let lockedPreStart = preStartList.find(p => p.preStartId === preStartId);
			if (lockedPreStart)
				lockedPreStart.isLocked = false;
	
			this.setState({ 
				preStartIdCheckingOut: null,
				preStartList
			}, this.forceUpdate);
		}
					
		this.supervisorChangeBoxLockerStatus(preStartId, isLocked);
	};
	
	handleSubmitCheckout = () => {
		setTimeout(() => {
			this.setState({ preStartIdCheckingOut: null }, this.forceUpdate);
		}, 1500);
	};

	render() {
		const { state, props } = this;
		const latePreStarts = this.getLatePreStarts();

		return (
			<MainContent title="Pre-Start - Checkout">
				<FormGroup>
					<PlacesList
						showAllOption
						id="depotId"
						type="preStart" 
						className="input-lg"
						value={state.depotId}
						onChange={this.handleChangeDepotId} 
					/>
				</FormGroup>
				<SignalRContainer onConnected={this.handleSignalRConnected}>
					{
						state.isFetchingPreStartList ? (
							<Loader text="Fetching Pre Start List..."/>
						) : (
							<div>
								{
									latePreStarts.length > 0 && (
										<Row>
											<Col xs={12}>
												<FormGroup>
													<Button 
														block
														bsStyle="warning"  
														onClick={this.handleToggleLateReports}
													>
														<b>{latePreStarts.length} Late Report(s)</b> - Click here to <b>{state.showLateReports ? 'Hide' : 'Show'}</b>
													</Button>
												</FormGroup>
											</Col>
										</Row>
									)
								}
								{
									state.preStartList.length === 0 ? (
										<Callout title="No data to show" color="info">
										There are no Pre-Starts waiting for approval or you need to change the depot filter
										</Callout>
									) : (
										<div>
											{
												!props.isReadOnly && (
													<Row>
														<Col xs={12}>
															<FormGroup>
																{
																	state.isSavingAllWithoutProblems ? (
																		<div className="text-center">
																			<h4>Saving the data. Please Wait...</h4>
																			<ProgressBar currentValue={100} color="primary" />
																		</div>
																	) : (
																		<Button 
																			block
																			bsStyle="success" 
																			bsSize="lg" 
																			disabled={state.preStartIdCheckingOut ? true : false}
																			onClick={this.handleClickApproveAllWithoutProblem}
																		>
																								Approve all Pre-Starts without any problem
																		</Button>
																	)
																}
															</FormGroup>
														</Col>
													</Row>
												)
											}
											<Row className="aligned-row">
												{
													state.preStartList.map(preStart => {
														if (!preStart.preStart)
															return null;

														return (
															<Col key={preStart.preStartId} xs={12} sm={6} md={4} lg={3}>
																<CheckoutBox 
																	data={preStart} 
																	isBeingCheckedOut={preStart.preStartId === state.preStartIdCheckingOut}
																	isLocked={preStart.isLocked}
																	onToggleCheckout={this.handleToggleCheckout} 
																	onToggleIsCheckingOut={this.supervisorIsCheckingOut}
																	isCheckoutWithBlur={(state.preStartIdCheckingOut && state.preStartIdCheckingOut !== preStart.preStartId) || state.isSavingAllWithoutProblems}
																	isReadOnly={props.isReadOnly}
																	onSubmitCheckout={this.handleSubmitCheckout}
																/>
															</Col>
														);})
												}
											</Row>
										</div>
									)
								}
							</div>
						)
					}
				</SignalRContainer>
				{
					state.preStartsWithoutProblemToApprove.length > 0 && (
						<CheckoutApproveAllModal 
							preStartsToApprove={state.preStartsWithoutProblemToApprove}
							show={state.showApproveAllModal}
							onClose={this.handleCloseApproveAllModal}
							onSubmit={this.handleSubmitApproveAll}
						/>
					)
				}
			</MainContent>
		);
	}
}

const mapStateToProps = state => ({
	preStartHub: state.signalr.hubs.preStartHub
});

export default connect(mapStateToProps)(CheckoutContainer);