import meEndpoints from "./endpoints/meEndpoints";
import httpRequests from "./httpRequests";
import { ITaskDetail } from "../models/Task";

export default {
  /**
   * Gets Logged User Details
   */
  getUserDetails() {
    const endpoint = meEndpoints.root;
    return httpRequests.get(endpoint);
  },

  /**
   * Updates the logged user data
   * @param data User data
   */
  updateUserDetails(data: object) {
    const endpoint = meEndpoints.root;
    return httpRequests.putWithFiles(endpoint, data);
  },

  /**
   * Get the base64 of the profile picture
   */
  getProfilePhoto() {
    const endpoint = meEndpoints.photo;
    return httpRequests.getImage(endpoint);
  },

  /**
   * Get logged user Pre-Starts list
   */
  getPreStartsList(filters: string) {
    const endpoint = meEndpoints.preStarts;

    if (filters) filters = JSON.stringify(filters);

    return httpRequests.get(endpoint, { filterString: filters });
  },

  /**
   * Get Leave Requests List
   */
  getLeaveRequestsList(filters: string, onlyActive?: boolean) {
    const endpoint = meEndpoints.leaveRequests;

    if (filters) filters = JSON.stringify(filters);

    return httpRequests.get(endpoint, { filterString: filters, onlyActive });
  },

  /**
   * Gets the logged user's roster based on the start time
   */
  getRoster(startTimeLocal: string, finishTimeLocal: string): Promise<ITaskDetail[]> {
    const endpoint = meEndpoints.roster;
    return httpRequests.get(endpoint, { startTimeLocal, finishTimeLocal });
  },

	/** Gets the logged user's tasks based on the start time  */
  getTasks(startTimeLocal: string, finishTimeLocal: string, onlyPending = true, onlyInProgress = false): Promise<ITaskDetail[]> {
    const endpoint = meEndpoints.activeTasks;
    return httpRequests.get(endpoint, { startTimeLocal, finishTimeLocal, onlyPending, onlyInProgress });
  },
};