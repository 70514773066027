/* eslint-disable eqeqeq */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';

import { VehicleTypes } from '../../../../../../app/models/Vehicle';
import { RadioButtonList, VehicleSearch, YesNoRadio, LimitNumberInput } from '../../../../../common/inputs';
import { Callout, Form, Loader } from '../../../../../common/uiElements';
import { Row, Col, FormGroup, FormControl } from 'react-bootstrap';
import StringMask from 'string-mask';
import VehiclesApi from '../../../../../../app/api/VehiclesApi';
import Axios from 'axios';
import { PlacesList } from '../../../../../common/lists';

const radioItemsGaugeLevel = [
	{ text: 'E', value: 0 }, 
	{ text: '1/4', value: 0.25 },
	{ text: '1/3', value: 0.33 },
	{ text: '1/2', value: 0.5 },
	{ text: '2/3', value: 0.66 },
	{ text: '3/4', value: 0.75 },
	{ text: 'F', value: 1 }
];

const propTypes = {
	id: PropTypes.string,
	data: PropTypes.object,
	mainVehFleetNumber: PropTypes.string,
	trailer1FleetNumber: PropTypes.string,
	trailer2FleetNumber: PropTypes.string,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,
	showTitle: PropTypes.bool,
	isGuideMode: PropTypes.bool,
	addGuideSteps: PropTypes.func,
	isPreStartV2: PropTypes.bool,
	isMainVehicleNotTracked: PropTypes.bool,
	isTrailer1NotTracked: PropTypes.bool,
	subContractorAbn: PropTypes.string
};

const defaultProps = {
	id: 'GeneralInformation',
	readOnly: false,
	showTitle: true,
	data: {
		depotId: '',
		mainVehFleetNumber: '',
		odometerStart: '',
		mainFreezerHourStart: '',
		trailer1VehFleetNumber: '',
		trailer1FreezerHourStart: '',
		trailer2VehFleetNumber: '',
		trailer2FreezerHourStart: '',
		palletJackVehFleetNumber: '',
		dollyVehFleetNumber: '',
		probeNumber: '',
		hasFuelCard: '',
		hasHandControlOnBoard: '',
		inspectedOnLocal: '',
		isLateReport: false
	}
};

const validations = {
	depotId: 'required',
	mainVehFleetNumber: 'required',
	mainFuelLevel: 'required',
	mainFreezerHourStart: { required: true, min: 1 },
	adBlueLevel: 'required',
	trailer1VehFleetNumber: 'required',
	trailer1FuelLevel: 'required',
	trailer1FreezerHourStart: { required: true, min: 1 },
	trailer1JawsPhoto: 'required',
	trailer1TugTestDone: 'required',
	trailer2FuelLevel: 'required',
	trailer2FreezerHourStart: { required: true, min: 1 },
	trailer2JawsPhoto: 'required',
	trailer2TugTestDone: 'required',
	isDollyDrawbarsOk: 'required',
	odometerStart: { required: true, min: 1 },
	probeNumber: 'required',
	hasFuelCard: 'required',
	hasHandControlOnBoard: 'required',
	hasNewDamage: 'required',
	inspectedOnLocal: 'required',
	palletJackIsIn: 'required',
	palletJackVehFleetNumber: 'required',
	isUsingTrolley: 'required',
	trolleyIsIn: 'required',
	isUsingPalletJack: 'required'
};

// const guideSteps = [
// 	{
// 		title: 'Step 1 - General Information',
// 		text: 'This is the first part of the Pre-Start Check',
// 		selector: '.pre-start__general-information',
// 		position: 'top'
// 	},
// 	{
// 		title: 'Main Vehicle',
// 		text: 'Select the main vehicle',
// 		selector: '.pre-start__mainVehFleetNumber',
// 		position: 'top'
// 	},
// 	{
// 		title: 'Odometer and Fridge Engine Hours',
// 		text: 'Update the Odometer and the Fridge Engine hours from the vehicle\'s dashboard',
// 		selector: '.pre-start__mainVehOdometerFridge',
// 		position: 'top'
// 	},
// 	{
// 		title: 'Trailer 1',
// 		text: 'If a trailer is required, provide its information',
// 		selector: '.pre-start__trailer-1',
// 		position: 'top'
// 	},
// 	{
// 		title: 'Dolly',
// 		text: 'If a dolly is required, provide its information',
// 		selector: '.pre-start__dolly',
// 		position: 'top'
// 	},
// 	{
// 		title: 'Trailer 2',
// 		text: 'If a B-Double is required, provide its information as well',
// 		selector: '.pre-start__trailer-2',
// 		position: 'top',
// 		isLastStep: true
// 	}
// ];

class GeneralInformation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoadingVehicles: false,
			currentOdometerValueMeters: 0,
			mainFreezerCurrentValueSec: 0,
			mainVehHasOdometerCounter: false,
			mainVehHasFreezerOnCounter: false,
			mainVehTypeID: '',
			mainVehCanDollyAttach: false,
			trailer1FreezerCurrentValueSec: 0,
			trailer1VehTypeID: '',
			trailer1HasFreezerOnCounter: false,
			trailer1CanDollyAttach: false,
			trailer2FreezerCurrentValueSec: 0,
			trailer2HasFreezerOnCounter: false,
			isUsingPalletJack: undefined
		};

		this.mainVehicleFilter = [VehicleTypes.PRIME_MOVER, VehicleTypes.RIGID];
		
		// If its the preStartV2 and there's a trailer, the main vehicle filter should be only the prime movers
		// Because the trailer is selected from the tasks and it's not possible to change it
		if (props.isPreStartV2 && props.data.trailer1FleetNumber)
			this.mainVehicleFilter = [VehicleTypes.PRIME_MOVER];
	}

	componentDidMount() {
		// this.props.addGuideSteps && this.props.addGuideSteps(guideSteps);

		if (!this.props.data.odometerStart)
			this.fetchVehiclesData();

	}

	fetchVehiclesData() {
		const { data } = this.props;
		const promises = [];
		promises.push(data.mainVehFleetNumber ? VehiclesApi.getVehicleDetailsOld(data.mainVehFleetNumber) : null);
		promises.push(data.trailer1FleetNumber ? VehiclesApi.getVehicleDetailsOld(data.trailer1FleetNumber) : null);
		promises.push(data.trailer2FleetNumber ? VehiclesApi.getVehicleDetailsOld(data.trailer2FleetNumber) : null);
			
		if (promises.length > 0) {
			this.setState({ isLoadingVehicles: true });
			Axios.all(promises)
			.then(Axios.spread((MainVehicle, Trailer1, Trailer2) => {
				if (MainVehicle)
					this.handleSelectMainVehicle(MainVehicle);
				if (Trailer1)
					this.handleSelectTrailer(Trailer1, 1);
				if (Trailer2)
					this.handleSelectTrailer(Trailer2, 2);
			}
			))
			.finally(() => {
				this.setState({ isLoadingVehicles: false });
				this.forceUpdate();
			})
		}
	}

	handleChange = data => {
		let { onChange, id } = this.props;
		if (onChange)
			onChange(data, id);
	}

	handleChangeInput = (e, value) => {
		let { data } = this.props;
		var fieldId = e.target.id;
		value = e.target.type === 'checkbox' ? e.target.checked : (typeof value !== 'undefined' ? value : e.target.value);
		
		if (fieldId === 'palletJackVehFleetNumber' && value === '')
			data.palletJackIsIn = '';
		else if (fieldId === 'isUsingTrolley' && value === false)
			data.trolleyIsIn = '';

		data[fieldId] = value;

		return this.handleChange(data);
	}

	handleChangeDepot = (e, dtoDepotName) => {
		let { data } = this.props;
		let fieldId = e.target.id;
		let value = e.target.value;

		data = {
			...data,
			[fieldId]: value,
			dtoDepotName
		};

		return this.handleChange(data);
	}

	/**
	 * Handle when select the main vehicle
	 */
	handleSelectMainVehicle = (vehicle) => {
		let { data, isPreStartV2, isTrailer1NotTracked } = this.props;
		
		var { 
			vehFleetNumber, 
			vehTypeID, 
			vehOdometerTotalMeter, 
			vehFreezerOnTotalSec,
			vehSetHasOdometerCounter,
			vehSetHasFreezerOnCounter,
			vehCanAttachDolly
		} = vehicle;
		
		data = {
			...data,
			mainVehFleetNumber: vehFleetNumber,
			odometerStart: '',
			mainFreezerHourStart: '',
			mainVehTypeID: vehTypeID,
			mainVehCanDollyAttach: vehCanAttachDolly,
			mainFuelLevel: '',
			trailer2VehFleetNumber: '',
			trailer2FreezerHourStart: '',
			trailer2FuelLevel: '',
			isUsingTrolley: '',
			trolleyIsIn: '',
			palletJackVehFleetNumber: '',
			palletJackIsIn: '',
			dollyVehFleetNumber: '',
		};

		// If there's a trailer and its not the preStartV2 or the trailer is not tracked, reset the trailer data
		// Because we cant reset the trailer if it's a preStartV2 and the trailer is tracked as it came from the Tasks and cant be changed
		if (data.trailer1VehFleetNumber && (!isPreStartV2 || isTrailer1NotTracked)) {
			data = {
				...data,
				trailer1VehFleetNumber: '',
				trailer1FreezerHourStart: '',
				trailer1FuelLevel: '',
				trailer1CanDollyAttach: '',
			}
		}

		this.handleChange(data);

		return this.setState({
			currentOdometerValueMeters: vehOdometerTotalMeter,
			mainFreezerCurrentValueSec: vehFreezerOnTotalSec,
			mainVehHasOdometerCounter: vehSetHasOdometerCounter,
			mainVehHasFreezerOnCounter: vehSetHasFreezerOnCounter,
			mainVehTypeID: vehTypeID,
			mainVehCanDollyAttach: vehCanAttachDolly,
		});
	}

	/**
	 * Handle when select a trailer
	 * @param {object} vehicle Vehicle object type
	 * @param {number} number The trailer number (1 or 2)
	 */
	handleSelectTrailer = (vehicle, number) => {
		if (!vehicle)
			return;

		let { data } = this.props;
		
		var { 
			vehFleetNumber, 
			vehTypeID, 
			vehSetHasFreezerOnCounter,
			vehFreezerOnTotalSec,
			vehCanAttachDolly
		} = vehicle;
		data = {
			...data,
			[`trailer${number}VehFleetNumber`]: vehFleetNumber,
			[`trailer${number}FreezerHourStart`]: '',
			[`trailer${number}FuelLevel`]: '',
			isUsingTrolley: '',
			trolleyIsIn: '',
			palletJackVehFleetNumber: '',
			palletJackIsIn: ''
		};

		if (number == '1') {
			data.trailer2VehFleetNumber = '';
			data.trailer2FreezerHourStart = '';
			data.trailer1CanDollyAttach = vehCanAttachDolly;
		}
		
		this.handleChange(data);
		return this.setState({
			[`trailer${number}FreezerCurrentValueSec`]: vehFreezerOnTotalSec,
			[`trailer${number}VehTypeID`]: vehTypeID,
			[`trailer${number}HasFreezerOnCounter`]: vehSetHasFreezerOnCounter,
		});

	}

	/**
	 * Handle when select a dolly
	 * @param {object} vehicle Vehicle object type
	 */
	handleSelectDolly = (vehicle) => {
		let { data } = this.props;
		
		var { 
			vehFleetNumber, 
			vehTypeID, 
		} = vehicle;
		data = {
			...data,
			dollyVehFleetNumber: vehFleetNumber,
		};

		this.handleChange(data);
		return this.setState({
			dollyVehTypeID: vehTypeID,
		});

	}

	handleChangeOdometer = newData => {
		let { data } = this.props;
		data = {
			...data,
			...newData
		};

		return this.handleChange(data);
	}


	handleChangeFreezerHours = newData => {
		let { data } = this.props;
		data = {
			...data,
			...newData
		};

		return this.handleChange(data);
	}

	handleChangeTrailerJawPhoto = e => {
		if (this.unmounted) return;
		
		let { data } = this.props;
		const { id, files } = e.target;
		
		if (files && files.length > 0) {
			const file = files[0];
			data = {
				...data,
				[id]: file
			};
	
			return this.handleChange(data);
		}
	}

	handleChangeUsingPalletJack = e => {
		const isUsingPalletJack = e.target.value === 'true';
		const data = {
			...this.props.data,
			palletJackVehFleetNumber: '',
			palletJackIsIn: ''
		};

		this.handleChange(data);
		this.setState({ isUsingPalletJack });
	};

	getGaugeLevel = value => {
		const item = radioItemsGaugeLevel.find(p => p.value === value);
		return item ? item.text : '-';
	}

	renderFleetsIn = () => {
		const { data } = this.props;

		const fleets = [
			{ text: data.trailer1VehFleetNumber || data.mainVehFleetNumber, value: data.trailer1VehFleetNumber || data.mainVehFleetNumber }
		];

		if (data.trailer2VehFleetNumber)
			fleets.push({ text: data.trailer2VehFleetNumber, value: data.trailer2VehFleetNumber });

		return fleets;
	}

	renderPreLoadedData = value => (
		<p style={{ fontSize: '30px', marginTop: '-10px' }}>
			<b>{ value }</b>
		</p>
	)

	render() {
		const { state, props } = this;
		let { data, readOnly, showTitle, isGuideMode } = this.props;

		if (state.isLoadingVehicles)
			return <Loader text="Loading Fleet Data..." />

		return (
			<Form validations={validations} className="pre-start__general-information">
				{ 
					showTitle && (
						<h3 className="title">
							<Icon name="info-circle" /> General Info
						</h3> 
					)
				}

				<Row className="aligned-row">
					<Col xs={12} lg={3}>
						<FormGroup className="pre-start__mainVehFleetNumber">
							<label>Main Vehicle *</label>
							{
								readOnly ? (
									<p>{ data.mainVehFleetNumber }</p>
								) : (
									props.isPreStartV2 && data.mainVehFleetNumber ? (
										this.renderPreLoadedData(data.mainVehFleetNumber)
									) : (
									<VehicleSearch 
										onlyActive
										id="mainVehFleetNumber" 
										value={data.mainVehFleetNumber}
										vehTypeFilter={this.mainVehicleFilter} 
										subContractorAbnFilter={props.isMainVehicleNotTracked ? props.subContractorAbn : undefined}
										onSelectVehicle={this.handleSelectMainVehicle} 
									/>
									)
								)
							}
						</FormGroup>
						{
							// Main Vehicle
							(isGuideMode || data.mainVehFleetNumber) && (
								<div className="pre-start__mainVehOdometerFridge">
									<div className="pre-start__fuelLevel">
										<label>Fuel Level *</label>
										{
											readOnly ? (
												<p>{ this.getGaugeLevel(data.mainFuelLevel) }</p>
											) : (
												<RadioButtonList 
													id="mainFuelLevel" 
													items={radioItemsGaugeLevel} 
													value={data.mainFuelLevel}
													onChange={this.handleChangeInput}
												/>
											)
										}
										<label>AD Blue Level *</label>
										{
											readOnly ? (
												<p>{ this.getGaugeLevel(data.adBlueLevel) }</p>
											) : (
												<RadioButtonList 
													id="adBlueLevel" 
													items={radioItemsGaugeLevel} 
													value={data.adBlueLevel}
													onChange={this.handleChangeInput}
												/>
											)
										}
									</div>
									<FormGroup>
										<label>Odometer *</label>
										{
											readOnly ? (
												<p>{ StringMask.apply((data.odometerStart / 1000).toFixed(0), '###,###,###', { reverse: true }) } Km</p>
											) : (
												<LimitNumberInput 
													id="odometerStart"
													initialValue={state.currentOdometerValueMeters}
													elementId={data.mainVehFleetNumber}
													type="kilometers"
													typeShort="KM"
													warningMaxDiff={state.mainVehHasOdometerCounter ? 50 : 200} // in case it's a sub-contractor
													errorMaxDiff={state.mainVehHasOdometerCounter ? 15000 : undefined} // in case it's a sub-contractor
													className="required-hidden number" 
													value={data.odometerStart}
													onChange={this.handleChangeOdometer}
												/>
											)
										}
									</FormGroup>
									{
										(isGuideMode || state.mainVehHasFreezerOnCounter || (readOnly && data.mainFreezerHourStart > 0)) && (
											<FormGroup>
												<label>Fridge Engine Hours *</label>
												{
													readOnly ? (
														<p>{ StringMask.apply((data.mainFreezerHourStart / 60 / 60).toFixed(0), '###,###,###', { reverse: true }) } hours</p>
													) : (
														<LimitNumberInput 
															id="mainFreezerHourStart"
															elementId={data.mainVehFleetNumber}
															initialValue={state.mainFreezerCurrentValueSec}
															type="hours"
															typeShort="hrs"
															warningMaxDiff={20}
															errorMaxDiff={1000}
															className="required-hidden" 
															value={data.mainFreezerHourStart}
															onChange={this.handleChangeFreezerHours}
														/>
													)
												}
											</FormGroup>
										)
									}
								</div>
							)
						}
					</Col>
					{
						// Trailer 1
						(
							isGuideMode 
							|| (readOnly && data.trailer1VehFleetNumber) 
							|| (
								(state.mainVehTypeID == VehicleTypes.PRIME_MOVER || data.trailer1VehFleetNumber)
								&& (
									((!props.isPreStartV2 || data.trailer1VehFleetNumber || props.isTrailer1NotTracked))
									|| (props.isMainVehicleNotTracked)
								)
							)
						) && (
							<Fragment>
								<Col xs={12} lg={3} className="pre-start__trailer-1" key={1}>
									<hr className="visible-xs" />
									<FormGroup>
										<label>Trailer 1</label>
										{
											readOnly ? (
												<p>{ data.trailer1VehFleetNumber || 'None' }</p>
											) : (
												props.isPreStartV2 && data.trailer1VehFleetNumber ? (
													this.renderPreLoadedData(data.trailer1VehFleetNumber)
												) : (
													<VehicleSearch 
														id="trailer1VehFleetNumber" 
														value={data.trailer1VehFleetNumber}
														firstItems={[{value: '', text: 'None'}]}
														vehTypeFilter={[VehicleTypes.TRAILER, VehicleTypes.B_DOUBLE]} 
														subContractorAbnFilter={props.isTrailer1NotTracked ? props.subContractorAbn : undefined}
														fleetNumbersToExclude={[data.trailer2FleetNumber]}
														onlyActive
														onSelectVehicle={(vehicle) => { this.handleSelectTrailer(vehicle, 1); }}
													/>
												)
											)
										}
									</FormGroup>
									{
										data.trailer1VehFleetNumber && (
											<div className="pre-start__fuelLevel">
												<label>Fuel Level *</label>
												{
													readOnly ? (
														<p>{ this.getGaugeLevel(data.trailer1FuelLevel) }</p>
													) : (
														<RadioButtonList 
															id="trailer1FuelLevel" 
															items={radioItemsGaugeLevel} 
															value={data.trailer1FuelLevel || ''}
															onChange={this.handleChangeInput}
														/>
													)
												}
											</div>
										)
									}
									{
										(isGuideMode || (readOnly && data.trailer1FreezerHourStart > 0) || (data.trailer1VehFleetNumber && state.trailer1HasFreezerOnCounter)) && (
											<Fragment>
												<FormGroup>
													<label>Fridge Engine Hours *</label>
													{
														readOnly ? (
															<p>{ StringMask.apply((data.trailer1FreezerHourStart / 60 / 60).toFixed(0), '###,###,###', { reverse: true }) } hours</p>
														) : (
															<LimitNumberInput 
																id="trailer1FreezerHourStart"
																elementId={data.trailer1VehFleetNumber}
																initialValue={state.trailer1FreezerCurrentValueSec}
																type="hours"
																typeShort="hrs"
																warningMaxDiff={20}
																errorMaxDiff={1000}
																className="required-hidden" 
																value={data.trailer1FreezerHourStart}
																onChange={this.handleChangeFreezerHours}
															/>
														)
													}
												</FormGroup>
												{/* <FormGroup>
													<Callout color="warning" title={`Take a photo of jaws engaged with the trailer ${ data.trailer1VehFleetNumber } pin`}>
														<Button 
															block 
															bsStyle="default"
															bsSize="small" 
															onClick={() => this.trailer1JawsPhoto.click()}
														>
															<Icon name="camera" />&nbsp;
															{ !data.trailer1JawsPhoto ? 'Take Photo' : 'Take another photo' }
														</Button>
													</Callout>
													{
														data.trailer1JawsPhoto && (
															<Callout color="success">
																<Icon name="check" /> Photo successfully added
															</Callout>
														)
													}
													<FormControl 
														id="trailer1JawsPhoto"
														inputRef={ref => this.trailer1JawsPhoto = ref}
														type="file"
														className="hidden"
														accept="image/*;capture=camera"
														onChange={this.handleChangeTrailerJawPhoto}
													/>
												</FormGroup> */}
											</Fragment>
										)
									}
								</Col>
								{
									// Tailer 2
									(
										isGuideMode 
										|| (readOnly && data.trailer2VehFleetNumber) 
										|| (data.trailer1VehFleetNumber && state.trailer1VehTypeID == VehicleTypes.B_DOUBLE && (!props.isPreStartV2 || data.trailer2VehFleetNumber))
									) && (
										<Fragment>
											<Col xs={12} lg={3} className="pre-start__trailer-2" key={2}>
												<hr className="visible-xs" />
												<FormGroup>
													<label>Trailer 2</label>
													{
														readOnly ? (
															<p>{ data.trailer2VehFleetNumber || 'None' }</p>
														) : (
															props.isPreStartV2 && data.trailer2VehFleetNumber ? (
																this.renderPreLoadedData(data.trailer2VehFleetNumber)
															) : (
																<VehicleSearch 
																	onlyActive
																	id="trailer2VehFleetNumber" 
																	value={data.trailer2VehFleetNumber}
																	firstItems={[{value: '', text: 'None'}]}
																	fleetNumbersToExclude={[data.trailer1VehFleetNumber]}
																	vehTypeFilter={[3, 5]} 
																	onSelectVehicle={(vehicle) => { this.handleSelectTrailer(vehicle, 2); }}
																/>
															)
														)
													}
												</FormGroup>
												{
													data.trailer2VehFleetNumber && (
														<div className="pre-start__fuelLevel">
															<label>Fuel Level *</label>
															{
																readOnly ? (
																	<p>{ this.getGaugeLevel(data.trailer2FuelLevel) }</p>
																) : (
																	<RadioButtonList 
																		id="trailer2FuelLevel" 
																		items={radioItemsGaugeLevel} 
																		value={data.trailer2FuelLevel || ''}
																		onChange={this.handleChangeInput}
																	/>
																)
															}
														</div>
													)
												}
												{
													((isGuideMode || (readOnly && data.trailer2FreezerHourStart > 0)) || (data.trailer2VehFleetNumber && state.trailer2HasFreezerOnCounter)) && (
														<Fragment>
															<FormGroup>
																<label>Fridge Engine *</label>
																{
																	readOnly ? (
																		<p>{ StringMask.apply((data.trailer2FreezerHourStart / 60 / 60).toFixed(0), '###,###,###', { reverse: true }) } hours</p>
																	) : (
																		<LimitNumberInput 
																			id="trailer2FreezerHourStart"
																			elementId={data.trailer2VehFleetNumber}
																			initialValue={state.trailer2FreezerCurrentValueSec}
																			type="hours"
																			typeShort="hrs"
																			warningMaxDiff={20}
																			errorMaxDiff={1000}
																			className="required-hidden" 
																			value={data.trailer2FreezerHourStart}
																			onChange={this.handleChangeFreezerHours}
																		/>
																	)
																}
															</FormGroup>
															{/* <FormGroup>
																<Callout color="warning" title={`Take a photo of jaws engaged with the trailer ${ data.trailer2VehFleetNumber } pin`}>
																	<Button 
																		block 
																		bsStyle="default"
																		bsSize="small" 
																		onClick={() => this.trailer2JawsPhoto.click()}
																	>
																		<Icon name="camera" />&nbsp;
																		{ !data.trailer2JawsPhoto ? 'Take Photo' : 'Take another photo' }
																	</Button>
																</Callout>
																{
																	data.trailer2JawsPhoto && (
																		<Callout color="success">
																			<Icon name="check" /> Photo successfully added
																		</Callout>
																	)
																}
																<FormControl 
																	id="trailer2JawsPhoto"
																	inputRef={ref => this.trailer2JawsPhoto = ref}
																	type="file"
																	className="hidden"
																	accept="image/*;capture=camera"
																	onChange={this.handleChangeTrailerJawPhoto}
																/>
															</FormGroup> */}
														</Fragment>
													)
												}
											</Col>
										</Fragment>
									)
								}
								{
									// Dolly
									(isGuideMode || (readOnly && data.dollyVehFleetNumber) || (data.trailer1VehFleetNumber && state.trailer1VehTypeID == VehicleTypes.B_DOUBLE &&
										data.mainVehCanDollyAttach && data.trailer1CanDollyAttach ) ) && (
										<Fragment>
											<Col xs={12} sm={4} md={4} lg={3} className="pre-start__dolly" key={2}>
												<hr className="visible-xs" />
												<FormGroup>
													<label>Dolly</label>
													{
														readOnly ? (
															<p>{ data.dollyVehFleetNumber || 'None' }</p>
														) : (
															<VehicleSearch 
																onlyActive
																id="dollyVehFleetNumber" 
																value={data.dollyVehFleetNumber}
																firstItems={[{value: '', text: 'None'}]}
																fleetNumbersToExclude={[data.dollyVehFleetNumber]}
																vehTypeFilter={[7]} 
																onSelectVehicle={(vehicle) => { this.handleSelectDolly(vehicle); }}/>
														)
													}
												</FormGroup>
											</Col>
										</Fragment>
									)
								}
							</Fragment>
						)
					}
				</Row>
				<hr />
				<Row className="aligned-row">
					<Col xs={12} sm={12} md={12} lg={4}>
						<FormGroup>
							<label>Starting Depot *</label>
							{
								readOnly ? (
									<p>{ data.dtoDepotName }</p>
								) : (
									props.isPreStartV2 ? (
										this.renderPreLoadedData(data.dtoDepotName)
									) : (
										<PlacesList id="depotId" type="preStart" value={data.depotId} onChange={this.handleChangeDepot} />
									)
								)
							}
						</FormGroup>
					</Col>
					<Col xs={12} sm={6} md={6} lg={4}>
						<FormGroup>
							<label>Probe Number *</label>
							{
								readOnly ? (
									<p>{ data.probeNumber || 'None' }</p>
								) : (
									<FormControl
										required
										id="probeNumber"
										type="tel" 
										value={data.probeNumber || ''} 
										maxLength="4" 
										onChange={this.handleChangeInput} 
									/>
								)
							}
						</FormGroup>
					</Col>
				</Row>
				<Row className="aligned-row">
					{
						!readOnly && (
							<Col xs={12} sm={6} md={6} lg={4}>
								<FormGroup>
									<label>Are you using a Pallet Jack?</label>
									<YesNoRadio 
										id="isUsingPalletJack"
										value={state.isUsingPalletJack}
										onChange={this.handleChangeUsingPalletJack}
									/>
								</FormGroup>
							</Col>
						)
					}
					{
						(state.isUsingPalletJack || readOnly) && (
							<Col xs={12} sm={6} md={6} lg={4}>
								<FormGroup>
									<label>Pallet Jack *</label>
									{
										readOnly ? (
											<p>{ data.palletJackVehFleetNumber || 'None' }</p>
										) : (
											<VehicleSearch 
												onlyActive
												withoutSearch
												id="palletJackVehFleetNumber" 
												value={data.palletJackVehFleetNumber}
												vehTypeFilter={1}
												onSelectVehicle={(vehicle, event) => this.handleChangeInput(event) }/>
										)
									}
								</FormGroup>
							</Col>
						)
					}
				{
					((state.isUsingPalletJack && data.mainVehFleetNumber) || (readOnly && data.palletJackIsIn)) && (
						<Col xs={12} md={6} lg={4}>
							<FormGroup>
								<label>Which trailer is the Pallet Jack {data.palletJackVehFleetNumber} in?</label>
								{
									readOnly ? (
										<p>{data.palletJackIsIn}</p>
									)	 : (
										<RadioButtonList 
											id="palletJackIsIn" 
											value={data.palletJackIsIn}
											items={this.renderFleetsIn()} 
											onChange={this.handleChangeInput} 
										/>
									)
								}
							</FormGroup>
						</Col>
					)
				}
				</Row>
				<Row className="aligned-row">	
					<Col xs={12} md={6} lg={4}>
						<FormGroup>
							<label>Are you using a trolley? *</label>
							{
								readOnly ? (
									<p>{ data.trolleyIsIn ? 'Yes' : 'No' }</p>
								) : (
									<YesNoRadio 
										id="isUsingTrolley" 
										value={data.isUsingTrolley}
										onChange={this.handleChangeInput} 
									/>
								)
							}
						</FormGroup>
					</Col>
					{
						((data.isUsingTrolley && data.mainVehFleetNumber) || (readOnly && data.trolleyIsIn)) && (
							<Col xs={12} md={6} lg={4}>
								<FormGroup>
									<label>Which trailer is the trolley in? *</label>
									{
										readOnly ? (
											<p>{ data.trolleyIsIn }</p>
										) : (
											<RadioButtonList 
												id="trolleyIsIn" 
												value={data.trolleyIsIn}
												items={this.renderFleetsIn()} 
												onChange={this.handleChangeInput} 
											/>
										)
									}
								</FormGroup>
							</Col>		
						)
					}
				</Row>
				<Row className="aligned-row">
					{
						data.trailer1VehFleetNumber && (
							<Col xs={12} sm={6} lg={4}>
								{
									readOnly ? (
										<FormGroup>
											<label>Have you completed your safety tug test for the trailer { data.trailer1VehFleetNumber }? *</label>
											<p>{ data.trailer1TugTestDone == null ? '-' : data.trailer1TugTestDone ? 'Yes' : 'No' }</p>
										</FormGroup>
									) : (
										<Callout title="Attention" icon="warning" color="warning">
											<FormGroup>
												<label>Have you completed your safety tug test for the trailer { data.trailer1VehFleetNumber }? *</label>
												<YesNoRadio 
													id="trailer1TugTestDone" 
													value={data.trailer1TugTestDone}
													onChange={this.handleChangeInput} 
												/>
											</FormGroup>
										</Callout>
									)
								}
							</Col>
						)
					}
					{
						data.trailer2VehFleetNumber && (
							<Col xs={12} sm={6} lg={4}>
								{
									readOnly ? (
										<FormGroup>
											<label>Have you completed your safety tug test for the trailer { data.trailer2VehFleetNumber }? *</label>
											<p>{ data.trailer2TugTestDone == null ? '-' : data.trailer2TugTestDone ? 'Yes' : 'No' }</p>
										</FormGroup>

									) : (
										<Callout title="Attention" icon="warning" color="warning">
											<FormGroup>
												<label>Have you completed your safety tug test for the trailer { data.trailer2VehFleetNumber }? *</label>
												<YesNoRadio 
													id="trailer2TugTestDone" 
													value={data.trailer2TugTestDone}
													onChange={this.handleChangeInput} 
												/>
											</FormGroup>
										</Callout>
									)
								}
							</Col>
						)
					}
					{
						data.dollyVehFleetNumber && (
							<Col xs={12} sm={6} lg={4}>
								{
									readOnly ? (
										<FormGroup>
											<label>Have you checked the tow coupling and drawbars to ensure security and integrity? *</label>
											<p>{ data.isDollyDrawbarsOk == null ? '-' : data.isDollyDrawbarsOk ? 'Yes' : 'No' }</p>
										</FormGroup>

									) : (
										<Callout title="Attention" icon="warning" color="warning">
											<FormGroup>
												<label>Have you checked the tow coupling and drawbars to ensure security and integrity? *</label>
												<YesNoRadio 
													id="isDollyDrawbarsOk" 
													value={data.isDollyDrawbarsOk}
													onChange={this.handleChangeInput} 
												/>
											</FormGroup>
										</Callout>
									)
								}
							</Col>
						)
					}
					<Col xs={12} sm={12} lg={4}>
						<FormGroup>
							<label>Do you have in your possession the Fuel Card for the allocated vehicle? *</label>
							{
								readOnly ? (
									<p>{ data.hasFuelCard ? 'Yes' : 'No' }</p>
								) : (
									<YesNoRadio 
										id="hasFuelCard" 
										value={data.hasFuelCard}
										onChange={this.handleChangeInput} 
									/>
								)
							}
						</FormGroup>
					</Col>
					<Col xs={12} sm={12} lg={4}>
						<FormGroup>
							<label>Have you got a hand control on board? *</label>
							{
								readOnly ? (
									<p>{ data.hasHandControlOnBoard == null ? '-' : data.hasHandControlOnBoard ? 'Yes' : 'No' }</p>
								) : (
									<YesNoRadio 
										id="hasHandControlOnBoard" 
										value={data.hasHandControlOnBoard}
										onChange={this.handleChangeInput} 
									/>
								)
							}
						</FormGroup>
					</Col>
				</Row>
			</Form>
		);
	}
}

GeneralInformation.propTypes = propTypes;
GeneralInformation.defaultProps = defaultProps;

export default GeneralInformation;