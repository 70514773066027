import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import StatusTypes from '../../app/schemas/signalr/StatusTypes';
import { Loader } from './uiElements';

const propTypes = {
	children: PropTypes.element,
	onConnected: PropTypes.func,
	showConnectingLoader: PropTypes.bool
};

const defaultProps = {
	showConnectingLoader: true
};

class SignalRContainer extends Component {    
	constructor(props) {
		super(props);
		this.state = {
			isConnecting: false
		};
	}
    
	componentDidMount() {
		const { signalr, onConnected } = this.props;
		if (signalr.status === StatusTypes.CONNECTED)
			onConnected && onConnected();
	}

	componentDidUpdate(prevProps) { 
		if (prevProps.signalr.status !== StatusTypes.CONNECTED && this.props.signalr.status === StatusTypes.CONNECTED)
			this.props.onConnected && this.props.onConnected();
	} 
    
	render() {
		const { signalr, showConnectingLoader } = this.props;

		if (showConnectingLoader && signalr.status === StatusTypes.CONNECTING)
			return <Loader text="Connecting to the server..." />;

		return this.props.children || <div></div>;
	}
}

SignalRContainer.propTypes = propTypes;
SignalRContainer.defaultProps = defaultProps;

const mapStateToProps = state => ({
	signalr: state.signalr
});

export default connect(mapStateToProps)(SignalRContainer);