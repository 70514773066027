import React, { useMemo } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Icon from 'react-fontawesome';
import { FormGroup, Button, ProgressBar } from 'react-bootstrap';
import DurationParse from '../../../../app/helpers/DurationParse';
import { IPalletJackLiveMonitoring } from '../../../../app/models/Panel';

interface IProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
	data: IPalletJackLiveMonitoring;
	isSimplified?: boolean;
	onClick: (fleetNumber: string) => void;
}

const PalletJackBox: React.FC<IProps> = props => {
	const { data } = props;
	const chargingTimeInSec = useMemo(() => moment().diff(moment(data.startedChargingOnLocal), 'seconds'), [data.startedChargingOnLocal]);
	const totalTimeNotChargingInSec = useMemo(() => moment().diff(moment(data.lastChargeFinishTimeOnLocal), 'seconds'), [data.lastChargeFinishTimeOnLocal]);

	const currentBatteryPercentage = useMemo(() => {
		const maxVoltage = 25290; // 100%
		const minVoltage = 24140; // 0%
		const voltagePerPercent = 21.1;

		if (data.currentVoltage <= minVoltage) return 0;
		if (data.currentVoltage >= maxVoltage) return 100;

    return Math.round((data.currentVoltage - minVoltage) / voltagePerPercent);
	}, [data.currentVoltage]);

	const color = useMemo(() => {
		if (data.isCharging) return { icon: "plug", boxColor: "bg-blue", btnColor: "primary"  };
		if (currentBatteryPercentage < 10) return { icon: "battery-0", boxColor: "bg-red", btnColor: "danger" };
		if (currentBatteryPercentage < 25) return { icon: "battery-1", boxColor: "bg-red", btnColor: "danger" };
		if (currentBatteryPercentage < 50) return { icon: "battery-2", boxColor: "bg-yellow", btnColor: "warning"  };
		if (currentBatteryPercentage < 75) return { icon: "battery-3", boxColor: "bg-green", btnColor: "success"  };
		return { icon: "battery-4", boxColor: "bg-green", btnColor: "success" };
	}, [data.isCharging, currentBatteryPercentage]);

	const handleClick = () => {
		props.onClick(data.fleetNumber);
	};

	if (props.isSimplified)
		return (
			<FormGroup className="pallet-jack-box-simplified" onClick={handleClick}>
				<Button block bsStyle={color.btnColor}>
					{ data.fleetNumber } <br />
					<Icon name={color.icon} /><br />
					<i>{ data.isCharging ? '---' : `${ currentBatteryPercentage }%` }</i>
				</Button>
			</FormGroup>
		);

	return (
		<div className={classNames('pallet-jack-box', 'small-box', color.boxColor, props.className)} onClick={handleClick}>
			{ data.isCharging && <ProgressBar active now={100} className="charging-progress-bar" /> }
			<div className="box-content">
				<div className="pallet-jack-box-title">
					<span className="title">{ data.fleetNumber }</span>
					<div className="box-icon">
						<Icon name={color.icon} />
						{ !data.isCharging && <span className="battery-percentage">{ currentBatteryPercentage }%</span> }
					</div>
				</div>
				<span className="battery-info">
					{
						data.isCharging ? (
							`Charging for ${ DurationParse.toHourMin(chargingTimeInSec, 'short') }`
						) : (
							`Last Charged ${!data.lastChargeFinishTimeOnLocal ? '24+ hours ago' : DurationParse.toHourMin(totalTimeNotChargingInSec, 'short')} ago`
						)
					}
				</span>
			</div>
		</div>
	)
}

export default PalletJackBox;