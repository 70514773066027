import React, { ChangeEvent } from 'react';
import { PlacesList } from '../../../../../common/lists';
import { WeeksOfYear, Years } from '../../../../../common/inputs';
import { FieldGroup, ContentBox } from '../../../../../common/uiElements';
import { Row, FormControl } from 'react-bootstrap';
import { TasksListMainFilter as StaffRosterMainFilterClass } from '../../../../../../app/models/Task';
import { getEndDateFromYearWeekNumber, getStartDateFromYearWeekNumber } from '../../../../../../app/helpers/dateTimeHelper';

interface IProps extends StaffRosterMainFilterClass {
	hideDepot?: boolean;
	yearReadOnly?: boolean;
	weekReadOnly?: boolean;
	weekNumbersToDisable?: number[];
	yearToDisable?: number[];
	onChangeYear: (year: number) => void;
	onChangeWeek: (week: number) => void;
	onChangeDepot: (depotId?: string, depotName?: string) => void;
}

const RosterMainFilter: React.FC<IProps> = props => {
	function handleChangeYear(e: ChangeEvent<FormControl & HTMLInputElement>) {
		const year = parseInt(e.target.value);
		props.onChangeYear(year);
	}

	function handleChangeWeek(e: ChangeEvent<FormControl & HTMLInputElement>) {
		props.onChangeWeek(parseInt(e.target.value));
	}

	function handleChangeDepot(e: ChangeEvent<FormControl & HTMLInputElement>, depotName: string) {
		props.onChangeDepot(e.target.value, depotName);
	}

	return (
		<ContentBox>
			<Row>
				<FieldGroup label="Year" sm={props.hideDepot ? 6 : 4}>
					{
						props.yearReadOnly ? (
							<p>{ props.year }</p>
						) : (
							<Years
								value={props.year || ''}
								onChange={handleChangeYear}
							/>
						)
					}
				</FieldGroup>
				<FieldGroup label="Week" sm={props.hideDepot ? 6 : 4}>
					{
						props.weekReadOnly ? (
							<p>
								Week {props.weekNumber} -&nbsp;
								{ getStartDateFromYearWeekNumber(props.year!, props.weekNumber!).format('DD/MM') } to&nbsp;
								{ getEndDateFromYearWeekNumber(props.year!, props.weekNumber!).format('DD/MM') }
							</p>
						) : (
							<WeeksOfYear 
								year={props.year || ''}
								value={props.weekNumber || ''}
								weekNumbersToDisable={props.weekNumbersToDisable}
								onChange={handleChangeWeek}
							/>
						)
					}
				</FieldGroup>
				{
					!props.hideDepot && (
						<FieldGroup withoutFormGroup label="Starting Place" sm={4}>
							<PlacesList
								type="rosterStartingDepot"
								value={props.startingDepotId}
								onChange={handleChangeDepot}
							/>
						</FieldGroup>
					)
				}
			</Row>
		</ContentBox>
	);
}

export default RosterMainFilter;
