import React, { useEffect, useMemo, useState } from "react";
import { Callout, FieldGroup } from "../../../common/uiElements";
import { Badge, FormControl, ProgressBar, Row } from "react-bootstrap";
import { VehicleBreadcrumbsList } from "../../../common/tracking";
import { GoogleMapContainer, GoogleMarker } from "../../../common/googleMaps";
import { TaskForm } from "../../../../app/models/Task";
import moment from "moment";

interface IProps {
	task: TaskForm;
	showBreadcrumbs: boolean;
}

const TaskJourney: React.FC<IProps> = props => {
	const mapRef = React.useRef(null);

	const [selectedFleetNumber, setSelectedFleetNumber] = useState("");
	const [isLoadingBreadcrumbs, setIsLoadingBreadcrumbs] = useState(false);
	const [wasBreadcrumbsAlreadyLoaded, setWasBreadcrumbsAlreadyLoaded] = useState(false);
	
	const { task } = props;
	const { MainVehicle, Trailer1, Trailer2 } = task;
	
	// Only select vehicles that are not subcontractors
	useEffect(() => {
		if (MainVehicle && !MainVehicle.subContractorAbn)
			return setSelectedFleetNumber(MainVehicle.fleetNumber);

		if (Trailer1 && !Trailer1.subContractorAbn)
			return setSelectedFleetNumber(Trailer1.fleetNumber);

		if (Trailer2)
			return setSelectedFleetNumber(Trailer2.fleetNumber);
	}, [MainVehicle, Trailer1, Trailer2]);

	const handleChangeVehicleJourney = (e: React.ChangeEvent<HTMLInputElement & FormControl>) => {
		setSelectedFleetNumber(e.target.value);
	}

	const handleFetchBreadcrumbsComplete = () => {
		setIsLoadingBreadcrumbs(false);
		setWasBreadcrumbsAlreadyLoaded(true);
	}

	// Only add vehicles that are not subcontractors
	const fleetNumbers = useMemo(() => {
		const fleetNumbers: string[] = [];

		if (MainVehicle && !MainVehicle.subContractorAbn)
			fleetNumbers.push(MainVehicle.fleetNumber);

		if (Trailer1 && !Trailer1.subContractorAbn)
			fleetNumbers.push(Trailer1.fleetNumber);

		if (Trailer2)
			fleetNumbers.push(Trailer2.fleetNumber);

		return fleetNumbers;
	}, [MainVehicle, Trailer1, Trailer2]);

	const $startTime = useMemo(() => !task.startTimeLocal ? undefined : moment(task.startTimeLocal), [task.startTimeLocal]);
	const $finishTime = useMemo(() => (!task.startTimeLocal && !task.actualFinishTimeLocal) || !task.budgetedTime || task.budgetedTime === "0" ? undefined : moment(task.actualFinishTimeLocal || task.startTimeLocal).add(task.budgetedTime, "hours"), [task.startTimeLocal, task.budgetedTime, task.actualFinishTimeLocal]);

	if (!$startTime)
		return (
			<Callout icon="warning" color="warning" title="Task has no start time">
				The task has no start time and therefore no tracking data available.
			</Callout>
		)

	if (!$finishTime)
		return (
			<Callout icon="warning" color="warning" title="Task has no budgeted time">
				The task has no budgeted time and therefore no tracking data available.
			</Callout>
		)

	if (fleetNumbers.length === 0)
		return (
			<Callout icon="warning" color="warning" title="No vehicle assigned">
				There's no vehicle assigned to this task and therefore no tracking data available.
			</Callout>
		)

	return (
		<div>
			<Row>
				<FieldGroup label="Vehicle" sm={3}>
					<FormControl 
						componentClass="select" 
						value={selectedFleetNumber} 
						onChange={handleChangeVehicleJourney}
					>
						{ fleetNumbers.map(fleetNumber => <option key={fleetNumber} value={fleetNumber}>{ fleetNumber }</option>) }
					</FormControl>
				</FieldGroup>
				<FieldGroup label="Start Time" xs={6} sm={3} md={2}>
					<p>{ $startTime.format("DD/MM/YYYY HH:mm") }</p>
				</FieldGroup>
				<FieldGroup label="Finish Time" xs={6} sm={3} md={2}>
					<p>{ $finishTime.format("DD/MM/YYYY HH:mm") }</p>
				</FieldGroup>
			</Row>
			{ isLoadingBreadcrumbs && <ProgressBar active now={100} label="Loading tracking data..." /> }
			<hr />
			<ul className="list-inline text-right">
				<li><Badge className="muted">Not Visited yet</Badge></li>
				<li><Badge className="primary">Delivery in Progress</Badge></li>
				<li><Badge className="success">Delivery Completed</Badge></li>
			</ul>
			<GoogleMapContainer 
				refs={(ref: any) => mapRef.current = ref}
				defaultZoom={10}
				height="50vh"
			>
				{
					task.RunCustomerOrders.map(order => {
						if (!order.Place)
							return null;
						
						const { Place } = order;
						return (
							<GoogleMarker 
								key={Place.id}
								label={{
									text: `(${order.deliverySequence}) ${Place.name}${Place.storeNumber ? ` (${Place.storeNumber})` : ""}`,
									className: `google-marker-label ${order.storeDepartureTimeLocal ? "bg-green" : order.storeArrivalTimeLocal ? "bg-blue" : ""}`
								}}
								position={{
									lat: Place.lat,
									lng: Place.lng
								}}
								zIndex={999}
							/>
						)
					})
				}
				{
					(props.showBreadcrumbs || wasBreadcrumbsAlreadyLoaded) && (
						<VehicleBreadcrumbsList 
							map={mapRef.current}
							startTime={$startTime.format()}
							finishTime={$finishTime.format()}
							vehFleetNumber={selectedFleetNumber}
							onFetchBreadcrumbsStart={() => setIsLoadingBreadcrumbs(true)}
							onFetchBreadcrumbsComplete={handleFetchBreadcrumbsComplete}
						/>
					)
				}
			</GoogleMapContainer>
		</div>
	)
}

export default TaskJourney;