import moment from "moment";

export interface IVehicleMaintenanceListDetails {
	id: number;
	fleetNumber: string;
	vehicleTypeName: string;
	startTimeLocal: string;
	finishTimeLocal: string;
}

export interface IVehicleMaintenance extends IVehicleMaintenanceListDetails {
	id: number;
	fleetNumber: string;
	vehicleTypeName: string;
	startTimeLocal: string;
	finishTimeLocal: string;
}

export class VehicleMaintenanceForm {
	id?: number;
	fleetNumber?: string;
	vehicleTypeName?: string;
	startTimeLocal?: string;
	finishTimeLocal?: string;

	constructor(vehicleMaintenance?: IVehicleMaintenance) {
		this.id = vehicleMaintenance?.id;
		this.fleetNumber = vehicleMaintenance?.fleetNumber || '';
		this.vehicleTypeName = vehicleMaintenance?.vehicleTypeName || '';
		this.startTimeLocal = vehicleMaintenance?.startTimeLocal ? moment(vehicleMaintenance.startTimeLocal).format('YYYY-MM-DD HH:mm') : '';
		this.finishTimeLocal = vehicleMaintenance?.finishTimeLocal ? moment(vehicleMaintenance.finishTimeLocal).format('YYYY-MM-DD HH:mm') : '';
	}
}

export class VehicleMaintenancePOST {
	fleetNumber: string;
	startTimeLocal: string;
	finishTimeLocal: string;

	constructor(vehicleMaintenance: VehicleMaintenanceForm) {
		this.fleetNumber = vehicleMaintenance.fleetNumber!;
		this.startTimeLocal = moment(vehicleMaintenance.startTimeLocal).format('YYYY-MM-DD HH:mm');
		this.finishTimeLocal = moment(vehicleMaintenance.finishTimeLocal).format('YYYY-MM-DD HH:mm');
	}
}

export class VehicleMaintenancePUT extends VehicleMaintenancePOST {}

export class VehicleMaintenanceListFilter {
	startTimeLocal: string = moment().hour(0).minute(0).second(0).format('YYYY-MM-DD HH:mm');
	finishTimeLocal: string = moment().hour(23).minute(59).second(59).add(30, 'days').format('YYYY-MM-DD HH:mm');
	fleetNumber: string = '';
	vehicleTypeIds: number[] = [];
}