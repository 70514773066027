import axios from 'axios';
import { createPromise } from '../utils';
import { IRequestData } from '../../../models/Application';

export function put(endpoint: string, data: IRequestData) {
	return createPromise('PUT', endpoint, data, undefined);
}

export function putWithFiles(endpoint: string, data: IRequestData) {
	if (!data.attachedFiles)
		data.attachedFiles = [];

	return createPromise('PUT', endpoint, data);
}

export function putMany(promisesList: any[]) {
	return axios.all(promisesList);
}