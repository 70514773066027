import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';

const propTypes = {
	hideRightNowItem: PropTypes.bool,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			color: PropTypes.string,
			icon: PropTypes.string,
			date: PropTypes.string,
			header: PropTypes.any,
			body: PropTypes.any
		}).isRequired
	).isRequired
};

const TimelineContainer = props => (
	<ul className="timeline">
		{
			!props.hideRightNowItem && (
				<li className="time-label">
					<span className="bg-light-blue">
						Right Now
					</span>
				</li>
			)
		}
		{
			props.items.map((item, i) => (
				<TimelineItem key={i} {...item} />
			))
		}

	</ul>
);

TimelineContainer.propTypes = propTypes;

export { TimelineContainer };

const TimelineItem = ({ color, icon, date, header, body }) => (
	<li>
		<Icon name={icon} className={`bg-${color}`} />
		<div className="timeline-item">
			{
				date && <span className="time">{ date }</span>
			}
			{
				header && <h3 className="timeline-header">{ header }</h3>
			}
			{
				body && <div className="timeline-body">{ body }</div>
			}
		</div>
	</li>
);

TimelineItem.propTypes = {
	color: PropTypes.string,
	icon: PropTypes.string,
	date: PropTypes.string,
	header: PropTypes.any,
	body: PropTypes.any
};

TimelineItem.defaultProps = {
	color: 'grey',
	icon: 'clock-o'
};