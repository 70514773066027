import React from "react";

interface EmbedPowerBIProps {
	reportId: string;
}

const EmbedPowerBI: React.FC<EmbedPowerBIProps> = (props) => (
	<iframe 
		id="power-bi-iframe" 
		title="Delivery Summaries to Publish" 
		style={{ height: '92vh' }} 
		width="100%" 
		frameBorder="0"
		src={`https://app.powerbi.com/reportEmbed?reportId=${props.reportId}&amp;autoAuth=true&amp;ctid=9cb36146-2a3f-4c85-8fcf-7715438f7f7e`}
	/>
);

export default EmbedPowerBI;