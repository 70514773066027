import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, InputGroup, FormControl } from 'react-bootstrap';

import { RadioButtonList, UserSearch } from '../../../common/inputs';

const propTypes = {
    startTime: PropTypes.string,
    finishTime: PropTypes.string,
    isChecked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    isHidden: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    hasEmployeeAddedFinishTime: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    hasHRAddedDataManually: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onChange: PropTypes.func,
    onPressEnter: PropTypes.func
};

const WorkerTimeSheetFilters = props => {
    const handleChange = e => {
        const { id, type, checked, value } = e.target;
		var val = type === 'checkbox' ? checked : value;
        props.onChange && props.onChange({ [id]: val });
    }

    const handleChangeWorker = user => {
        props.onChange && props.onChange({ "workerId": user.value });
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            props.onPressEnter && props.onPressEnter();
        }
    }

    const statusOptions = [
        { text: "All", value: "" },
				{ text: "Yes", value: true },
        { text: "No", value: false }
    ]

    return (
        <div>
            <Row>
                <Col sm={12} md={6} lg={5}>
                    <FormGroup>
                        <label>Period</label>
                        <InputGroup>
                            <InputGroup.Addon>From</InputGroup.Addon>
                            <FormControl 
                                id="startTime" 
                                type="date" 
                                value={props.startTime} 
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                            />
                            <InputGroup.Addon>To</InputGroup.Addon>
                            <FormControl 
                                id="finishTime" 
                                type="date" 
                                value={props.finishTime} 
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs={8} sm={9} md={4} lg={5}>
                    <FormGroup>
                        <label>Employee</label>
												<UserSearch 
													id="workerId"
													value={props.workerId || ''}
													onlyActive={false}
													onChange={handleChangeWorker} 
                        />
                    </FormGroup>
                </Col>
                <Col xs={4} sm={3} md={2}>
                    <FormGroup>
                        <label>Min. Hrs</label>
                        <FormControl 
                            id="minimumActualWorkedHours" 
                            type="number" 
                            value={props.minimumActualWorkedHours} 
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={6} md={3}>
                    <FormGroup>
                        <label>Is Checked</label>
                        <RadioButtonList id="isChecked" items={statusOptions} onChange={handleChange} value={props.isChecked} />
                    </FormGroup>
                </Col>
                <Col sm={6} md={3}>
                    <FormGroup>
                        <label>Is Hidden</label>
                        <RadioButtonList id="isHidden" items={statusOptions} onChange={handleChange} value={props.isHidden} />
                    </FormGroup>
                </Col>
                <Col sm={6} md={3}>
                    <FormGroup>
                        <label>Data added manually</label>
                        <RadioButtonList id="hasHRAddedDataManually" items={statusOptions} onChange={handleChange} value={props.hasHRAddedDataManually} />
                    </FormGroup>
                </Col>
                <Col sm={6} md={3}>
                    <FormGroup>
                        <label>Finish Time added manually</label>
                        <RadioButtonList id="hasEmployeeAddedFinishTime" items={statusOptions} onChange={handleChange} value={props.hasEmployeeAddedFinishTime} />  
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}

WorkerTimeSheetFilters.propTypes = propTypes;

export default WorkerTimeSheetFilters;