/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import VehiclesApi from '../../../../app/api/VehiclesApi';
import { Row, Col } from 'react-bootstrap';
import { UserProfilePicture, ErrorBox, Loader, UserDetailsLink, FieldGroup } from '../../../common/uiElements';
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';
import TaskModal from '../../roster/tasks/TaskModal';
import PreStartDetailsModal from '../../compliance/preStartCheck/subComponents/PreStartDetailsModal';
import FontAwesome from 'react-fontawesome';

const propTypes = {
	vehFleetNumber: PropTypes.string
};

class VehicleDriverDetails extends Component {
	constructor() {
		super();
		this.state = {
			currentDriverDetails: null,
			showTaskModal: false,
			showPreStartModal: false,
			isLoading: false,
			error: null
		};
	}

	componentDidMount() {
		if (this.props.vehFleetNumber)
			this.fetchCurrentVehicleDriver();
	}

	fetchCurrentVehicleDriver = () => {
		const promise = () => VehiclesApi.getCurrentDriver(this.props.vehFleetNumber);
		componentRequestHandler(this, promise, 'currentDriverDetails');
	}

	toggleShowTaskModal = () => {
		this.setState({ showTaskModal: !this.state.showTaskModal });
	}

	toggleShowPreStartModal = () => {
		this.setState({ showPreStartModal: !this.state.showPreStartModal });
	}

	renderContent = () => {
		const { state } = this;
		
		if (state.isLoading)
			return <Loader text="Loading current driver..." />;
		
		if (state.error)
			return <ErrorBox error={state.error} retryFunc={this.fetchCurrentVehicleDriver} />;

		if (!state.currentDriverDetails)
			return <i><b><u>No driver is assigned to this vehicle</u></b></i>;
				
		return (
			<Fragment>
				<Row>
					<Col xs={4}>
						<UserProfilePicture 
							className="driver-img"
							azureId={state.currentDriverDetails.azureId}
						/>
					</Col>
					<Col xs={8}>
						<b>DRIVER DETAILS</b><br/>
						<UserDetailsLink azureId={state.currentDriverDetails.azureId}>
							{ state.currentDriverDetails.shortName }
						</UserDetailsLink>
						<br />
						{ 
							state.currentDriverDetails.phoneNumber ? <a href={`tel:${state.currentDriverDetails.phoneNumber}`}>{ state.currentDriverDetails.phoneNumber }</a> : '' 
						}
					</Col>
				</Row>
				<br />
				<Row>
					<FieldGroup label="Task / Run Number" xs={6}>
						<p>
							<a onClick={this.toggleShowTaskModal} title="See Task / Run Details">
								<FontAwesome name="search" /> <u>{ state.currentDriverDetails.runNumber || '-' }</u>
							</a>
						</p>
					</FieldGroup>
					<FieldGroup label="Pre-Start Done At" xs={6}>
						<p>
							<a onClick={this.toggleShowPreStartModal} title="See Pre-Start Check Details">
							<FontAwesome name="search" /> <u>{ moment(state.currentDriverDetails.inspectedOnLocal).format('DD/MM/YY HH:mm' )}</u>
							</a>
						</p>
					</FieldGroup>
				</Row>
				{
					state.showTaskModal && (
						<TaskModal 
							show
							isReadOnly
							taskId={state.currentDriverDetails.taskId}
							isChangeDateEnabled={false}
							isChangePlaceEnabled={false}
							onClose={this.toggleShowTaskModal}
						/> 
					)
				}
				{
					state.showPreStartModal && (
						<PreStartDetailsModal 
							show
							preStartId={state.currentDriverDetails.preStartId} 
							onClose={this.toggleShowPreStartModal}
						/>
					)
				}
			</Fragment>
		);
	}

	render() {
		return (
			<div className="vehicle-driver-details">
				{ this.renderContent() }
			</div>
		);
	}
}

VehicleDriverDetails.propTypes = propTypes;

export default VehicleDriverDetails;