import React, { useEffect, useState, ChangeEvent } from 'react';
import { getTotalWeeksInYear } from '../../../app/helpers/dateTimeHelper';
import { Select } from './Select';
import moment from 'moment';
import { Callout } from '../uiElements';
import { FormControl } from 'react-bootstrap';

interface IProps {
	id?: string;
	value?: string | number;
	year?: string | number;
	weekNumbersToDisable?: number[];
	onChange?: (e: ChangeEvent<FormControl & HTMLInputElement>) => void;
}

const WeeksOfYear: React.FC<IProps> = props => {
	const [weeksOfYearOptions, setWeeksOfYearOptions] = useState([]);
	
	useEffect(() => {
		if (!props.year) return;

		const year = typeof props.year === 'string' ? parseInt(props.year) : props.year
		setWeeksOfYearOptions(getWeeksSelectOptions(year, props.weekNumbersToDisable));
	}, [props.year, props.weekNumbersToDisable]);
	
	if (!props.year)
		return <Callout text="The YEAR is required" />

	return (
		<Select
			{...props}
			data={weeksOfYearOptions}
			onChange={props.onChange}
		/>
	);
}

export { WeeksOfYear };

function getWeeksSelectOptions(year: number, weekNumbersToDisable?: number[]) {
	const options: any = [];
	getTotalWeeksInYear(year).forEach(data => {
		const { week, startDate, endDate } = data;
		const currentDate = moment();
		const isCurrent =  currentDate.year() === year && currentDate.isoWeek() === week;
		const disabled = weekNumbersToDisable && weekNumbersToDisable.indexOf(week) !== -1;
		options.push({
			id: week,
			name: `Week ${week} - ${startDate.format('DD/MMM')} to ${endDate.format('DD/MMM')}${isCurrent ? ' (Current)' : ''}`,
			className: isCurrent ? 'text-bold' : '',
			disabled
		});
	});
	return options;
}