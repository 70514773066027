import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';

import { Checkbox, SelectInputWithData } from './';

const propTypes = {
	id: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	schema: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string, 
			defaultValue: PropTypes.string,
			description: PropTypes.string, 
			type: PropTypes.oneOf(['text', 'select', 'checkbox', 'tel']),
			selectType: PropTypes.oneOf(['driverLicenceTypes', 'nationalities', 'relationships', 'states']),
			isRequired: PropTypes.bool,
			cssClass: PropTypes.string, 
			cssCol: PropTypes.string
		})
	).isRequired,
	startWithInput: PropTypes.bool
};

const defaultProps = { 
	id: '', 
	defaultValue: '',
	description: '', 
	type: 'text',
	selectType: '',
	isRequired: false,
	cssClass:'', 
	cssCol: ''
};

class MultipleInputs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: this.getDefaultValues(),
			data: []
		};
	}

	getDefaultValues = () => {
		var { schema } = this.props;
		var fields = {};

		for (var i in schema) {
			var defaultValue = '';
			switch(schema[i].type) {
			case 'checkbox':
				defaultValue = schema[i].defaultValue || false;
				break;
			default:
				defaultValue = schema[i].defaultValue || '';
			}

			fields[schema[i].id] = defaultValue;
		}

		return fields;
	}

	UNSAFE_componentWillMount() {
		var data = [];

		if (this.props.startWithInput)		
			data.push(this.getDefaultValues());

		var defaultData = this.props.data;
		if (defaultData && defaultData.length > 0) {
			var { schema } = this.props;
			var newData = [];
			for (var i in defaultData) {
				var defaultDataValues = {};
				for (var j in schema) {
					var id = schema[j].id;
					var value = defaultData[i][id]; 
					defaultDataValues[id] = value;
				}

				var newDataObj = { ...defaultDataValues };
				
				if (defaultData[i].id)
					newDataObj = { ...defaultData[i] };

				newData.push(newDataObj);
			}
			return this.setState({ data: newData });
		} else {
			return this.setState({ data });
		}
	}

	componentDidMount() {
		return this.onChangeData();
	}

	handleAdd = () => {
		var { data } = this.state;
		data.push({ ...this.getDefaultValues() });
		
		this.setState({ data }, this.onChangeData);

		return setTimeout(() => {
			window.$(this.multipleInputs).find('.box').last().find('input, select, textarea').first().focus();
		}, 50);
	}

	handleChangeInput = (e, index) => {
		var stateCopy = Object.assign({}, this.state);

		window.$(e.target).removeClass('error');

		var id = e.target.id.split('_')[0];
		var value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		stateCopy.data[index][id] = value;

		return this.setState(stateCopy, this.onChangeData);
	}

	handleRemove = (index) => {
		var data = this.state.data;
		if (data[index].id)
			data[index].isDeleted = true;
		else	
			data.splice(index, 1);

		return this.setState({ data }, this.onChangeData);
	}

	onChangeData = () => {
		var data = this.state.data;
		var id = this.props.id;

		return this.props.onChange({ [id]: data });
	}

	renderInput = (input, index) => {
		var { id, type, maxLength, selectType, cssClass='' } = input;
		var value = this.state.data[index][input.id];
		switch(input.type) {
		case 'checkbox':
			return (
				<Checkbox id={(id + '_' + index)} text={value ? 'Yes' : 'No'} checked={value} onChange={(event) => { this.handleChangeInput(event, index); }} />
			);
		case 'select':
			return (
				<SelectInputWithData id={(id + '_' + index)} cssClass={cssClass} dataType={selectType} onChange={(event) => { this.handleChangeInput(event, index); }} value={value} />
			);
		default:
			return (
				<input 
					id={(id + '_' + index)} {...maxLength ? {maxLength} : {}} 
					type={type} 
					value={value || ''} 
					className={('form-control ' + cssClass)} 
					onChange={(event) => { this.handleChangeInput(event, index); }} />
			);
		}
	}

	render() {
		var s = this.state;
		var { schema, startWithInput } = this.props;
		
		return (
			<div id="multipleInputs" ref={ref => this.multipleInputs = ref}>
				{
					s.data.map((data, index) => {
						return data.isDeleted ? '' : (
							<Row key={index}>
								<Col xs={12}>
									<div className="box box-primary box-bordered">
										{
											(!startWithInput || (startWithInput && index > 0)) && (
												<Button 
													className="btn-rounded"
													style={{ 
														fontSize: '17px',
														position: 'absolute',
														left: '-13px',
														top: '-15px'
													}}
													bsStyle="danger"
													onClick={() => { this.handleRemove(index); }}
												>
													<Icon name="trash" />
												</Button>
											)
										}
										<div className="box-body">
											{
												schema.map((input, indexData) => {
													return (
														<div className={input.cssCol} key={indexData}>
															<FormGroup>
																<label>{input.description}</label>
																{ this.renderInput(input, index) }
															</FormGroup>
														</div>
													);
												})
											}
										</div>
									</div>
									{
										(!startWithInput || (startWithInput && index > 0)) && (<hr />)
									}
								</Col>
							</Row>
						);
					})	
				}
				<Row>
					<Col xs={12}>
						<FormGroup>
							<Button bsStyle="success" onClick={this.handleAdd}>
								<Icon name="plus" /> Add New
							</Button>
						</FormGroup>
					</Col>
				</Row>				
			</div>
		);
	}
}

MultipleInputs.propTypes = propTypes;
MultipleInputs.defaultProps = defaultProps;

export { MultipleInputs };