import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Icon from 'react-fontawesome';
import Joyride from 'react-joyride';
import 'react-joyride/lib/react-joyride-compiled.css';
import { PreStart } from '../../../../../app/schemas/signalr/Hubs';

import PreStartApi from '../../../../../app/api/PreStartApi';
import SignalRContainer from '../../../../common/SignalRContainer';
import PreStartApprovalStatus from './PreStartApprovalStatus';

import { UserSearch } from '../../../../common/inputs';
import { Form, ContentBox, Loader, ProgressBar, ErrorBox } from '../../../../common/uiElements';

const propTypes = {
	preStartId: PropTypes.string,
	isGuideMode: PropTypes.bool,
	isFirstTime: PropTypes.bool,
	addGuideSteps: PropTypes.func
};

const firstGuideSteps = [
	{
		title: 'Waiting for approval',
		text: 'You must wait for the supervisor authorisation. <b>Do not leave.<b/>',
		selector: '.pre-start__waiting-status',
		position: 'top',
		goToSomeoneIsChecking: true
	}
];

const someoneIsCheckingGuideStep = [{
	title: 'Pre-start being authorised',
	text: 'Your supervisor is reviewing the information you provided',
	selector: '.pre-start__waiting-status',
	position: 'top',
	goToApprovalFeedback: true
}];

const approvalFeedbackGuideStep = [
	{
		title: 'Supervisor Authorisation',
		text: 'This is where the supervisor will approve or deny your departure',
		selector: '.pre-start__supervisor-authorisation',
		position: 'top',
	},
	{
		title: 'Approval',
		text: 'If your supervisor authorises your departure, this message on green will appear',
		selector: '.pre-start__approval-message',
		position: 'top',
		goToDenyFeedback: true
	}
];

const denyFeedbackGuideStep = [
	{
		title: 'Denied',
		text: 'If your supervisor denies your departure, this message in red will appear and you will not be authorised to depart',
		selector: '.pre-start__deny-message',
		position: 'top',
	}, 
	{
		title: 'Completed',
		text: 'You have finished the tour guide and now you are allowed to do your Pre-Start',
		selector: '.pre-start__supervisor-authorisation',
		position: 'top',
		finishGuideAndResetData: true
	}
];

class PreStartWaitingApproval extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isWaitingAnswer: true,
			whoIsCheckingOut: null,
			supervisorAnswer: null,
			supervisorIdToNotify: '',
			isNotifyingSupervisor: false,
			isSupervisorNotified: false,
			errorSupervisorNotification: false
		};

		this.guideSteps = firstGuideSteps;
	}

	_handleNotifySupervisorViaSMS = () => {
		if (window.$(this.notifySupervisorForm).find('form').valid()) {
			this.setState({ isNotifyingSupervisor: true, errorSupervisorNotification: false });
			PreStartApi.notifySupervisor(this.props.preStartId, this.state.supervisorIdToNotify)
				.then(() => {
					if (this.unmounted) return;

					this.setState({ isSupervisorNotified: true });
				})
				.catch(error => {
					this.setState({ errorSupervisorNotification: error });
				});
		}
	}

	/**
	 * ============================
	 * SignalR functions below
	 * ============================
	 */

	/**
	 * When the supervisor sends the answer
	 */
	driverRefreshPreStartAnswer = (preStartId, supervisorName, supervisorPhoneNumbers, checkOutIsApproved, checkOutComments) => {
		if (preStartId === this.props.preStartId)
			this.setState({ 
				supervisorAnswer: {
					supervisorName,
					supervisorPhoneNumbers,
					checkOutIsApproved,
					checkOutComments
				}
			}, () => { setTimeout(() => this.setState({ isWaitingAnswer: false }), 1000); });
	}

	/**
	 * When supervisor opens the CheckOut Box
	 */
	driverRefreshCheckingStatus = (preStartId, isChecking, supervisorName) => {
		if (preStartId === this.props.preStartId)
			this.setState({ 
				whoIsCheckingOut: isChecking ? supervisorName : null
			});
	}

	/**
	 * ============================
	 * End SignalR functions
	 * ============================
	 */

	componentWillUnmount() {
		this.unmounted = true;
		this.props.preStartHub.off(PreStart.client.DRIVER_REFRESH_ANSWER);
		this.props.preStartHub.off(PreStart.client.DRIVER_REFRESH_CHECKING_STATUS);
	}

	UNSAFE_componentWillMount() {
		this.props.preStartHub.on(PreStart.client.DRIVER_REFRESH_ANSWER, this.driverRefreshPreStartAnswer);
		this.props.preStartHub.on(PreStart.client.DRIVER_REFRESH_CHECKING_STATUS, this.driverRefreshCheckingStatus);
	}

	componentDidMount() {
		this.props.addGuideSteps && this.props.addGuideSteps(this.guideSteps);   
	}

	guideCallBack = (data) => {
		if(data.action === 'close')
			window.location.reload();
		
		if (data.action !== 'back' && data.type === 'step:after' && data.step) {
			if (data.step.goToSomeoneIsChecking) {
				this.guideSteps = someoneIsCheckingGuideStep;
				this.setState({ whoIsCheckingOut: 'Wayne Franklyn' }, () => this.joyride.reset(true));
			}
			else if (data.step.goToApprovalFeedback) {
				this.guideSteps = approvalFeedbackGuideStep;
				this.setState({ 
					isWaitingAnswer: false,
					supervisorAnswer: {
						supervisorName: 'Wayne Franklyn',
						supervisorPhoneNumbers: ['99 9999 9999'], 
						checkOutComments: 'You can go.',
						checkOutIsApproved: true
					} 
				}, () => this.joyride.reset(true));
			}
			else if (data.step.goToDenyFeedback) {
				this.guideSteps = denyFeedbackGuideStep;
				this.setState({ isWaitingAnswer: true }, () => {
					this.setState({ 
						isWaitingAnswer: false,
						supervisorAnswer: {
							supervisorName: 'Wayne Franklyn',
							supervisorPhoneNumbers: ['99 9999 9999'], 
							checkOutComments: 'You are not authorised to depart.',
							checkOutIsApproved: false
						} 
					}, () => this.joyride.reset(true));
				});
			}
			else if (data.step.finishGuideAndResetData) {
				sessionStorage.setItem('hidePreStartGuideTour', true);
				window.location.reload();
			}
					
		}
	}

	render() {
		const s = this.state;
		const p = this.props;

		let progress = 33;
		let color = 'danger';
		let statusText = 'All supervisors were contacted';
		if (s.supervisorAnswer) {
			progress = 100;
			color = 'success';
			statusText = `${s.supervisorAnswer.supervisorName} answered your Pre-Start Check`;
		} else if (s.whoIsCheckingOut) {
			progress = 66;
			color = 'warning';
			statusText = `${s.whoIsCheckingOut} is checking out your Pre-Start right now`;
		}
		
		return (
			<div>
				{
					p.isGuideMode && (
						<Joyride
							ref={ref => this.joyride = ref}
							disableOverlay={p.isFirstTime}
							steps={this.guideSteps}
							autoStart={true}
							type="continuous"
							run={true}
							locale={{ back: 'Back', close: 'Close', last: 'Next', next: 'Next', skip: 'Skip' }}
							callback={this.guideCallBack}
						/>
					)
				}
				<SignalRContainer>
					{
						s.isWaitingAnswer ? (
							<ContentBox color="primary">
								<div className="pre-start__waiting-approval">
									<Loader />
									<h4 className="text-center">
										Please wait until your Pre-Start is checked...
									</h4>
									<hr />
									<div className="pre-start__waiting-status">
										<div className="text-center">
											<label>{statusText}</label>
										</div>
										<ProgressBar 
											currentValue={progress}
											color={color}
										/>
									</div>
								</div>
								{
									!s.supervisorAnswer && !s.whoIsCheckingOut && (
										<div ref={ref => this.notifySupervisorForm = ref}>
											<hr />
											<Form validations={{ supervisorIdToNotify: 'required' }}>
												{
													s.errorSupervisorNotification ? (
														<ErrorBox error={s.errorSupervisorNotification} retryFunc={this._handleNotifySupervisorViaSMS} />
													) : (
														s.isSupervisorNotified ? (
															<div className="text-center text-success">
																<h4><Icon name="check-circle" size="2x" /></h4>
																<h5>The SMS has been sent to your supervisor</h5>
															</div>
														) : (
															s.isNotifyingSupervisor ? (
																<Loader text="Sending the SMS. Please, wait..." />
															) : (
																<div>
																	<label>Notify your supervisor via SMS</label>
																	<Row>
																		<Col xs={12} sm={5} md={4} lg={3}>
																			<FormGroup>
																				<UserSearch 
																					id="supervisorIdToNotify"
																					type="preStartSupervisors"
																					value={s.supervisorIdToNotify}
																					onChange={user => this.setState({ supervisorIdToNotify: user.value })} />
																			</FormGroup>
																		</Col>
																		<Col xs={12} sm={3} md={2}>
																			<FormGroup>
																				<Button block bsStyle="primary" onClick={this._handleNotifySupervisorViaSMS}>
																					Send <Icon fixedWidth name="send" />
																				</Button>
																			</FormGroup>
																		</Col>
																	</Row>
																	<i className="text-muted">
																		Only notify your supervisor via SMS if you are aware that no one is in front of
																		the computer
																	</i>
																</div>
															)
														)
													)
												}
											</Form>
										</div>
									)
								}
							</ContentBox>
						) : (
							<div style={{paddingTop: '10px'}}>
								<div className="pre-start__supervisor-authorisation">
									<PreStartApprovalStatus showSignOutTimer={!p.isGuideMode} supervisorAnswer={s.supervisorAnswer} />
								</div>
							</div>
						)
					}
				</SignalRContainer>
			</div>
		);
	}
}

PreStartWaitingApproval.propTypes = propTypes;

const mapStateToProps = state => ({
	preStartHub: state.signalr.hubs.preStartHub
});

export default connect(mapStateToProps)(PreStartWaitingApproval);