import React, { ChangeEvent } from 'react';
import ListsApi from '../../../app/api/ListsApi';
import ListContainer from './_ListContainer';
import { ITaskType } from '../../../app/models/Task';
import { FormControl } from 'react-bootstrap';

interface IProps {
	id?: string;
	value?: string;
	isRunRequired?: boolean;
	isDriversLicenceRequired?: boolean;
	onChange?: (e: ChangeEvent<FormControl & HTMLInputElement>, text?: string, TaskType?: ITaskType) => void;
}

export const TaskTypesList: React.FC<IProps> = props => (
	<ListContainer 
		{...props as any}
		promise={() => ListsApi.getTaskTypesList(true, props.isDriversLicenceRequired, props.isRunRequired)}
	/>
);