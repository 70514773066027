/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Link, IndexLink } from 'react-router';
import { connect } from 'react-redux';

import { UserProfilePicture } from '../uiElements';

const propTypes = {
	loggedUser: PropTypes.object
};

class LeftMenu extends Component {
	componentDidMount() {
		window.$('.sidebar').slimScroll();
		window.$(document).on('click','.sidebar-open .main-sidebar a[href^="/"]', () => {
			window.$('body').removeClass('sidebar-open');
		});
		window.$(document).on('click','.sidebar-collapse .main-sidebar', () => {
			window.$('body').removeClass('sidebar-collapse');
		});
	}

	getMenuItems() {
		const { menuItems } = require('../../../routes');
		return menuItems;
	}

	renderMenuItem = (item, index) => {
		let { title, urlPath, icon, nested, external, header } = item;
	
		if (!urlPath && !header)
			return;

		if (header)
			return <li key={index} className="header">{ title }</li>;
	
		return (
			!nested || nested.length === 0 ? (
				<li key={index}>
					{
						urlPath === '/' ? (
							<IndexLink to={urlPath}>
								<Icon fixedWidth name={icon} tag="i" />&nbsp;&nbsp;
								<span>{title}</span>
							</IndexLink>
						) : external ? (
							<a href={urlPath} target="_blank" rel="noopener noreferrer">
								<Icon fixedWidth name={icon} tag="i" />&nbsp;&nbsp;
								<span>{title}</span>
								<Icon fixedWidth style={{ marginTop: 3 }} name="external-link" className="pull-right" />
							</a>
						) : (
							<Link className="no-nested-item" to={urlPath}>
								<Icon fixedWidth name={icon} tag="i" />&nbsp;&nbsp;
								<span>{title}</span>
							</Link>
						)
					}
				</li>
			) : (
				<li key={index} className="treeview">
					<a href="#">
						<Icon fixedWidth name={icon} tag="i" />&nbsp;&nbsp;
						<span>{title}</span>
						<span className="pull-right-container">
							<Icon name="angle-right" className="pull-right" tag="i" />
						</span>
					</a>
					<ul className="treeview-menu">
						{
							nested.map((nestedItem, index) => {
								nestedItem.urlPath = item.urlPath + nestedItem.urlPath;
	
								return this.renderMenuItem(nestedItem, index);
							})
						}
					</ul>
				</li>
			)  
		);
	}

	render() {
		const { loggedUser } = this.props;

		return (
			<div>
				<aside className="main-sidebar">
					<section className="sidebar">
						<div className="user-panel">
							<UserProfilePicture divClassName="pull-left image" azureId={loggedUser.azureId} />
							<div className="pull-left info">
								<p>{ loggedUser.shortName }</p>
								{ loggedUser.currentRoleName }
							</div>
						</div>
						<ul className="sidebar-menu" data-widget="tree">
							{ this.getMenuItems().map((item, index) => this.renderMenuItem(item, index)) }
						</ul>
					</section>
				</aside>
			</div>
		);
	}
}

LeftMenu.propTypes = propTypes;

const mapStateToProps = ({ user }) => ({ loggedUser: user.data });

const LeftMenuComponent = connect(mapStateToProps)(LeftMenu);
export { LeftMenuComponent as LeftMenu } ;