import React, { Fragment } from 'react';
import { IWorkerBasicInfo } from '../../../app/models/Worker';
import { ITaskType } from '../../../app/models/Task';
import { canDriveVehicleCombination } from '../../../app/helpers/vehicles';
import { IVehicleBasicInfo } from '../../../app/models/Vehicle';
import { Callout } from './Callout';

interface IProps {
	worker?: IWorkerBasicInfo;
	taskType?: ITaskType;
	mainVehicle?: IVehicleBasicInfo;
	trailer1?: IVehicleBasicInfo;
	trailer2?: IVehicleBasicInfo;
}

const DriversLicenceCheckCallout: React.FC<IProps> = ({
	worker,
	taskType,
	mainVehicle,
	trailer1,
	trailer2
}) => {
	if (!worker || !mainVehicle || !taskType || !taskType.isDriversLicenceRequired)
		return null;

	if (canDriveVehicleCombination(worker, mainVehicle, trailer1, trailer2))
		return null;

	return (
		<Callout icon="warning" title={ worker.currentLicenceType ? 'Licence Not Valid' : 'No Licence Registered' }>
			{
				!worker.currentLicenceType ? (
					<Fragment>
						<strong>{ worker.firstName } </strong>
						does not have a valid driver's licence registered in the TMS. It was not possible to verify if the licence can be used to drive the current fleet combination.
					</Fragment>
				) : (
					<Fragment>
						<strong>{ worker.firstName }'s </strong>
						registered driver's licence in the TMS is
						<strong> { worker.currentLicenceType } </strong>
						and it can't be used to drive the current fleet combination. Please check with the driver if they have upgraded their licence in the system.
					</Fragment>
				)
			}
		</Callout>
	)
}

export { DriversLicenceCheckCallout }