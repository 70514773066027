import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Table } from 'react-bootstrap';
import DurationParse from '../../../../../app/helpers/DurationParse';
import helper from '../helper';

const propTypes = {
	data: PropTypes.object.isRequired,
	securityIssues: PropTypes.array,
	mechanicalIssues: PropTypes.array
};

const defaultProps = {
	securityIssues: [],
	mechanicalIssues: []
};

const CheckoutSummary = ({data, securityIssues, mechanicalIssues}) => {
	const isLastPreStartReport = data.lastReportedOnLocal ? true : false;
	const FaultReports = data.FaultReports.filter(f => f.isLastPreStartReport === isLastPreStartReport);
	const VehicleDamages = data.VehicleDamages.filter(f => f.isLastPreStartReport === isLastPreStartReport);
	const isThereTimeSpentProblem = (!isLastPreStartReport && helper.isThereProblemFirstPartTimeSpent(data.firstPartTimeSpentInSec)) || (isLastPreStartReport && helper.isThereProblemLastPartTimeSpent(data.lastPartTimeSpentInSec));
	
	return (
		<div>
			<Row>
				<Col xs={12}>
					<Table bordered condensed>
						<tbody>
							<tr>
								<th>Depot</th>
								<td>{data.dtoDepotName}</td>
							</tr>
							<tr>
								<th>Vehicle(s)</th>
								<td>
									{data.mainVehFleetNumber}
									{data.trailer1VehFleetNumber && `, ${data.trailer1VehFleetNumber}`}
									{data.dollyVehFleetNumber && `, ${data.dollyVehFleetNumber}` }
									{data.trailer2VehFleetNumber && `, ${data.trailer2VehFleetNumber}`} 

								</td>
							</tr>
							<tr>
								<th>Run(s)</th>
								<td>
									{ 
										!data.PreStartRuns || data.PreStartRuns.length === 0 ? '-' : (
											data.PreStartRuns.map(({ number }, index) => (
												`${index > 0 ? ' ,' : ''} ${number}`
											))
										) 
									}
								</td>
							</tr>
							<tr className={isThereTimeSpentProblem ? 'text-danger' : 'text-success'}>
								<th>Time Spent</th>
								<td>{DurationParse.toHourMinSec(data.lastPartTimeSpentInSec || data.firstPartTimeSpentInSec, 'short')}</td>
							</tr>
							{
								!isLastPreStartReport && [
									<tr key={0} className={securityIssues.length > 0 ? 'text-danger' : 'text-success'}>
										<th>Pre-Start Issues</th>
										<td>{securityIssues.length || 'OK'}</td>
									</tr>,
									<tr key={1} className={mechanicalIssues.length > 0 ? 'text-danger' : 'text-success'}>
										<th>Mechanical Issues</th>
										<td>{mechanicalIssues.length || 'OK'}</td>
									</tr>
								]
							}
							<tr className={FaultReports.length > 0 ? 'text-danger' : 'text-success'}>
								<th>Fault Reports</th>
								<td>{FaultReports.length || 'None'}</td>
							</tr>
							<tr className={VehicleDamages.length > 0  ? 'text-danger' : 'text-success'}>
								<th>Veh. Damages</th>
								<td>{VehicleDamages.length || 'None'}</td>
							</tr>
						</tbody>
					</Table>
				</Col>
			</Row> 
		</div>
	);
};

CheckoutSummary.propTypes = propTypes;
CheckoutSummary.defaultProps = defaultProps;

export default CheckoutSummary;