import groups from '../app/schemas/PermissionGroups';

import PalletLoadContainer from '../components/features/pallets/PalletLoad/PalletLoadContainer';
import PalletUnloadContainer from '../components/features/pallets/PalletUnload';

const authorizeRoot = [
	groups.ADMIN_STAFF_FULL, groups.ADMIN_STAFF_SIMPLE, 
	groups.WASHBAY_ADMIN, groups.WASHBAY_MECHANICS, groups.DEPOT_ADMIN,
	groups.SUPERVISORS, groups.KIOSK
];

export default {
	path: 'pallets',
	menuItem: { title: 'Pallets', icon: 'archive' },
	authorize: authorizeRoot,
	childRoutes: [
		{ 
			path: 'loads', 
			menuItem: { title: 'Loads', icon: 'download' }, 
			component: PalletLoadContainer,
		},
		{ 
			path: 'unloads', 
			menuItem: { title: 'Unloads', icon: 'upload' }, 
			component: PalletUnloadContainer,
		},
	]
};
