/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import CarOnFireImage from '../../app/assets/img/car_on_fire.png';
import MainLayout from './_MainLayout';
import { logout } from '../../app/config/auth.config';

const UncaughtError = () => (
	<MainLayout
		title="Error"
		color="red"
		text="Oops! Something went wrong :("
		image={CarOnFireImage}
	>
		We will work on fixing that right away. <br/>
		<a href="/">Click here</a> to try again or <a onClick={logout}>Sign Out</a>.
	</MainLayout>
);

export { UncaughtError };