import classNames from 'classnames';
import React from 'react';

interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
	text?: string;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

const Radio: React.FC<IProps> = props => {
	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (props.onChange) {
			props.onChange(e, e.target.value);
		}
	}

	return (
		<label className={classNames("radio-custom", { "radio-checked": props.checked })}>
			<input
				type="radio"
				id={props.id}
				name={props.id}
				value={props.value}
				checked={props.checked}
				onChange={handleChange}
			/>
			{props.text}
		</label>
	);
}

export { Radio };