export default {
	root: '/Worker',
	sendAccountDetailsByEmail: '/Worker/SendLoginDetailsEmail',
	exportReport: '/Worker/Report',
	byId: (azureId: string) => `/Worker/${azureId}`,
	checkUserExists: (user: string) => `/Worker/Azure/${user}/Exist`,
	checkTfnExists: (tfn: string) => `/Worker/Exist/TFN/${tfn}`,
	lastPreStart: (azureId: string) => `/Worker/${azureId}/LastPreStart`,
	photo: (azureId: string) => azureId ? `/Worker/${azureId}/Photo` /* /me */ : `/Worker/${azureId}/Photo`,
	lastVersionSeen: (azureId: string) => `/Worker/${azureId}/lastVersionSeen`,
	document: (azureId: string, fileId: number) => `/Worker/${azureId}/Document/${fileId}`,
	covidTest: (azureId: string, fileId: number) => `/Worker/${azureId}/Covid/${fileId}`,
	driverLicence: (azureId: string, driverLicenceId: number, type: 'front' | 'back') => `/Worker/${azureId}/DriverLicence/${driverLicenceId}/${type}`,
	quickPin: (azureId: string) => `/Worker/${azureId}/QuickPin`,
	resetPassword: (azureId: string) => `/Worker/${azureId}/ResetPassword`,
	sendEmail: (azureId: string) => `/Worker/${azureId}/SendEmail`,
	sendPin: (azureId: string) => `/Worker/${azureId}/SendPin`
};