import React, { Fragment } from 'react';
import { Modal, Button, Row } from 'react-bootstrap';
import { Loader, ErrorBox, Form, FieldGroup } from '../../../../common/uiElements';
import componentRequestHandler from '../../../../../app/api/helpers/componentRequestHandler';
import { IError } from '../../../../../app/models/Application';
import { IVehicleMaintenance, VehicleMaintenanceForm, VehicleMaintenancePOST, VehicleMaintenancePUT } from '../../../../../app/models/VehicleMaintenance';
import VehicleMaintenanceApi from '../../../../../app/api/VehicleMaintenanceApi';
import confirm from '../../../../../app/helpers/confirm';
import { DatePeriodInput, VehicleSelect } from '../../../../common/inputs';
import { IVehicleBasicInfo } from '../../../../../app/models/Vehicle';
import moment, { Moment } from 'moment';
import $ from 'jquery';

interface IProps {
	maintenanceId?: number;
	onClose: () => void;
	onCompleteSaving?: () => void;
}

interface IState {
	details: VehicleMaintenanceForm;
	isLoading: boolean;
	isSaving: boolean;
	error?: IError;
	errorSaving?: IError;
}

const validations = {
	fleetNumber: 'required',
	startTimeLocal: 'required',
	finishTimeLocal: 'required'
}

class VehicleMaintenanceModal extends React.Component<IProps, IState> {
	state = { 
		details: new VehicleMaintenanceForm(),
		isLoading: false,
		isSaving: false,
		error: undefined,
		errorSaving: undefined
	}

	componentDidMount() {
		this.fetchDetails();
	}

	fetchDetails = () => {
		const { maintenanceId } = this.props;
		if (!maintenanceId) return;

		const promise = () => VehicleMaintenanceApi.getDetails(maintenanceId);
		componentRequestHandler(this, promise, 'details')
			.then((details: IVehicleMaintenance) => {
				this.setState({
					details: new VehicleMaintenanceForm(details)
				})
			});
	}

	saveData = () => {
		const { maintenanceId, onCompleteSaving } = this.props;
		const { details } = this.state;

		let promise = () => VehicleMaintenanceApi.create(new VehicleMaintenancePOST(details));
		if (maintenanceId)
			promise = () => VehicleMaintenanceApi.update(maintenanceId, new VehicleMaintenancePUT(details));

		componentRequestHandler(this, promise, undefined, { 
			showSuccessNotification: true,
			loadingAttrName: 'isSaving',
			errorAttrName: 'errorSaving'
		})
			.then(onCompleteSaving);
	}

	cancelService = () => {
		const { maintenanceId, onCompleteSaving } = this.props;
		if (!maintenanceId) return;

		const promise = () => VehicleMaintenanceApi.delete(maintenanceId);
		componentRequestHandler(this, promise, undefined, { 
			showSuccessNotification: true,
			loadingAttrName: 'isSaving' ,
			errorAttrName: 'errorSaving' 
		})
			.then(onCompleteSaving);
	}

	handleChangePeriod = (startTime: Moment, finishTime: Moment) => {
		this.setState({
			details: {
				...this.state.details,
				startTimeLocal: startTime.format('YYYY-MM-DD HH:mm'),
				finishTimeLocal: finishTime.format('YYYY-MM-DD HH:mm')
			}
		})
	}

	handleChangeVehicle = (vehicle?: IVehicleBasicInfo) => {
		this.setState({
			details: {
				...this.state.details,
				fleetNumber: vehicle?.fleetNumber
			}
		})
	}
	
	handleSave = () => {
		if ($('#vehicle-maintenance-form').valid())
			this.saveData();
	}

	handleCancelService = () => {
		confirm('This Vehicle Maintenance Service will be cancelled. Do you wish to proceed?', this.cancelService);
	}

	renderBodyContent = () => {
		const { details, isLoading, isSaving, error, errorSaving } = this.state;

		if (isSaving || isLoading)
			return <Loader isSaving={isSaving} isLoading={isLoading} />;

		if (error)
			return <ErrorBox error={error} retryFunc={this.fetchDetails} />;

		return (
			<Fragment>
				{ errorSaving && <ErrorBox error={errorSaving} retryFunc={this.saveData} /> }
				<Form id="vehicle-maintenance-form" validations={validations}>
					<Row>
						<FieldGroup xs={4} label="Vehicle" required>
							{
								this.props.maintenanceId ? (
									<p>{ details.fleetNumber }</p>
								) : (
									<VehicleSelect 
										id="fleetNumber"
										value={details.fleetNumber}
										onChange={this.handleChangeVehicle}
									/>
								)
							}
							</FieldGroup>
						<FieldGroup xs={8} label="Period" required>
							<DatePeriodInput 
								showHours
								startDate={details.startTimeLocal ? moment(details.startTimeLocal) : ''}
								endDate={details.finishTimeLocal ? moment(details.finishTimeLocal) : ''}
								onChange={this.handleChangePeriod}
							/>
						</FieldGroup>
					</Row>
				</Form>
			</Fragment>
		)
	}

	render() {
		const { state, props } = this;

		return (
			<Modal
				show
				id="vehicle-maintenance-modal"
				backdrop="static"
				onHide={props.onClose}
				onEscapeKeyDown={props.onClose}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Book Maintenance Service
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{ this.renderBodyContent() }
				</Modal.Body>
				{
					!state.isSaving && (
						<Modal.Footer>
							<Button 
								bsStyle="success"
								className="pull-left"
								onClick={this.handleSave}
							>
								{ props.maintenanceId ? 'Save Changes' : 'Save Booking' }
							</Button>
							{
								props.maintenanceId && (
									<Button 
										bsStyle="danger"
										onClick={this.handleCancelService}
									>
										Cancel Service
									</Button>
								)
							}
							<Button onClick={props.onClose}>
								Close
							</Button>
						</Modal.Footer>
					)
				}
			</Modal>
		)
	}
}

export default VehicleMaintenanceModal;