import { IRunCustomer, IRunCustomerOrder, IRunShuttleTaskLink, RunCustomerOrderForm, RunShuttleTaskLinkForm } from './Run/Run';
import { getTaskUpdateReasonLabel, getVehicleExceedsStoreMaxPalletCapacity } from '../../components/features/roster/tasks/tasksHelper';
import moment from 'moment';
import { IVehicleDetailsForTask, VehicleTypes } from './Vehicle';
import { IWorkerForTaskOrRoster } from './Worker';
import { ICompanyBasicInfo } from './Company';

export const TaskTypes = Object.freeze({
	DELIVERY: 1,
	SUPERVISOR: 2,
	LOADER: 3,
	ADMIN: 4,
	OFFSIDER: 5,
	TRAINING: 6,
	INDUCTION: 7,
	MAINTENANCE: 9,
	YARD_DUTIES: 10,
	SHUTTLE: 13
});

export interface ITaskDetail {
	id: number;
	startTimeLocal: string;
	date?: string;
	startTime?: string;
	taskTypeId: number;
	startingDepotId?: number;
	startingDepotName?: string;
	runId?: number;
	runNumber?: string;
	runNumberCombined?: string;
	area?: string;
	budgetedKm?: number;
	budgetedTime?: number;
	isPreLoaded?: boolean;
	isCancelled?: boolean;
	actualFinishTimeLocal?: string;
	actualStartTimeLocal?: string;
	isStartTimeAdjustNeeded: boolean;
	subContractorAbn?: string;
	storePalletCapacityExceeded: boolean;
	mainVehFleetNumber?: string;
	trailer1FleetNumber?: string;
	trailer2FleetNumber?: string;
	isDriverNotTracked: boolean;
	isMainVehicleNotTracked: boolean;
	isTrailer1NotTracked: boolean;
	isTrailer2NotTracked: boolean;

	totalPalletsAU_estimates: number | null;
	totalPalletsT3_estimates: number | null;
	totalCartons_estimates: number | null;
	totalPalletsAU_actuals: number | null;
	totalPalletsT3_actuals: number | null;
	totalCartons_actuals: number | null;

	SubContractor?: ICompanyBasicInfo;
	MainVehicle?: IVehicleDetailsForTask,
	Trailer1?: IVehicleDetailsForTask,
	Trailer2?: IVehicleDetailsForTask,
	Worker?: IWorkerForTaskOrRoster;
	TaskType?: ITaskType;
	RunCustomers?: IRunCustomer[];
	RunCustomerOrders?: IRunCustomerOrder[];
	RunShuttleTaskLinks?: IRunShuttleTaskLink[];
}

export interface ITaskListItem {
	id: number;
	startTimeLocal: string;
	startingDepotId: number;
	startingDepotName: string;
	taskTypeId: number;
	isCancelled: boolean;
	runId: number;
	runNumber: string;
	area: string;
	budgetedKm: number;
	subContractorAbn?: any;
	storePalletCapacityExceeded: boolean;
	budgetedTime: number;
	isPreLoaded: boolean;
	actualStartTimeLocal?: string;
	actualFinishTimeLocal?: string;
	isStartTimeAdjustNeeded: boolean;

	isDriverNotTracked: boolean;
	isMainVehicleNotTracked: boolean;
	isTrailer1NotTracked: boolean;
	isTrailer2NotTracked: boolean;
	
	totalStores: number | null;
	totalPalletsAU_estimates: number | null;
	totalPalletsT3_estimates: number | null;
	totalCartons_estimates: number | null;
	totalPalletsAU_actuals: number | null;
	totalPalletsT3_actuals: number | null;
	totalCartons_actuals: number | null;

	SubContractor?: ICompanyBasicInfo;
	TaskType: ITaskType;
	MainVehicle: IVehicleDetailsForTask;
	Trailer1: IVehicleDetailsForTask;
	Trailer2: IVehicleDetailsForTask;
	Worker?: IWorkerForTaskOrRoster;
}

export class TaskForm {
	id?: number;
	date: string;
	startTime: string;
	startTimeLocal: string;
	taskTypeId: string;
	runNumber: string;
	workerId: string;
	startingDepotId: string;
	startingDepotName?: string;
	area: string;
	budgetedKm: string;
	budgetedTime: string;
	isPreLoaded: boolean;
	mainVehFleetNumber: string;
	trailer1FleetNumber: string;
	trailer2FleetNumber: string;
	isDriverNotTracked: boolean;
	isMainVehicleNotTracked: boolean;
	isTrailer1NotTracked: boolean;
	isTrailer2NotTracked: boolean;
	runId: string;
	isCancelled: boolean;
	actualStartTimeLocal?: string;
	actualFinishTimeLocal?: string;
	isStartTimeAdjustNeeded: boolean;
	subContractorAbn?: string;
	storePalletCapacityExceeded: boolean;

	totalPalletsAU_estimates: number | null;
	totalPalletsT3_estimates: number | null;
	totalCartons_estimates: number | null;
	totalPalletsAU_actuals: number | null;
	totalPalletsT3_actuals: number | null;
	totalCartons_actuals: number | null;

	SubContractor?: ICompanyBasicInfo;
	MainVehicle?: IVehicleDetailsForTask;
	Trailer1?: IVehicleDetailsForTask;
	Trailer2?: IVehicleDetailsForTask;
	Worker?: IWorkerForTaskOrRoster;
	TaskType?: ITaskType;
	RunCustomers: IRunCustomer[];
	RunCustomerOrders: RunCustomerOrderForm[];
	RunShuttleTaskLinks: RunShuttleTaskLinkForm[];

	constructor(staffRosterTask?: ITaskDetail) {
		this.id = staffRosterTask?.id;
		this.date = staffRosterTask ? moment(staffRosterTask?.startTimeLocal).format('YYYY-MM-DD') : '';
		this.startTime = staffRosterTask ? moment(staffRosterTask.startTimeLocal).format('HH:mm') : '';
		this.startTimeLocal = staffRosterTask?.startTimeLocal || '';
		this.taskTypeId = staffRosterTask?.taskTypeId.toString() || '';
		this.runNumber = staffRosterTask?.runNumberCombined || '';
		this.runId = staffRosterTask?.runId?.toString() || '';
		this.workerId = staffRosterTask?.Worker?.azureId || '';
		this.startingDepotId = staffRosterTask?.startingDepotId?.toString() || '';
		this.startingDepotName = staffRosterTask?.startingDepotName;
		this.area = staffRosterTask?.area || '';
		this.budgetedKm = staffRosterTask?.budgetedKm?.toString() || '';
		this.budgetedTime = staffRosterTask?.budgetedTime?.toString() || '';
		this.isPreLoaded = staffRosterTask?.isPreLoaded || false;
		this.mainVehFleetNumber = staffRosterTask?.MainVehicle?.fleetNumber || '';
		this.trailer1FleetNumber = staffRosterTask?.Trailer1?.fleetNumber || '';
		this.trailer2FleetNumber = staffRosterTask?.Trailer2?.fleetNumber || '';
		this.isDriverNotTracked = staffRosterTask?.isDriverNotTracked || false;
		this.isMainVehicleNotTracked = staffRosterTask?.isMainVehicleNotTracked || false;
		this.isTrailer1NotTracked = staffRosterTask?.isTrailer1NotTracked || false;
		this.isTrailer2NotTracked = staffRosterTask?.isTrailer2NotTracked || false;
		this.actualStartTimeLocal = staffRosterTask?.actualStartTimeLocal;
		this.actualFinishTimeLocal = staffRosterTask?.actualFinishTimeLocal;
		this.isCancelled = staffRosterTask?.isCancelled || false;
		this.isStartTimeAdjustNeeded = staffRosterTask?.isStartTimeAdjustNeeded || false;
		this.subContractorAbn = staffRosterTask?.subContractorAbn || undefined;
		this.storePalletCapacityExceeded = staffRosterTask?.storePalletCapacityExceeded || false;

		this.totalPalletsAU_estimates = staffRosterTask?.totalPalletsAU_estimates || null;
		this.totalPalletsT3_estimates = staffRosterTask?.totalPalletsT3_estimates || null;
		this.totalCartons_estimates = staffRosterTask?.totalCartons_estimates || null;
		this.totalPalletsAU_actuals = staffRosterTask?.totalPalletsAU_actuals || null;
		this.totalPalletsT3_actuals = staffRosterTask?.totalPalletsT3_actuals || null;
		this.totalCartons_actuals = staffRosterTask?.totalCartons_actuals || null;

		this.SubContractor = staffRosterTask?.SubContractor;
		this.MainVehicle = staffRosterTask?.MainVehicle;
		this.Trailer1 = staffRosterTask?.Trailer1;
		this.Trailer2 = staffRosterTask?.Trailer2;
		this.Worker = staffRosterTask?.Worker;
		this.TaskType = staffRosterTask?.TaskType;
		this.RunCustomers = staffRosterTask?.RunCustomers || [{ runNumber: '', runTypeId: undefined }];
		this.RunCustomerOrders = staffRosterTask?.RunCustomerOrders?.map(order => new RunCustomerOrderForm(order)) || [new RunCustomerOrderForm()];
		this.RunShuttleTaskLinks = staffRosterTask?.RunShuttleTaskLinks?.map(link => new RunShuttleTaskLinkForm(link.vehicleSection, link.id, link.Run_id)) || [];

		// If its a Shuttle Task and no RunShuttleTaskLinks is present
		if (staffRosterTask?.RunCustomers?.some(rc => rc.runTypeIsShuttle) && this.RunShuttleTaskLinks.length === 0) {
			// If there's a main vehicle and its not a prime mover, add a Main Vehicle link
			if (this.MainVehicle && this.MainVehicle.typeId !== VehicleTypes.PRIME_MOVER)
				this.RunShuttleTaskLinks.push(new RunShuttleTaskLinkForm(0))
			else {
				if (this.trailer1FleetNumber)
					this.RunShuttleTaskLinks.push(new RunShuttleTaskLinkForm(1))

				if (this.trailer2FleetNumber)
					this.RunShuttleTaskLinks.push(new RunShuttleTaskLinkForm(2))
			}
		}

	}
}

export class TaskPOST {
	startTimeLocal: string;
	taskTypeId: number;
	workerId?: string;
  startingDepotId: number;
	budgetedTime: number;
	runId?: number;
	subContractorAbn?: string;
	storePalletCapacityExceeded?: boolean;

	mainVehFleetNumber?: string;
  trailer1FleetNumber?: string;
	trailer2FleetNumber?: string;
	isDriverNotTracked: boolean;
	isMainVehicleNotTracked: boolean;
	isTrailer1NotTracked: boolean;
	isTrailer2NotTracked: boolean;
	area?: string;
	budgetedKm?: number;
	isPreLoaded?: boolean;

	RunCustomers?: IRunCustomer[];
	RunCustomerOrders?: IRunCustomerOrder[];
	RunShuttleTaskLinks?: IRunShuttleTaskLink[];
	
	constructor(task: TaskForm) {
		this.taskTypeId = parseInt(task.taskTypeId!);
		this.startingDepotId = parseInt(task.startingDepotId!);
		this.startTimeLocal = task.date + ' ' + task.startTime;
		this.budgetedTime = parseFloat(task.budgetedTime!);
		this.workerId = task.workerId;
		this.runId = task.runId ? parseInt(task.runId) : undefined;
		this.subContractorAbn = task.subContractorAbn;

		this.isDriverNotTracked = task.isDriverNotTracked;
		this.isMainVehicleNotTracked = task.isMainVehicleNotTracked;
		this.isTrailer1NotTracked = task.isTrailer1NotTracked;
		this.isTrailer2NotTracked = task.isTrailer2NotTracked;
		this.mainVehFleetNumber = task.mainVehFleetNumber;
		this.trailer1FleetNumber = task.trailer1FleetNumber;
		this.trailer2FleetNumber = task.trailer2FleetNumber;
		this.area = task.area;
		this.budgetedKm = task.budgetedKm ? parseInt(task.budgetedKm) : undefined;
		this.isPreLoaded = task.isPreLoaded;

		this.RunCustomers = task.RunCustomers;
		this.storePalletCapacityExceeded = task.RunCustomerOrders?.find(({ storeMaxPalletCapacity }) => 
			storeMaxPalletCapacity && getVehicleExceedsStoreMaxPalletCapacity(storeMaxPalletCapacity, task.MainVehicle, task.Trailer1, task.Trailer2)
		) !== undefined;
		this.RunCustomerOrders = task.RunCustomerOrders?.map((order, index) => ({
			...order,
			deliverySequence: index + 1,
			placeId: parseInt(order.placeId)
		})) || [];
		this.RunShuttleTaskLinks = task.RunShuttleTaskLinks?.map(link => ({
			...link,
			Run_id: link.Run_id ? parseInt(link.Run_id) : undefined
		})) || [];
	}
}

export class TaskPUT {
	id: number;
	startTimeLocal: string;
	workerId: string;
	startingDepotId: number;
	storePalletCapacityExceeded?: boolean;

	isDriverNotTracked: boolean;
	isMainVehicleNotTracked: boolean;
	isTrailer1NotTracked: boolean;
	isTrailer2NotTracked: boolean;
	subContractorAbn?: string;
	isCancelled?: boolean;
	isStartTimeAdjustNeeded?: boolean;
	budgetedKm?: number;
	budgetedTime: number;
	isPreLoaded?: boolean;
	mainVehFleetNumber?: string;
  trailer1FleetNumber?: string;
	trailer2FleetNumber?: string;

	UpdateReasons?: ITaskUpdateReason[];
	RunCustomerOrders?: IRunCustomerOrder[];
	RunShuttleTaskLinks?: IRunShuttleTaskLink[];
	
	constructor(task: TaskForm, UpdateReasons?: TaskUpdateReasonForm[]) {
		this.id = task.id!;
		this.startingDepotId = parseInt(task.startingDepotId!);
		this.startTimeLocal = task.date + ' ' + task.startTime;
		this.isCancelled = task.isCancelled;
		this.isStartTimeAdjustNeeded = false;
		this.budgetedKm = task.budgetedKm ? parseInt(task.budgetedKm) : undefined;
		this.budgetedTime = parseFloat(task.budgetedTime!);
		this.workerId = task.workerId!;
		this.subContractorAbn = task.subContractorAbn;
		this.isDriverNotTracked = task.isDriverNotTracked;
		this.isMainVehicleNotTracked = task.isMainVehicleNotTracked;
		this.isTrailer1NotTracked = task.isTrailer1NotTracked;
		this.isTrailer2NotTracked = task.isTrailer2NotTracked;
		this.isPreLoaded = task.isPreLoaded;
		this.mainVehFleetNumber = task.mainVehFleetNumber;
		this.trailer1FleetNumber = task.trailer1FleetNumber;
		this.trailer2FleetNumber = task.trailer2FleetNumber;
		this.storePalletCapacityExceeded = task.RunCustomerOrders?.find(({ storeMaxPalletCapacity }) => 
			storeMaxPalletCapacity && getVehicleExceedsStoreMaxPalletCapacity(storeMaxPalletCapacity, task.MainVehicle, task.Trailer1, task.Trailer2)
		) !== undefined;
		this.UpdateReasons = UpdateReasons?.map(p => ({
			updateReasonId: parseInt(p.updateReasonId),
			updateDescription: p.updateDescription
		}));
		this.RunCustomerOrders = task.RunCustomerOrders?.map((order, index) => ({
			...order,
			deliverySequence: index + 1,
			placeId: parseInt(order.placeId)
		})) || [];
		this.RunShuttleTaskLinks = task.RunShuttleTaskLinks?.map(link => ({
			...link,
			Run_id: link.Run_id ? parseInt(link.Run_id) : undefined
		})) || [];
	}
}

export class TaskCANCEL {
	updateReasonId: number;
	updateDescription: string;

	constructor(updateReasonId: string, updateDescription: string) {
		this.updateReasonId = parseInt(updateReasonId);
    this.updateDescription = updateDescription;
	}
}

export class TaskDELETE extends TaskCANCEL {}

export interface ITaskUpdateReason {
	updateReasonCategoryId?: number;
	updateReasonId: number;
	updateDescription: string;
}

export class TaskUpdateReasonForm {
	updateReasonCategoryId: string;
	updateReasonLabel?: string;
	updateReasonId: string;
	updateDescription: string;
	oldValue?: string;
	newValue?: string;

	constructor(updateReasonCategoryId: number, oldValue?: string, newValue?: string) {
		this.updateReasonCategoryId = updateReasonCategoryId.toString();
		this.updateReasonLabel = getTaskUpdateReasonLabel(updateReasonCategoryId);
		this.updateReasonId = '';
		this.updateDescription = '';
		this.oldValue = oldValue;
		this.newValue = newValue;
	}
}

export interface ITaskType {
	id: number;
	name: string;
	isRunRequired: boolean;
	isDriversLicenceRequired: boolean;
	canHaveMultipleRuns: boolean;
}


export const TaskUpdateReasonCategory = Object.freeze({
	VEHICLE: 1,
	WORKER: 2,
	TASK_CANCELLED_OR_DELETED: 3,
	START_TIME: 4,
	BUDGETED_TIME: 5,
	BUDGETED_KM: 6,
	MAX_STORE_PALLET_CAPACITY_EXCEEDED: 7
})

export class TasksListMainFilter {
  year?: number = moment().year();
  weekNumber?: number = moment().isoWeek();
  date? = moment().format('YYYY-MM-DD');
	startingDepotId?: string;
	startingDepotName?: string;
}

export class TasksListFilter {
  hoursRange = '';
  workerId = '';
  workerRoleId = '';
	workingStatus = '';
	workerCurrentCompanyAbn = '';
	startingDepotId: number[] = [];
	[key: string]: any; // eslint-disable-line
}