import React, { useEffect, useState } from 'react';
import PalletApi from '../../../../app/api/PalletApi';
import { IError, IReactSelectReturn } from '../../../../app/models/Application';
import { IPalletLoadListItem } from '../../../../app/models/Pallet';
import { PlacesListNew } from '../../../common/lists';
import { Callout, ErrorBox, Loader } from '../../../common/uiElements';
import PalletLoadBox from './PalletLoadBox';
import PalletLoadPanelSummary from './PalletLoadPanelSummary';
import { Location } from 'history';
import './PalletLoadPanel.css';
import FontAwesome from 'react-fontawesome';
import { orderBy } from 'lodash';
import PalletLoadDetailsModal from '../../pallets/PalletLoad/Details/PalletLoadDetailsModal';

interface IProps {
  location: Location & { query: { [key: string]: string } };
}

const PalletLoad: React.FC<IProps> = props => {
	const query = props.location.query;

	const [depotIdFilter, setDepotIdFilter] = useState(query.depotId || '');
	const [loadsList, setLoadsList] = useState<IPalletLoadListItem[] | undefined>(undefined)
	const [isLoading, setIsLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [modalLoadId, setModalLoadId] = useState('');
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [errorLoading, setErrorLoading] = useState<IError | undefined>(undefined);

	useEffect(() => {
		fetchData(true);
		// fetch every 2 minutes
		const interval = setInterval(fetchData, 1000 * 120);
		return () => clearInterval(interval);
	}, [])

	useEffect(() => {
		setTimeout(() => document.getElementsByTagName('body')[0].classList.add('sidebar-collapse'), 400);
	}, [])

	const fetchData = (showLoading?: boolean) => {
		if (showLoading)
			setIsLoading(true);
		else
			setIsRefreshing(true);

		PalletApi.loads.getLoadsListForPanel()
		.then(loads => setLoadsList(loads.filter(load => !load.loadCancelledOn)))
		.catch(setErrorLoading)
		.finally(() => {
			setIsLoading(false);
			setIsRefreshing(false)
		});
	}
	
	const handleChangeDepot = (selectedItem: IReactSelectReturn) => {
		setDepotIdFilter(selectedItem.value?.toString() || '');
	};

	const handleModalChanges = (id: string, show: boolean) => {
		setModalLoadId(id);
		setShowModal(show);
	}
	
	const filteredLoadsList = loadsList ? loadsList.filter(p => !p.loadCancelledOn && (!depotIdFilter || p.depotId.toString() === depotIdFilter)) : undefined;
	const loadsCompleted = orderBy(filteredLoadsList?.filter(p => p.loadFinishedOn) || [], ['loadFinishedOn'], ['desc']);
	const loadsInProgress = filteredLoadsList?.filter(p => !p.loadFinishedOn && p.fleetNumber && p.workerId) || [];
	return (
		<section className="content panel-live-monitoring">
			<div className="title">
				<span>Pallet Load - Live Monitoring</span>
				<PlacesListNew 
					value={depotIdFilter} 
					type="preStart"
					placeholder="All Depots"
					onChange={handleChangeDepot}
				/>
				{ isRefreshing && <Loader inline small /> }
			</div>
			{
				isLoading || errorLoading ? (
					errorLoading ? (
						<ErrorBox error={errorLoading} retryFunc={fetchData} />
					) : (
						<Loader isLoading={isLoading} />
					)
				) : (
					!filteredLoadsList || filteredLoadsList.length === 0 ? (
						<Callout title="NO LOADS TO SHOW" color="info" />
					) : (
						<div id="pallet-load-panel">
							<PalletLoadPanelSummary data={filteredLoadsList} />
							<div className="flex flex-column panel-row-container loads-panel-header">
								<div className="flex-1 flex panel-row">
									<span className="flex-2">RUN</span>
									<span className="flex-2">DEPOT</span>
									<span className="flex-1">DOCK</span>
									<span className="flex-2">TRAILER</span>
									<span className="flex-2">LOADED</span>
									<span className="flex-3">LOADER</span>
									<span className="flex-2">DURATION</span>
								</div>
							</div>
							<div className="loads-panel-body">
								{ 
									loadsInProgress.length === 0 ? (
										<Callout italicTitle title="NO LOADS IN PROGRESS AT THE MOMENT" color="info" />
									) : (
										loadsInProgress.map((load, index) => <PalletLoadBox key={index} data={load} onClick={(id) => handleModalChanges(id, true) }/>) 
									)
								}
								{
									loadsCompleted.length > 0 && (
										<>
											<FontAwesome name="arrow-down" className="divider" />
											{ loadsCompleted.map((load, index) => <PalletLoadBox key={index} data={load} onClick={(id) => handleModalChanges(id, true) } />) }
										</>
									)
								}
								{
									showModal && (
										<PalletLoadDetailsModal
											loadId={modalLoadId}
											onClose={() => setShowModal(false)}
											onCompleteSaving={() => handleModalChanges(modalLoadId, false)}
											onCompleteDeletion={() => handleModalChanges('', false)}
										/>
									)
								}
							</div>
						</div>
					)
				)
			}
		</section>
	)
}

export default PalletLoad;