export default {
	root: '/Vehicle',
	types: '/Vehicle/Types',
	damages: '/Vehicle/Damages',
	byId: (fleetNumber: string) => `/Vehicle/${fleetNumber}`,
	byIdOld: (fleetNumber: string) => `/Vehicle/Old/${fleetNumber}`,
	maintenance: (fleetNumber: string) => `/Vehicle/${fleetNumber}/Maintenance`,
	faultReports: (fleetNumber: string) => `/Vehicle/${fleetNumber}/FaultReport`,
	damagesById: (fleetNumber: string) => `/Vehicle/${fleetNumber}/Damages`,
	damagePhoto: (fleetNumber: string, damageId: number) => `/Vehicle/${fleetNumber}/DamagePhoto/${damageId}`,
	damageRepair: (damageId: number) => `/Vehicle/Damages/Repair/${damageId}`,
	currentDriver: (fleetNumber: string) => `/Vehicle/${fleetNumber}/DriverDetails`,
	updateOdometer: (fleetNumber: string, odometerValueInMeters: number) => `/Vehicle/${fleetNumber}/Odometer/${odometerValueInMeters}`,
	updateFreezerHours: (fleetNumber: string, freezerHoursValueInSec: number) => `/Vehicle/${fleetNumber}/FreezerHours/${freezerHoursValueInSec}`
};