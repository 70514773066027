import React, { useEffect, useState } from 'react';
import { TasksListFilter as tasksListFilterClass} from '../../../../../../app/models/Task';
import { HoursRange } from '../../../../../../app/models/Application';
import { ITaskDetail } from '../../../../../../app/models/Task';
import { Tabs, Tab, FormGroup } from 'react-bootstrap';
import { totalTasksByHoursRange } from '../../tasksHelper';
import './ListFilter.scss';

interface IProps extends tasksListFilterClass {
	tasksList?: ITaskDetail[];
	selectedDepotId?: string;
	hideHoursRangeFilter?: boolean;
	onChange?: Function;
	onClear?: Function;
}

const TasksListFilter: React.FC<IProps> = ({ 
	tasksList,
	selectedDepotId,
	hideHoursRangeFilter,
	hoursRange, 
	onChange,
	onClear
}) => {
	const [totalTasksMorning, setTotalRostersMorning] = useState(0);
	const [totalTasksAfternoon, setTotalRostersAfternoon] = useState(0);
	const [totalTasksEvening, setTotalRostersEvening] = useState(0);

	useEffect(() => {
		if (!tasksList) return;
		
		setTotalRostersMorning(totalTasksByHoursRange(tasksList, HoursRange.MORNING, selectedDepotId));
		setTotalRostersAfternoon(totalTasksByHoursRange(tasksList, HoursRange.AFTERNOON, selectedDepotId));
		setTotalRostersEvening(totalTasksByHoursRange(tasksList, HoursRange.EVENING, selectedDepotId));
	}, [tasksList, selectedDepotId])

	const handleSelect = (hoursRangeFilter: any) => {
		if (hoursRangeFilter === hoursRange) 
			return onClear && onClear();

		onChange && onChange('hoursRange', hoursRangeFilter);
	}

  return (
    <div className="roster-list-filter">
      {
				!hideHoursRangeFilter && (
					<FormGroup>
						<Tabs
							id="hoursRangeTab"
							justified
							bsStyle="pills"
							style={{ backgroundColor: 'white' }}
							activeKey={hoursRange}
							onSelect={handleSelect}
						>
							<Tab 
								eventKey={HoursRange.MORNING} 
								title={`00:00 - 12:00 (${totalTasksMorning})`}
							/>
							<Tab 
								eventKey={HoursRange.AFTERNOON} 
								title={`12:00 - 18:00 (${totalTasksAfternoon})`}
							/>
							<Tab 
								eventKey={HoursRange.EVENING} 
								title={`18:00 - 00:00 (${totalTasksEvening})`}
							/>
						</Tabs>
					</FormGroup>
				)
      }
    </div>
  );
};

export default TasksListFilter;
