/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Button, Modal, Table } from 'react-bootstrap';

// APIs
import WorkerApi from '../../../../../app/api/WorkerApi';
import { ContentBox, Callout, ErrorBox } from '../../../../common/uiElements';

const propTypes = {
/** To use when it comes from the SignalR */
	supervisorAnswer: PropTypes.shape({
		supervisorName: PropTypes.string.isRequired, 
		checkOutComments: PropTypes.string.isRequired,
		checkOutIsApproved: PropTypes.bool.isRequired
	}),

	/** The Pre-Start data */
	data: PropTypes.object,

	/** 
 * If the user will be disconnected while is reading the status
 * Used to show or hide the "showSignOutTimer" component
 */
	showSignOutTimer: PropTypes.bool
};

class PreStartApprovalStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showContactsModal: false,
			isLoadingSupervisor: false,
			supervisorName: '',
			checkOutComments: '',
			checkOutIsApproved: false
		};
	}

	UNSAFE_componentWillMount() {
		let { data, supervisorAnswer } = this.props;
		if (data) {
			let { checkOutComments, checkOutIsApproved } = data;
			return this.setState({
				checkOutComments,
				checkOutIsApproved
			}, () => { this.fetchSupervisorData(); });
		}
		else if (supervisorAnswer)
			return this.setState({ ...supervisorAnswer });
		else
			throw new Error('The supervisorAnswer or pre start data must be passed');

	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	fetchSupervisorData = () => {
		this.setState({ isLoadingSupervisor: true, error: null });
		
		let { checkOutById } = this.props.data;
		WorkerApi.getWorkerDetails(checkOutById)
			.then(supervisor => {
				const { dtoFullName } = supervisor;
				this.setState({
					isLoadingSupervisor: false,
					supervisorName: dtoFullName
				});
			})
			.catch(error => {
				console.error(error);
				this.setState({ error });
			});

	}

	handleCloseContactsModal = () => {
		this.setState({ showContactsModal: false });
	}

	renderAnswerTable = () => {
		let { isLoadingSupervisor, supervisorName, checkOutComments } = this.state;
		return (
			<table className="table table-bordered table-condensed">
				<tbody>
					<tr>
						<th width="80">Supervisor</th>
						<td>{isLoadingSupervisor ? 'Loading supervisor data...' : supervisorName}</td>
					</tr>
					<tr>
						<th width="80">Comment</th>
						<td>{checkOutComments || 'None'}</td>
					</tr>
				</tbody>
			</table>
		);
	}

	renderContactNumbersModal = () => {
		const contactNumbers = [
			{ name: 'Prospect Supervisors', number: '0413 138 781' },
			{ name: 'APS Line-haul', number: '0412 049 641' },
			{ name: 'APS Supervisors', number: '0412 656 648' },
			{ name: 'QLD Line-haul', number: '0414 995 518' },
			{ name: 'QLD Supervisor', number: '0412 151 244' }
		];

		return (
			<Modal show={this.state.showContactsModal} bsSize="small" onHide={this.handleCloseContactsModal}>
				<Modal.Header closeButton>
					<Modal.Title>Contact Numbers</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Table>
						<tbody>
							{
								contactNumbers.map(({name, number}) => (
									<tr key={number}>
										<th>{ name }</th>
										<td><a href={`tel:${number}`}>{ number }</a></td>
									</tr>
								))
							}
						</tbody>
					</Table>
				</Modal.Body>
				<Modal.Footer>
					<Button bsStyle="danger" onClick={this.handleCloseContactsModal}>Close</Button>
				</Modal.Footer>
			</Modal>
		)
	}

	render() {
		const s = this.state;
		
		return (
			<div>
				{
					s.error && <ErrorBox error={s.error} retryFunc={this.fetchSupervisorData} />
				}
				<ContentBox color={s.checkOutIsApproved ? 'success' : 'danger'}>
					{
						s.checkOutIsApproved ? (
							<div>
								<div className="text-success text-center pre-start__approval-message">
									<Icon name="check-circle" size="4x" />
									<h4><b>Your Pre-Start was approved</b></h4>
								</div>
								<br />
								{ this.renderAnswerTable() }
								<Callout color="success">
									You can commence your trip. Drive safe! :)
								</Callout>
							</div>
						) : (
							<div>
								<div className="text-danger text-center pre-start__deny-message">
									<Icon name="times-circle" size="4x" />
									<h4><b>Approval has not been granted</b></h4>
								</div>
								<br />
								{ this.renderAnswerTable() }
								<Callout title="Attention" icon="warning">
									Please, do not leave. Report to your supervisor immediately.
								</Callout>
							</div>
						)
					}
					{ this.renderContactNumbersModal() }
					<Button 
						block
						bsStyle="primary"
						onClick={() => this.setState({ showContactsModal: true })}
					>
						<Icon name="phone" />&nbsp;
						Contact Numbers
					</Button>
				</ContentBox>
			</div>
		);
	}
}

PreStartApprovalStatus.propTypes = propTypes;

export default PreStartApprovalStatus;