import React, { useEffect, useState } from 'react';
import { StaffRosterListFilter as StaffRosterListFilterClass, IStaffRosterListItem } from '../../../../../../app/models/StaffRoster';
import { HoursRange } from '../../../../../../app/models/Application';
import { Tabs, Tab, Button, FormGroup } from 'react-bootstrap';
import './ListFilter.scss';
import { totalRostersByHourRange } from '../../staffRosterHelper';

interface IProps extends StaffRosterListFilterClass {
	staffRosterList?: IStaffRosterListItem[];
	hideHoursRangeFilter?: boolean;
	onChange?: Function;
	onClear?: Function;
}

const StaffRosterListFilter: React.FC<IProps> = ({ 
	staffRosterList,
	hideHoursRangeFilter,
	hoursRange, 
	onChange,
	onClear
}) => {
	const [totalRostersMorning, setTotalRostersMorning] = useState(0);
	const [totalRostersAfternoon, setTotalRostersAfternoon] = useState(0);
	const [totalRostersEvening, setTotalRostersEvening] = useState(0);

	useEffect(() => {
		if (!staffRosterList) return;
		
		setTotalRostersMorning(totalRostersByHourRange(staffRosterList, HoursRange.MORNING));
		setTotalRostersAfternoon(totalRostersByHourRange(staffRosterList, HoursRange.AFTERNOON));
		setTotalRostersEvening(totalRostersByHourRange(staffRosterList, HoursRange.EVENING));
	}, [staffRosterList])

  return (
    <div className="roster-list-filter">
      {
				!hideHoursRangeFilter && (
					<FormGroup>
						<Tabs
							id="hoursRangeTab"
							justified
							bsStyle="pills"
							style={{ backgroundColor: 'white' }}
							activeKey={hoursRange}
							onSelect={(hoursRangeFilter: any) => onChange && onChange('hoursRange', hoursRangeFilter)}
						>
							<Tab 
								eventKey={HoursRange.MORNING} 
								title={`00:00 - 12:00 (${totalRostersMorning})`}
							/>
							<Tab 
								eventKey={HoursRange.AFTERNOON} 
								title={`12:00 - 18:00 (${totalRostersAfternoon})`}
							/>
							<Tab 
								eventKey={HoursRange.EVENING} 
								title={`18:00 - 00:00 (${totalRostersEvening})`}
							/>
						</Tabs>
					</FormGroup>
				)
      }
			{
				hoursRange && (
					<FormGroup>
						<Button 
							block
							bsStyle="warning"
							onClick={onClear as any}
						>
							Clear Hour Filter
						</Button>
					</FormGroup>
        )
			}
    </div>
  );
};

export default StaffRosterListFilter;
