import React from 'react';
import { Loader } from './common/uiElements';
import Logo from '../app/assets/img/logo.png';

const LoadingData = () => (
	<div className="loading-page">
		<img className="logo img-responsive" src={Logo} alt="Micway logo" />
		<div className="loader-box">
			<Loader />
			<i>Loading your data...</i>
		</div>
	</div>
);

export default LoadingData;