import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import Icon from 'react-fontawesome';
import { Row, Col, Button } from 'react-bootstrap';
import DurationParse from '../../../../../app/helpers/DurationParse';
import helper from '../helper';

import { QuickPinNeededModal } from '../../../../common/layout';
import CheckoutApproval from './CheckoutApproval';
import CheckoutDetails from './CheckoutDetails';
import CheckoutSummary from './CheckoutSummary';

import { Callout, UserDetailsLink, UserProfilePicture } from '../../../../common/uiElements';

const tabs = {
	SUMMARY: 'summary',
	DETAILS: 'details',
	APPROVAL: 'approval',
};

class CheckoutBox extends Component {
	static propTypes = {
		loggedUser: PropTypes.object,

		data: PropTypes.object,
		
		/** When the user enters or leaves the Checkout tab */
		onToggleCheckout: PropTypes.func,
		
		/** When the user enters or leaves the Checkout tab after it knows who is checking out */
		onToggleIsCheckingOut: PropTypes.func,

		/** When the user submit the checkout */
		onSubmitCheckout: PropTypes.func,
		
		/** If this Pre-start is being Checked Out by the current user */
		isBeingCheckedOut: PropTypes.bool,

		/** If the user is checking one Pre-Start, he can't Checkout any other */
		isCheckoutWithBlur: PropTypes.bool,

		/** Will hide action buttons such as "see details" */
		isReadOnly: PropTypes.bool
	}

	constructor(props) {
		super(props);
		this.state = {
			isSaved: false,
			currentTabActive: tabs.SUMMARY,
			inspectedOnSecs: 0,
			inactiveTimer: 240,
			showQuickPinModal: false,
			supervisorId: ''
		};

		this.handleChangeTab = (tab) => {
			const { preStartId } = this.props.data;
			const { loggedUser } = this.props;
			
			this.setState({ currentTabActive: tab }, () => {
				if (tab !== tabs.SUMMARY) {
					this.startInactiveTimer();
					this.props.onToggleCheckout(preStartId, true);
					if (!this.state.supervisorId && loggedUser.isElevationRequired) 
						this.setState({ showQuickPinModal: true });
					else {
						this.props.onToggleIsCheckingOut(preStartId, this.state.supervisorId || loggedUser.azureId, true);
					}
	
				} else {
					if (loggedUser.isElevationRequired)
						this.setState({ supervisorId: '' });

					this.clearInactiveTimer();
					this.props.onToggleCheckout(preStartId, false);
					this.props.onToggleIsCheckingOut(preStartId, loggedUser.azureId, false);
				}
			});
		};

		this.handleSubmitCheckout = () => {
			this.setState({ 
				isSaved: true 
			}, this.props.onSubmitCheckout);
		};

		this.handleSubmitQuickPin = (supervisorId) => {
			const { preStartId } = this.props.data;
			this.props.onToggleIsCheckingOut(preStartId, supervisorId, true);
			
			this.setState({ 
				showQuickPinModal: false,
				supervisorId,
			});
		};

		this.handleClosePinModal = () => {
			this.setState({ 
				showQuickPinModal: false, 
				supervisorId: '',
			}, this.handleChangeTab.bind(this, tabs.SUMMARY));
		};
	}
	
	UNSAFE_componentWillMount() {
		const { loggedUser } = this.props;
		const { preStart } = this.props.data;
		const { inspectedOnLocal, lastReportedOnLocal } = preStart;
		this.setState({ 
			supervisorId: loggedUser.isElevationRequired ? '' : this.props.loggedUser.azureId,
			inspectedOnSecs: moment().diff(moment(lastReportedOnLocal || inspectedOnLocal), 'seconds')
		}, this.startReportedOnTimer);
	}

	componentWillUnmount() {
		this.clearReportedOnTimer();
		this.clearInactiveTimer();

		if (this.props.isBeingCheckedOut) {
			const { preStartId } = this.props.data;
			this.props.onToggleCheckout(preStartId, false);
			this.props.onToggleIsCheckingOut(preStartId, this.state.supervisorId || this.props.loggedUser.azureId, false);
		}
	}

	startInactiveTimer = () => {
		this.clearInactiveTimer();
		if (this.state.currentTabActive === tabs.SUMMARY)
			return;

		this.inactiveTimer = setInterval(() => {
			this.setState({ inactiveTimer: this.state.inactiveTimer - 1 });
			if (this.state.inactiveTimer === 0) {
				this.clearInactiveTimer();
				this.handleClosePinModal();
			}
		}, 1000);
	}

	clearInactiveTimer = () => {
		window.clearInterval(this.inactiveTimer);
		this.setState({ inactiveTimer: 240 });
	}

	startReportedOnTimer = () => {
		this.sentTimer = setInterval(() => {
			this.setState({ inspectedOnSecs: this.state.inspectedOnSecs + 60 });
		}, 60000);
	}

	clearReportedOnTimer = () => {
		window.clearInterval(this.sentTimer);
	}

	render() {
		const { state, props } = this;
		
		const {
			securityIssues,
			mechanicalIssues,
			preStart,
		} = this.props.data;
		const allIssues = { securityIssues, mechanicalIssues };

		const isThereDangerProblem = helper.isThereAnyProblem(this.props.data);
		const isThereWarningProblem = (!preStart.lastReportedOnLocal && preStart.firstPartTimeSpentInSec < (10 * 60));

		return (
			<div className={classNames(
				'checkout-box', 
				{ 'blink': state.inspectedOnSecs > 60 * 7 && state.currentTabActive === tabs.SUMMARY },
				{ 'blur': this.props.isCheckoutWithBlur }
			)} 
			onClick={this.startInactiveTimer}
			>
				<div className={classNames('box', [`box-${isThereDangerProblem ? 'danger' : isThereWarningProblem ? 'warning' : 'success'}`]) }>
					{
						this.props.isCheckoutWithBlur && !state.isSaved && (
							<div className="blur-overlay"></div>
						)
					}
					{
						props.isLocked && (
							<div className="locked">
										Someone is already checking it.
							</div>
						)
					}
					<div className="box-header with-border">
						<div className="user-block">
							<UserProfilePicture azureId={preStart.reportedById} />
							<span className="username">
								<UserDetailsLink showPopover={false} azureId={preStart.reportedById}>
									{preStart.WorkerReportedBy.shortName}
								</UserDetailsLink>
							</span>
							<span className="description">
								{
									state.inspectedOnSecs < 60 ? (
										<i>Just now</i>
									) : (
										<i>{DurationParse.toHourMin(state.inspectedOnSecs, 'short')} ago</i>
									)   
								}
							</span>
							{
								preStart.lastReportedOnLocal && (
									<Icon name="sign-out" size="3x" title="Last Pre-Start Step" className="box-header-icon" />
								)
							}
						</div>
					</div>
					{
						state.isSaved ? (
							<div className="text-center" style={{padding: '63px 0px'}}>
								<svg width="150" height="150">
									<path id="check-animation" d="M10,50 l25,40 l95,-70" />
								</svg>
							</div>
						) : (
							<Fragment>
								<div className="box-body">
									{
										state.inactiveTimer < 60 && (
											<Callout>You seem to be inactive. This box will be unlocked in <b>{state.inactiveTimer} seconds</b>.</Callout>
										)
									}
									{ state.currentTabActive === tabs.SUMMARY && <CheckoutSummary data={preStart} {...allIssues} timeSpentInSec={state.timeSpentInSec} /> }
									{ state.currentTabActive === tabs.DETAILS && <CheckoutDetails data={preStart} {...allIssues} timeSpentInSec={state.timeSpentInSec} /> }
									{ 
										state.currentTabActive === tabs.APPROVAL && (
											<CheckoutApproval 
												whoIsApprovingId={state.supervisorId}
												data={preStart} 
												onSubmitCheckout={this.handleSubmitCheckout}
											/> 
										)
									}
								</div>
								{
									!props.isReadOnly && (
										<div className="box-footer">
											{
												state.currentTabActive === tabs.SUMMARY ? (
													<Row>
														<Col xs={12}>
														<Button 
															block
															bsStyle={isThereDangerProblem ? 'danger' : isThereWarningProblem ? 'warning' : 'success'}
															onClick={() => this.handleChangeTab(tabs.DETAILS)}>
															<Icon name="search" /> See Details
														</Button>
														</Col>
													</Row>
												) : (
													state.currentTabActive === tabs.APPROVAL ? (
														<Button 
															bsStyle="danger"
															onClick={() => this.handleChangeTab(tabs.DETAILS)}>
															<Icon name="arrow-left" /> Back
														</Button>
													) : (
														<Row>
															<Col xs={5}>
																<Button 
																	block
																	bsStyle="danger"
																	onClick={() => this.handleChangeTab(tabs.SUMMARY)}>
																	<Icon name="arrow-left" /> Back
																</Button>
															</Col>
															<Col xs={7}>
																<Button 
																	block
																	bsStyle="success" 
																	onClick={() => this.handleChangeTab(tabs.APPROVAL)}>
																	<Icon name="gavel" /> Checkout
																</Button>
															</Col>
														</Row>
													)
												)
											}
										</div>
									)
								}
							</Fragment>
						)
					}
				</div>
				<QuickPinNeededModal 
					show={state.showQuickPinModal} 
					onClose={this.handleClosePinModal}
					onSubmit={this.handleSubmitQuickPin}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => ({ loggedUser: state.user.data });

export default connect(mapStateToProps)(CheckoutBox);