/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import moment from 'moment';
import toastr from 'toastr';
import { VehicleTypes } from '../../../../app/models/Vehicle';
import { BatteryChargesList } from '../../../common/tracking';

import VehiclesApi from '../../../../app/api/VehiclesApi';
import { Tabs, Tab, Table, FormControl, Panel, Col, Button } from 'react-bootstrap';
import VehicleDriverDetails from './VehicleDriverDetails';
import mapsHelper from '../../../../app/helpers/maps';
import TrackingApi from '../../../../app/api/TrackingApi';
import DurationParse from '../../../../app/helpers/DurationParse';

const propTypes = {
	vehFleetNumber: PropTypes.string, 
	vehRego: PropTypes.string,
	vehTypeID: PropTypes.number,
	vehTypeName: PropTypes.string,
	vehState: PropTypes.string,
	vehMaker: PropTypes.string,
	vehModel: PropTypes.string,
	vehModelYear: PropTypes.number,
	vehVin: PropTypes.string,
	vehLowBatteryEventId: PropTypes.number,
	vehSetHasVehicleBattCharger: PropTypes.bool,
	trkUnitIsExternalPowerOff: PropTypes.bool,
	trkCollectedOnEpoch: PropTypes.number,
	trkSpeedKm: PropTypes.number,
	trkPositionLat: PropTypes.number,
	trkPositionLong: PropTypes.number,
	vehSetHasTempZone1Sen: PropTypes.bool,
	vehSetHasTempZone2Sen: PropTypes.bool,
	vehSetHasTempZone3Sen: PropTypes.bool,
	trkTempZone1: PropTypes.number,
	trkTempZone2: PropTypes.number,
	trkTempZone3: PropTypes.number,
	vehSetHasVehicleBattVoltSen: PropTypes.bool,
	trkVehicleBattVolt: PropTypes.number,
	vehSetHasDoorSen: PropTypes.bool,
	trkIsDoorOpen: PropTypes.bool,
	vehSetHasEngineSen: PropTypes.bool,
	trkIsEngineOn: PropTypes.bool,
	vehSetHasFreezerSen: PropTypes.bool,
	trkIsFreezerOn: PropTypes.bool,
	vehOdometerTotalMeter: PropTypes.number,
	vehFreezerOnTotalSec: PropTypes.number,
	breadcrumbsEnabled: PropTypes.bool,
	autoFitEnabled: PropTypes.bool,
	onCloseClick: PropTypes.func,
	onEnableAutoFit: PropTypes.func,
	onEnableBreadcrumbs: PropTypes.func,
	onShowTaskModal: PropTypes.func
};

class VehicleDetailsBox extends Component {
	constructor() {
		super();
		this.state = {
			activeTabKey: 1,
			vehOdometerTotalKms: 0,
			vehOdometerTotalKmsOriginal: 0,
			vehFreezerOnTotalHours: 0,
			vehFreezerOnTotalHoursOriginal: 0,
			showOdometerInput: false,
			showFreezerHoursInput: false,

			// Address
			isLoadingAddress: true,
			currentAddress: '',

			// Parked Time
			isLoadingParkedTime: true,
			parkedOn: '',
			parkedOnDuration: ''
		};
	}

	componentDidMount() {
		this.fetchCurrentAddress();
		this.fetchParkedTime();

		const { vehOdometerTotalMeter, vehFreezerOnTotalSec } = this.props;
		const vehOdometerTotalKms = (Math.round(vehOdometerTotalMeter) / 1000).toFixed(0);
		const vehFreezerOnTotalHours = (vehFreezerOnTotalSec / 3600).toFixed(0);
		this.setState({
			vehOdometerTotalKms,
			vehOdometerTotalKmsOriginal: vehOdometerTotalKms,
			vehFreezerOnTotalHours,
			vehFreezerOnTotalHoursOriginal: vehFreezerOnTotalHours
		});
	}

	componentDidUpdate(prevProps) {
		const { trkCollectedOnEpoch, trkPositionLat, trkPositionLong } = this.props;
		if (trkPositionLat !== prevProps.trkPositionLat || trkPositionLong !== prevProps.trkPositionLong)
			return this.fetchCurrentAddress();
		else if (trkCollectedOnEpoch && trkCollectedOnEpoch !== prevProps.trkCollectedOnEpoch)
			return this.fetchParkedTime();

	}

	handleEditOdometer = () => {
		this.setState({ 
			showOdometerInput: true
		}, () => {
			this.odometerInput.focus();
		});
	}

	handleSaveOdometer = () => {
		this.setState({ showOdometerInput: false });
		const odometerValueInMeters = this.state.vehOdometerTotalKms * 1000;
		VehiclesApi.updateVehicleOdometer(this.props.vehFleetNumber, odometerValueInMeters)
			.then(() => {
				toastr.success('The data was successfully saved', 'Success!');
				this.setState({ vehOdometerTotalKmsOriginal: this.state.vehOdometerTotalKms });
			})
			.catch(error => {
				console.error(error);
				this.setState({ vehOdometerTotalKms: this.state.vehOdometerTotalKmsOriginal });
			});
	}

	handleEditFreezerHours = () => {
		this.setState({ 
			showFreezerHoursInput: true
		}, () => {
			this.freezerHoursInput.focus();
		});
	}

	handleSaveFreezerHours = () => {
		this.setState({ showFreezerHoursInput: false });
		const freezerHoursInSec = this.state.vehFreezerOnTotalHours * 60 * 60;
		VehiclesApi.updateFreezerHours(this.props.vehFleetNumber, freezerHoursInSec)
			.then(() => {
				toastr.success('The data was successfully saved', 'Success!');
				this.setState({ vehFreezerOnTotalHoursOriginal: this.state.vehFreezerOnTotalHours });
			})
			.catch(error => {
				console.error(error);
				this.setState({ vehFreezerOnTotalHours: this.state.vehFreezerOnTotalHoursOriginal });
			});
	}

	handleClickAutoFit = () => {
		this.props.onEnableAutoFit && this.props.onEnableAutoFit();
	}

	handleClickShowBreadcrumbs = () => {
		this.props.onEnableBreadcrumbs && this.props.onEnableBreadcrumbs();
	}

	fetchCurrentAddress = () => {
		const { trkPositionLat, trkPositionLong } = this.props;
		if (!trkPositionLat || !trkPositionLong)
			return;

		this.setState({ isLoadingAddress: true });

		mapsHelper.loadAddress(trkPositionLat, trkPositionLong)
			.then(currentAddress => {
				if (this.unmounted) return;

				this.setState({ currentAddress, isLoadingAddress: false });
			});
	}

	fetchParkedTime = () => {
		TrackingApi.parkedTimeByVeh(this.props.vehFleetNumber)
			.then(response => {
				if (this.unmounted || !response) return;
				
				const { startTimeSec, totalParkedSec } = response;
				this.setState({ 
					parkedOn: startTimeSec ? moment.unix(startTimeSec).format('DD/MM/YY HH:mm') : null,
					parkedOnDuration: totalParkedSec ? DurationParse.toHourMin(totalParkedSec, 'short') : null
				});
			});
	}

	renderRow = (title = '', value, withInput, id) => {
		return (
			<tr>
				<th>{ title }</th>
				{
					withInput ? (
						<td>
							<FormControl 
								value={this.state[id]}
							/>
						</td>
					) : (
						<td>{ value || '-' }</td>
					)
				}
			</tr>
		);
	}

	renderBatteryStatus = () => {
		const { 
			vehLowBatteryEventId, 
			vehSetHasVehicleBattCharger, 
			trkUnitIsExternalPowerOff
		} = this.props;

		if (vehLowBatteryEventId)
			return 'Low Battery';
		
		if (vehSetHasVehicleBattCharger && trkUnitIsExternalPowerOff)
			return 'Charging';

		return 'OK';
	};

	render() {
		const s = this.state;
		const p = this.props;

		const collectedOn = moment.unix(p.trkCollectedOnEpoch);
		const collectedOnFormatted = collectedOn.format('DD/MM/YY HH:mm');
		const collectedTimeInSec = moment.duration(moment.unix(p.trkCollectedOnEpoch).diff(moment())).asSeconds();
		const collectedTime = DurationParse.toHourMin(collectedTimeInSec, 'short');

		return (
			<div className="info-window">
				<Panel>
					<Icon 
						name="times" 
						className="close-button pull-right" 
						onClick={p.onCloseClick}
					/>
					<VehicleDriverDetails 
						vehFleetNumber={p.vehFleetNumber}
					/>
					<br />
					<Tabs 
						id="info-window"
						defaultActiveKey={1}
						animation={false}
					>
						<Tab eventKey={1} title="Tracking">
							<Table condensed>
								<tbody>
									{ this.renderRow('Collected On', <b className={(moment().diff(collectedOn, 'hours') > 4 ? 'text-danger ' : '')}>{collectedOnFormatted} ({collectedTime} ago)</b>) }
									{ this.renderRow('Speed', `${p.trkSpeedKm}Km/h`) }
									{ this.renderRow('Latitude', p.trkPositionLat ? p.trkPositionLat.toFixed(8) : '-') }
									{ this.renderRow('Longitude', p.trkPositionLong ? p.trkPositionLong.toFixed(8) : '-') }
									{ this.renderRow('Last Address', s.isLoadingAddress ? 'Loading...' : s.currentAddress) }
									{ s.parkedOn ? this.renderRow('Parked On', s.parkedOn) : null }
									{ s.parkedOnDuration ? this.renderRow('Parked Time', s.parkedOnDuration) : null }
									{ this.renderRow('CalAmp ID', p.vehDeviceId) }
									{ this.renderRow('CalAmp Number', p.vehDevicePhoneId) }
								</tbody>
							</Table>
						</Tab>
						<Tab eventKey={2} title="Vehicle">
							<Table condensed>
								<tbody>
									{ this.renderRow('Fleet', p.vehFleetNumber) }
									{ p.vehRego && this.renderRow('Rego', p.vehRego) }
									{ this.renderRow('Type', p.vehTypeName) }
									{ this.renderRow('State', p.vehState) }
									{ p.vehMaker && this.renderRow('Maker', p.vehMaker) }
									{ p.vehModel && this.renderRow('Model', p.vehModel) }
									{ p.vehModelYear && this.renderRow('Year', p.vehModelYear) }
									{ p.vehVin && this.renderRow('VIN', p.vehVin) }
								</tbody>
							</Table>
						</Tab>
						<Tab eventKey={3} title="Sensors">
							<Table condensed>
								<tbody>
									{ p.vehSetHasTempZone1Sen && (p.trkTempZone1 ? this.renderRow('Zone 1', p.trkTempZone1 + ' °C') : 'Unknown') }
									{ p.vehSetHasTempZone2Sen && (p.trkTempZone2 ? this.renderRow('Zone 2', p.trkTempZone2 + ' °C') : 'Unknown') }
									{ p.vehSetHasTempZone3Sen && (p.trkTempZone3 ? this.renderRow('Zone 3', p.trkTempZone3 + ' °C') : 'Unknown') }
									{ this.renderRow('Battery', this.renderBatteryStatus()) }
									{ p.vehSetHasVehicleBattVoltSen && this.renderRow('Battery Volt.', `${(p.trkVehicleBattVolt / 1000).toFixed(2)}V`) }
									{
										p.vehSetHasOdometerCounter && (
											<tr>
												<th>Odometer</th>
												{
													s.showOdometerInput ? (
														<td>
															<input 
																ref={ref => this.odometerInput = ref}
																type="number"
																className="input-sensors"
																value={s.vehOdometerTotalKms}
																onBlur={this.handleSaveOdometer}
																onKeyPress={e => e.key === 'Enter' && this.handleSaveOdometer() }
																onChange={e => this.setState({ vehOdometerTotalKms: e.target.value })}
															/>
															&nbsp;Km
														</td>
													) : (
														<td>
															<span 
																style={{ cursor: 'text' }}
																onClick={this.handleEditOdometer}
															>
																{ s.vehOdometerTotalKms } Km <Icon name="edit" />
															</span>
														</td>
													)
												}
											</tr>
										)
									}
									{
										p.vehSetHasFreezerOnCounter && (
											<tr>
												<th>Freezer Hours</th>
												{
													s.showFreezerHoursInput ? (
														<td>
															<input 
																ref={ref => this.freezerHoursInput = ref}
																type="number"
																className="input-sensors"
																value={s.vehFreezerOnTotalHours}
																onBlur={this.handleSaveFreezerHours}
																onKeyPress={e => e.key === 'Enter' && this.handleSaveFreezerHours() }
																onChange={e => this.setState({ vehFreezerOnTotalHours: e.target.value })}
															/>
															&nbsp;hour(s)
														</td>
													) : (
														<td>
															<span 
																style={{ cursor: 'text' }}
																onClick={this.handleEditFreezerHours}
															>
																{ s.vehFreezerOnTotalHours } hour(s) <Icon name="edit" />
															</span>
														</td>
													)
												}
											</tr>
										)
									}
									{ p.vehSetHasDoorSen && this.renderRow('Door', p.trkIsDoorOpen ? 'Open' : 'Close') }
									{ p.vehSetHasEngineSen && this.renderRow('Engine', p.trkIsEngineOn ? 'On' : 'Off') }
									{ p.vehSetHasFreezerSen && this.renderRow('Freezer', p.trkIsFreezerOn ? 'On' : 'Off') }
								</tbody>
							</Table>
						</Tab>
						{
							VehicleTypes.PALLET_JACK === this.props.vehTypeID && (
								<Tab eventKey={4} title="Charges">
									<BatteryChargesList 
										vehFleetNumber={p.vehFleetNumber} 
										showFleet={false} 
										showVoltage={false}
										fetchWithPeriod={false}
									/>
								</Tab>
							)
						}
					</Tabs>
				</Panel>
				<div className="buttons-box">
					<Col xs={6}>
						<Button 
							block 
							bsSize="small"
							bsStyle={p.autoFitEnabled ? 'success' : 'default'}
							onClick={this.handleClickAutoFit}
						>
							<Icon name="search" /> Auto Fit
						</Button>
					</Col>
					<Col xs={6}>
						<Button 
							block 
							bsSize="small"
							bsStyle={p.breadcrumbsEnabled ? 'success' : 'default'}
							onClick={this.handleClickShowBreadcrumbs}
						>
							<Icon name="location-arrow" /> Breadcrumbs
						</Button>
					</Col>
				</div>
			</div>
		);
	}
}

VehicleDetailsBox.propTypes = propTypes;

export default VehicleDetailsBox;