import groups from '../app/schemas/PermissionGroups';

// Map
import FullMap from '../components/features/tracking/FullMap';
import VehiclesByPlace from '../components/features/tracking/VehiclesByPlace';

// Reports
import ReportExceptions from '../components/features/tracking/ReportExceptions';
import ReportMissingVehicles from '../components/features/tracking/ReportMissingVehicles';
import ReportJourney from '../components/features/tracking/ReportJourney';
import ReportJourneySteps from '../components/features/tracking/ReportJourneySteps';
import ReportPalletJackCharges from '../components/features/tracking/ReportPalletJackCharges';
import ReportPalletJackLowBatteries from '../components/features/tracking/ReportPalletJackLowBatteries';
import ReportSpeeding from '../components/features/tracking/ReportSpeeding';
import ReportOdometers from '../components/features/tracking/ReportOdometers';

// Geofence
import GeofenceContainer from '../components/features/tracking/Geofences';
import WorkerPermission from '../app/enums/WorkerPermission';

const authorizeRoot = [
	groups.ADMIN_STAFF_FULL, groups.ADMIN_STAFF_SIMPLE, 
	groups.WASHBAY_ADMIN, groups.WASHBAY_MECHANICS, groups.DEPOT_ADMIN,
	groups.SUPERVISORS, groups.KIOSK
];

export default {
	path: 'tracking',
	menuItem: { title: 'Tracking', icon: 'map-marker' }, 
	allowedPermissionId: WorkerPermission.ENABLE_FLEET_TRACKING,
	authorize: authorizeRoot,
	childRoutes: [
		{ 
			path: 'fullMap',
			indexRoute: { component: FullMap },
			menuItem: { title: 'Fleet Tracking', icon: 'globe' },
			authorize: authorizeRoot,
			allowedPermissionId: WorkerPermission.ENABLE_FLEET_TRACKING
		},
		{ 
			path: 'geofences',
			indexRoute: { component: GeofenceContainer },
			menuItem: { title: 'Geofences', icon: 'bullseye' },
			authorize: authorizeRoot
		},
		{ 
			path: 'reports',
			menuItem: { title: 'Reports', icon: 'line-chart' }, 
			authorize: authorizeRoot,
			childRoutes: [
				{ 
					path: 'journey(/:vehFleetNumber)', 
					menuItem: { title: 'Journey', icon: 'road' }, 
					component: ReportJourney,
				},
				{ 
					path: 'journeySteps(/:vehFleetNumber)', 
					menuItem: { title: 'Journey Steps', icon: 'list-ol' }, 
					component: ReportJourneySteps
				},
				{ 
					path: 'vehiclesByPlace', 
					menuItem: { title: 'Veh. By Place', icon: 'map-signs' }, 
					component: VehiclesByPlace,
				},
				{ 
					path: 'exceptions', 
					menuItem: { title: 'Exceptions', icon: 'warning' }, 
					component: ReportExceptions
				},
				{ 
					path: 'missingVehicles', 
					menuItem: { title: 'Missing Vehicles', icon: 'question-circle' }, 
					component: ReportMissingVehicles
				},
				{ 
					path: 'palletJackCharges(/:vehFleetNumber)', 
					menuItem: { title: 'Pallet - Charges', icon: 'plug' }, 
					component: ReportPalletJackCharges
				},
				{ 
					path: 'palletJackLowBatteries', 
					menuItem: { title: 'Pallet - Low Battery', icon: 'battery-1' }, 
					component: ReportPalletJackLowBatteries,
				},
				{ 
					path: 'speeding', 
					menuItem: { title: 'Speeding', icon: 'tachometer' }, 
					component: ReportSpeeding
				},
				{ 
					path: 'odometers', 
					menuItem: { title: 'Odometers', icon: 'bar-chart' }, 
					component: ReportOdometers
				},
			]
		},
	]
};