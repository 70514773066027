/* eslint-disable no-undef */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import AccessPointDetails from './AccessPointDetailsContainer';
import AccessPointWindows from './AccessWindowsContainer';
import CurfewWindowsContainer from './CurfewWindowsContainer';
import './styles.scss';

export default class AccessPointModal extends Component {
	static propTypes = {
		data: PropTypes.object,
		geofencePolygonArray: PropTypes.array.isRequired,
		geofencePolygonBounds: PropTypes.object.isRequired,
		placeCategoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		show: PropTypes.bool,
		onSave:  PropTypes.func,
		onClose: PropTypes.func
	}

	state = {
		currentTabKey: 1
	}

	handleClickSave = () => {
		this.setState({ currentTabKey: 1 }, this.accessPointDetailsForm.submit);
	}

	handleSubmit = () => {
		const AccessPointDetails = this.accessPointDetails.state.data;
		const AccessWindows = this.accessPointWindows.state.accessWindows;
		const AccessCurfews = this.accessPointCurfews.state.curfews;

		const AccessPointData = {
			...AccessPointDetails,
			AccessWindows,
			AccessCurfews,
			hasBeenEdited: true
		};

		this.props.onSave && this.props.onSave(AccessPointData);
	}

	render() {
		const { props } = this;
		
		return (
			<Modal bsSize="lg" show={props.show} onHide={props.onClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						{ props.data ? 'Edit' : 'Add New' } Access Point
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Tabs
						id="access_point_tab_container"
						justified
						animation={false}
						activeKey={this.state.currentTabKey}
						onSelect={currentTabKey => this.setState({ currentTabKey })}		
					>
						<Tab eventKey={1} title={<span><Icon name="info-circle" /> Details</span>}>
							<AccessPointDetails 
								ref={ref => this.accessPointDetails = ref}
								formRef={ref => this.accessPointDetailsForm = ref }
								data={props.data}
								placeCategoryId={props.placeCategoryId}
								geofencePolygonArray={props.geofencePolygonArray}
								geofencePolygonBounds={props.geofencePolygonBounds}
								onSubmit={this.handleSubmit}
							/>
						</Tab>
						<Tab eventKey={2} title={<span><Icon name="clock-o" /> Windows</span>}>
							<AccessPointWindows 
								ref={ref => this.accessPointWindows = ref}
								placeCategoryId={props.placeCategoryId}
								accessWindows={props.data && props.data.AccessWindows}
							/>
						</Tab>
						<Tab eventKey={3} title={<span><Icon name="calendar-times-o" /> Curfews</span>}>
							<CurfewWindowsContainer 
								ref={ref => this.accessPointCurfews = ref}
								curfews={props.data && props.data.AccessCurfews} 
							/>
						</Tab>
					</Tabs>
				</Modal.Body>
				<Modal.Footer>
					<Button className="pull-left" onClick={this.handleClickSave} bsStyle="success">Save</Button>
					<Button onClick={props.onClose} bsStyle="danger">Cancel</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}