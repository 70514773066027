/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import classNames from 'classnames';
import WorkerApi from '../../../app/api/WorkerApi';
import { UserProfilePicture, Loader } from './';
import componentRequestHandler from '../../../app/api/helpers/componentRequestHandler';
import { IWorkerBasicInfo } from '../../../app/models/Worker';
import { IError } from '../../../app/models/Application';
import { ErrorBox } from './ErrorBox';
import { Callout } from './Callout';
import './UserDetailsBox.scss';
import { UserDetailsLink } from './UserDetailsLink';
import { Well } from 'react-bootstrap';

interface IProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	azureId: string;
	workerData?: IWorkerBasicInfo;
}

interface IState {
	data?: IWorkerBasicInfo;
	isLoading: boolean;
	error?: IError;
}

class UserDetailsBox extends Component<IProps, IState> {
	unmounted = false;

	constructor(props: IProps) {
		super(props);
		this.state = {
			data: props.workerData,
			isLoading: false,
			error: undefined
		}
	}

	componentWillUnmount() {
		this.unmounted = true;    
	}

	componentDidUpdate(prevProps: IProps) {
		if (this.props.workerData && this.props.workerData !== prevProps.workerData)
			this.setState({ data: this.props.workerData });
		else if (!this.props.workerData && prevProps.azureId !== this.props.azureId)	
			this.fetchData();
	}	

	componentDidMount() {
		if (!this.props.workerData)
			this.fetchData();
	}
	
	fetchData = () => {	
		const promise = () => WorkerApi.getWorkerDetailsSimple(this.props.azureId);
		componentRequestHandler(this, promise, 'data');
	}

	render() {
		const { props, state } = this;

		if (state.isLoading)
			return <Loader isLoading text="Loading Employee Data. Please wait..." />;
		
		if (state.error)
			return <ErrorBox error={state.error} retryFunc={this.fetchData} />;

		if (!state.data)
			return (
				<Callout title="Data not available">
					It was not possible to retrieve the employee data
				</Callout>
			);

		
		const { shortName, currentRoleName, phoneNumber } = state.data! as IWorkerBasicInfo;
		
		return (
			<Well bsSize="small" className={classNames('user-details-box', props.className)}>
				<UserProfilePicture 
					azureId={props.azureId} 
					height={70} 
				/>
				<ul className="list-unstyled">
					<li>
						<UserDetailsLink 
							showPopover={false}
							azureId={props.azureId}
						>
							<b>{ shortName }</b>
						</UserDetailsLink>
					</li>
					<li>
						{
							phoneNumber ? (
								<a href={`tel:${phoneNumber}`}>{ phoneNumber }</a>
							) : ( 
								'No valid contact number registered'
							)
						}
					</li>
					<li>
						{ currentRoleName || 'No role assigned' }
					</li>
				</ul>
			</Well>	
		);
	}
}

export { UserDetailsBox };