import React from 'react';
import PropTypes from 'prop-types';

import ListsApi from '../../../app/api/ListsApi';
import ListContainer from './_ListContainer';

const propTypes = {
	placeCategoryId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	type: PropTypes.oneOf(['preStart', 'palletJackLiveMonitoring', 'rosterStartingDepot']).isRequired,
	id: PropTypes.string,
	name: PropTypes.string,
	bsSize: PropTypes.oneOf(['small', 'large']),
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	disabled: PropTypes.bool,
	showAllOption: PropTypes.bool,
	firstOptionText: PropTypes.string,
	onChange: PropTypes.func
};

const PlacesList = props => {
	let promise;

	switch (props.type) {
	case 'preStart':
		promise = ListsApi.getPreStartDepots;
		break;
	case 'palletJackLiveMonitoring':
		promise = ListsApi.getPalletJackLiveMonitoringDepots;
		break;
	case 'rosterStartingDepot':
		promise = ListsApi.getRosterStartingDepots;
		break;
	default:
		promise = ListsApi.getPlacesList.bind(this, props.placeCategoryId);
	}

	return (
		<ListContainer 
			{...props}
			promise={promise}
		/>
	);
};

PlacesList.propTypes = propTypes;

export { PlacesList };