import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Row, Col, FormGroup, FormControl, InputGroup } from 'react-bootstrap';
import StringMask from 'string-mask';
import '../style.css';

import VehiclesApi from '../../../../../../app/api/VehiclesApi';
import { RadioButtonList, LimitNumberInput } from '../../../../../common/inputs';
import { ErrorBox, Form, Loader } from '../../../../../common/uiElements';
import deviceHelper from '../../../../../../app/helpers/device';

const radioYesNoItems = [
	{ text: 'Yes', value: true }, 
	{ text: 'No', value: false }
];

const radioItemsGaugeLevel = [
	{ text: 'E', value: 0 }, 
	{ text: '1/4', value: 0.25 },
	{ text: '1/3', value: 0.33 },
	{ text: '1/2', value: 0.5 },
	{ text: '2/3', value: 0.66 },
	{ text: '3/4', value: 0.75 },
	{ text: 'F', value: 1 }
];

const propTypes = {
	data: PropTypes.shape({
		lastIsThereAnyIssue: PropTypes.bool,
		FaultReports: PropTypes.array,
	}).isRequired,
	onChange: PropTypes.func,
	isReadOnly: PropTypes.bool,
};

class DepotReturnInformation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			error: null,
			currentOdometerValueMeters: 0,
			mainFreezerCurrentValueSec: 0,
			trailer1FreezerCurrentValueSec: 0,
			trailer2FreezerCurrentValueSec: 0
		};

		this.validations = {
			lastOdometerStart: { required: true, min: 1 },
			lastMainFreezerHourStart: { required: true, min: 1 },
			lastTrailer1FreezerHourStart: { required: true, min: 1 },
			lastTrailer2FreezerHourStart: { required: true, min: 1 },
			lastColdestTemp: {
				required: true,
				number: true
			},
			lastWarmestTemp: {
				required: true,
				number: true
			},
			lastMainFuelLevel: 'required',
			lastTrailer1FuelLevel: 'required',
			lastTrailer2FuelLevel: 'required',
			lastAdBlueLevel: 'required',
			lastIsFuelCardAttached: 'required',
			lastHasTempProbeReturned: 'required',
			lastIsCabinClean: 'required'
		};

		this.handleChange = (e) => {
			const id = e.target.id;
			const value = e.target.value;
			return this.props.onChange && this.props.onChange({ [id]: value });
		};

		this.handleChangeRefrigeration = e => {
			const { id, value } = e.target;
			
			if (value.length > 3 || (isNaN(value) && value !== '-')) return;
            
			return this.props.onChange && this.props.onChange({ [id]: value });
		};

		this.handleChangeOdometer = (e) => {
			const id = e.target.id;
			const value = e.target.value;

			if (isNaN(value)) return;
			return this.props.onChange && this.props.onChange({ [id]: value * 1000 });
		};

		this.handleChangeFreezerHours = (e) => {
			const id = e.target.id;
			const value = e.target.value;

			if (isNaN(value)) return;
			return this.props.onChange && this.props.onChange({ [id]: value * 60 * 60 });
		};
	}

	UNSAFE_componentWillMount() {
		if (!this.props.isReadOnly)
			return this.fetchVehiclesData();
	}

	fetchVehiclesData = () => {
		let { data } = this.props;
		let { mainVehFleetNumber, trailer1VehFleetNumber, trailer2VehFleetNumber } = data;
		let promises = [];
		promises.push(VehiclesApi.getVehicleDetailsOld(mainVehFleetNumber));
		trailer1VehFleetNumber && promises.push(VehiclesApi.getVehicleDetailsOld(trailer1VehFleetNumber));
		trailer2VehFleetNumber && promises.push(VehiclesApi.getVehicleDetailsOld(trailer2VehFleetNumber));

		this.setState({ isLoading: true, error: null });
		axios.all(promises)
			.then(axios.spread((mainVeh, trailer1, trailer2) => {
				this.setState({ 
					isLoading: false,
					mainVehHasOdometerCounter: mainVeh && mainVeh.vehSetHasOdometerCounter,
					mainVehHasFreezerOnCounter: mainVeh && mainVeh.vehSetHasFreezerOnCounter,
					trailer1HasFreezerOnCounter: trailer1 && trailer1.vehSetHasFreezerOnCounter,
					trailer2HasFreezerOnCounter: trailer2 && trailer2.vehSetHasFreezerOnCounter,

					currentOdometerValueMeters: mainVeh && mainVeh.vehOdometerTotalMeter,
					mainFreezerCurrentValueSec: mainVeh && mainVeh.vehFreezerOnTotalSec,
					trailer1FreezerCurrentValueSec: trailer1 && trailer1.vehFreezerOnTotalSec,
					trailer2FreezerCurrentValueSec: trailer2 && trailer2.vehFreezerOnTotalSec
				});
			}))
			.catch(error => {
				console.error(error);
				this.setState({ isLoading: false, error });
			});
	}

	getGaugeLevel = value => {
		const item = radioItemsGaugeLevel.find(p => p.value === value);
		return item ? item.text : '-';
	}

	renderRadioLabel = field => {
		const label = radioYesNoItems.find(o => o.value === this.props.data[field]);
		return label ? label.text : '-';
	}

	render() {
		const s = this.state;
		const p = this.props;

		if (s.isLoading)
			return <Loader text="Loading vehicles data..."/>;

		if (s.error)
			return <ErrorBox error={s.error} retryFunc={this.fetchVehiclesData} />;

		return (
			<Form validations={this.validations}>
				<Row className="aligned-row">
					<Col xs={12} sm={4} md={4} lg={4}>
						<FormGroup>
							<label>Fleet Number</label>
							<p>{p.data.mainVehFleetNumber}</p>
						</FormGroup>
						<FormGroup>
							<label>Odometer *</label>
							{
								p.isReadOnly ? (
									<p>{ StringMask.apply((p.data.lastOdometerStart / 1000).toFixed(0), '###,###,###', { reverse: true }) } Km</p>
								) : (
									<LimitNumberInput 
										id="lastOdometerStart"
										elementId={p.data.mainVehFleetNumber}
										initialValue={s.currentOdometerValueMeters}
										type="kilometers"
										typeShort="KM"
										warningMaxDiff={s.mainVehHasOdometerCounter ? 100 : 1000} // 1000 KM in case it's a sub-contractor or the tracking device isn't capturing the odometer properly
										errorMaxDiff={s.mainVehHasOdometerCounter ? 15000 : undefined} // no error in case it's a sub-contractor or the tracking device isn't capturing the odometer properly
										value={p.data.lastOdometerStart}
										onChange={this.props.onChange}
									/>
								)
							}
						</FormGroup>
						<FormGroup className="pre-start__fuelLevel">
							<label>Fuel Level</label>
							{
								p.isReadOnly ? (
									<p>{ this.getGaugeLevel(p.data.lastMainFuelLevel) }</p>
								) : (
									<RadioButtonList 
										id="lastMainFuelLevel" 
										items={radioItemsGaugeLevel} 
										value={p.data.lastMainFuelLevel}
										onChange={this.handleChange}
									/>
								)
							}
						</FormGroup>
						<FormGroup className="pre-start__fuelLevel">
							<label>Ad Blue Level</label>
							{
								p.isReadOnly ? (
									<p>{ this.getGaugeLevel(p.data.lastAdBlueLevel) }</p>
								) : (
									<RadioButtonList 
										id="lastAdBlueLevel" 
										value={p.data.lastAdBlueLevel}
										items={radioItemsGaugeLevel} 
										onChange={this.handleChange}
									/>
								)
							}
						</FormGroup>
						{
							s.mainVehHasFreezerOnCounter && (
								<FormGroup>
									<label>Fridge Engine Hours *</label>
									{
										p.isReadOnly ? (
											<p>{ StringMask.apply((p.data.lastMainFreezerHourStart / 60 / 60).toFixed(0), '###,###,###', { reverse: true }) } hours</p>
										) : (
											<LimitNumberInput 
												id="lastMainFreezerHourStart"
												elementId={p.data.mainVehFleetNumber}
												initialValue={s.mainFreezerCurrentValueSec}
												type="hours"
												typeShort="hrs"
												warningMaxDiff={20}
												errorMaxDiff={1000}
												value={p.data.lastMainFreezerHourStart}
												onChange={this.props.onChange}
											/>
										)
									}
								</FormGroup>
							)
						}
					</Col>
					{
						p.data.trailer1VehFleetNumber && (
							<Col xs={12} sm={4} md={4} lg={4}>
								<hr className="visible-xs" />
								<FormGroup>
									<label>Trailer 1</label>
									<p>{p.data.trailer1VehFleetNumber}</p>
								</FormGroup>
								<FormGroup className="pre-start__fuelLevel">
									<label>Fuel Level</label>
									{
										p.isReadOnly ? (
											<p>{ this.getGaugeLevel(p.data.lastTrailer1FuelLevel) }</p>
										) : (
											<RadioButtonList 
												id="lastTrailer1FuelLevel" 
												items={radioItemsGaugeLevel} 
												value={p.data.lastTrailer1FuelLevel}
												onChange={this.handleChange}
											/>
										)
									}
								</FormGroup>
								{
									s.trailer1HasFreezerOnCounter && (
										<FormGroup>
											<label>Fridge Engine Hours *</label>
											{
												p.isReadOnly ? (
													<p>{ (p.data.lastTrailer1FreezerHourStart / 1000).toFixed(0) } hours</p>
												) : (
													<LimitNumberInput 
														id="lastTrailer1FreezerHourStart"
														elementId={p.data.trailer1VehFleetNumber}
														initialValue={s.trailer1FreezerCurrentValueSec}
														type="hours"
														typeShort="hrs"
														warningMaxDiff={20}
														errorMaxDiff={1000}
														value={p.data.lastTrailer1FreezerHourStart}
														onChange={this.props.onChange}
													/>
												)
											}
										</FormGroup>
									)
								}
							</Col>
						)
					}
					{
						p.data.trailer2VehFleetNumber && (
							<Col xs={12} sm={4} md={4} lg={4}>
								<hr className="visible-xs" />
								<FormGroup>
									<label>Trailer 2</label>
									<p>{p.data.trailer2VehFleetNumber}</p>
								</FormGroup>
								<FormGroup className="pre-start__fuelLevel">
									<label>Fuel Level</label>
									{
										p.isReadOnly ? (
											<p>{ this.getGaugeLevel(p.data.lastTrailer2FuelLevel) }</p>
										) : (
											<RadioButtonList 
												id="lastTrailer2FuelLevel" 
												items={radioItemsGaugeLevel} 
												value={p.data.lastTrailer2FuelLevel}
												onChange={this.handleChange}
											/>
										)
									}
								</FormGroup>
								{
									s.trailer2HasFreezerOnCounter && (
										<FormGroup>
											<label>Fridge Engine Hours *</label>
											{
												p.isReadOnly ? (
													<p>{ (p.data.lastTrailer2FreezerHourStart / 1000).toFixed(0) } hours</p>
												) : (
													<LimitNumberInput 
														id="lastTrailer2FreezerHourStart"
														elementId={p.data.trailer2VehFleetNumber}
														initialValue={s.trailer2FreezerCurrentValueSec}
														type="hours"
														typeShort="hrs"
														warningMaxDiff={20}
														errorMaxDiff={1000}
														value={p.data.lastTrailer2FreezerHourStart}
														onChange={this.props.onChange}
													/>
												)
											}
										</FormGroup>
									)
								}
							</Col>
						)
					}
				</Row>
				<hr />  
				{
					!p.isReadOnly && (
						<label>Refrigeration - throughout the run please check in Truck Monitoring and record the following:</label>       
					)
				}
				<Row>
					<Col xs={6}>
						<FormGroup>
							<label>Coldest Temp.</label>
							{
								p.isReadOnly ? (
									<p>{ p.data.lastColdestTemp } °C</p>
								) : (
									<InputGroup>
										<FormControl 
											id="lastColdestTemp"
											type={ deviceHelper.getOS() === 'iOS' ? 'text' : 'tel' }
											value={p.data.lastColdestTemp || ''}
											onChange={this.handleChangeRefrigeration}
										/>
										<InputGroup.Addon>°C</InputGroup.Addon>
									</InputGroup>
								)
							}
						</FormGroup>
					</Col>
					<Col xs={6}>
						<FormGroup>
							<label>Warmest Temp.</label>
							{
								p.isReadOnly ? (
									<p>{ p.data.lastWarmestTemp } °C</p>
								) : (
									<InputGroup>
										<FormControl 
											id="lastWarmestTemp"
											type={ deviceHelper.getOS() === 'iOS' ? 'text' : 'tel' }
											value={p.data.lastWarmestTemp || ''}
											onChange={this.handleChangeRefrigeration}
										/>
										<InputGroup.Addon>°C</InputGroup.Addon>
									</InputGroup>
								)
							}
						</FormGroup>
					</Col>
				</Row>
				<hr /> 
				<Row>
					<Col xs={12} sm={6} lg={4}>
						<FormGroup>
							<label>Is the fuel card attached?</label>
							{
								p.isReadOnly ? (
									<p>{ this.renderRadioLabel('lastIsFuelCardAttached') }</p>
								) : (
									<RadioButtonList 
										id="lastIsFuelCardAttached" 
										value={p.data.lastIsFuelCardAttached}
										items={radioYesNoItems} 
										onChange={this.handleChange}
									/>
								)
							}
						</FormGroup>
					</Col>
					<Col xs={12} sm={6} lg={4}>
						<FormGroup>
							<label>Has the temp. probe been returned?</label>
							{
								p.isReadOnly ? (
									<p>{ this.renderRadioLabel('lastHasTempProbeReturned') }</p>
								) : (
									<RadioButtonList 
										id="lastHasTempProbeReturned" 
										value={p.data.lastHasTempProbeReturned}
										items={radioYesNoItems} 
										onChange={this.handleChange}
									/>
								)
							}
						</FormGroup>
					</Col>
					<Col xs={12} sm={6} lg={4}>
						<FormGroup>
							<label>Is the cabin clean?</label>
							{
								p.isReadOnly ? (
									<p>{ this.renderRadioLabel('lastIsCabinClean') }</p>
								) : (
									<RadioButtonList 
										id="lastIsCabinClean" 
										value={p.data.lastIsCabinClean}
										items={radioYesNoItems} 
										onChange={this.handleChange}
									/>
								)
							}
						</FormGroup>
					</Col>
				</Row>
			</Form>
		);
	}
}

DepotReturnInformation.propTypes = propTypes;

export default DepotReturnInformation;