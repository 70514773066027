import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from 'react-fontawesome';
import TrackingApi from '../../../app/api/TrackingApi';
import DurationParse from '../../../app/helpers/DurationParse';
import { ErrorBox, Table, Loader } from '../uiElements';

const propTypes = {
	palletJackLowBatteriesData: PropTypes.array
};

class PalletJackLowBatteriesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			palletJackLowBatteriesData: null,
			isLoading: false,
			error: false
		};
	}
	
	componentWillUnmount() {
		this.unmounted = true;
	}
	
	UNSAFE_componentWillMount() {
		var { palletJackLowBatteriesData } = this.props;

		if (palletJackLowBatteriesData)
			return this.setState({ palletJackLowBatteriesData });
		else
			return this.fetchPalletJacksLowBattery();
	}

	fetchPalletJacksLowBattery = () => {
		this.setState({ isLoading: true, palletJackLowBatteriesData: null, error: null });

		TrackingApi.palletJackLowBattery()
			.then(palletJackLowBatteriesData => {
				if (this.unmounted) return;
			
				this.setState({ palletJackLowBatteriesData, isLoading: false });
			})
			.catch(error => {
				console.error(error);
				this.setState({ 
					error: 'It wasn\'t possible to retrieve the data. Try again or contact the TMS Support.', 
					isLoading: false 
				});
			});
	}

	render() {
		const s = this.state;
		const p = this.props;

		if (s.error)
			return <ErrorBox error={s.error} retryFunc={this.fetchPalletJacksLowBattery} />;

		if (s.isLoading)
			return <Loader text="Loading data..." />;

		return (
			<div>
				{
					s.palletJackLowBatteriesData && s.palletJackLowBatteriesData.length > 0 ? (
						<div>
							<Table 
								info={p.info} 
								lengthChange={p.lengthChange} 
								ordering={p.ordering} 
								pageLength={p.pageLength} 
								paging={p.paging} 
								searching={p.searching} 
								cssClass="table-hover"
							>
								<thead>
									<tr>
										<th title="Vehicle"><Icon name="truck" /> Vehicle</th>
										<th title="State"><Icon name="globe" /> State</th>
										<th title="On Charge"><Icon name="bolt" /> On Charge</th>
										<th title="Started At"><Icon name="clock-o" /> Started At</th>
										<th title="Message"><Icon name="warning" /> Message</th>
									</tr>
								</thead>
								<tbody>
									{
										s.palletJackLowBatteriesData.map((palletJackData, index) => {
											var startedAtTime = palletJackData.trkevStartedCollectedOnEpoch;
											var startedCharging = palletJackData.battChargeStartedOnEpoch;
											var totalSecondsCharging = moment.unix(moment().unix()).diff(moment.unix(startedCharging), 'seconds');
											return (
												<tr className="cursor-pointer" key={index}>
													<td>{palletJackData.vehFleetNumber}</td>
													<td>{palletJackData.vehState}</td>
													<td>
														{
															// If the pallet jack is on charge
															palletJackData.trkUnitIsExternalPowerOff ? (
																<span>
																	<b>
																		<Icon name="circle" className="text-success" /> Yes
																	</b> 
																	&nbsp;- {DurationParse.toHourMin(totalSecondsCharging, 'short')} now
																</span>
																	
															) : (
																<b>
																	<Icon name="circle" className="text-danger" /> No
																</b>
															)
														}
													</td>
													<td>{moment.unix(startedAtTime).format('DD/MM/YY HH:mm')}</td>
													<td>{palletJackData.trkevMessage}</td>
												</tr>
											);
										})
									}
								</tbody>
							</Table>
						</div>
					) : (
						<p><i>No data has been found</i></p>
					)
				}
			</div>
		);
	}
}

PalletJackLowBatteriesList.propTypes = propTypes;

export { PalletJackLowBatteriesList };