import React, { Fragment } from 'react';
import Icon from 'react-fontawesome';
import { MainContent } from '../../../common/layout';
import { ContentBox, ErrorBox } from '../../../common/uiElements';
import { IError, IListItem, ListRequestOptions, ListReturn } from '../../../../app/models/Application';
import { SortingRule} from 'react-table';
import componentRequestHandler from '../../../../app/api/helpers/componentRequestHandler';
import PalletLoadList from './List/PalletLoadList';
import PalletApi from '../../../../app/api/PalletApi';
import { Button, FormGroup } from 'react-bootstrap';
import { IPalletLoadListItem, PalletsListFilter as PalletLoadListClass } from '../../../../app/models/Pallet';
import PalletLoadListFilter from './List/PalletLoadListFilter';
import PalletLoadDetailsModal from './Details/PalletLoadDetailsModal';
import { IVehicleBasicInfo } from '../../../../app/models/Vehicle';

interface IState {
	filter: PalletLoadListClass;
	listRequestOptions: ListRequestOptions;
	listReturn: ListReturn<IPalletLoadListItem>
	selectedLoadId?: string;
	isLoading: boolean;
	error?: IError;
	isExporting: boolean;
	errorExporting: undefined;
}

class PalletLoadContainer extends React.Component<{}, IState> {
	state = {
		filter: new PalletLoadListClass(),
		listReturn: new ListReturn<IPalletLoadListItem>(),
		listRequestOptions: new ListRequestOptions(),
		selectedLoadId: undefined,
		isLoading: false,
		error: undefined,
		isExporting: false,
		errorExporting: undefined,
	}

	fetchList = () => {
		const { filter, listRequestOptions } = this.state;
		const promise = () => PalletApi.loads.getLoadsList(listRequestOptions, filter);
		componentRequestHandler(this, promise, 'listReturn');
	}

	changeFilter = (fieldId: string, value: any, fetch?: boolean) => {
		this.setState({
			listRequestOptions: new ListRequestOptions(),
			filter: {
				...this.state.filter,
				[fieldId]: value
			}
		}, () => fetch ? this.fetchList() : undefined )
	}

	changeListOption = (fieldId: string, value: any) => {
		this.setState({
			listRequestOptions: {
				...this.state.listRequestOptions,
				[fieldId]: value
			}
		}, this.fetchList)
	}

	handleChangePage = (page: number) => {
		this.changeListOption('page', page);
	};

	handleChangePageSize = (pageSize: number) => {
		this.changeListOption('pageSize', pageSize);
	};

	handleChangeSorted = (sorted: SortingRule[]) => {
		this.changeListOption('sorted', sorted);
	};

	handleChangePeriod = (startTimeLocal: string, finishTimeLocal: string) => {
		this.setState({
			listRequestOptions: new ListRequestOptions(),
			filter: {
				...this.state.filter,
				startTimeLocal,
				finishTimeLocal
			}
		}, this.fetchList)
	}

	handleChangeRunNumber = (runNumber?: string) => {
		this.changeFilter('runNumber', runNumber);
	}

	handleChangeLoader = (loader?: IListItem) => {
		this.changeFilter('workerId', loader?.id || '', true);
	}

	handleChangeFleetNumber = (vehicle?: IVehicleBasicInfo) => {
		this.changeFilter('fleetNumber', vehicle?.fleetNumber, true);
	}

	handleChangeStatus = (status?: string) => {
		this.changeFilter('status', status || '', true);
	}

	handleSelectRow = (selectedLoadId?: string) => {
		this.setState({ selectedLoadId })
	}

	handleCloseDetailsModal = () => {
		this.setState({ selectedLoadId: undefined })
	}

	handleCompleteSaving = (loadId: string) => {
		this.handleSelectRow(loadId);
	}

	handleCompleteDeletion = () => {
		this.setState({ selectedLoadId: undefined }, this.fetchList)
	}

	handleExport = () => {
		const { filter } = this.state;
		const promise = () => PalletApi.loads.getLoadsListReportFile({ ...filter });
		componentRequestHandler(this, promise, undefined, {
			loadingAttrName: 'isExporting',
			errorAttrName: 'errorExporting',
		})
	}

	render() {
		const { state } = this;
		
		return (
			<MainContent title="Pallets - Loads">
				{
					state.selectedLoadId && (
						<PalletLoadDetailsModal
							loadId={state.selectedLoadId}
							onClose={this.handleCloseDetailsModal}
							onCompleteSaving={this.handleCompleteSaving}
							onCompleteDeletion={this.handleCompleteDeletion}
						/>
					)
				}
				<ContentBox title="Filters">
					<PalletLoadListFilter 
						{...state.filter}
						onChangePeriod={this.handleChangePeriod}
						onChangeRunNumber={this.handleChangeRunNumber}
						onChangeLoader={this.handleChangeLoader}
						onChangeFleetNumber={this.handleChangeFleetNumber}
						onChangeStatus={this.handleChangeStatus}
						onPressEnter={this.fetchList}
					/>
				</ContentBox>
				{ state.errorExporting && <ErrorBox	error={state.errorExporting} retryFunc={this.handleExport} /> }
				<FormGroup>
					<Button
						block
						bsStyle="info"
						onClick={this.fetchList}
					>
						<Icon name="search" /> Search
					</Button>
				</FormGroup>
				<FormGroup>
					<Button
							bsStyle="success"
							onClick={this.handleExport}
							disabled={state.isExporting}
						>	
							{
								state.isExporting ? (
									<Fragment>
										<Icon name="spinner" spin /> Generating
									</Fragment>
								) : (
									<Fragment>Export XLS</Fragment>
								)
							}
					</Button>
				</FormGroup>
				{
					state.error ? (
						<ErrorBox 
							error={state.error} 
							retryFunc={this.fetchList}
						/>
					) : (
						<PalletLoadList 
							{...state.listRequestOptions}
							{...state.listReturn}
							isLoading={state.isLoading}
							onPageChange={this.handleChangePage}
							onPageSizeChange={this.handleChangePageSize}
							onSortedChange={this.handleChangeSorted}
							onSelectRow={this.handleSelectRow}
						/>
					)
				}
			</MainContent>
		)
	}
}

export default PalletLoadContainer;