/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from 'react-fontawesome';
import WorkerApi from '../../../../../app/api/WorkerApi';

const propTypes = {
	azureId: PropTypes.string.isRequired, 
	documentsList: PropTypes.arrayOf(
		PropTypes.shape({
			typeName: PropTypes.string.isRequired,
			description: PropTypes.string,
			number: PropTypes.string,
			expiryDate: PropTypes.string
		}).isRequired
	)
};

class WorkerDocumentsList extends Component {
	state = {
		showArchivedFiles: false
	};
    

	toggleArchivedFiles = () => {
		this.setState({ showArchivedFiles: !this.state.showArchivedFiles });
	}
	
	render() {
		const p = this.props;
		const s = this.state;
		
		var filesArray = [];
		if (p.documentsList) {
			p.documentsList.forEach(doc => {
				if (!doc.isArchived || s.showArchivedFiles) {
					const { typeId, typeName } = doc;
						
					var isAlreadyAdded = filesArray.map(function(x) {return x.typeId; }).indexOf(typeId) !== -1;
						
					if (!isAlreadyAdded)
						filesArray.push({ typeId, typeName, files: [] });
	
					var typeIdIndex = filesArray.map(function(x) {return x.typeId; }).indexOf(typeId);
					filesArray[typeIdIndex].files.push(doc);
				}
			});
		}

		return (
			<div className="worker-documents-list">
				{
					p.documentsList.length === 0 ? (
						<i>No Files uploaded.</i>
					) : (
						<div className="nav-tabs-custom">
							<ul className="nav nav-tabs">
								<li className="cursor-pointer" onClick={this.toggleArchivedFiles}>
									{
										s.showArchivedFiles ? (
											<a className="text-muted">
												<Icon name="eye-slash" /> Hide archiveds
											</a>
										) : (
											<a>
												<Icon name="eye" /> Show archiveds
											</a>
										)
									}
								</li>
								{
									filesArray.map((tab, index) => {
										return (
											<li className={index === 0 ? 'active' : ''} key={index}>
												<a href={('#' + tab.typeId)} data-toggle="tab" aria-expanded="true">{tab.typeName} ({tab.files.length})</a>
											</li>
										);
									})
								}
							</ul>
							<div className="tab-content">
								{
									filesArray.map((tab, index) => {
										return (
											<div className={('tab-pane ' + (index === 0 ? 'active' : ''))} id={tab.typeId} key={index}>
												<table className="table table-bordered table-condensed">
													<thead>
														<tr>
															<th>Type</th>
															<th>Description</th>
															<th>Number</th>
															<th>Expiry Date</th>
															<th>Download</th>
														</tr>
													</thead>
													<tbody>
														{
															tab.files.map((file, indexFile) => {
																var { typeName, description, number, expiryDate } = file;
																return (
																	<tr className={(file.isArchived ? 'text-muted' : '')} key={indexFile}>
																		<td>{typeName == '' ? '-' : typeName}</td>
																		<td>{description == '' ? '-' : description}</td>
																		<td>{number == '' ? '-' : number}</td>
																		<td>{expiryDate ? moment(expiryDate).format('DD/MM/YYYY') : '-'}</td>
																		<td width={40} className="text-center">
																			<a onClick={() => WorkerApi.getWorkerDocument(p.azureId, file.id)}>
																				<Icon name="download" />
																			</a>
																		</td>
																	</tr>
																);
															})
														}
													</tbody>
												</table>
											</div>
										);
									})
								}
							</div>
						</div>
					)
				}
			</div>
		);
	}
}

WorkerDocumentsList.propTypes = propTypes;

export default WorkerDocumentsList;