import { IListItem } from './../models/List';
/* eslint-disable eqeqeq */

import run from './endpoints/runEndpoints';
import httpRequests from './httpRequests';
import { IRun } from '../models/Run/Run';

export default {
	/**
	 * Gets a list with the runs
	 */
	getRunsList(date: string, startingDepotId?: number | string): Promise<IRun[]> {
		const endpoint = run.root;
		const filterString = JSON.stringify({ date, startingDepotId, pageSize: 1000, sorted: [], page: 0 });
		return httpRequests.get(endpoint, { filterString }).then(result => result.records);
	},

  /**
	* @param id Run ID
	*/
  getRunDetails(id: number): Promise<IRun> {
		const endpoint = run.byId(id);
		return httpRequests.get(endpoint);
	},
	
	/**
	* Creates a new Run
	* @param data Run Data
	*/
	createRun(data: any /* change ANY when create Run POST model*/ ) {
		const endpoint = run.root;
		return httpRequests.post(endpoint, data);
	},

	/**
	 * Get Stores by Run ID
	 */
	getStores(runId: number): Promise<IListItem[]> {
		const endpoint = run.storesByRunId(runId);
		return httpRequests.get(endpoint);
	}
};

