import React from 'react';
import moment from 'moment';
import ReactTable, { RowInfo, Column } from 'react-table';
import { IListComponentProps } from '../../../../../app/models/Application';
import DurationParse from '../../../../../app/helpers/DurationParse';
import { IPalletUnloadListItem } from '../../../../../app/models/Pallet';
import './PalletUnloadList.css';

const PalletUnloadList: React.FC<IListComponentProps<IPalletUnloadListItem>> = props => (
	<div id="pallet-unload-list-table">
		<ReactTable
			manual
			minRows={0}
			data={props.records}
			loading={props.isLoading}
			page={props.page}
			pages={props.totalPages}
			pageSizeOptions={props.pageSizeOptions}
			pageSize={props.pageSize}
			onPageChange={props.onPageChange}
			onPageSizeChange={props.onPageSizeChange}
			onSortedChange={props.onSortedChange}
			getTrProps={(state: any, rowInfo?: RowInfo) => {
				if (!rowInfo)
					return {};

				const unload = rowInfo.original as IPalletUnloadListItem;
				let colorClass = 'in-progress';

				if (unload.cancelledAt)
					colorClass = 'cancelled';
				else if (unload.finishedAt)
					colorClass = 'completed';

				return { className: colorClass };
			}}
			getTdProps={(state: any, rowInfo?: RowInfo, column?: Column) => {
				if (!rowInfo)
					return {};

				const unload = rowInfo.original as IPalletUnloadListItem;
				return {
					onClick: () => props.onSelectRow && props.onSelectRow(unload.id, rowInfo.index)
				}
			}}
			columns={[
				{
					Header: 'Date',
					accessor: 'startedAt',
					width: 70,
					Cell: row => moment.utc(row.value).local().format('DD/MM/YY'),
				},
				{
					Header: 'DoW',
					accessor: 'startedAt',
					width: 50,
					Cell: row => moment.utc(row.value).local().format('ddd'),
				},
				{
					Header: 'Depot',
					accessor: 'depot.name',
					width: 140
				},
				// {
				// 	Header: 'Run Number',
				// 	accessor: 'run.number',
				// 	width: 120
				// },
				{
					Header: 'Dock',
					accessor: 'dockNumber',
					width: 45,
					Cell: row => row.value || '-'
				},
				{
					Header: 'Trailer',
					accessor: 'fleetNumber',
					width: 55,
					Cell: row => row.value || '-'
				},
				{
					Header: 'Unloaded By',
					id: 'unloadedBy',
					width: 180,
					Cell: row => {
						const { unloadedBy } = row.original as IPalletUnloadListItem;

						if (unloadedBy.length === 1)
							return unloadedBy[0].name;

						return unloadedBy.map((loader, i) => (
							<span key={loader.id}>
								{ i > 0 && <br /> }
								{loader.name} ({loader.unloadPercentage}%)
							</span>
						));
					}
				},
				{
					id: 'pallets',
					Header: 'Shuttled',
					width: 65,
					className: 'text-bold',
					Cell: row => {
						const data = row.original as IPalletUnloadListItem;
						return (
							<>
								{(data.pallets && data.pallets.length) || 0}
							</>
						)
					}
				},
				{
					Header: 'E. LOSC',
					accessor: 'totalEmptyPalletsLoscam',
					width: 60,
					className: 'text-bold text-primary',
					Cell: row => row.value || 0
				},
				{
					Header: 'E. CHEP',
					accessor: 'totalEmptyPalletsChep',
					width: 60,
					className: 'text-bold text-danger',
					Cell: row => row.value || 0
				},
				{
					Header: 'E. T3',
					accessor: 'totalEmptyPalletsT3',
					width: 60,
					className: 'text-bold text-success',
					Cell: row => row.value || 0
				},
				{
					Header: 'W/ Crates',
					width: 70,
					className: 'text-bold',
					Cell: row => {
						const unload = row.original as IPalletUnloadListItem;
						return unload.totalPalletsWithCratesChep + unload.totalPalletsWithCratesLoscam;
					}
				},
				{
					Header: 'Total',
					width: 45,
					className: 'text-bold',
					Cell: row => {
						const unload = row.original as IPalletUnloadListItem;
						return unload.totalEmptyPalletsChep + unload.totalEmptyPalletsLoscam + unload.totalEmptyPalletsT3 
						+ unload.totalPalletsWithCratesChep + unload.totalPalletsWithCratesLoscam + (unload.pallets?.length || 0)
					}
				},
				{
					Header: 'Start',
					accessor: 'startedAt',
					width: 55,
					Cell: row => moment.utc(row.value).local().format('HH:mm'),
				},
				{
					Header: 'Finish',
					accessor: 'finishedAt',
					width: 55,
					Cell: row => row.value ? moment.utc(row.value).local().format('HH:mm') : '-'
				},
				{
					Header: 'Time Spent',
					id: 'status',
					width: 100,
					accessor: p => p,
					Cell: row => {
						const { startedAt, finishedAt, cancelledAt } = row.value as IPalletUnloadListItem;
						
						if (cancelledAt)
							return "-";

						const $loadStartTime = moment(startedAt);
						const $loadFinishTime = moment(finishedAt || Date.now());
						const totalTimeInSec = moment.duration($loadFinishTime.diff($loadStartTime)).asSeconds();
						return DurationParse.toHourMin(totalTimeInSec, 'short');
					}
				},
				{
					Header: 'Device',
					accessor: 'deviceName',
					Cell: row => row.value || '-',
					width: 115
				},
				{
					Header: 'Status',
					id: 'status',
					accessor: p => p,
					Cell: row => {	
						const { cancelledAt, finishedAt } = row.value as IPalletUnloadListItem;

						if (cancelledAt)
							return <b className="text-danger">Cancelled</b>;

						if (finishedAt)
							return <b className="text-success">Completed</b>;

						return <b className="text-info">In Progress</b>;
					}
				},
			]}
		/>
	</div>
);

export default PalletUnloadList;