/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import Icon from 'react-fontawesome';

import { Row, Col, Modal, Button } from 'react-bootstrap';
import WorkerDriverLicenceSchema from '../../../../../app/schemas/worker/WorkerDriverLicenceSchema.json';
import WorkerDriverLicenceDetails from './WorkerDriverLicenceDetails';

const propTypes = {
	id: PropTypes.string,
	data: PropTypes.array,
	editMode: PropTypes.bool,
	onChange: PropTypes.func,

	/** If true, it will keep the last data active from DB */
	mustHaveOneActive: PropTypes.bool,

	/** If true, the user won't be able to edit any already added data  */
	isEditable: PropTypes.bool,
	
	/** If true, the user won't be able to delete any already added data  */
	isRemovable: PropTypes.bool,

	/** If true, the user won't be able to active any already added data  */
	isActivatable: PropTypes.bool
};

const defaultProps = {
	id: 'WorkerDriverLicences',
	data: [WorkerDriverLicenceSchema],
	editMode: false,
	mustHaveOneActive: true
};


class WorkerDriverLicences extends Component {
	constructor() {
		super();
		this.state = {
			driverLicenceToModal: null
		};
	}

	UNSAFE_componentWillMount() {
		// Store the current active in case of need to keep at least one active
		if (this.props.mustHaveOneActive) {
			const currentActive = this.props.data.find(p => p.isActive);
			currentActive && this.setState({ currentActive });
		}

		return this.handleChange(this.props.data, true);
	}

	reorderDriverLicences = data => {
		return data.sortBy(o => [ -o.isActive, -new Date(o.expiryDate || moment()) ]);	
	}

	handleChange = (data, shouldOrder) => {
		var { id, onChange } = this.props;
		if (shouldOrder)
			data = this.reorderDriverLicences(data);

		if (onChange)
			onChange(data, id);
	}

	handleAddDriverLicence = () => {
		var { workerId, data } = this.props;
		var activeDriverLicence = data.filter(d => d.isActive);
		var dataToModal = { ...WorkerDriverLicenceSchema };

		if (activeDriverLicence.length > 0) {
			var { number, state, type, conditionsArray, conditionsDescription } = activeDriverLicence[0];
			dataToModal = {
				...dataToModal,
				number,
				type,
				state,
				conditionsArray,
				conditionsDescription
			};
		}
		return this.setState({ driverLicenceToModal: { workerId, ...dataToModal } });
	}

	handleToggleDeletedStatus = (indexDriverLicence) => {
		let { data } = this.props;
		const { currentActive } = this.state;
		
		if (data[indexDriverLicence].id)
			data[indexDriverLicence].isDeleted = !data[indexDriverLicence].isDeleted;
		else
			data.splice(indexDriverLicence, 1);

		const hasOneActive = data.find(p => p.isActive);
		if (!hasOneActive && currentActive) {
			let dataToBeActive = data.find(p => p.id === currentActive.id);
			dataToBeActive.isActive = true;
			dataToBeActive.isDeleted = false;
		}

		return this.handleChange(data);
	}

	validateDriverLicenceForm = () => {
		return window.$(this.driverLicenceModal).find('form').valid();		
	}

	handleChangeDataDriverLicence = (driverLicenceData) => {
		return this.setState({
			driverLicenceToModal: driverLicenceData
		});
	}

	handleSaveDriverLicenceChanges = () => {
		if (this.validateDriverLicenceForm()) {
			var { driverLicenceToModal } = this.state;
			var { data } = this.props;

			if (driverLicenceToModal.id || driverLicenceToModal.index >= 0) {
				let index = driverLicenceToModal.index;
				data[index] = driverLicenceToModal;
				this.handleChange(data, true);
			} else {
				data.push(driverLicenceToModal);
				this.handleSetDriverLicenceActive(data.length - 1);
			}

			this.handleCloseModal();
		}
	}

	handleSetDriverLicenceActive = (indexDriverLicence) => {
		var { data } = this.props;
		for (let i in data) {
			if (indexDriverLicence == i)
				data[i].isActive = true;
			else
				data[i].isActive = false;
		}

		return this.handleChange(data, true);
	}

	handleSetDriverLicenceInactive = (indexDriverLicence) => {
		var { data } = this.props;
		data[indexDriverLicence].isActive = false;

		return this.handleChange(data, true);
	}

	handleCloseModal = () => {
		this.setState({
			driverLicenceToModal: null
		});
	}

	renderDriverLicenceModal = () => {
		const { driverLicenceToModal } = this.state;
		const isReadOnly = this.props.readOnly || (!this.props.isEditable && driverLicenceToModal && driverLicenceToModal.id) ? true : false;

		return (
			<Modal bsSize="lg" show={driverLicenceToModal !== null} onHide={this.handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>Driver&apos;s Licence</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div ref={ref => this.driverLicenceModal = ref}>
						<Row>
							<Col xs={12}>
								<WorkerDriverLicenceDetails 
									id="driverLicenceToModal" 
									readOnly={isReadOnly} 
									data={ driverLicenceToModal } 
									onChange={this.handleChangeDataDriverLicence} 
								/>
							</Col>
						</Row>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{ !isReadOnly && <Button onClick={this.handleSaveDriverLicenceChanges} bsStyle="success">Save</Button> }
					<Button onClick={this.handleCloseModal} bsStyle="danger">{isReadOnly ? 'Close' : 'Cancel'}</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	renderDriverLicenceHeader = () => {
		
		return (
			<thead>
				<tr>
					<th>Number</th>
					<th>Type</th>
					<th>Expiry Date</th>
					<th>State</th>
					<th>Conditions</th>
					<th colSpan={3}></th>
				</tr>
			</thead>
		);
	}

	renderDriverLicenceRow = (driverLicence, indexDriverLicence) => {
		var { editMode, readOnly, isEditable, isRemovable, isActivatable } = this.props;
		var { id, number, type, expiryDate, state, conditionsArray, isActive, isDeleted } = driverLicence;
		return (
			<tr key={indexDriverLicence} className={classNames({'deleted': isDeleted})}>
				<td>{ number }</td>
				<td>{ type }</td>
				<td>{ moment(expiryDate).format('DD/MM/YYYY') }</td>
				<td>{ state }</td>
				<td>
					{
						!conditionsArray || JSON.parse(conditionsArray).length === 0 ? 'None' : (
							JSON.parse(conditionsArray).join(', ')
						)
					}
				</td>
				{ 
					editMode && isActivatable && !isActive && (
						<td width={80} className="text-center text-middle">
							{
								isDeleted ? (
									<span>-</span>
								) : (
									<Button
										disabled={isDeleted}
										bsStyle="primary" 
										bsSize="sm" 
										onClick={this.handleSetDriverLicenceActive.bind(this, indexDriverLicence)}
									>
											Set as current
									</Button>
								)
							}
						</td>
					)
				}
				{
					!readOnly && (isEditable || !id) && (
						<td width={40} className="text-center text-middle">
							{
								isDeleted ? (
									<span>-</span>
								) : (
									<a className="text-warning cursor-pointer"
										title="Edit" 
										onClick={() => { this.setState({ driverLicenceToModal: { ...driverLicence, index: indexDriverLicence } }); }}
									>
										<Icon name="edit" size="2x" />
									</a>
								)
							}
						</td>
					)
				}
				{
					!readOnly && (isRemovable || !id) && (
						<td width={40} className="text-center text-middle">
							<a className={`${isDeleted ? 'text-success' : 'text-danger'} cursor-pointer`} 
								title={`${isDeleted ? 'Undo' : 'Delete'}`} 
								onClick={() => { this.handleToggleDeletedStatus(indexDriverLicence);}}
							>
								<Icon fixedWidth name={isDeleted ? 'undo' : 'trash'} size="2x" />
							</a>
						</td>
					)
				}
				{
					!isEditable && !isDeleted && id && (
						<td width={40} className="text-center text-middle">
							<a className="text-primary cursor-pointer"
								title="View" 
								onClick={() => { this.setState({ driverLicenceToModal: { ...driverLicence, index: indexDriverLicence } }); }}>
								<Icon name="search" size="2x" />
							</a>
						</td>
					)
				}
			</tr>
		);
	}

	render() {
		const p = this.props;

		let indexDriverLicence = 0;
		if (p.readOnly && p.data.length === 0) {
			return (
				<i>{"No Driver's Licence has been uploaded."}</i>
			);
		}

		let activeDriverLicences = p.data.filter(d => d.isActive);
		let inactiveDriverLicences = p.data.filter(d => !d.isActive);
		let newDriverLicence = p.data.find(d => !d.id);

		return (
			<div>
				{ this.renderDriverLicenceModal() }
				{
					((p.editMode && (p.isActivatable || !newDriverLicence)) || (!p.readOnly && !p.editMode && p.data.length === 0)) && (
						<Button bsStyle="warning" onClick={this.handleAddDriverLicence}>
							<Icon fixedWidth name="plus" /> {"Add new Driver's Licence"}
						</Button>
					)
				}
				{
					p.data && p.data.length > 0 && (
						<Row>
							<Col xs={12}>
								<div>
									{ (p.readOnly || p.editMode) && <h3 className="title">Current</h3> }
									{
										activeDriverLicences.length === 0 ? (
											<i>{"No Driver's Licence has been activated yet."}</i>
										) : (
											<div className="table-responsive">
												<table className="table table-bordered">
													{ this.renderDriverLicenceHeader() }
													<tbody>	
														{
															activeDriverLicences.map(driverLicence => (													
																this.renderDriverLicenceRow(driverLicence, indexDriverLicence++)
															))
														}										
													</tbody>
												</table>
											</div>
										)
									}
									{
										inactiveDriverLicences.length > 0 && (
											<div>
												<h3 className="title">History</h3>
												<div className="table-responsive">
													<table className="table table-bordered table-condensed">
														{ this.renderDriverLicenceHeader() }
														<tbody>
															{
																p.data.filter(d => !d.isActive).map((driverLicence) => {													
																	return this.renderDriverLicenceRow(driverLicence, indexDriverLicence++);
																})
															}
														</tbody>
													</table>
												</div>
											</div>
										)
									}
								</div>
							</Col>
						</Row>
					)
				}
			</div>
		);
	}
}

WorkerDriverLicences.propTypes = propTypes;
WorkerDriverLicences.defaultProps = defaultProps;

export default WorkerDriverLicences;