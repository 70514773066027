import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { IPallet, PalletDetailsForm as PalletDetailsFormClass } from '../../../../../app/models/Pallet';
import FontAwesome from 'react-fontawesome';
import PalletDetailsForm from './PalletDetailsForm';
import $ from'jquery';
import { IReactSelectOption } from '../../../../../app/models/Application';
import cloneDeep from 'lodash/cloneDeep';

interface IProps {
	palletsList?: IPallet[];
	pallet: IPallet;
	isReadOnly?: boolean;
	isAddingNewPallet?: boolean;
	onClose: () => void;
	onSave: (pallet: PalletDetailsFormClass, palletsList?: IPallet[]) => void;
	onChangePalletList?: (pallets: IPallet[]) => void;
}

const PalletDetailsModal: React.FC<IProps> = (props) => {
	const [pallet, setPallet] = useState(cloneDeep(props.pallet));
	const [palletsList, setPalletsList] = useState<IPallet[] | undefined>(cloneDeep(props.palletsList?.filter(p => p.store?.id === pallet.store.id && p.palletId !== pallet.palletId && !p.isUpstacked)));

	const handleChangeInput = (id: string, value?: string) => {
		setPallet({ ...pallet, [id]: value });
	}

	const handleChangeStore = (store: any) => {
		setPallet({ ...pallet, store });
	}

	const handleChangeLoader = (loader: IReactSelectOption) => {
		setPallet({ ...pallet, loadedBy: { id: loader.value, name: loader.label } });
	}

	const handleChangeConsolidationType = (wasPalletTimberKept: boolean) => {
		setPallet({ ...pallet, wasPalletTimberKept });
	}

	const handleAddConsolidatedPallet = (consolidatedPallet: IPallet) => {
		const { isUpstackedWith } = pallet;
		setPallet({ ...pallet, isUpstackedWith: isUpstackedWith ? [...isUpstackedWith, consolidatedPallet] : [consolidatedPallet] });
	}

	const handleConsolidateExistingPallet = (consolidatedPallet: IPallet) => {
		// Set the state of the selected pallet to add the consolidated pallet to the isUpstackedWith list
		const palletToUpdate = palletsList?.find(p => p.palletId === consolidatedPallet.palletId);
		if (palletToUpdate) {
			palletToUpdate.isUpstacked = true;
			palletToUpdate.palletType = '';
			
			// If no pallets exist in the isUpstackedWith list, add the pallet to the list
			// Otherwise, copy the array and add the pallet to the list as well
			setPallet(prevPallet => ({
				...prevPallet,
				isUpstackedWith: prevPallet.isUpstackedWith
				? [...prevPallet.isUpstackedWith, palletToUpdate]
				: [palletToUpdate]
			}));
		}
	}
	
	const handleRemoveConsolidatedPallet = (consolidatedPallet: IPallet) => {
		const { isUpstackedWith } = pallet;
		// Remove the selected pallet from the isUpstackedWith list
		const newUpstackedWith = isUpstackedWith?.filter(p => p.palletId !== consolidatedPallet.palletId) || [];
		
		// Update the state of the selected pallet to remove the consolidated pallet
		setPallet({ ...pallet, isUpstackedWith: [...newUpstackedWith] });
		
		const palletToUpdate = consolidatedPallet;
		if (palletsList) { 
			palletToUpdate.isUpstacked = false;
			palletToUpdate.palletType = 'CHEP';
			palletToUpdate.locationInTrailer = 'L';

			if(!palletsList.find(p => p.palletId === palletToUpdate.palletId))
				setPalletsList([...palletsList, palletToUpdate]);
		}
	}
	
	const handleSaveConsolidatedPallet = (consolidatedPallet: IPallet) => {
		const { isUpstackedWith } = pallet;
		if (!isUpstackedWith) return;
		
		const palletIndex = isUpstackedWith.findIndex(p => p.palletId === consolidatedPallet.palletId);

		if (palletIndex === -1)
			return;

		isUpstackedWith[palletIndex] = { ...consolidatedPallet };
		setPallet({ ...pallet, isUpstackedWith });
	}

	const handleSave = () => {
		if (!$('#pallet-details-form').valid())
			return;

		props.onSave(pallet, palletsList?.filter(p => !p.isUpstacked));
	}

	return (
		<>
			<Modal
				show
				id="pallet-details-modal"
				onHide={props.onClose}
				onEscapeKeyDown={props.onClose}
			>
				<Modal.Header closeButton>
					<Modal.Title> Pallet Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<PalletDetailsForm
						palletsList={palletsList}
						isConsolidatedPallet={pallet.isUpstacked}
						isReadOnly={props.isReadOnly}
						isAddingNewPallet={props.isAddingNewPallet}
						pallet={pallet}
						onChangeInput={handleChangeInput}
						onChangeStore={handleChangeStore}
						onChangeLoader={handleChangeLoader}
						onChangeConsolidationType={handleChangeConsolidationType}
						onAddNewConsolidatedPallet={handleAddConsolidatedPallet}
						onConsolidateExistingPallet={handleConsolidateExistingPallet}
						onRemoveConsolidatedPallet={handleRemoveConsolidatedPallet}
						onSaveConsolidatedPallet={handleSaveConsolidatedPallet}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button 
						bsStyle="success"
						className="pull-left"
						bsSize="lg"
						onClick={handleSave}
					>
						<FontAwesome name="check" /> Save Changes
					</Button>
					<Button bsSize="lg" onClick={props.onClose}>
						<FontAwesome name="sign-out"/> Close Without Saving
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default PalletDetailsModal;