import React from 'react';
import { IWorkerForTaskOrRoster } from '../../../app/models/Worker';
import { Callout } from './Callout';
import moment from 'moment';

interface IProps {
	date: string;
	Worker: IWorkerForTaskOrRoster;
}

const LeaveRequestOverlapAlert: React.FC<IProps> = props => {
	if (!props.Worker?.LeaveRequest) 
		return null;

	const { absenceReasonTypeName, startDate, endDate, isApproved } = props.Worker.LeaveRequest;

	return (
		<Callout color={isApproved ? 'danger' : 'warning'} icon="warning" title={`${isApproved ? 'Approved' : 'Pending'} Leave Request`}>
			{ props.Worker.firstName} has { isApproved ? 'an' : 'a' } <b>{ isApproved ? 'approved' : 'pending' }</b> <b>{absenceReasonTypeName} Request</b> from <b>{moment(startDate).format('DD/MM')}</b> to <b>{moment(endDate).format('DD/MM')}</b> and might not be able to execute this task.
			&nbsp;Please check the employee's availability for this date.
		</Callout>
	)
};

export { LeaveRequestOverlapAlert };

