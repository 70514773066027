import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, FormGroup, FormControl } from 'react-bootstrap';

import FaultReportListContainer from '../../FaultReportListContainer';
import { YesNoRadio, VehicleSearch } from '../../../../../common/inputs';
import { Form } from '../../../../../common/uiElements';

const propTypes = {
	data: PropTypes.shape({
		mainVehFleetNumber:  PropTypes.string,
		trailer1VehFleetNumber:  PropTypes.string,
		trailer2VehFleetNumber:  PropTypes.string,
		lastIsThereAnyIssue: PropTypes.bool,
		FaultReports: PropTypes.array,
	}).isRequired,
	onChange: PropTypes.func
};

class FaultReports extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showEmptyFaultReportError: true,
			showPalletJackQuestion: false,
			hasUsedPalletJack: '',
		};

		this.validations = {
			lastIsThereAnyIssue: 'required',
			faultReportItems: 'required',
			hasUsedPalletJack: 'required'
		};
        
		this.messages = {
			faultReportItems: { required: 'You must add at least 1 Fault Report' }
		};

		this.handleChange = (e, val) => {
			const id = e.target.id;
			const value = val !== undefined ? val : e.target.value;
			return this.props.onChange && this.props.onChange({ [id]: value });
		};

		this.handleChangeFaultReport = (FaultReports) => {
			FaultReports = FaultReports.map(faultReport => {
				faultReport.isLastPreStartReport = true;
				return faultReport;
			});
			return this.props.onChange && this.props.onChange({ FaultReports });
		};

		this.handleChangeUsedPalletJack = (e, val) => {
			const id = e.target.id;
			const value = val !== undefined ? val : e.target.value;

			if (value === false)
				this.props.onChange && this.props.onChange({ palletJackVehFleetNumber: '' });

			this.setState({ [id]: value });
		};
	}

	UNSAFE_componentWillMount() {
		const { data } = this.props;
		this.setState({
			showPalletJackQuestion: data.palletJackVehFleetNumber ? false : true
		});
	}

	render() {
		const s = this.state;
		const p = this.props;
		const fleets = [];
		fleets.push(p.data.mainVehFleetNumber);
		p.data.trailer1VehFleetNumber && fleets.push(p.data.trailer1VehFleetNumber);
		p.data.trailer2VehFleetNumber && fleets.push(p.data.trailer2VehFleetNumber);
		p.data.dollyVehFleetNumber && fleets.push(p.data.dollyVehFleetNumber);

		const FaultReportsLast = p.data.FaultReports && p.data.FaultReports.filter(f => f.isLastPreStartReport);
		return (
			<Form validations={this.validations} messages={this.messages}>
				<label>This is the last part of the pre-start check for the following fleet:</label>
				<p>{ fleets.join(', ') }</p>
				<hr />
				{
					s.showPalletJackQuestion && (
						<Row>
							<Col xs={12}>
								<FormGroup>
									<label>Did you use a Pallet Jack?</label>
									<YesNoRadio 
										id="hasUsedPalletJack"
										value={s.hasUsedPalletJack}
										onChange={ this.handleChangeUsedPalletJack }
									/>
								</FormGroup>
								{
									s.hasUsedPalletJack && (
										<FormGroup>
											<VehicleSearch 
												onlyActive
												withoutSearch
												id="palletJackVehFleetNumber" 
												className="required"
												value={p.data.palletJackVehFleetNumber || ''}
												vehTypeFilter={1}
												onSelectVehicle={ (vehicle, event) => this.handleChange(event) }
											/>
										</FormGroup>
									)
								}
							</Col>
						</Row>          
					)
				}
				<Row>
					<Col xs={12}>
						<FormGroup>
							<label>Is there any dashboard alarm present or any issue for the following?</label>
							<ul className="list">
								<li>Vehicle</li>
								<li>Pan</li>
								<li>Tailgate</li>
								<li>Fridge</li>
								<li>Pallet Jack</li>
							</ul>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<FormGroup>
							<YesNoRadio 
								id="lastIsThereAnyIssue" 
								value={p.data.lastIsThereAnyIssue}
								onChange={this.handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				{ p.data.lastIsThereAnyIssue && <h3 className="title">Add Fault Reports</h3> }
				{
					p.data.lastIsThereAnyIssue && (
						<div>
							<FaultReportListContainer 
								faultReportItems={FaultReportsLast}
								fleetNumbers={p.fleetNumbers} 
								onChange={this.handleChangeFaultReport}
								fetchData={false}
							/>
							{ 
								(!FaultReportsLast || FaultReportsLast.length === 0) && (
									<div className="text-center">
										<FormControl 
											id="faultReportItems" 
											type="hidden" 
											value={FaultReportsLast && FaultReportsLast.length > 0 ? 'hasFiles' : ''}
										/>
									</div>
								)
							}
						</div>
					)
				}
			</Form>
		);
	}
}

FaultReports.propTypes = propTypes;

export default FaultReports;