import React, { Fragment } from 'react';
import { IWorkerForTaskOrRoster } from '../../../app/models/Worker';
import { Popover, Overlay, OverlayTrigger } from 'react-bootstrap';
import { uniqueId } from 'lodash';
import moment from 'moment';

interface IProps {
	worker?: IWorkerForTaskOrRoster;
	taskStartTime?: string;
	show?: boolean;
	targetElementId?: string;
}

export const WorkerRosterStartTimeOverlayTrigger: React.FC<IProps> = props => {
	if (!props.worker?.Roster || !props.taskStartTime)
		return (
			<Fragment>
				{ props.children }
			</Fragment>
		);


	const title = (
		<b className="text-danger">
				Roster{"'"}s Start Time does not match Task{"'"}s Start Time
		</b>
	);

	const { Roster } = props.worker;

	const content = (
		<Popover title={title} id={'tooltip_roster_' + uniqueId()}>
			{ props.worker.firstName } is rostered to start at <b>{moment(Roster.startTimeLocal).format('HH:mm')}</b> but this task starts at <b>{moment(props.taskStartTime).format('HH:mm')}</b>.
			Please check the { props.worker.firstName }{"'"}s availability for this time and update their Roster{"'"}s Start Time.
		</Popover>
	)
	if (props.show && props.targetElementId)
		return (
			<div>
				{ props.children }
				<Overlay
					show
					placement="top" 
					target={() => document.getElementById(props.targetElementId!)}
				>
					{ content }
				</Overlay>
			</div>
		)

	return (
		<OverlayTrigger
			placement="top" 
			overlay={content}
		>
			{ props.children }
		</OverlayTrigger>
	)
}