import React from 'react';
import { GoogleMap, withGoogleMap } from 'react-google-maps';
import { GoogleMapProps } from 'react-google-maps/lib/components/GoogleMap';
import DrawingManager, { DrawingManagerProps } from 'react-google-maps/lib/components/drawing/DrawingManager';


interface IProps extends GoogleMapProps, Omit<DrawingManagerProps, 'options' | 'defaultOptions'> {
	refs?: any;
	height?: string | number;
	enableDrawing?: boolean;
	onOverlayComplete?: any;
	children?: React.ReactNode;
}

const GoogleMapContainer: React.FC<IProps> = props => (
	<Map
		{...props}
		containerElement={ <div style={{ height: props.height || 400 }} /> }
		mapElement={ <div style={{ height: '100%' }} /> }
	/>
);

const Map = withGoogleMap((props: IProps) =>
	<GoogleMap
		ref={props.refs}
		defaultZoom={props.defaultZoom || 11}
		defaultCenter={props.defaultCenter || new window.google.maps.LatLng(-33.8259137,151.1305758)}
		defaultOptions={props.defaultOptions}
		onClick={props.onClick}
		onDragEnd={props.onDragEnd}
		onZoomChanged={props.onZoomChanged}
	>
		{ props.children }
		{
			props.enableDrawing && (
				<DrawingManager
					onOverlayComplete={props.onOverlayComplete}
					drawingMode={props.drawingMode as any}
					defaultOptions={{
						drawingControl: true,
						drawingControlOptions: {
							position: window.google.maps.ControlPosition.TOP_CENTER,
							drawingModes: ['polygon'] as any
						},
						polygonOptions: {
							fillColor: '#00804d',
							fillOpacity: 0.2,
							strokeColor: '#000000',
							strokeOpacity: 0.9,
							strokeWeight: 2,
							clickable: true,
							editable: true,
							draggable: true,
							zIndex: 1
						},
					}}
				/>
			)
		}
	</GoogleMap>
);

export { GoogleMapContainer };