/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import driverLicenceTypes from '../../../app/store/DriverLicenceTypes.json';
import nationaties from '../../../app/store/NationalitiesList.json';
import relationships from '../../../app/store/RelationshipsList.json';
import states from '../../../app/store/States.json';

import { WorkerDocTypesList } from './';
import { ErrorBox } from '../uiElements';

const propTypes = {
	id: PropTypes.string,
	cssClass: PropTypes.string,
	dataType:  PropTypes.string.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired
};

const defaultProps = {
	cssClass: ''
};

class SelectInputWithData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			isLoading: true,
			value: '',
			error: null
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchData();
	}
  
	fetchData = () => {
		var data = [];
		switch (this.props.dataType) {
		case 'driverLicenceTypes':
			data = driverLicenceTypes;
			break;
		case 'nationalities':
			data = nationaties;
			break;
		case 'relationships':
			data = relationships;
			break;
		case 'states':
			data = states;
			break;
		default:
			break;
		}

		if (data)
			return this.setState({ data });
	}

	componentDidMount() {
		if (!this.state.data)
			this.setState({ error: 'Something went wrong.' });

		if (this.props.value)
			this.setState({ value: this.props.value });      

		this.setState({ isLoading: false });
	}

	componentDidUpdate() {
		var currentValue = this.state.value;
		var nextValue = this.props.value;
		if (currentValue != nextValue)
			this.setState({ value: nextValue });
	}

	handleChange = e => {
		var value = e.target.value;
		this.setState({ value }, this.props.onChange(e));
	}

	render() {
		const s = this.state;
		const p = this.props;

		if (p.dataType === 'workerDocTypesList')
			return (<WorkerDocTypesList cssClass={p.cssClass} id={p.id} onChange={this.handleChange} value={s.value}/>);

		return (
			<select id={p.id} className={('form-control ' + p.cssClass)} value={s.value} onChange={this.handleChange}>
				{
					s.isLoading ? (
						<option value="">Loading... Please wait.</option>
					) : (
						s.error ? (
							<ErrorBox error={s.error} retryFunc={this.fetchData} />
						) : ([
							<option key={-1} value="">--- SELECT ---</option>,
							s.data.map((item, index) => (
								<option key={index} value={item.value || item}>{item.text || item}</option>
							))
						])
					)
				}
			</select>
		);
	}
}

SelectInputWithData.propTypes = propTypes;
SelectInputWithData.defaultProps = defaultProps;

export { SelectInputWithData };